<template>
    <Popper
        ref="tooltipButton"
        :options="{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        padding: { top: 10, bottom: 10 },
                    },
                    boundariesElement: 'viewport',
                },
            ],
        }"
        placement="bottom"
        v-bind="$attrs"
        arrow
        hover
        class="light-popper calculated-price-popper"
        style="padding: 5px !important"
    >
        <template #content>
            <div class="popper-table">
                <div class="d-flex">
                    <div class="d-flex flex-column">
                        <div class="c-item invisible" style="padding: 3px 9px !important">X</div>
                        <div class="c-item" style="padding: 3px 9px !important" v-for="item in items" :key="item.key">{{ $t('list.product_search.tooltip.' + item.key) }}</div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="c-item" style="text-align: right; font-weight: 600 !important; padding: 3px 9px !important">{{ targetCurrency }}</div>
                        <div class="c-item text-danger" style="text-align: right; padding: 3px 9px !important" v-for="item in items" :key="item.key">{{ (+item.value).toFixed(2) }}</div>
                    </div>
                </div>
            </div>
        </template>
        <ExchangablePrice :oldValue="oldValue" calculated :price="price" :rate="rate" :to="to" :from="from" />
    </Popper>
</template>

<script>
import { countryInfo } from '@/utils/helper'
import { mapGetters } from 'vuex'
import ExchangablePrice from './ExchangablePrice.vue'

const countries = countryInfo()

export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        oldValue: {
            type: Number,
            default: 0,
        },
        price: {
            type: Number,
            default: 0,
        },
        to: {
            type: String,
            default: null,
        },
        from: {
            type: String,
            default: null,
        },
        rate: {
            type: Number,
            default: 1,
        },
    },
    data: function () {
        return {
            fromCurrency: null,
            toCurrency: null,
            fromPrice: null,
            toPrice: null,
        }
    },
    methods: {
        closeTooltip() {
            // Tooltip'i kapat
            this.$refs.tooltipButton.$el.dispatchEvent(new Event('mouseleave'))
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.closeTooltip, true)
    },
    mounted() {
        window.addEventListener('scroll', this.closeTooltip, true)
    },
    watch: {
        from: {
            handler: function (val, oldVal) {
                if (!val) return
                const currency = countries[val].symbol

                this.fromCurrency = currency
            },
            deep: true,
            immediate: true,
        },
        to: {
            handler: function (val, oldVal) {
                if (!val) return
                const currency = countries[val].symbol

                this.toCurrency = currency
            },
            immediate: true,
        },
        price: {
            handler: function (val, oldVal) {
                if (!this.to) return
                this.toPrice = val
                const currency = countries[this.to].symbol
                const toRate = this.marketExchangeRates.find((rate) => (rate.currency = currency))
                const to = toRate ? toRate.value : 1
                this.fromPrice = this.price / to
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters('settings', ['marketExchangeRates']),
        targetCurrency() {
            return countries[this.to].currency
        },
    },
    components: {
        ExchangablePrice,
    },
}
</script>

<style>
.price_from {
    border-top: 1px solid rgba(255, 166, 1, 0.232);
    padding: 5px;
    white-space: nowrap;
}
.price_to {
    padding: 5px;
    white-space: nowrap;
}
.calculated-price-popper .popper {
    padding: 2px !important;
    position: fixed !important;
}
</style>
