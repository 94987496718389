<template>
    <div>Redirecting...</div>
</template>

<script>
export default {
    name: 'AuthorizeCard',
    components: {},
    computed: {},
}
</script>
