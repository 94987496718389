<template>
    <div
        :class="{
            detailed_filters: detailed,
            accordion: !detailed,
        }"
        id="accordionExample"
    >
        <div
            :class="{
                'accordion-item p-2': !detailed,
                'detailed-item': detailed,
                'position-relative': true,
            }"
            style="border-top: none"
        >
            <div class="accordion-header" id="headingOne">
                <a :class="!detailed && 'accordion-button collapsed'" role="button" :data-bs-toggle="!detailed && 'collapse'" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    {{ $t('cards.filters.accordion.general') }}
                </a>
            </div>
            <div id="collapseOne" :class="!detailed && 'accordion-collapse collapse'" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row pt-4 pb-2">
                        <div class="col-12">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.words_in_title') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.words_in_title')"></InfoTooltip>
                            </label>
                            <div class="d-flex flex-row mb-2">
                                <input type="text" v-model="filter_options.words_in_title.words" :placeholder="$t('inputs.filters.placeholders.words_in_title')" class="sm-input form-control mr-1" />
                                <button class="dropdown btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                    {{ $t('actions.' + (filter_options.words_in_title.operator || 'included')) }}
                                </button>
                                <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                                    <a class="dropdown-item" href="#" @click.prevent="filter_options.words_in_title.operator = 'included'">{{ $t('actions.included') }}</a>
                                    <a class="dropdown-item" href="#" @click.prevent="filter_options.words_in_title.operator = 'excluded'">{{ $t('actions.excluded') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bordered-top pt-4 pb-2">
                        <div :class="detailed ? 'col-12' : 'col-6'">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fba_min_profit_and_rate') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fba_min_profit_and_rate')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input v-maska:[options] type="text" :placeholder="$t('inputs.filters.placeholders.profit')" v-model="filter_options.fba_profit" class="sm-input form-control" />
                                <span class="input-group-text">{{ currency }}</span>
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fba_profit_rate" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div :class="detailed ? 'col-12' : 'col-6'">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fba_min_net_profit_and_rate') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fba_min_net_profit_and_rate')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input v-maska:[options] type="text" :placeholder="$t('inputs.filters.placeholders.profit')" v-model="filter_options.fba_net_profit" class="sm-input form-control" />
                                <span class="input-group-text">{{ currency }}</span>
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fba_net_profit_rate" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fba_profit_margin') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fba_profit_margin')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fba_profit_margin" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fba_net_profit_margin') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fba_net_profit_margin')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fba_net_profit_margin" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row bordered-top pt-4 pb-2">
                        <div :class="detailed ? 'col-12' : 'col-6'">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fbm_min_profit_and_rate') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fbm_min_profit_and_rate')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input v-maska:[options] type="text" :placeholder="$t('inputs.filters.placeholders.profit')" v-model="filter_options.fbm_profit" class="sm-input form-control" />
                                <span class="input-group-text">{{ currency }}</span>
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fbm_profit_rate" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div :class="detailed ? 'col-12' : 'col-6'">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fbm_min_net_profit_and_rate') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fbm_min_net_profit_and_rate')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input v-maska:[options] type="text" :placeholder="$t('inputs.filters.placeholders.profit')" v-model="filter_options.fbm_net_profit" class="sm-input form-control" />
                                <span class="input-group-text">{{ currency }}</span>
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fbm_net_profit_rate" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fbm_profit_margin') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fbm_profit_margin')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fbm_profit_margin" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.fbm_net_profit_margin') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.fbm_net_profit_margin')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" :placeholder="$t('inputs.filters.placeholders.rate')" v-model="filter_options.fbm_net_profit_margin" class="sm-input form-control" />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row bordered-top pt-4 pb-4">
                        <div class="col-12 p-0">
                            <table class="table table-responsive" style="background-color: #f8fafd">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="white-space: break-spaces">
                                            {{ $t('inputs.filters.labels.target_price') }}
                                            <InfoTooltip :content="$t('inputs.filters.help_text.target_price')"></InfoTooltip>
                                        </th>
                                        <th style="white-space: break-spaces">
                                            {{ $t('inputs.filters.labels.price_diff_rate') }}
                                            <InfoTooltip :content="$t('inputs.filters.help_text.price_diff_rate')"></InfoTooltip>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :key="diffRate.from" v-for="(diffRate, idx) in filter_options.price_diff_rates">
                                        <td class="position-relative">
                                            <div class="input-group">
                                                <input type="text" v-maska:[options] placeholder="Min" v-model="diffRate.min" class="sm-input form-control" />
                                                <input type="text" v-maska:[options] v-model="diffRate.max" placeholder="Max" class="sm-input form-control" />
                                                <span class="input-group-text">{{ currency }}</span>
                                            </div>
                                        </td>
                                        <td class="position-relative">
                                            <input type="number" v-model="diffRate.rate" :placeholder="$t('inputs.filters.placeholders.rate')" class="sm-input form-control" />
                                        </td>
                                        <td>
                                            <button v-if="idx === 0" @click="filter_options.price_diff_rates.push({ from: null, to: null, rate: null })" class="btn btn-success"><i class="bi bi-plus"></i></button>
                                            <button v-if="idx > 0" @click="filter_options.price_diff_rates = filter_options.price_diff_rates.slice(idx)" class="btn btn-outline-danger"><i class="fa fa-minus"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row bordered-top pt-4 pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.height') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.height')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="min_height" v-model="filter_options.height.min" name="height_min" class="form-control" />
                                <span class="input-group-text">in</span>
                                <input type="number" placeholder="Max" v-model="filter_options.height.max" id="height_max" name="height_max" class="form-control" />
                                <span class="input-group-text">in</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.width') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.width')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="width_min" v-model="filter_options.width.min" name="width_min" class="form-control" />
                                <span class="input-group-text">in</span>
                                <input type="number" placeholder="Max" v-model="filter_options.width.max" id="width_max" name="width_max" class="form-control" />
                                <span class="input-group-text">in</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.length') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.length')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="length_min" v-model="filter_options.length.min" name="length_min" class="form-control" />
                                <span class="input-group-text">in</span>
                                <input type="number" placeholder="Max" v-model="filter_options.length.max" id="length_max" name="length_max" class="form-control" />
                                <span class="input-group-text">in</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.weight') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.weight')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="min_weight" v-model="filter_options.weight.min" name="min_weight" class="form-control" />
                                <span class="input-group-text">lbs</span>
                                <input type="number" placeholder="Max" v-model="filter_options.weight.max" id="max_weight" name="max_weight" class="form-control" />
                                <span class="input-group-text">lbs</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.dim_weight') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.dim_weight')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="dim_weight_min" v-model="filter_options.dim_weight.min" name="dim_weight_min" class="form-control" />
                                <span class="input-group-text">lbs</span>
                                <input type="number" placeholder="Max" id="dim_weight_max" v-model="filter_options.dim_weight.max" name="dim_weight_max" class="form-control" />
                                <span class="input-group-text">lbs</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.max_quantity_in_box') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.max_quantity_in_box')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="packageble_min" v-model="filter_options.packageble.min" name="packageble_min" class="form-control" />
                                <input type="number" placeholder="Max" v-model="filter_options.packageble.max" id="packageble_max" name="packageble_max" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2 mt-2 pt-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_without_dimension" v-model="filter_options.exclude_without_dimension" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_without_dimension"
                                    >{{ $t('inputs.filters.labels.exclude_without_dimension') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_without_dimension')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row bordered-top mt-2 pt-2">
                        <label class="align-self-center mb-2">{{ $t('navbar.following_list.ignore_list') }}</label>
                        <div class="row pb-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <input id="exclude_restricted_brand" v-model="filter_options.exclude_restricted_brand" type="checkbox" class="form-check-input" />
                                    <label class="ml-05" for="exclude_restricted_brand">
                                        {{ $t('inputs.filters.labels.exclude_restricted_brand') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_restricted_brand')"></InfoTooltip>
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex">
                                    <input id="exclude_restricted_seller" v-model="filter_options.exclude_restricted_seller" type="checkbox" class="form-check-input" />
                                    <label class="ml-05" for="exclude_restricted_seller"
                                        >{{ $t('inputs.filters.labels.exclude_restricted_seller') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_restricted_seller')"></InfoTooltip>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <input id="exclude_restricted_asin" v-model="filter_options.exclude_restricted_asin" type="checkbox" class="form-check-input" />
                                    <label class="ml-05" for="exclude_restricted_asin">
                                        {{ $t('inputs.filters.labels.exclude_restricted_asin') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_restricted_asin')"></InfoTooltip>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bordered-top mt-4 pt-2 pb-2">
                        <label class="align-self-center mb-2"> {{ $t('navbar.restricted_list.violation_pool') }} </label>
                        <div class="row pb-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <input id="exclude_violation_pool_brand" v-model="filter_options.exclude_violation_pool_brand" type="checkbox" class="form-check-input" />
                                    <label class="ml-05" for="exclude_violation_pool_brand">
                                        {{ $t('inputs.filters.labels.exclude_restricted_brand') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_restricted_brand')"></InfoTooltip>
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex">
                                    <input id="exclude_violation_pool_asin" v-model="filter_options.exclude_violation_pool_asin" type="checkbox" class="form-check-input" />
                                    <label class="ml-05" for="exclude_violation_pool_asin">
                                        {{ $t('inputs.filters.labels.exclude_restricted_asin') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_restricted_asin')"></InfoTooltip>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="{
                'accordion-item p-2': !detailed,
                'detailed-item': detailed,
                sticky_item: detailed,
            }"
        >
            <div class="accordion-header" id="headingTwo">
                <a :class="!detailed && 'accordion-button collapsed'" role="button" :data-bs-toggle="!detailed && 'collapse'" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    {{ $t('cards.filters.accordion.source') }}</a
                >
            </div>
            <div id="collapseTwo" :class="!detailed && 'accordion-collapse collapse'" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row pt-4 pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.sales_rank') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.sales_rank')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="sales_rank_from_min" v-model="filter_options.sales_rank_from.min" name="sales_rank_from_min" class="form-control" />
                                <input type="number" placeholder="Max" v-model="filter_options.sales_rank_from.max" id="sales_rank_from_max" name="sales_rank_from_max" class="form-control" />
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.reviews') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.reviews')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" v-model="filter_options.reviews_from.min" id="reviews_from_min" name="reviews_from_min" class="form-control" />
                                <input type="number" placeholder="Max" v-model="filter_options.reviews_from.max" id="reviews_from_max" name="reviews_from_max" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.rating') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.rating')"></InfoTooltip>
                            </label>
                            <div class="d-flex w-90">
                                <div class="star-select-box" @click="show_min_star_list_from = true" @mouseleave="show_min_star_list_from = false">
                                    <div class="star-box">
                                        <Rating :rating="filter_options.star_from.min" :star-size="20" :padding="3" />
                                    </div>
                                    <ul class="star-list" :class="show_min_star_list_from && 'active-star-list'">
                                        <li @click="filter_options.star_from.min = 0">0 {{ $t('filters.index.star') }}</li>
                                        <template :key="item - 0.5" v-for="item in 5">
                                            <li @click="filter_options.star_from.min = item - 0.5">{{ item - 0.5 }} {{ $t('filters.index.star') }}</li>
                                            <li @click="filter_options.star_from.min = item">{{ item }} {{ $t('filters.index.star') }}</li>
                                        </template>
                                    </ul>
                                </div>
                                <div class="star-select-box ml-1" @click="show_max_star_list_from = true" @mouseleave="show_max_star_list_from = false">
                                    <div class="star-box">
                                        <Rating :rating="filter_options.star_from.max" />
                                    </div>
                                    <ul class="star-list" :class="show_max_star_list_from && 'active-star-list'">
                                        <li @click="filter_options.star_from.max = 0">0 {{ $t('filters.index.star') }}</li>
                                        <template :key="item - 0.5" v-for="item in 5">
                                            <li @click="filter_options.star_from.max = item - 0.5">{{ item - 0.5 }} {{ $t('filters.index.star') }}</li>
                                            <li @click="filter_options.star_from.max = item">{{ item }} {{ $t('filters.index.star') }}</li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.price') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.price')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input v-maska:[options] type="text" placeholder="Min" v-model="filter_options.price_from.min" id="price_from_min" name="price_from_min" class="form-control" />
                                <input v-maska:[options] type="text" placeholder="Max" v-model="filter_options.price_from.max" id="price_from_max" name="price_from_max" class="form-control" />
                                <span class="input-group-text">{{ currency }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.max_order_quantity') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.max_order_quantity')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" :placeholder="$t('inputs.filters.labels.quantity')" v-model="filter_options.max_order_quantity" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="{
                'accordion-item p-2': !detailed,
                'detailed-item': detailed,
            }"
        >
            <div class="accordion-header" id="headingThree">
                <a :class="!detailed && 'accordion-button collapsed'" role="button" :data-bs-toggle="!detailed && 'collapse'" :data-bs-target="!detailed && '#collapseThree'" aria-expanded="false" aria-controls="collapseThree">
                    {{ $t('cards.filters.accordion.target') }}
                </a>
            </div>
            <div id="collapseThree" :class="!detailed && 'accordion-collapse collapse'" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row pt-4 pb-2">
                        <div class="col-6 position-relative">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.first_available') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.first_available')"></InfoTooltip>
                            </label>
                            <div class="d-flex flex-row mb-2">
                                <input type="number" v-model="filter_options.first_available" class="sm-input form-control mr-1" />
                                <button class="dropdown btn btn-sm btn-primary dropdown-toggle pr-2" data-bs-toggle="dropdown">
                                    {{ $t('actions.' + filter_options.first_available_option) }}
                                </button>
                                <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                                    <a class="dropdown-item" href="#" @click.prevent="filter_options.first_available_option = 'lower'">{{ $t('actions.lower') }}</a>
                                    <a class="dropdown-item" href="#" @click.prevent="filter_options.first_available_option = 'higher'">{{ $t('actions.higher') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.sales_rank') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.sales_rank')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" id="sales_rank_to_min" v-model="filter_options.sales_rank_to.min" name="sales_rank_to_min" class="form-control" />
                                <input type="number" placeholder="Max" v-model="filter_options.sales_rank_to.max" id="sales_rank_to_max" name="sales_rank_to_max" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.rating') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.rating')"></InfoTooltip>
                            </label>
                            <div class="d-flex w-90">
                                <div class="star-select-box" @click="show_min_star_list_to = true" @mouseleave="show_min_star_list_to = false">
                                    <div class="star-box">
                                        <Rating :rating="filter_options.star_to.min" />
                                    </div>
                                    <ul class="star-list" :class="show_min_star_list_to && 'active-star-list'">
                                        <li @click="filter_options.star_to.min = 0">0 {{ $t('filters.index.star') }}</li>
                                        <template :key="item - 0.5" v-for="item in 5">
                                            <li @click="filter_options.star_to.min = item - 0.5">{{ item - 0.5 }} {{ $t('filters.index.star') }}</li>
                                            <li @click="filter_options.star_to.min = item">{{ item }} {{ $t('filters.index.star') }}</li>
                                        </template>
                                    </ul>
                                </div>
                                <div class="star-select-box ml-1" @click="show_max_star_list_to = true" @mouseleave="show_max_star_list_to = false">
                                    <div class="star-box">
                                        <Rating :rating="filter_options.star_to.max" />
                                    </div>
                                    <ul class="star-list" :class="show_max_star_list_to && 'active-star-list'">
                                        <li @click="filter_options.star_to.max = 0">0 {{ $t('filters.index.star') }}</li>
                                        <template :key="item - 0.5" v-for="item in 5">
                                            <li @click="filter_options.star_to.max = item - 0.5">{{ item - 0.5 }} {{ $t('filters.index.star') }}</li>
                                            <li @click="filter_options.star_to.max = item">{{ item }} {{ $t('filters.index.star') }}</li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-self-center mb-2">
                                {{ $t('inputs.filters.labels.reviews') }}
                                <InfoTooltip :content="$t('inputs.filters.help_text.reviews')"></InfoTooltip>
                            </label>
                            <div class="input-group mb-3">
                                <input type="number" placeholder="Min" v-model="filter_options.reviews_to.min" id="reviews_to_min" name="reviews_to_min" class="form-control" />
                                <input type="number" placeholder="Max" v-model="filter_options.reviews_to.max" id="reviews_to_max" name="reviews_to_max" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_fba_seller" v-model="filter_options.exclude_fba_seller" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_fba_seller"
                                    >{{ $t('inputs.filters.labels.exclude_fba_seller') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_fba_seller')"></InfoTooltip>
                                </label>
                            </div>

                            <div class="input-group mt-2">
                                <div class="input-group">
                                    <input :disabled="!filter_options.exclude_fba_seller" type="number" placeholder="Min" v-model="filter_options.fba_seller_count.min" class="sm-input form-control" />
                                    <input :disabled="!filter_options.exclude_fba_seller" type="number" placeholder="Max" v-model="filter_options.fba_seller_count.max" class="sm-input form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_fbm_seller" v-model="filter_options.exclude_fbm_seller" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_fbm_seller"
                                    >{{ $t('inputs.filters.labels.exclude_fbm_seller') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_fbm_seller')"></InfoTooltip>
                                </label>
                            </div>
                            <div class="input-group mt-2">
                                <div class="input-group">
                                    <input :disabled="!filter_options.exclude_fbm_seller" type="number" placeholder="Min" v-model="filter_options.fbm_seller_count.min" class="sm-input form-control" />
                                    <input :disabled="!filter_options.exclude_fbm_seller" type="number" placeholder="Max" v-model="filter_options.fbm_seller_count.max" class="sm-input form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <fieldset v-if="keepaDomainAvailable">
                        <legend><img :src="getKeepaImage()" width="80" /></legend>
                        <div class="row mt-4 pb-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <input :disabled="!keepaAvailable" id="exclude_bought_in_past" v-model="filter_options.exclude_bought_in_past" type="checkbox" class="form-check-input" />
                                    <label :style="detailed ? 'max-width: 80%' : ''" class="ml-05" for="exclude_bought_in_past"
                                        >{{ $t('inputs.filters.labels.exclude_bought_in_past') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_bought_in_past')"></InfoTooltip>
                                        <KeepaLogo
                                            :color="'#256a93'"
                                            :style="{
                                                marginTop: '-3px',
                                                marginLeft: '3px',
                                            }"
                                            :width="15"
                                            :height="15"
                                        />
                                    </label>
                                </div>
                                <div class="input-group mt-2">
                                    <div class="input-group">
                                        <input :disabled="!filter_options.exclude_bought_in_past || !keepaAvailable" type="number" placeholder="Min" v-model="filter_options.exclude_bought_in_past_count.min" class="sm-input form-control" />
                                        <input :disabled="!filter_options.exclude_bought_in_past || !keepaAvailable" type="number" placeholder="Max" v-model="filter_options.exclude_bought_in_past_count.max" class="sm-input form-control" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="d-flex">
                                    <input :disabled="!keepaAvailable" id="exclude_out_of_stock_90" v-model="filter_options.exclude_out_of_stock_90" type="checkbox" class="form-check-input" />
                                    <label :style="detailed ? 'max-width: 80%' : ''" class="ml-05" for="exclude_out_of_stock_90"
                                        >{{ $t('inputs.filters.labels.exclude_out_of_stock_90') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_out_of_stock_90')"></InfoTooltip>
                                        <KeepaLogo
                                            :color="'#256a93'"
                                            :style="{
                                                marginTop: '-3px',
                                                marginLeft: '3px',
                                            }"
                                            :width="15"
                                            :height="15"
                                        />
                                    </label>
                                </div>
                                <div class="input-group mt-2">
                                    <div class="input-group">
                                        <input :disabled="!filter_options.exclude_out_of_stock_90 || !keepaAvailable" type="number" placeholder="Min" v-model="filter_options.exclude_out_of_stock_90_count.min" class="sm-input form-control" />
                                        <input :disabled="!filter_options.exclude_out_of_stock_90 || !keepaAvailable" type="number" placeholder="Max" v-model="filter_options.exclude_out_of_stock_90_count.max" class="sm-input form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 pb-2">
                            <div class="col-4">
                                <div class="d-flex">
                                    <input :disabled="!keepaAvailable" id="exclude_bsr_drop_30" v-model="filter_options.exclude_bsr_drop_30" type="checkbox" class="form-check-input" />
                                    <label :style="detailed ? 'max-width: 80%' : 'max-width: 90%'" class="ml-05" for="exclude_bsr_drop_30"
                                        >{{ $t('inputs.filters.labels.exclude_bsr_drop_30') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_bsr_drop_30')"></InfoTooltip>
                                        <KeepaLogo
                                            :color="'#256a93'"
                                            :style="{
                                                marginTop: '-3px',
                                                marginLeft: '3px',
                                            }"
                                            :width="15"
                                            :height="15"
                                        />
                                    </label>
                                </div>
                                <div class="input-group mt-2">
                                    <div class="input-group">
                                        <input :disabled="!filter_options.exclude_bsr_drop_30 || !keepaAvailable" type="number" placeholder="Min" v-model="filter_options.exclude_bsr_drop_30_count.min" class="sm-input form-control" />
                                        <input :disabled="!filter_options.exclude_bsr_drop_30 || !keepaAvailable" type="number" placeholder="Max" v-model="filter_options.exclude_bsr_drop_30_count.max" class="sm-input form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="d-flex">
                                    <input :disabled="!keepaAvailable" id="exclude_bsr_drop_90" v-model="filter_options.exclude_bsr_drop_90" type="checkbox" class="form-check-input" />
                                    <label :style="detailed ? 'max-width: 80%' : 'max-width: 90%'" class="ml-05" for="exclude_bsr_drop_90"
                                        >{{ $t('inputs.filters.labels.exclude_bsr_drop_90') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_bsr_drop_90')"></InfoTooltip>
                                        <KeepaLogo
                                            :color="'#256a93'"
                                            :style="{
                                                marginTop: '-3px',
                                                marginLeft: '3px',
                                            }"
                                            :width="15"
                                            :height="15"
                                        />
                                    </label>
                                </div>
                                <div class="input-group mt-2">
                                    <div class="input-group">
                                        <input :disabled="!filter_options.exclude_bsr_drop_90 || !keepaAvailable" type="number" placeholder="Min" v-model="filter_options.exclude_bsr_drop_90_count.min" class="sm-input form-control" />
                                        <input :disabled="!filter_options.exclude_bsr_drop_90 || !keepaAvailable" type="number" placeholder="Max" v-model="filter_options.exclude_bsr_drop_90_count.max" class="sm-input form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="d-flex">
                                    <input :disabled="!keepaAvailable" id="exclude_bsr_drop_90" v-model="filter_options.exclude_bsr_drop_180" type="checkbox" class="form-check-input" />
                                    <label :style="detailed ? 'max-width: 80%' : 'max-width: 90%'" class="ml-05" for="exclude_bsr_drop_180"
                                        >{{ $t('inputs.filters.labels.exclude_bsr_drop_180') }}
                                        <InfoTooltip :content="$t('inputs.filters.help_text.exclude_bsr_drop_180')"></InfoTooltip>
                                        <KeepaLogo
                                            :color="'#256a93'"
                                            :style="{
                                                marginTop: '-3px',
                                                marginLeft: '3px',
                                            }"
                                            :width="15"
                                            :height="15"
                                        />
                                    </label>
                                </div>
                                <div class="input-group mt-2">
                                    <div class="input-group">
                                        <input :disabled="!filter_options.exclude_bsr_drop_180 || !keepaAvailable" type="number" placeholder="Min" v-model="filter_options.exclude_bsr_drop_180_count.min" class="sm-input form-control" />
                                        <input :disabled="!filter_options.exclude_bsr_drop_180 || !keepaAvailable" type="number" placeholder="Max" v-model="filter_options.exclude_bsr_drop_180_count.max" class="sm-input form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div class="row mt-4 pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_with_variation" v-model="filter_options.exclude_with_variation" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_with_variation"
                                    >{{ $t('inputs.filters.labels.exclude_with_variation') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_with_variation')"></InfoTooltip>
                                </label>
                            </div>
                            <div class="input-group mt-2">
                                <div class="input-group">
                                    <input :disabled="!filter_options.exclude_with_variation" type="number" placeholder="Min" v-model="filter_options.exclude_with_variation_count.min" class="sm-input form-control" />
                                    <input :disabled="!filter_options.exclude_with_variation" type="number" placeholder="Max" v-model="filter_options.exclude_with_variation_count.max" class="sm-input form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_fba_without_stock_in_target" v-model="filter_options.exclude_fba_without_stock_in_target" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_fba_without_stock_in_target"
                                    >{{ $t('inputs.filters.labels.exclude_fba_without_stock_in_target') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_fba_without_stock_in_target')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_hazmat" v-model="filter_options.exclude_hazmat" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_hazmat">
                                    {{ $t('inputs.filters.labels.exclude_hazmat') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_hazmat')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_used_product_by_amazon" v-model="filter_options.exclude_used_product_by_amazon" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_used_product_by_amazon"
                                    >{{ $t('inputs.filters.labels.exclude_used_product_by_amazon') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_used_product_by_amazon')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_used_product_by_other" v-model="filter_options.exclude_used_product_by_other" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_used_product_by_other">
                                    {{ $t('inputs.filters.labels.exclude_used_product_by_other') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_used_product_by_other')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_amazon_seller_product_local" v-model="filter_options.exclude_amazon_seller_product_local" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_amazon_seller_product_local"
                                    >{{ $t('inputs.filters.labels.exclude_amazon_seller_product_local') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_amazon_seller_product_local')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_amazon_seller_product" v-model="filter_options.exclude_amazon_seller_product" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_amazon_seller_product"
                                    >{{ $t('inputs.filters.labels.exclude_amazon_seller_product') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_amazon_seller_product')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_fulfilled_by_amazon" v-model="filter_options.exclude_fulfilled_by_amazon" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_fulfilled_by_amazon">
                                    {{ $t('inputs.filters.labels.exclude_fulfilled_by_amazon') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_fulfilled_by_amazon')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 pb-2">
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_same_seller" v-model="filter_options.exclude_same_seller" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_same_seller">
                                    {{ $t('inputs.filters.labels.exclude_same_seller') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_same_seller')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <input id="exclude_non_buybox" v-model="filter_options.exclude_non_buybox" type="checkbox" class="form-check-input" />
                                <label class="ml-05" for="exclude_non_buybox">
                                    {{ $t('inputs.filters.labels.exclude_non_buybox') }}
                                    <InfoTooltip :content="$t('inputs.filters.help_text.exclude_non_buybox')"></InfoTooltip>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="detailed" class="detailed_filters_apply">
        <div class="mr-1">
            <select v-model="filter_id" @change="handleFilterChange" :placeholder="$t('actions.select_saved_filter')" class="form-select" style="width: 250px">
                <option value="">{{ $t('actions.select_saved_filter') }}</option>
                <option v-for="filter in filters" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
            </select>
        </div>
        <div class="btn-group">
            <button type="button" @click="handleSubmit" class="btn btn-primary">
                {{ $t('actions.apply_filters') }}
            </button>
            <button class="dropdown btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"></button>
            <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                <a class="dropdown-item" :class="!filter_id && 'disabled'" @click="handleSaveOnSelectedFilter" href="#"> {{ $t('actions.save_on_selected_filter') }}</a>
                <a class="dropdown-item" :class="!filter_id && 'disabled'" @click="handleRemoveSelectedFilter" href="#"> {{ $t('actions.delete_selected_filter') }}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="handleNewFilter"> {{ $t('actions.add_filter') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { vMaska } from 'maska'
import Rating from '../Inputs/Rating.vue'
import { mapActions, mapGetters } from 'vuex'
import UserFilterService from '@/services/user-filter'
import { countryInfo } from '@/utils/helper'
import InfoTooltip from '@/components/Variations/InfoTooltip.vue'
import KeepaLogo from '@/components/KeepaLogo.vue'

const countries = countryInfo()
export default {
    name: 'Filters',
    props: {
        handleFilterFormChange: {
            type: Function,
            default: () => {},
        },
        selectedFilter: {
            default: null,
        },
        defaultFilters: {
            type: Object,
            default: null,
        },
        detailed: {
            type: Boolean,
            default: false,
        },
        keepaAvailable: {
            type: Boolean,
            default: true,
        },
        keepaDomainAvailable: {
            type: Boolean,
            default: true,
        },
    },
    directives: {
        maska: vMaska,
    },
    data: () => ({
        toggleDropdown: false,
        filter_id: '',
        form_id: Math.round(Math.random() * 1000),
        filter_options: {
            fba_profit: null,
            fba_profit_rate: null,
            fba_net_profit: null,
            fba_net_profit_rate: null,
            fba_profit_margin: null,
            fba_net_profit_margin: null,
            fbm_profit: null,
            fbm_profit_rate: null,
            fbm_net_profit: null,
            fbm_net_profit_rate: null,
            fbm_profit_margin: null,
            exclude_restricted_brand: false,
            exclude_violation_pool_brand: false,
            exclude_violation_pool_asin: false,
            exclude_restricted_seller: false,
            exclude_restricted_asin: false,
            first_available_option: 'lower',
            price_diff_rates: [
                {
                    min: null,
                    max: null,
                    rate: null,
                },
            ],
            not_available_for_global_shipping: false,
            exclude_without_dimension: false,
            default_price_for_non_dimension_product: null,
            max_order_quantity: null,
            exclude_non_refundable: false,
            words_in_title: {
                words: '',
                operator: 'included',
            },
            height: {
                min: null,
                max: null,
            },
            width: {
                min: null,
                max: null,
            },
            length: {
                min: null,
                max: null,
            },
            weight: {
                min: null,
                max: null,
            },
            dim_weight: {
                min: null,
                max: null,
            },
            packageble: {
                min: null,
                max: null,
            },
            sales_rank_from: {
                min: null,
                max: null,
            },
            sales_rank_to: {
                min: null,
                max: null,
            },
            reviews_from: {
                min: null,
                max: null,
            },
            reviews_to: {
                min: null,
                max: null,
            },
            star_from: {
                min: 0,
                max: 5,
            },
            star_to: {
                min: 0,
                max: 5,
            },
            price_from: {
                min: null,
                max: null,
            },
            price_to: {
                min: null,
                max: null,
            },
            first_available: null,
            exclude_fulfilled_by_amazon: false,
            exclude_same_seller: false,
            exclude_fba_without_stock_in_target: false,
            exclude_used_product_by_amazon: false,
            exclude_used_product_by_other: false,
            exclude_hazmat: false,
            exclude_fba_seller: false,
            fba_seller_count: {
                min: null,
                max: null,
            },
            exclude_fbm_seller: false,
            fbm_seller_count: {
                min: null,
                max: null,
            },
            exclude_with_variation: false,
            exclude_with_variation_count: {
                min: null,
                max: null,
            },
            exclude_out_of_stock_90: false,
            exclude_out_of_stock_90_count: {
                min: null,
                max: null,
            },
            exclude_bsr_drop_30: false,
            exclude_bsr_drop_30_count: {
                min: null,
                max: null,
            },
            exclude_bsr_drop_90: false,
            exclude_bsr_drop_90_count: {
                min: null,
                max: null,
            },
            exclude_bsr_drop_180: false,
            exclude_bsr_drop_180_count: {
                min: null,
                max: null,
            },
            exclude_bought_in_past: false,
            exclude_bought_in_past_count: {
                min: null,
                max: null,
            },
            exclude_amazon_seller_product: false,
            exclude_amazon_seller_except_target_market: false,
            exclude_non_buybox: false,
            exclude_same_sellers: false,
        },
        show_min_star_list_from: false,
        show_max_star_list_from: false,
        show_min_star_list_to: false,
        show_max_star_list_to: false,
        tagOptions: [],
        options: {
            preProcess: (val) => val.replace(/[$,]/g, ''),
            postProcess: (val) => {
                if (!val) return ''
                const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
                return Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
                    .format(val)
                    .replace('$', '')
                    .slice(0, sub ? -sub : undefined)
            },
        },
    }),
    methods: {
        ...mapActions('productSearch', ['getFilters', 'applyFilter', 'setDefaultFilter']),
        getKeepaImage() {
            return require('@/assets/img/keepa-logo.webp')
        },
        addTag(newTag) {
            console.log(newTag)
            this.tagOptions.push(newTag)
            this.filter_options.words_in_title.words.push(newTag)
        },
        customLabel({ domain }) {
            return `${domain}`
        },
        async handleSubmit() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            await this.applyFilter({
                id: this.defaultFilter.id,
                options: this.filter_options,
            })
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            window.location.reload()
        },
        handleNewFilter: async function () {
            // confirm with input
            const generated4Digit = Math.floor(1000 + Math.random() * 9000)
            const filter_name = prompt('Lütfen filtre adını giriniz.', 'Yeni Filtre ' + generated4Digit)
            if (filter_name) {
                this.$store.commit('set', { k: 'loading', v: { status: true, text: 'Filtre kaydediliyor' } })
                const createdFilter = await UserFilterService.create('inventory', {
                    name: filter_name,
                    options: this.filter_options,
                })
                this.filter_id = createdFilter.data.id
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleRemoveSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true, text: 'Filtre siliniyor' } })
                await UserFilterService.delete(this.filter_id)
                this.filter_id = ''
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleFilterChange: function (e) {
            this.filter_id = e.target.value
        },
        handleSaveOnSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true, text: 'Filtre kaydediliyor' } })

                await UserFilterService.update(this.filter_id, {
                    options: this.filter_options,
                })
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        async handleGetFilters() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getFilters()
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    mounted() {
        this.handleGetFilters()
    },
    beforeUnmount() {
        this.setDefaultFilter(null)
    },
    computed: {
        ...mapGetters('productSearch', ['filters', 'defaultFilter']),
        ...mapGetters('market', ['currentMarket']),
        currency() {
            if (!this.currentMarket) return ''
            return countries[this.currentMarket.short].symbol
        },
    },
    components: { Rating, InfoTooltip, KeepaLogo },
    watch: {
        filter_options: {
            handler: function (val, oldVal) {
                this.$emit('handleFilterFormChange', val)
            },
            deep: true,
            immediate: true,
        },
        filter_id: {
            handler: function (val, oldVal) {
                const selectedFilter = this.filters.find((item) => item.id == val)
                console.log(selectedFilter)
                if (+val > 0) {
                    let options = selectedFilter.options

                    if (typeof options === 'string') {
                        options = JSON.parse(options)
                    }
                    this.filter_options = options
                }
            },
            deep: true,
            immediate: true,
        },
        selectedFilter: {
            handler: function (val, oldVal) {
                const selectedFilter = this.filters.find((item) => item.id == val)
                if (+val > 0) {
                    let options = selectedFilter.options

                    if (typeof options === 'string') {
                        options = JSON.parse(options)
                    }
                    this.filter_options = options
                }
            },
            deep: true,
            immediate: true,
        },
        defaultFilter: {
            handler: function (val, oldVal) {
                console.log(val)
                if (val && val.filter && Object.keys(val.filter).length > 0) {
                    Object.keys(this.filter_options).forEach((value) => {
                        this.filter_options[value] = val.filter[value] ? val.filter[value] : this.filter_options[value]
                    })
                }
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style>
fieldset {
    border: 1px solid #e9ebf3;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
}
legend {
    margin-top: -12px;
    width: 90px;
    background-color: #fafcfe;
}

.detailed_filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
    background-color: #f8fafd;
    margin-bottom: 0.5rem;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.detailed_filters label[class='ml-05'] {
    max-width: 80%;
}
.detailed_filters_apply {
    display: flex;
    padding: 1rem;
    background-color: #f8fafd;
    margin-bottom: 0.5rem;
    justify-content: flex-end;
}
.detailed_filters .detailed-item {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.75rem;
    position: relative;
}
.sticky_item {
    max-height: 35rem;
    position: sticky;
}
.detailed_filters .detailed-item .accordion-header {
    margin-bottom: 0.7rem;
    margin-top: 0.3rem;
}

.detailed_filters .detailed-item a:not(.dropdown-item) {
    font-size: 15px;
    color: #00203f;
    font-weight: bold;
}
.detailed_filters .star-select-box {
    margin-right: 2px !important;
    margin-left: 0 !important;
}

.detailed_filters .star-select-box .star {
    height: 18px !important;
    width: 18px !important;
}
.detailed_filters .multiselect {
    min-width: 220px !important;
}
.option__image {
    width: 20px;
}
.multiselect__option {
    display: flex !important;
    align-items: center;
}
.multiselect__option--highlight {
    background-color: #fff !important;
    color: #000 !important;
}
.multiselect__option--selected {
    background-color: #f8fafd !important;
    color: #000 !important;
}
.filter-item-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}
.asin-list {
    height: 100px !important;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    min-height: 250px !important;
    margin-bottom: 5px !important;
    max-height: 600px !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffe3be47), color-stop(50%, #fff));
    background-image: linear-gradient(#ffe3be47 50%, #fff 0);
    background-attachment: local;
    background-size: 100% 50px !important;
    line-height: 25px !important;
    font-size: 14px !important;
    padding: 0 8px !important;
}
.filter-item-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
    align-items: flex-start;
    line-height: normal;
}
.filter-item-row input[type='checkbox'] {
    margin-right: 8px;
}
input.check {
    display: none;
}
input.check[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 20px;
    padding-left: 30px;
    padding-top: 3px;
}
input.check[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
}
input.check[type='checkbox']:checked + label:before {
    background-color: #fd9405;
    -webkit-box-shadow: inset 0 0 0 1px #fd9405;
    box-shadow: inset 0 0 0 1px #fd9405;
}
input.check[type='checkbox']:checked + label:after {
    content: '';
    width: 11px;
    height: 6px;
    display: block;
    position: absolute;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 5px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.star-select-box {
    position: relative;
    max-width: 190px;
    height: 45px;
    width: 100%;
}
.star-select-box .star-box {
    width: 100%;
    height: 45px;
    padding: 5px 8px;
    background-color: #fff;
    border: 1px solid #dbe3ef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.star-select-box ul {
    padding-left: 0;
}
.star-select-box ul.star-list {
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    border: 1px solid #dbe3ef;
    border-radius: 5px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.star-select-box .active-star-list {
    opacity: 1 !important;
    pointer-events: auto !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.star-select-box ul.star-list li {
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ecf0f7;
    font-size: 14px;
}
.filter-item-row {
    position: relative;
}
.currency-suffix {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #a0a0a0;
    font-size: 14px;
    pointer-events: none;
}
</style>
