import AuthService from '@/services/auth'
import Cookies from 'js-cookie'

const state = {
    isAuthenticated: false,
    isSubscribed: false,
    token: null,
    adminToken: null,
    user: null,
    activePackage: null,
    activeSubscription: null,
    packageList: [],
    privatePackages: [],
    product: null,
    packageFeatures: [],
    registerForm: null,
}

const getters = {
    isAuthenticated: (state) => state.isAuthenticated,
    isSubscribed: (state) => state.isSubscribed,
    token: (state) => state.token,
    adminToken: (state) => state.adminToken,
    user: (state) => state.user,
    activePackage: (state) => state.activePackage,
    packageFeatures: (state) => state.packageFeatures,
    packageList: (state) => state.packageList,
    product: (state) => state.product,
    privatePackages: (state) => state.privatePackages,
    registerForm: (state) => state.registerForm,
}

const mutations = {
    SET_ADMIN_TOKEN(state, payload) {
        state.adminToken = payload
    },
    SET_TOKEN(state, payload) {
        state.token = payload
    },
    SET_AUTHENTICATED(state, payload) {
        state.isAuthenticated = payload
    },
    SET_USER(state, payload) {
        state.user = payload
    },
    SET_SUBSCRIBED(state, payload) {
        state.isSubscribed = payload
    },
    SET_PACKAGE(state, payload) {
        state.activePackage = payload
    },
    SET_PACKAGE_FEATURES(state, payload) {
        state.packageFeatures = payload
    },
    SET_PACKAGE_LIST(state, payload) {
        state.packageList = payload
    },
    SET_PRODUCT(state, payload) {
        state.product = payload
    },
    SET_PRIVATE_PACKAGES(state, payload) {
        state.privatePackages = payload
    },
    SET_REGISTER_FORM(state, payload) {
        state.registerForm = payload
    },
}

const actions = {
    setSubcribed({ commit }, payload) {
        commit('SET_SUBSCRIBED', payload)
    },
    me({ commit }) {
        return new Promise((resolve, reject) => {
            AuthService.me()
                .then((response) => {
                    window.parent.postMessage({ token: window.localStorage.getItem('token') }, '*')
                    const packages = response.data.packages.filter((p) => p.active && p.product.id === 9)
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_USER', response.data)
                    commit('SET_SUBSCRIBED', packages.length > 0)
                    if (packages.length > 0) {
                        commit('SET_PACKAGE', packages[0])
                        commit('SET_PACKAGE_FEATURES', packages[0].package.features)
                    }
                    localStorage.setItem('user', JSON.stringify(response.data))
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                    commit('SET_AUTHENTICATED', false)
                })
        })
    },
    setToken({ commit }, payload) {
        commit('SET_TOKEN', payload)
        if (typeof payload === 'string') {
            commit('SET_AUTHENTICATED', true)
            window.parent.postMessage({ token: payload }, '*')
            localStorage.setItem('token', payload)
            localStorage.setItem('lang', 'tr')
        }
    },
    setAdminToken({ commit }, payload) {
        commit('SET_ADMIN_TOKEN', payload)
        localStorage.setItem('adminToken', payload)
        Cookies.set('adminToken', payload, { path: '' })
    },
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AuthService.login(payload)
                .then((response) => {
                    localStorage.removeItem('customerLogin')
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateContract({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AuthService.updateContract(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setRegisterForm({ commit }, payload) {
        commit('SET_REGISTER_FORM', payload)
    },
    register({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AuthService.register(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async autoLogin({ commit }) {
        const token = localStorage.getItem('token')

        if (token) {
            commit('SET_AUTHENTICATED', true)
            commit('SET_TOKEN', token)
        }
        const user = localStorage.getItem('user')
        if (user) {
            commit('SET_USER', JSON.parse(user))
            const packages = JSON.parse(user).packages
            commit('SET_SUBSCRIBED', packages.filter((p) => p.active).length > 0)
        }
        const adminToken = localStorage.getItem('adminToken')

        if (adminToken) {
            commit('SET_ADMIN_TOKEN', adminToken)
        }
    },
    adminLogout({ commit }) {
        localStorage.removeItem('adminToken')
        localStorage.removeItem('customerLogin')
        Cookies.remove('adminToken')
        window.location.href = '/login'
        commit('SET_ADMIN_TOKEN', false)
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            AuthService.logout()
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    window.localStorage.removeItem('token')
                    window.localStorage.removeItem('adminToken')
                    window.localStorage.removeItem('user')
                    window.localStorage.removeItem('markets')
                    window.localStorage.removeItem('currentMarket')
                    commit('SET_AUTHENTICATED', false)
                    commit('SET_TOKEN', null)
                    window.parent.postMessage({ isLogout: true }, '*')
                    if (window.location.pathname !== '/extension') {
                        window.location.href = '/login'
                    }
                })
        })
    },
    getAllPackages({ commit }) {
        return new Promise((resolve, reject) => {
            AuthService.getAllPackages()
                .then((response) => {
                    commit('SET_PACKAGE_LIST', response.data.packages || [])
                    commit('SET_PRIVATE_PACKAGES', response.data.privatePackages || [])
                    commit('SET_PRODUCT', response.data.product || null)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
