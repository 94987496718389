import { ApiInstance } from './base'

const ExportingService = {
    startExport(payload, query) {
        return ApiInstance.post('/export?' + query, payload)
    },
    checkJob(id) {
        return ApiInstance.get('/export/' + id)
    },
    sendToEmail(id) {
        return ApiInstance.post('/export/' + id + '/email')
    },
}

export default ExportingService
