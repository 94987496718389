<!-- eslint-disable max-len -->
<template>
    <div class="compare-tab">
        <div v-if="extension.compare.list.length < 1 && !extension.compare.loading" class="alert alert-soft-primary" role="alert">{{ $t('views.extension.compare.noSelectedDomain') }}</div>
        <div v-if="extension.compare.loading" class="d-flex gap-2 justify-content-center align-items-center fs-6">
            <img src="@/assets/extension-loader.gif" width="48" height="48" /> <span>{{ $t('views.extension.loading') }}</span>
        </div>
        <div v-for="(item, index) in sortCompareList" :key="index" class="compare-tab__item" :class="{ noParameters: !item.parameters?.fba_shipping_per_pound || item.isNotFound }">
            <div v-if="!item.parameters?.fba_shipping_per_pound && !item.isNotFound" class="alert alert-primary noParameters__alert" role="alert">
                <div class="d-flex">
                    <div class="flex-shrink-0">
                        <i class="bi-exclamation-triangle-fill"></i>
                    </div>
                    <div class="flex-grow-1 ms-2" v-html="$t('views.extension.compare.noParameters')"></div>
                </div>
            </div>
            <div v-else-if="item.isNotFound" class="alert alert-primary noParameters__alert" role="alert" style="left: 45%">
                <div class="d-flex">
                    <div class="flex-shrink-0">
                        <i class="bi-exclamation-triangle-fill"></i>
                    </div>
                    <div class="flex-grow-1 ms-2" v-html="$t('views.extension.compare.isNotFoundProduct', { domain: showDomainByShort(item.short) })"></div>
                </div>
            </div>
            <div class="compare-tab__item-flag" style="width: 2.5%">
                <img class="svg-flag" :src="require(`@/assets/svg-flag/${item.short}.svg`)" width="30" height="30" alt="" />
            </div>
            <div class="compare-tab__item-img" style="width: 5%"><img v-if="item.detail?.image" :src="item.detail?.image" width="50" height="50" alt="" /></div>
            <h4 class="compare-tab__item-title" style="width: 10%">{{ item.detail?.title }}</h4>
            <div class="compare-tab__item-specs" style="width: 75%" :class="{ blurItem: !item.parameters?.fba_shipping_per_pound || item.isNotFound }">
                <div class="d-flex flex-column" style="width: 20%">
                    <div v-if="item.identifiers?.upc">
                        <span class="text-secondary fw-semibold">
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content><div v-html="$t('views.extension.compare.tooltips.upc')"></div></template>UPC:</Popper
                            >
                        </span>
                        <span class="text-primary">{{ item.identifiers?.upc && item.identifiers?.upc != 0 ? item.identifiers?.upc : '-' }}</span>
                        <span v-if="item.identifiers?.upc">
                            <ExtensionClipboard :text="item.identifiers?.upc && item.identifiers?.upc != 0 ? item.identifiers?.upc : '-'" />
                            <ExtensionSearch :text="item.identifiers?.upc && item.identifiers?.upc != 0 ? item.identifiers?.upc : '-'" />
                        </span>
                    </div>
                    <div v-if="item.detail?.bsr" class="mb-1">
                        <Popper class="light-popper text-secondary fw-semibold" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.compare.tooltips.bsr')"></div> </template>BSR:</Popper
                        >
                        <Popper class="light-popper text-success" hover placement="bottom" arrow>
                            <template #content>{{ item.detail?.bsrCategory }}</template
                            >{{ item.detail?.bsr }}</Popper
                        >
                    </div>
                    <span v-if="item.detail?.bsrCategory"
                        ><span class="fw-semibold">{{ $t('views.extension.cat') }}:</span> <span class="text-primary">{{ item.detail?.bsrCategory }}</span>
                    </span>
                </div>
                <div class="d-flex flex-column" style="width: 15%">
                    <div class="mb-1">
                        <span class="fw-semibold">{{ $t('views.extension.weight') }}:</span>
                        <Popper v-if="item.dimension?.imperial?.dimWeight" class="light-popper link-secondary" hover placement="bottom" arrow>
                            <template #content>
                                <div class="popper-table">
                                    <p>{{ $t('views.extension.productSizeAndWeight') }}</p>
                                    <div class="d-flex">
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.system') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.size') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.volume') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.weight') }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.imperial') }}</div>
                                            <div class="c-item cbr">{{ item.dimension?.imperial.dimensionFlat }}</div>
                                            <div class="c-item cbr">{{ item.dimension?.imperial.volumeFlat }}</div>
                                            <div class="c-item cbr">{{ item.dimension?.imperial.weightFlat }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbb">{{ $t('views.extension.metric') }}</div>
                                            <div class="c-item">{{ item.dimension?.metric.dimensionFlat }}</div>
                                            <div class="c-item">{{ item.dimension?.metric.volumeFlat }}</div>
                                            <div class="c-item">0{{ item.dimension?.metric.weightFlat }}</div>
                                        </div>
                                    </div>
                                </div> </template
                            >{{ item.dimension?.imperial?.weight }} {{ item.dimension?.imperial?.weightUnit }}</Popper
                        >
                        <span v-else>-</span>
                    </div>
                    <div class="mb-1">
                        <span class="text-secondary fw-semibold">{{ $t('views.extension.dim') }}:</span>
                        <span class="text-secondary">
                            <Popper v-if="item.dimension?.imperial?.dimWeight" class="light-popper link-secondary" hover placement="bottom" arrow>
                                <template #content>
                                    <div class="popper-table">
                                        <p>{{ $t('views.extension.productSizeAndWeight') }}</p>
                                        <div class="d-flex">
                                            <div class="d-flex flex-column">
                                                <div class="c-item cbr cbb">{{ $t('views.extension.system') }}</div>
                                                <div class="c-item cbr">{{ $t('views.extension.size') }}</div>
                                                <div class="c-item cbr">{{ $t('views.extension.volume') }}</div>
                                                <div class="c-item cbr">{{ $t('views.extension.weight') }}</div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="c-item cbr cbb">{{ $t('views.extension.imperial') }}</div>
                                                <div class="c-item cbr">{{ item.dimension?.imperial.dimensionFlat }}</div>
                                                <div class="c-item cbr">{{ item.dimension?.imperial.volumeFlat }}</div>
                                                <div class="c-item cbr">{{ item.dimension?.imperial.weightFlat }}</div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="c-item cbb">{{ $t('views.extension.metric') }}</div>
                                                <div class="c-item">{{ item.dimension?.metric.dimensionFlat }}</div>
                                                <div class="c-item">{{ item.dimension?.metric.volumeFlat }}</div>
                                                <div class="c-item">0{{ item.dimension?.metric.weightFlat }}</div>
                                            </div>
                                        </div>
                                    </div> </template
                                >{{ item.dimension?.imperial?.dimWeight }} {{ item.dimension?.imperial?.dimWeightUnit }}</Popper
                            >
                            <span v-else>-</span>
                        </span>
                    </div>
                    <div>
                        <span class="text-secondary fw-semibold">
                            <Popper class="light-popper link-secondary fs-normal" hover placement="bottom" arrow>
                                <template #content> <span class="fw-normal" v-html="$t('views.extension.compare.tooltips.maxQ')"></span> </template>{{ $t('views.extension.maxQ') }}:</Popper
                            >
                        </span>
                        <span class="text-secondary">{{ item.dimension?.maxItemOnPackage }}</span>
                    </div>
                </div>
                <div class="d-flex flex-column" style="width: 20%">
                    <div class="d-flex justify-content-between mt-1" style="background-color: var(--bs-gray-200); border-radius: 5px; padding: 5px">
                        <div class="d-flex gap-2">
                            FBA:
                            <Popper class="light-popper text-primary cursor-pointer" style="cursor: pointer" hover placement="bottom" arrow>
                                <template #content>{{ $t('views.extension.fbaOfferCount') }}</template
                                ><a :href="`https://www.${$route.query.domain}/gp/offer-listing/${$route.query.asin}/ref=olp_f_primeEligible?ie=UTF8&f_new=true&f_primeEligible=true`" target="_blank">{{ item.detail?.fbaOfferCount }}</a></Popper
                            >
                            <Popper class="light-popper text-primary" style="cursor: pointer" hover placement="bottom" arrow>
                                <template #content>{{ $t('views.extension.lowestFbaOffers') }}</template
                                ><a :href="`https://www.${$route.query.domain}/gp/offer-listing/${$route.query.asin}/ref=olp_f_primeEligible?ie=UTF8&f_new=true&f_primeEligible=true`" target="_blank">{{ item.detail?.lowestFbaOffers }}</a></Popper
                            >
                            <!--                     <span>Est: <span class="text-success">8.889</span></span>
 -->
                            <!--                     <span class="text-success">€216.713,82</span>
 -->
                        </div>
                        <div class="line-gray" style="height: 15px"></div>
                        <div class="d-flex gap-2">
                            FBM:
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content>{{ $t('views.extension.fbmOfferCount') }}</template
                                ><a :href="`https://www.${$route.query.domain}/gp/offer-listing/${$route.query.asin}/ref=dp_olp_new_mbc?ie=UTF8&condition=new`" target="_blank">{{ item.detail?.fbmOfferCount }}</a></Popper
                            >
                            <Popper class="light-popper text-primary" style="cursor: pointer" hover placement="bottom" arrow>
                                <template #content>{{ $t('views.extension.lowestFbmOffers') }}</template
                                ><a :href="`https://www.${$route.query.domain}/gp/offer-listing/${$route.query.asin}/ref=dp_olp_new_mbc?ie=UTF8&condition=new`" target="_blank">{{ item.detail?.lowestFbmOffers }}</a></Popper
                            >
                            <!--                     <span>Est: 0</span>
 -->
                            <!--                     <span class="text-down">€0</span>
 -->
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-4" style="width: 30%">
                    <div class="column-warehouse">
                        <span class="invisible">X</span>
                        <div>
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.inputs.tooltips.profit')"></div></template>
                                <div v-html="$t('views.extension.profit')"></div
                            ></Popper>
                        </div>
                        <div>
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.inputs.tooltips.roi')"></div> </template>
                                <div v-html="$t('views.extension.roi')"></div
                            ></Popper>
                        </div>
                        <div>
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.inputs.tooltips.margin')"></div> </template>
                                <div v-html="$t('views.extension.margin')"></div
                            ></Popper>
                        </div>
                    </div>
                    <div class="column-warehouse">
                        <span>FBA</span>
                        <div :class="isHyphenClass(item?.calculatedData?.fba?.profit)">
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content>
                                    <div class="popper-table">
                                        <div class="d-flex">
                                            <div class="d-flex flex-column">
                                                <div class="c-item invisible">X</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.sellerPrice') }}</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.referralFeePercent') }} {{ item?.calculatedData?.fba?.referralFeePercent }}%</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.cog') }}</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.refFee') }}</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.3plLogistics') }}</div>
                                                <div class="c-item fw-bold">{{ $t('views.extension.profit') }}</div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="c-item fw-bold">FBA</div>
                                                <div class="c-item fw-bold text-success">{{ item?.calculatedData?.fba?.price }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fba?.referralFee }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fba?.productCost }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fba?.fbaFee }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fba?.warehouseCost }}</div>
                                                <div class="c-item fw-bold" :class="isHyphenClass(item?.calculatedData?.fba?.profit)">{{ item?.calculatedData?.fba?.profit }}</div>
                                            </div>
                                        </div>
                                    </div> </template
                                >{{ currencyFormatter(item.short, item?.calculatedData?.fba?.profit) }}</Popper
                            >
                        </div>
                        <div :class="isHyphenClass(item?.calculatedData?.fba?.roi)">{{ item?.calculatedData?.fba?.roi ? `%${item?.calculatedData?.fba?.roi}` : '-' }}</div>

                        <div :class="isHyphenClass(item?.calculatedData?.fba?.profitMargin)">{{ item?.calculatedData?.fba?.profitMargin ? `%${item?.calculatedData?.fba?.profitMargin}` : '-' }}</div>
                    </div>
                    <div class="column-warehouse">
                        <span>FBM</span>
                        <div :class="isHyphenClass(item?.calculatedData?.fbm?.profit)">
                            <Popper class="light-popper" hover placement="bottom" arrow>
                                <template #content>
                                    <div class="popper-table">
                                        <div class="d-flex">
                                            <div class="d-flex flex-column">
                                                <div class="c-item invisible">X</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.sellerPrice') }}</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.referralFeePercent') }} 8%</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.cog') }}</div>
                                                <div class="c-item">{{ $t('views.extension.inputs.tooltips.3plLogistics') }}</div>
                                                <div class="c-item fw-bold">{{ $t('views.extension.profit') }}</div>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="c-item fw-bold">FBM</div>
                                                <div class="c-item fw-bold text-success">{{ item?.calculatedData?.fbm?.price }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fbm?.referralFee }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fbm?.productCost }}</div>
                                                <div class="c-item text-down">{{ item?.calculatedData?.fbm?.warehouseCost }}</div>
                                                <div class="c-item fw-bold" :class="isHyphenClass(item?.calculatedData?.fbm?.profit)">{{ item?.calculatedData?.fbm?.profit }}</div>
                                            </div>
                                        </div>
                                    </div> </template
                                >{{ currencyFormatter(item.short, item?.calculatedData?.fbm?.profit) }}</Popper
                            >
                        </div>
                        <div :class="isHyphenClass(item?.calculatedData?.fbm?.roi)">{{ item?.calculatedData?.fbm?.roi ? `%${item?.calculatedData?.fbm?.roi}` : '-' }}</div>
                        <div :class="isHyphenClass(item?.calculatedData?.fbm?.profitMargin)">{{ item?.calculatedData?.fbm?.profitMargin ? `%${item?.calculatedData?.fbm?.profitMargin}` : '-' }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!item.isNotFound" class="compare-tab__item-price" :class="[{ highest: index === 0 && findLowestItemIndex !== 0, lowest: findLowestItemIndex === index && findLowestItemIndex !== 0 }]" style="width: 5%">
                <span>{{ item.userHistory?.buyboxPrice ? currencyFormatter(item.short, item.userHistory?.buyboxPrice) : '-' }}</span>
                <span v-if="item.userHistory?.buyboxPriceExchanged">{{ currencyFormatter(extension.availability.targetDomain?.short, item.userHistory?.buyboxPriceExchanged) }}</span>
                <span v-if="index === 0 && item.userHistory?.buyboxPrice && findLowestItemIndex !== 0" class="badge bg-success text-white">{{ $t('views.extension.compare.highest') }}</span>
                <span v-if="findLowestItemIndex === index && findLowestItemIndex !== 0" class="badge bg-danger text-white">{{ $t('views.extension.compare.lowest') }}</span>
            </div>
            <div class="compare-tab__item-actions" style="width: 7.5%">
                <Popper class="link-secondary" hover placement="bottom" arrow>
                    <template #content> {{ $t('views.extension.compare.lastUpdate') }} {{ printDateTime(item.updatedAt, 'HH:mm') }} </template
                    ><button type="button" class="btn btn-link link-secondary p-0" @click="setProductRefresh(item)" :disabled="loading.refresh[item.short]">
                        <span v-if="loading.refresh[item.short]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="width: 0.875em; height: 0.875em; margin-left: 0.3rem"></span>
                        <i v-else class="bi bi-arrow-repeat ms-1 text-secondary"></i></button
                ></Popper>
                <Popper v-if="item.violationData && item.violationData.length > 0" class="light-popper" hover placement="bottom" arrow>
                    <template #content
                        ><div v-html="$t('views.extension.violationData.text')"></div>

                        <div v-for="(vio, index) in item?.violationData" :key="index" class="d-flex align-items-center gap-4 fw-semibold mb-2" style="color: #b12704">
                            <span class="badge bg-danger text-white" style="background-color: #b12704 !important">{{ vio.dataType }}</span> <span>{{ moment(vio.createdAt).format('YYYY-MM-DD') }}:</span>
                            <span>{{ $t('views.extension.violationData.recieved') }}</span> <span>{{ vio.violationTypeText }}</span> <span>{{ $t('views.extension.violationData.complaint') }}</span>
                        </div>
                    </template>
                    <button type="button" class="btn btn-link">
                        <i class="bi bi-exclamation-triangle-fill fs-5" style="color: #f5512d"></i></button
                ></Popper>
                <button type="button" class="btn btn-link p-1" @click="setLike(item)">
                    <span v-if="loading.like[item.short]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <i v-else class="bi bi-bookmark-plus-fill fs-5" :class="[item.userHistory?.isLiked ? 'text-primary' : 'text-secondary']"></i>
                </button>
                <a :href="`https://www.${countries[item.short].domain}/dp/${item.asin}`" target="_blank" class="btn btn-link link-secondary p-1">
                    <i class="bi bi-box-arrow-up-right fs-5"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ExtensionClipboard from '@/components/Extension/components/Clipboard.vue'
import ExtensionSearch from '@/components/Extension/components/Search.vue'
import { countryInfo, printDateTime } from '@/utils/helper'
import { formatCurrency } from '@/utils/format-number'
import moment from 'moment'
import ExtensionService from '@/services/extension'

export default {
    name: 'ExtensionCompare',
    components: {
        ExtensionClipboard,
        ExtensionSearch,
    },
    props: {
        selectedDomains: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            countries: countryInfo(),
            formatCurrency,
            printDateTime,
            moment,
            loading: {
                like: {},
                refresh: {},
            },
        }
    },
    computed: {
        ...mapState(['extension']),
        sortCompareList() {
            const uniqueShorts = new Set()

            const filteredItems = this.extension.compare.list.filter((item) => {
                const matchingDomain = this.selectedDomains.find((domain) => domain.short === item.short)

                if (matchingDomain !== undefined && !uniqueShorts.has(item.short)) {
                    uniqueShorts.add(item.short)
                    return true
                }

                return false
            })

            const sortedItems = filteredItems.sort((a, b) => {
                const aPrice = a.userHistory?.buyboxPriceExchanged || 0
                const bPrice = b.userHistory?.buyboxPriceExchanged || 0

                return bPrice - aPrice
            })

            return sortedItems
        },
        findLowestItemIndex() {
            const filter = this.sortCompareList.filter((i) => i.userHistory?.buyboxPriceExchanged > 0)
            return filter.length - 1
        },
    },
    methods: {
        ...mapActions('extension', ['getProduct', 'like', 'getCompareTabDetail', 'productRefresh']),
        ...mapMutations('extension', ['SET_MASS_COMPARE_DATA']),
        isHyphenClass(text) {
            if (!text) return
            return {
                'text-down': text.toString().includes('-'),
                'text-success': !text.toString().includes('-'),
            }
        },
        currencyFormatter(short, number) {
            return short ? formatCurrency(this.countries[short]?.symbol, number) : '-'
        },
        setLike({ asin, short }) {
            this.loading.like[short] = true
            ExtensionService.like({ asin, domain: this.countries[short]?.domain }).then(() => {
                this.setProductRefresh({ asin, short, loading: false })
                    .then(() => {})
                    .finally(() => {
                        this.loading.like[short] = false
                    })
            })
        },
        async setProductRefresh({ asin, short, loading = true }) {
            if (loading) {
                this.loading.refresh[short] = true
            }
            const findSourceId = this.extension.availability.targetDomain.sourceDomains.find((i) => i.short === short)

            await ExtensionService.getCompareTabDetail(
                {
                    asin,
                    sourceId: findSourceId.id,
                    fees: this.extension.product?.userHistory?.fees,
                    price: this.extension.product?.userHistory?.buyboxPrice,
                    costs: {
                        fba: {
                            warehouseCost: this.extension.calculate.fba?.warehouseCost,
                        },
                        fbm: {
                            warehouseCost: this.extension.calculate.fbm?.warehouseCost,
                        },
                    },
                    target: this.extension.product?.short,
                },
                true,
            )
                .then((response) => {
                    const myArray = [...this.extension.compare.list]
                    const index = myArray.findIndex((i) => i.short === short)
                    myArray[index] = response.data
                    this.SET_MASS_COMPARE_DATA(myArray)
                })
                .finally(() => {
                    if (loading) {
                        this.loading.refresh[short] = false
                    }
                })
        },
        showDomainByShort(short) {
            return this.countries[short]?.domain
        },
    },
}
</script>

<style lang="scss">
.noParameters {
    > div:not(:first-child) {
        opacity: 0.3;
        pointer-events: none;
    }

    &__alert {
        position: absolute !important;
        top: auto;
        left: calc(100% - 70%);
        z-index: 1;
        margin-bottom: 0;
    }
    .blurItem {
        filter: blur(5px);
    }
}
</style>
