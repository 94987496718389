<template>
    <div id="excelExportModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold" id="exampleModalLabel">{{ $t('cards.excel_export.title') }}</h5>
                    <button type="button" style="margin-right: 5px" class="btn-close" @click="hideModal"></button>
                </div>
                <template v-if="content === 'options'">
                    <div class="modal-body" style="max-width: 100%">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-check mb-3">
                                    <input :disabled="selectedItems.length === 0" type="checkbox" id="onlySelectedRows" class="form-check-input" v-model="onlySelectedRows" />
                                    <label class="form-check-label align-middle" for="onlySelectedRows">{{ $t('cards.excel_export.labels.export_selected') }} {{ selectedItems.length > 0 ? '(' + selectedItems.length + ')' : '' }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="name">{{ $t('cards.excel_export.labels.columns') }}</label>
                                <select v-model="form.columns" class="form-select">
                                    <option value="default" selected>{{ $t('cards.excel_export.default') }} ({{ columns.filter((column) => column.isDefault).length }})</option>
                                    <option value="all">{{ $t('common.all') }} ({{ columns.length }})</option>
                                    <option value="custom">{{ $t('common.custom') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-4" v-if="form.columns === 'custom'">
                            <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 5px; font-size: 11px">
                                <template v-for="column in columns" :key="column.key">
                                    <div class="form-check">
                                        <input type="checkbox" v-model="selectedColumns" :id="'__' + column.key" class="form-check-input" :value="column.key" />
                                        <label class="form-check-label align-middle" :for="'__' + column.key">{{ column.label }}</label>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="form.columns !== 'custom' && selectedColumns && selectedColumns.length > 0">
                            <div class="col-12">
                                <small class="text-subdued">{{ selectedColumns.map((column) => columns.find((item) => item.key === column).label).join(', ') }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="padding: 0.8rem">
                        <button type="button" class="btn btn-primary" @click="handleExport">{{ $t('actions.export') }}</button>
                        <button type="button" class="btn btn-white" @click="hideModal">{{ $t('actions.cancel') }}</button>
                    </div>
                </template>
                <template v-else-if="content === 'exporting'">
                    <div class="modal-body">
                        <div class="row" v-if="processStatus === 0">
                            <div class="col-12">
                                <div class="d-flex justify-content-center">
                                    <div class="d-flex flex-column">
                                        <div class="spinner-border text-primary align-self-center" role="status">
                                            <span class="visually-hidden">{{ $t('common.loading') }}</span>
                                        </div>
                                        <div class="mt-2">
                                            <span>{{ $t('cards.excel_export.labels.exporting_started') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" v-else-if="processStatus === 1">
                            <div class="col-12">
                                <div class="d-flex justify-content-center">
                                    <div class="d-flex flex-column" style="width: 60%">
                                        <span class="align-self-center">{{ totalRows }} ürün dışa aktarılıyor <i class="fa fa-spinner fa-spin"></i></span>
                                        <div class="mt-4 progress">
                                            <div class="progress-bar bg-primary" role="progressbar" :style="'width:' + completedPercentage + '%'" :aria-valuenow="completedPercentage" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2" v-else-if="processStatus === 2">
                            <div class="col-12">
                                <span>Excel dosyanız hazırlandı. Henüz indirme başlamadıysa <a :href="downloadLink">buradan tekrar deneyebilirsiniz.</a></span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="padding: 0.8rem">
                        <button type="button" v-if="processStatus !== 2 && !isSendToEmail" class="btn btn-primary" @click.prevent="sendToEmail">{{ $t('cards.excel_export.labels.send_to_email') }}</button>
                        <button type="button" v-if="isSendToEmail" class="btn btn-primary" :disabled="true">{{ $t('cards.excel_export.labels.will_send_to_email') }}</button>
                        <button type="button" class="btn btn-white" @click="hideModal">{{ $t('actions.close') }}</button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import ExportingService from '@/services/exporting'
import { Modal } from 'bootstrap'

export default {
    name: 'ExcelExportModal',
    props: {
        id: {
            type: Number,
            default: 0,
        },
        entityType: {
            type: String,
            default: null,
        },
        query: {
            type: String,
            default: null,
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
    },
    components: {},
    data: () => ({
        form_submitted: false,
        isSendToEmail: false,
        modalInstance: null,
        onlySelectedRows: false,
        downloadLink: null,
        checkJob: null,
        totalRows: 0,
        completedPercentage: 0,
        jobId: null,
        content: 'options',
        processStatus: 1,
        selectedColumns: [],
        form: {
            columns: 'default',
        },
    }),
    mounted() {},
    computed: {},
    watch: {
        'form.columns': function (newValue, oldValue) {
            if (newValue === 'default') {
                this.selectedColumns = this.columns.filter((column) => column.isDefault).map((column) => column.key)
            } else if (newValue === 'all') {
                this.selectedColumns = this.columns.map((column) => column.key)
            } else {
                this.selectedColumns = this.columns.filter((column) => column.isDefault).map((column) => column.key)
            }
        },
        columns: {
            handler(newValue, oldValue) {
                this.selectedColumns = newValue.filter((column) => column.isDefault).map((column) => column.key)
            },
            deep: true,
            immediate: true,
        },
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.modalActive()
            }
        },
    },
    beforeUnmount() {
        clearInterval(this.checkJob)
    },
    methods: {
        async sendToEmail() {
            if (!this.jobId) {
                this.$toast.error('Dışa aktarma işlemi henüz başlatılmadı.')
                return
            }

            try {
                await ExportingService.sendToEmail(this.jobId)
                this.isSendToEmail = true
            } catch (e) {
                this.$toast.error('E-posta gönderilirken bir hata oluştu.')
            }
        },
        async downloadFile(fileUrl) {
            try {
                const { data } = await this.axios.get(fileUrl, {
                    responseType: 'blob',
                })
                const downloadUrl = window.URL.createObjectURL(new Blob([data]))

                const link = document.createElement('a')
                link.href = downloadUrl
                link.setAttribute('download', 'export.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            } catch (e) {
                console.log(e)
            }
        },
        async checkJobStatus() {
            try {
                const response = await ExportingService.checkJob(this.jobId)
                if (!response.data) {
                    return
                }
                this.completedPercentage = response.data.completedPercentage
                if (response.data.status === 2) {
                    this.totalRows = response.data.total_rows
                    this.processStatus = 1
                } else if (response.data.status === 1) {
                    this.processStatus = 2
                    this.downloadLink = response.data.url
                    await this.downloadFile(response.data.url)
                    clearInterval(this.checkJob)
                } else if (response.data.status === 0) {
                    this.processStatus = 0
                    this.content = 'options'
                    this.$toast.error('Dışa aktarma işlemi sırasında bir hata oluştu.')
                    clearInterval(this.checkJob)
                }
            } catch (e) {
                console.log(e)
                this.processStatus = 0
                this.content = 'options'
                this.$toast.error('Hhata oluştu.')
                clearInterval(this.checkJob)
            }
        },
        async handleExport() {
            if (this.selectedColumns.length === 0) {
                this.$toast.error('En az bir sütun seçmelisiniz.')
                return
            }
            this.content = 'exporting'
            this.processStatus = 0
            try {
                const response = await ExportingService.startExport(
                    {
                        entityId: this.id,
                        entityType: this.entityType,
                        onlySelectedRows: this.onlySelectedRows,
                        selectedItems: this.selectedItems,
                        columns: this.selectedColumns,
                    },
                    this.query,
                )
                if (response.data) {
                    this.jobId = response.data.jobId
                    this.checkJob = setInterval(this.checkJobStatus, 1000)
                }
            } catch (e) {
                this.processStatus = 0
                this.content = 'options'
                this.$toast.error('Dışa aktarma işlemi sırasında bir hata oluştu.')
            }
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('excelExportModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
            this.content = 'options'
            this.processStatus = 1
            this.downloadLink = null
            this.onlySelectedRows = false
            this.form.columns = 'default'
            this.checkJob = null
            this.totalRows = 0
            this.isSendToEmail = false
        },
    },
}
</script>

<style scoped></style>
