<template>
    <div>
        <Loading></Loading>
        <div v-if="customerLogin && user" class="d-flex">
            <div class="alert alert-soft-danger w-100 b-none" style="border-radius: 0; padding-left: 50px" role="alert">
                <strong>#{{ user.id }}</strong> ID'li, <strong>{{ user.email }}</strong> kullanıcısını görüntülüyorsunuz.
            </div>
        </div>
        <Header v-if="isLogged && ['extension', 'me', 'admin-login.user', 'admin-login', 'authorize-card'].indexOf($router.currentRoute.value.name) === -1"></Header>
        <main id="content" role="main" class="main">
            <router-view />
            <SupportFormModal :showModal="showSupportForm" @closeModal="showSupportForm = false" @handleSubmit="handleSendSupport" />
        </main>
    </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Loading from '@/components/Loading.vue'
import SupportFormModal from '@/components/SupportForm/Modal.vue'

export default {
    name: 'App',
    data: () => ({
        showSupportForm: false,
    }),
    components: {
        Header,
        Loading,
        SupportFormModal,
    },
    methods: {
        async getSystemSettings() {
            await this.$store.dispatch('system/getSettings')
        },
        async getMarketExchangeRates() {
            await this.$store.dispatch('settings/getMarketExchangeRates')
        },
        async me() {
            if (localStorage.getItem('token')) {
                try {
                    await this.$store.dispatch('auth/me')
                } catch (error) {
                    console.log(error)
                    // await this.$store.dispatch('auth/logout')
                }

                try {
                    await this.$store.dispatch('wallet/getUserPackage')
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getMarkets() {
            try {
                await this.$store.dispatch('market/findAll')
            } catch (error) {
                console.log(error)
            }
        },
        async getCurrentMarket() {
            if (this.currentMarket) {
                try {
                    const response = await this.$store.dispatch('market/findOne', this.currentMarket.idx)
                    this.$store.dispatch('market/setCurrentMarket', response.data)
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async getBalanceSummary() {
            try {
                await this.$store.dispatch('wallet/getBalanceSummary')
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
        customerLogin() {
            return localStorage.getItem('customerLogin') === '1'
        },
        isLogged() {
            return this.$store.getters['auth/isAuthenticated']
        },
        currentMarket() {
            return this.$store.getters['market/currentMarket']
        },
        isSubscribed() {
            return this.$store.getters['auth/isSubscribed']
        },
    },
    beforeUnmount() {
        this.emitter.off('handleSupportForm')
    },
    created() {
        window.addEventListener('message', ({ data }) => {
            const { lang, token } = data

            if (window.location.pathname === '/extension' && lang && lang !== localStorage.getItem('lang')) {
                localStorage.setItem('lang', lang)
                this.$i18n.locale = lang
            }

            if (token && typeof token === 'string') {
                window.localStorage.setItem('token', token)
                if ((!this.isLogged && window.location.pathname === '/extension') || window.location.pathname === '/login') {
                    window.location.reload()
                }
            }

            if (!this.isLogged && !localStorage.getItem('token')) {
                localStorage.clear()
            }
        })
        this.emitter.on('handleSupportForm', () => {
            this.showSupportForm = true
        })
        this.me()
        if (window.parent === window.self) {
            this.getSystemSettings()
            if (this.isLogged) {
                this.getMarketExchangeRates()
            }
        }
    },
    watch: {
        isLogged() {
            if (this.isLogged) {
                this.getBalanceSummary()
                if (window.parent === window.self) {
                    this.getMarkets()
                    this.getCurrentMarket()
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.css';
@import '@/assets/scss/custom.scss';
</style>
