<template>
    <Popper class="text-secondary" style="border: none !important; margin: 0 !important" hover placement="bottom" arrow>
        <template #content>{{ isCopied ? $t('views.extension.copied') : $t('views.extension.copyClipboard') }}</template
        ><button type="button" class="btn btn-link p-0" @click="clipboard(text)"><i class="bi ms-1 fs-6" :class="[isCopied ? 'bi-check2' : 'bi-clipboard-plus']"></i></button
    ></Popper>
</template>

<script>
export default {
    name: 'ExtensionClipboard',
    props: {
        text: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            isCopied: false,
        }
    },
    methods: {
        clipboard(value) {
            window.navigator.clipboard.writeText(value)
            this.isCopied = true
            setTimeout(() => {
                this.isCopied = false
            }, 2500)
        },
    },
}
</script>

<style scoped>
.btn-link {
    padding: 0.25rem 0.25rem;
    font-weight: 400;
    color: var(--fba-extension-secondary);
}
</style>
