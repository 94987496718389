<template>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div class="card-body text-center">
                    <div class="mb-4">
                        <img class="avatar avatar-xxl avatar-4x3" src="@/assets/svg/illustrations/oc-unlock.svg" alt="Image Description" data-hs-theme-appearance="default" />
                        <img class="avatar avatar-xxl avatar-4x3" src="@/assets/svg/illustrations-light/oc-unlock.svg" alt="Image Description" data-hs-theme-appearance="dark" />
                    </div>

                    <div class="mb-5">
                        <h1 class="display-5">{{ $t('views.auth.otp.title') }}</h1>
                        <p class="mb-0" v-if="responseType === 'sms'">{{ $t('views.auth.otp.code_sent') }}</p>
                        <p v-if="responseType === 'sms' && masked_phone">{{ masked_phone }} {{ $t('views.auth.otp.description') }}</p>
                        <p class="mb-0" v-if="responseType === 'email'">{{ $t('views.auth.otp.email_code_sent') }}</p>
                        <p v-if="responseType === 'email'">{{ emailResponse }}</p>
                        <p style="font-size: 2rem">{{ expirationFormatted }}</p>
                        <a v-if="expiration < 1" href="#" @click.prevent="sendSms(true)">{{ $t('views.auth.labels.resend_code') }}</a>
                    </div>

                    <div class="row gx-2 gx-sm-3" ref="verificationInputs">
                        <div class="col">
                            <div class="mb-4">
                                <input
                                    :disabled="expiration < 1"
                                    type="text"
                                    class="form-control form-control-single-number"
                                    name="code1"
                                    id="twoStepVerificationSrCodeInput1"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                    autofocus=""
                                />
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-4">
                                <input
                                    type="text"
                                    :disabled="expiration < 1"
                                    class="form-control form-control-single-number"
                                    name="code1"
                                    id="twoStepVerificationSrCodeInput1"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                    autofocus=""
                                />
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-4">
                                <input
                                    type="text"
                                    :disabled="expiration < 1"
                                    class="form-control form-control-single-number"
                                    name="code1"
                                    id="twoStepVerificationSrCodeInput1"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                    autofocus=""
                                />
                            </div>
                        </div>

                        <div class="col">
                            <div class="mb-4">
                                <input
                                    type="text"
                                    :disabled="expiration < 1"
                                    class="form-control form-control-single-number"
                                    name="code2"
                                    id="twoStepVerificationSrCodeInput2"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                />
                            </div>
                        </div>

                        <div class="col">
                            <div class="mb-4">
                                <input
                                    type="text"
                                    :disabled="expiration < 1"
                                    class="form-control form-control-single-number"
                                    name="code3"
                                    id="twoStepVerificationSrCodeInput3"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                />
                            </div>
                        </div>

                        <div class="col">
                            <div class="mb-4">
                                <input
                                    type="text"
                                    :disabled="expiration < 1"
                                    class="form-control form-control-single-number"
                                    name="code4"
                                    id="twoStepVerificationSrCodeInput4"
                                    placeholder=""
                                    aria-label=""
                                    maxlength="1"
                                    autocomplete="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="d-grid mb-3">
                        <button type="submit" class="btn btn-primary btn-lg">{{ $t('views.auth.otp.verify_account') }}</button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>
<script>
import AuthService from '@/services/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Otp',
    data() {
        return {
            code: null,
            responseType: 'sms',
            emailResponse: '',
            masked_phone: '',
            expiration: 120,
            expirationFormatted: '',
            form: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['registerForm']),
    },
    watch: {
        code(val) {
            if (val.length === 6) {
                this.verify()
            }
        },
        registerForm: {
            handler(val) {
                if (!val) {
                    //    this.$router.push({ name: 'register' })
                }
            },
            deep: true,
            immediate: true,
        },
        expiration(val) {
            if (val === '') return

            // if (val === 0) {
            //     this.$router.push({ name: 'register' })
            // }
            const minute = Math.floor(val / 60)
            const second = val % 60
            this.expirationFormatted = `${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
        },
    },
    methods: {
        ...mapActions('auth', ['register', 'setRegisterForm']),
        async verify() {
            if (this.code.length !== 6) return
            await this.verifyRegisterOtp({ email: this.registerForm.email, phone_country: this.registerForm.phoneCountry, phone_number: this.registerForm.mobileNo, code: this.code })
        },
        async verifyRegisterOtp(payload) {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            const resp = await AuthService.verifyRegisterOtp(payload)
            if (resp.data.ok) {
                this.code_verified = true
                this.sms_modal = false
                this.emitter.emit('otp', { verified: true, token: resp.data.token, ...this.registerForm })
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            } else if (resp.data.ok === false) {
                this.$toast.error(this.$t('views.auth.otp.invalid_code'))
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            } else if (resp.data.verified) {
                this.code_verified = true
                this.emitter.emit('otp', { verified: true, token: null, ...this.registerForm })
            } else if (resp.data.verified === false) {
                this.$toast.error(this.$t('views.auth.otp.invalid_code'))
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            } else {
                this.sms_modal = false
                this.code_verified = false
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        async countDown() {
            const interval = setInterval(() => {
                if (this.expiration > 0) {
                    this.expiration--
                } else {
                    clearInterval(interval)
                    //  this.$router.push({ name: 'register' })
                }
            }, 1000)
        },
        async sendSms(resend = false) {
            if (!this.registerForm && !resend) {
                this.$router.push({ name: 'register' })
                return
            }
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                const resp = await AuthService.sendRegisterOtp({
                    phone_country: this.registerForm.phoneCountry,
                    gsm: this.registerForm.mobileNo,
                    email: this.registerForm.email,
                })
                if (resp.data.ok) {
                    this.expiration = 120
                    this.responseType = resp.data.verify || 'sms'
                    this.masked_phone = resp.data.masked_phone
                    this.emailResponse = this.$t('views.auth.otp.email_response', { email: resp.data.masked_email })
                    this.countDown()
                } else {
                    if (resp.data.message === 'email_already_registered') {
                        this.$toast.error(this.$t('views.auth.register.email_already_registered'))
                        this.$router.push({ name: 'register' })
                    } else {
                        this.$toast.error(this.$t('views.auth.otp.invalid_code'))
                    }
                }
            } catch (e) {
                this.$toast.error(this.$t('views.auth.register.email_already_registered'))
                this.$router.push({ name: 'register' })
            }

            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    created() {
        this.sendSms()
    },
    mounted() {
        this.form = this.registerForm
        const inputs = this.$refs.verificationInputs.querySelectorAll('input')
        inputs.forEach((input, index) => {
            input.addEventListener('keydown', async (e) => {
                inputs[index].value = e.target.value.replace(/\D/g, '')
                let next = null
                if (e.key === 'Backspace') {
                    if (inputs[index - 1]) {
                        next = inputs[index - 1]
                    }
                } else if (e.key === 'ArrowLeft') {
                    if (inputs[index - 1]) {
                        next = inputs[index - 1]
                    }
                } else if (e.key === 'ArrowRight') {
                    if (inputs[index + 1]) {
                        next = inputs[index + 1]
                    }
                } else if (e.key === 'ArrowUp') {
                    if (inputs[index - 3]) {
                        next = inputs[index - 3]
                    }
                } else if (e.key === 'ArrowDown') {
                    if (inputs[index + 3]) {
                        next = inputs[index + 3]
                    }
                } else if (e.key === 'Enter') {
                    this.verify()
                } else if (e.key === 'Tab') {
                    e.preventDefault()
                    if (inputs[index + 1]) {
                        next = inputs[index + 1]
                    }
                } else if (e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') {
                    next = inputs[index + 1]
                } else {
                    e.preventDefault()
                }
                if (next) {
                    setTimeout(() => {
                        next.focus()
                    }, 50)
                }
                const _this = this
                setTimeout(() => {
                    if (inputs[0].value && inputs[1].value && inputs[2].value && inputs[3].value && inputs[4].value && inputs[5].value) {
                        _this.code = inputs[0].value + inputs[1].value + inputs[2].value + inputs[3].value + inputs[4].value + inputs[5].value
                    }
                }, 100)
            })
        })
    },
    unmounted() {
        this.setRegisterForm(null)
    },
}
</script>
