<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <multiselect
        :custom-label="customLabel"
        :show-labels="false"
        v-model="selectedItems"
        :track-by="session ? 'id' : 'short'"
        label="domain"
        :searchable="true"
        :multiple="multiple"
        :placeholder="title ? title : sources ? $t('inputs.general.labels.select_source') : $t('inputs.general.labels.select_store')"
        :options="items"
        :close-on-select="!multiple"
        :clear-on-select="false"
        :preselect-first="sourceAndTarget ? true : sources && preselectFirst"
    >
        <template v-slot:singleLabel="props"
            ><img class="option__image" :src="props.option.image" /><span class="option__desc ml-1"
                ><span class="option__title">{{ props.option.domain }}</span></span
            ></template
        >
        <template v-slot:option="props">
            <img class="option__image" v-if="props.option.image" :src="props.option.image" alt="test" />
            <i class="option__image" v-if="props.option.icon" :class="'fa fa-' + props.option.icon" />
            <div class="option__desc ml-1 d-flex justify-content-between" style="width: 100%">
                <span class="option__title">{{ props.option.domain }}</span>
                <template v-if="selectedItems">
                    <span
                        class="text-subdued"
                        v-if="selectedItems.short ? selectedItems.short === props.option.short && props.option.id === selectedItems.id : selectedItems.find((item) => item.short === props.option.short && item.id === props.option.id)"
                        >{{ $t('header.markets.selected') }}</span
                    >
                </template>
            </div>
        </template>

        <template v-slot:selection="{ values, isOpen }">
            <span class="multiselect__single" style="display: flex" v-if="values.length === 1" v-show="values.length === 1 && !isOpen">
                <img class="option__image" :src="values[0].image" alt="test" />
                <div class="option__desc ml-1 d-flex justify-content-between" style="width: 100%">
                    <span class="option__title">{{ values[0].domain }}</span>
                </div>
            </span>
            <span class="multiselect__single" style="align-items: center" v-if="values.length > 1" v-show="values.length > 1 && !isOpen">
                <img class="option__image mr-1" :src="value.image" v-for="value in values" alt="test" :key="value.image" />
            </span>
        </template>
    </multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { countryInfo } from '@/utils/helper'
import Multiselect from 'vue-multiselect'

const countries = countryInfo()

export default {
    name: 'MarketSelect',
    components: { Multiselect },
    props: {
        itemId: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
        session: {
            type: Boolean,
            default: false,
        },
        defaultValues: {
            type: Array,
            default: () => [],
        },
        preselectFirst: {
            type: Boolean,
            default: true,
        },
        isCurrentMarket: {
            type: Boolean,
            default: false,
        },
        sources: {
            type: Boolean,
            default: false,
        },
        all: {
            type: Boolean,
            default: false,
        },
        addNew: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        selectCountry: {
            type: Boolean,
            default: false,
        },
        sourceAndTarget: {
            type: Boolean,
            default: false,
        },
        sourceAndTargetItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showList: false,
            selectedItems: [],
        }
    },
    computed: {
        ...mapGetters('market', ['markets', 'currentMarket']),
        items() {
            if (this.currentMarket && this.sources && !this.all) {
                const param = this.$route.params.country
                const sources = this.currentMarket.sources

                if (param) {
                    return sources
                        .sort((a, b) => {
                            if (a.source === param) {
                                return -1
                            }
                            if (b.source === param) {
                                return 1
                            }
                            return 0
                        })
                        .map((item) => {
                            return {
                                short: item.source,
                                id: item.idx,
                                domain: this.getDomain(item.source),
                                image: this.getImage(item.source + '.svg'),
                                $isDisabled: !this.multiple && this.selectedItems.short === item.source,
                                itemId: this.itemId,
                            }
                        })
                }
                return sources.map((item) => {
                    return {
                        short: item.source,
                        id: item.idx,
                        domain: this.getDomain(item.source),
                        image: this.getImage(item.source + '.svg'),
                        $isDisabled: !this.multiple && this.selectedItems.short === item.source,
                        itemId: this.itemId,
                    }
                })
            }
            if (this.sourceAndTarget) {
                return this.sourceAndTargetItems
            }
            if (this.addNew) {
                return Object.keys(countries)
                    .sort((a, b) => {
                        if (countries[a].preferred && !countries[b].preferred) {
                            return -1
                        }
                        if (!countries[a].preferred && !countries[b].preferred) {
                            return a.localeCompare(b)
                        }
                        return 0
                    })
                    .map((country) => ({
                        short: country,
                        domain: !this.selectCountry ? countries[country].domain : country,
                        image: this.getImage(country + '.svg'),
                    }))
            }
            if (this.all) {
                return Object.keys(countries)
                    .sort((a, b) => {
                        if (countries[a].preferred && !countries[b].preferred) {
                            return -1
                        }
                        if (!countries[a].preferred && !countries[b].preferred) {
                            return a.localeCompare(b)
                        }
                        return 0
                    })
                    .map((country) => ({
                        short: country,
                        domain: !this.selectCountry ? countries[country].domain : country,
                        image: this.getImage(country + '.svg'),
                        $isDisabled: !this.sources && !this.selectCountry && this.currentMarket && this.currentMarket.short === country,
                    }))
            }
            let extra = []
            if (this.currentMarket) {
                extra.push({
                    short: 'new',
                    domain: this.$t('header.markets.new_store'),
                    image: null,
                    icon: 'plus',
                })
            }
            if (this.markets.length > 0) {
                return [
                    ...this.markets.map((item) => {
                        return {
                            short: item.short,
                            domain: item.name || this.getDomain(item.short),
                            id: item.idx,
                            image: this.getImage(item.short + '.svg'),
                            $isDisabled: this.currentMarket && this.currentMarket.short === item.short && this.currentMarket.idx === item.idx,
                        }
                    }),
                    ...extra,
                ]
            }
            return []
        },
    },
    methods: {
        ...mapActions('market', ['findAll']),
        getDomain(short) {
            return countries[short].domain || '-'
        },
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        customLabel({ domain }) {
            return `${domain}`
        },
    },
    watch: {
        selectedItems: {
            handler: function (newValue, oldVal) {
                if (newValue.short && newValue.short === 'new') {
                    this.selectedItems = oldVal.length > 0 ? oldVal[0] : oldVal
                    window.location.href = '/settings/integration'
                } else {
                    this.$emit('onSelect', this.selectedItems)
                }
            },
            deep: true,
        },
        defaultValues: {
            handler: function (val) {
                this.selectedItems = val
            },
            deep: true,
            immediate: true,
        },
        isCurrentMarket: {
            handler: function (val) {
                if (val && this.currentMarket) {
                    this.selectedItems = [
                        {
                            id: this.currentMarket.idx,
                            short: this.currentMarket.short,
                            domain: this.currentMarket.name || this.getDomain(this.currentMarket.short),
                            image: this.getImage(this.currentMarket.short + '.svg'),
                            itemId: this.itemId,
                        },
                    ]
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {},
}
</script>
