import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Auth/Login.vue'
import ForgotPassword from '@/views/Auth/ForgotPassword.vue'
import ResetPassword from '@/views/Auth/ResetPassword.vue'
import Register from '@/views/Auth/Register.vue'
import Extension from '@/views/Extension/index.vue'
import Me from '@/views/Me.vue'
import Packages from '@/views/Auth/Packages.vue'
import Otp from '@/views/Auth/Otp.vue'
import RestrictedList from '@/views/RestrictedList/index.vue'
import ViolationPool from '@/views/RestrictedList/ViolationPool.vue'
import ProductSearch from '@/views/ProductSearch/Create.vue'
import ExtensionWatchlist from '@/views/ExtensionWatchlist'
import PrepRequest from '@/views/PrepRequest/index.vue'
import WholesaleSearch from '@/views/WholesaleSearch/Create.vue'
import FollowingListCreate from '@/views/FollowingList/Create.vue'
import FollowingListDetail from '@/views/FollowingList/Detail.vue'
import FollowingList from '@/views/FollowingList/List.vue'
import ProductSearchHistory from '@/views/ProductSearch/History'
import ProductSearchHistoryDetail from '@/views/ProductSearch/History/Detail.vue'
import Setup from '@/views/Setup.vue'
import Settings from '@/views/Settings/Settings.vue'
import Parameters from '@/views/Store/Parameters.vue'
import store from '@/store'
import { decrypt } from '@/utils/encryption'
import { useToast } from 'vue-toast-notification'
import fca from '@/services/fca'
import AdminLoginVue from '@/views/Auth/AdminLogin.vue'
import User from '@/views/Auth/User.vue'
import i18n from '@/i18n'
import AuthorizeCard from '@/views/AuthorizeCard.vue'

const $toast = useToast()

const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        redirect: { name: 'product-search' },
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/following-list/new',
        name: 'following-list.new',
        component: FollowingListCreate,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/following-list/:id/edit',
        name: 'following-list.edit',
        component: FollowingListCreate,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/following-list/:id',
        name: 'following-list.detail',
        component: FollowingListDetail,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/following-list',
        name: 'following-list.list',
        component: FollowingList,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/wholesale-search',
        name: 'wholesale-search',
        component: WholesaleSearch,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/wholesale-search/history',
        name: 'wholesale-search.history',
        component: ProductSearchHistory,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/wholesale-search/history/:id',
        name: 'wholesale-search.history.detail',
        component: ProductSearchHistoryDetail,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/product-search',
        name: 'product-search',
        component: ProductSearch,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/product-search/history',
        name: 'product-search.history',
        component: ProductSearchHistory,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/product-search/history/:id',
        name: 'product-search.history.detail',
        component: ProductSearchHistoryDetail,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/restricted-list/:type',
        name: 'restricted-list',
        component: RestrictedList,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/restricted-list/violation-pool/:type',
        name: 'violation-pool',
        component: ViolationPool,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/packages',
        name: 'packages',
        component: Packages,
        meta: { requiresAuth: true, exceptSource: true },
    },
    {
        path: '/setup',
        name: 'setup',
        component: Setup,
        meta: { requiresAuth: true, requiresSubscription: true, exceptSource: true },
    },
    {
        path: '/store/settings',
        name: 'store.parameters',
        component: Parameters,
        redirect: { name: 'store.parameters.detail', params: { type: 'fba_warehouse' } },
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/store/settings/:type',
        name: 'store.parameters.detail',
        component: Parameters,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/store/settings/:type/:country',
        name: 'store.parameters.detail_c',
        component: Parameters,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/extension-watchlist',
        name: 'extension-watchlist',
        component: ExtensionWatchlist,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true },
    },
    {
        path: '/prep-request/new',
        name: 'create-prep-request',
        component: PrepRequest,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/authorize-card',
        name: 'authorize-card',
        component: AuthorizeCard,
    },
    {
        path: '/prep-request/',
        name: 'prep-request.detail',
        component: PrepRequest,
        meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        redirect: { name: 'settings.integration' },
        children: [
            {
                path: 'currency',
                name: 'settings.currency',
                component: () => import('@/views/Settings/Currency.vue'),
                meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
            },
            {
                path: 'integration',
                name: 'settings.integration',
                component: () => import('@/views/Settings/Integration.vue'),
                meta: { requiresAuth: true, requiresSubscription: true, requiresMarket: true, exceptSource: true },
            },
            {
                path: 'support',
                name: 'settings.support',
                component: () => import('@/views/Settings/SupportForm.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'profile',
                name: 'settings.profile',
                component: () => import('@/views/Settings/Profile.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'subscription',
                name: 'settings.subscription',
                component: () => import('@/views/Settings/Subscription/index.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'subscription/history',
                name: 'settings.subscription.history',
                component: () => import('@/views/Settings/Subscription/History.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'subscription/purchase',
                name: 'settings.subscription.purchase',
                component: () => import('@/views/Settings/Subscription/Purchase.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'subscription/payment/:type',
                name: 'settings.subscription.payment',
                component: () => import('@/views/Settings/Subscription/Payment.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'address',
                name: 'settings.address',
                component: () => import('@/views/Settings/Address.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
            {
                path: 'affiliate',
                name: 'settings.affiliate',
                component: () => import('@/views/Settings/Affiliate.vue'),
                meta: { requiresAuth: true, exceptSource: true },
            },
        ],
    },
    {
        path: '/redirect',
        name: 'redirect',
        // component: Setup,
        beforeEnter: (to, from, next) => {
            if (to.query.state) {
                const state = decrypt(to.query.state)
                next({ path: state.redirectTo, query: to.query })
            }
        },
        meta: { requiresAuth: true, requiresSubscription: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/super-login',
        name: 'admin-login',
        component: AdminLoginVue,
    },
    {
        path: '/super-login/user',
        name: 'admin-login.user',
        component: User,
        meta: { requiresAdminAuth: true },
    },
    {
        path: '/extension',
        name: 'extension',
        component: Extension,
    },
    {
        path: '/me',
        name: 'me',
        component: Me,
    },
    {
        path: '/password-recovery',
        name: 'password-recovery',
        component: ForgotPassword,
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: ResetPassword,
    },
    {
        path: '/two-fa',
        name: 'otp',
        component: Otp,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (window.parent === window.self) {
        fca.sendData(store.getters['auth/user'])
    }
    if (to.matched.some((record) => record.meta.requiresMarket)) {
        if (!store.getters['market/markets'].length) {
            next('/setup')
            return
        }
        const markets = store.getters['market/markets']

        const marketSources = markets.filter((m) => m.sources.length > 0)

        if (!marketSources.length) {
            next('/setup')
            return
        }

        const currentMarket = store.getters['market/currentMarket']

        if (currentMarket && currentMarket.sources.length === 0 && !to.matched.some((record) => record.meta.exceptSource)) {
            $toast.error(i18n.global.t('router.selectDomain'), toastOptions)
            next('/settings/integration')
            return
        }
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters['auth/isAuthenticated']) {
            console.log('not authenticated')
            next('/login')
            return
        }
    }
    if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
        if (!store.getters['auth/adminToken']) {
            next('/super-login')
            return
        }
    }
    if (to.matched.some((record) => record.meta.requiresSubscription)) {
        if (!store.getters['auth/isSubscribed']) {
            next('/packages')
            return
        }
    }

    next()
})

export default router
