const state = {
    selectedCard: null,
    differentCard: null,
    selectedPackage: null,
}

const getters = {
    selectedCard: (state) => state.selectedCard,
    differentCard: (state) => state.differentCard,
    selectedPackage: (state) => state.selectedPackage,
}

const mutations = {
    SET_SELECTED_CARD(state, payload) {
        state.selectedCard = payload
    },
    SET_DIFFERENT_CARD(state, payload) {
        state.differentCard = payload
    },
    SET_SELECTED_PACKAGE(state, payload) {
        state.selectedPackage = payload
    },
}

const actions = {
    setSelectedCard({ commit }, payload) {
        commit('SET_SELECTED_CARD', payload)
    },
    setDifferentCard({ commit }, payload) {
        commit('SET_DIFFERENT_CARD', payload)
    },
    clearSelectedCard({ commit }) {
        commit('SET_SELECTED_CARD', null)
    },
    clearDifferentCard({ commit }) {
        commit('SET_DIFFERENT_CARD', null)
    },
    setSelectedPackage({ commit }, payload) {
        commit('SET_SELECTED_PACKAGE', payload)
    },
    clearSelectedPackage({ commit }) {
        commit('SET_SELECTED_PACKAGE', null)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
