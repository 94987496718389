import { ApiInstance } from './base'

const MarketService = {
    findAll() {
        return ApiInstance.get('/markets')
    },
    update(payload) {
        return ApiInstance.patch('/markets/' + payload.marketId, payload.data)
    },
    remove(payload) {
        return ApiInstance.delete('/markets/' + payload)
    },
    findOne(payload) {
        return ApiInstance.get('/markets/' + payload)
    },
    setSources(payload) {
        return ApiInstance.post('/markets/' + payload.marketId + '/sources', payload.data)
    },
}

export default MarketService
