<template>
    <div style="background-color: var(--bs-gray-200); border-radius: 5px" :style="{ padding: isWarehouseComponent ? '8px' : '5px' }">
        <div v-if="!isWarehouseComponent" class="d-flex justify-content-between align-items-center p-2">
            <!--             <span class="text-down"><i class="bi bi-graph-down-arrow"></i> -1.96%</span>
 -->
            <button type="button" class="btn btn-link p-0" @click="$emit('changePrice', product.detail?.buyboxPrice)">
                Avg BB: <span class="text-primary">{{ product.detail?.buyboxPrice ? currencyFormatter(product.detail?.buyboxPrice) : 0 }}</span>
            </button>
            <button type="button" class="btn btn-link p-0" @click="$emit('changePrice', product.detail?.minFbaPrice)">
                Min FBA: <span class="text-primary">{{ product.detail?.minFbaPrice ? currencyFormatter(product.detail?.minFbaPrice) : 0 }}</span>
            </button>
            <button type="button" class="btn btn-link p-0" @click="$emit('changePrice', product.detail?.minFbmPrice)">
                Min FBM: <span class="text-primary">{{ product.detail?.minFbmPrice ? currencyFormatter(product.detail?.minFbmPrice) : 0 }}</span>
            </button>
        </div>

        <hr />

        <div class="d-flex justify-content-between align-items-centser px-2 py-2">
            <div class="column-warehouse" style="width: 30px">
                <span class="invisible">X</span>
                <span
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content> <div v-html="$t('views.extension.inputs.tooltips.profit')"></div> </template>{{ $t('views.extension.profit') }}</Popper
                    >
                </span>
                <span
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content> <div v-html="$t('views.extension.inputs.tooltips.roi')"></div> </template>{{ $t('views.extension.roi') }}</Popper
                    >
                </span>
                <span
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content> <div v-html="$t('views.extension.inputs.tooltips.margin')"></div> </template>{{ $t('views.extension.margin') }}</Popper
                    >
                </span>
            </div>
            <div class="column-warehouse text-right" style="width: 100px">
                <span>FBA</span>
                <span :class="isHyphenClass(calculate.fba?.profit)"
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>
                            <div class="popper-table">
                                <div class="d-flex">
                                    <div class="d-flex flex-column">
                                        <div class="c-item invisible">X</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.sellerPrice') }}</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.referralFeePercent') }} {{ calculate.fba?.referralFeePercent }}%</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.cog') }}</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.fbaFee') }}</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.3plLogistics') }}</div>
                                        <div class="c-item fw-bold">{{ $t('views.extension.profit') }}</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item fw-bold">FBA</div>
                                        <div class="c-item fw-bold text-success">{{ calculate.fba?.price }}</div>
                                        <div class="c-item text-down">{{ calculate.fba?.referralFee }}</div>
                                        <div class="c-item text-down">{{ calculate.fba?.productCost }}</div>
                                        <div class="c-item text-down">{{ calculate.fba?.fbaFee }}</div>
                                        <div class="c-item text-down">{{ calculate.fba?.warehouseCostOnSource }}</div>
                                        <div class="c-item fw-bold text-success">{{ calculate.fba?.profit }}</div>
                                    </div>
                                </div>
                            </div> </template
                        >{{ calculate.fba?.profit ? currencyFormatter(calculate.fba?.profit) : '-' }}</Popper
                    ></span
                >
                <span :class="isHyphenClass(calculate.fba?.roi)"
                    ><!-- <Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>
                            <div class="popper-table">
                                <p>Forecast your sell price:</p>
                                <div class="d-flex">
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">ROI</div>
                                        <div class="c-item cbr">Price</div>
                                        <div class="c-item cbr">Profit</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">{{ calculate.fba?.roi }}</div>
                                        <div class="c-item cbr">{{ calculate.fba?.price }}</div>
                                        <div class="c-item cbr">{{ calculate.fba?.profit }}</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">{{ calculate.fba?.roi }}</div>
                                        <div class="c-item cbr">{{ calculate.fba?.price }}</div>
                                        <div class="c-item cbr">{{ calculate.fba?.profit }}</div>
                                    </div>
                                </div>
                            </div> </template
                        >{{ currencyFormatter(calculate.fba?.roi) }}</Popper
                    > -->{{ calculate.fba?.roi ? `%${calculate.fba?.roi}` : '-' }}
                </span>

                <span :class="isHyphenClass(calculate.fba?.profitMargin)">{{ calculate.fba?.profitMargin ? `%${calculate.fba?.profitMargin}` : '-' }}</span>
            </div>
            <div class="column-warehouse text-right" style="width: 100px">
                <span>FBM</span>
                <span :class="isHyphenClass(calculate.fbm?.profit)"
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>
                            <div class="popper-table">
                                <div class="d-flex">
                                    <div class="d-flex flex-column">
                                        <div class="c-item invisible">X</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.sellerPrice') }}</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.referralFeePercent') }} {{ calculate.fbm?.referralFeePercent }}%</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.cog') }}</div>
                                        <div class="c-item">{{ $t('views.extension.inputs.tooltips.3plLogistics') }}</div>
                                        <div class="c-item fw-bold">{{ $t('views.extension.profit') }}</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item fw-bold">FBM</div>
                                        <div class="c-item fw-bold text-success">{{ calculate.fbm?.price }}</div>
                                        <div class="c-item text-down">{{ calculate.fbm?.referralFee }}</div>
                                        <div class="c-item text-down">{{ calculate.fbm?.productCost }}</div>
                                        <div class="c-item text-down">{{ calculate.fbm?.warehouseCostOnSource }}</div>
                                        <div class="c-item fw-bold text-success">{{ calculate.fbm?.profit }}</div>
                                    </div>
                                </div>
                            </div> </template
                        >{{ calculate.fbm?.profit ? currencyFormatter(calculate.fbm?.profit) : '-' }}</Popper
                    ></span
                >
                <span :class="isHyphenClass(calculate.fbm?.roi)"
                    ><!-- <Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>
                            <div class="popper-table">
                                <p>Forecast your sell price:</p>
                                <div class="d-flex">
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">ROI</div>
                                        <div class="c-item cbr">Price</div>
                                        <div class="c-item cbr">Profit</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">{{ calculate.fbm?.roi }}</div>
                                        <div class="c-item cbr">{{ calculate.fbm?.price }}</div>
                                        <div class="c-item cbr">{{ calculate.fbm?.profit }}</div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="c-item cbr cbb">{{ calculate.fbm?.roi }}</div>
                                        <div class="c-item cbr">{{ calculate.fbm?.price }}</div>
                                        <div class="c-item cbr">{{ calculate.fbm?.profit }}</div>
                                    </div>
                                </div>
                            </div> </template
                        >{{ currencyFormatter(calculate.fbm?.roi) }}</Popper
                    > -->
                    {{ calculate.fbm?.roi ? `%${calculate.fbm?.roi}` : '-' }}</span
                >
                <span :class="isHyphenClass(calculate.fbm?.profitMargin)">{{ calculate.fbm?.profitMargin ? `%${calculate.fbm?.profitMargin}` : '-' }}</span>
            </div>
            <div class="line-gray" style="width: 1px; height: 110px; margin-top: -8px; margin-right: 2rem; margin-left: 1rem"></div>

            <div class="column-warehouse" style="width: 100px; margin-left: -20px">
                <span class="btn-group">
                    <button
                        class="btn btn-link text-secondary dropdown-toggle p-0 text-secondary"
                        type="button"
                        id="dropdownMenuButtonGhostSecondary"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="color: var(--fba-text) !important; font-size: 12px !important; margin-top: -2px"
                    >
                        {{ currentWarehouse }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width: 55px">
                        <a v-for="item in ['FBA', 'FBM']" :key="item" class="dropdown-item" href="#" @click="currentWarehouse = item"> {{ item }}</a>
                    </div>
                </span>
                <template v-if="currentWarehouse === 'FBA'">
                    <span>{{ $t('views.extension.fees') }}</span>
                    <span
                        ><Popper class="light-popper" hover placement="bottom" arrow>
                            <template v-if="calculate.fba?.referralFeePercent" #content>%{{ calculate.fba?.referralFeePercent }}</template
                            >{{ $t('views.extension.refFee') }}</Popper
                        ></span
                    >
                    <span>3PL</span>
                </template>
                <template v-if="currentWarehouse === 'FBM'">
                    <span>{{ $t('views.extension.fees') }}</span>
                    <span
                        ><Popper class="light-popper" hover placement="bottom" arrow>
                            <template v-if="calculate.fbm?.referralFeePercent" #content>%{{ calculate.fbm?.referralFeePercent }}</template
                            >{{ $t('views.extension.refFee') }}</Popper
                        ></span
                    >
                    <span>3PL</span>
                </template>
            </div>
            <div v-if="currentWarehouse === 'FBA'" class="column-warehouse text-right" style="width: 50px">
                <span class="invisible">x</span>
                <span class="invisible">x</span>
                <span class="invisible">x</span>
                <span class="text-left" style="margin-top: -5px"
                    ><input
                        v-model="fbaWarehouseCost"
                        v-debounce:1000="fbaWarehouseCostFunc"
                        @input="clearFbaWarehouseCost"
                        type="text"
                        style="width: 40px !important; height: 20px"
                        :placeholder="[targetCurrency ? getCurrencySymbol(countries[targetCurrency]?.symbol, 1) : product?.short ? getCurrencySymbol(countries[product?.short]?.symbol, 1) : null]"
                    />
                </span>
            </div>
            <div v-if="currentWarehouse === 'FBM'" class="column-warehouse" style="width: 50px">
                <span class="invisible">x</span>
                <span class="invisible">x</span>
                <span class="invisible">x</span>
                <span class="text-left" style="margin-top: -5px"
                    ><input
                        v-model="fbmWarehouseCost"
                        v-debounce:1000="fbmWarehouseCostFunc"
                        @input="clearFbmWarehouseCost"
                        type="text"
                        style="width: 40px !important; height: 20px"
                        :placeholder="[targetCurrency ? getCurrencySymbol(countries[targetCurrency]?.symbol, 1) : product?.short ? getCurrencySymbol(countries[product?.short]?.symbol, 1) : null]"
                    />
                </span>
            </div>
            <div class="column-warehouse" style="width: 50px">
                <span v-if="isWarehouseComponent">
                    <button :class="{ invisible: isWarehouseComponent }"><i class="fa-solid fa-sliders"></i></button>
                </span>

                <span v-if="!isWarehouseComponent" class="text-right"
                    ><Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>{{ $t('views.extension.calculateTableText') }}</template
                        ><button v-if="!isWarehouseComponent" type="button" class="btn btn-link link-secondary m-0 p-0" data-bs-toggle="modal" data-bs-target=".warehouse-modal">
                            <i class="fa-solid fa-sliders" style="font-size: 14px"></i></button></Popper
                ></span>
                <template v-if="currentWarehouse === 'FBA'">
                    <span class="text-right">{{ calculate.fba?.totalFees ? currencyFormatter(calculate.fba?.totalFees) : '-' }}</span>
                    <span class="text-right">{{ calculate.fba?.referralFee ? currencyFormatter(calculate.fba?.referralFee) : '-' }}</span></template
                >
                <template v-if="currentWarehouse === 'FBM'">
                    <span class="text-right">{{ calculate.fbm?.totalFees ? currencyFormatter(calculate.fbm?.totalFees) : '-' }}</span>
                    <span class="text-right">{{ calculate.fbm?.referralFee ? currencyFormatter(calculate.fbm?.referralFee) : '-' }}</span></template
                >
                <span class="text-right">
                    <template v-if="targetCurrency !== product?.short">
                        <span v-if="warehouseCostLoading" class="spinner-border" role="status" style="margin-top: -10px; width: 1rem; height: 1rem; border-width: 0.1rem"></span>
                        <template v-else>{{ currentWarehouse === 'FBA' ? fbaWarehouseCostOnSource : fbmWarehouseCostOnSource }}</template></template
                    >
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { countryInfo } from '@/utils/helper'
import { formatCurrency, getCurrencySymbol } from '@/utils/format-number'

export default {
    name: 'ExtensionInputs',
    props: {
        isWarehouseComponent: {
            type: Boolean,
            default: false,
        },
        targetCurrency: {
            type: String,
            default: '',
        },
        notes: {
            type: String,
            default: '',
        },
        cogPrice: {
            type: [String, Number],
            default: 0,
        },
        userParameters: {
            type: Object,
            default: () => {},
        },
        parametersClone: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            countries: countryInfo(),
            formatCurrency,
            getCurrencySymbol,
            currentWarehouse: 'FBA',
            fbaWarehouseCost: null,
            fbmWarehouseCost: null,
        }
    },
    created() {},
    computed: {
        ...mapState('extension', ['product', 'calculate', 'costs', 'warehouseCostLoading', 'parameters']),
        fbaWarehouseCostOnSource() {
            if (this.calculate.fba?.warehouseCostOnSource && this.targetCurrency) {
                return formatCurrency(this.countries[this.product?.short]?.symbol, this.calculate.fba?.warehouseCostOnSource)
            }
            return null
        },
        fbmWarehouseCostOnSource() {
            if (this.calculate.fbm?.warehouseCostOnSource && this.targetCurrency) {
                return formatCurrency(this.countries[this.product?.short]?.symbol, this.calculate.fbm?.warehouseCostOnSource)
            }
            return null
        },
    },
    methods: {
        ...mapActions('extension', ['getProduct', 'setCalculate']),
        ...mapMutations('extension', ['SET_COSTS']),
        isHyphenClass(text) {
            if (!text) return
            return {
                'text-down': text.toString().includes('-'),
                'text-success': !text.toString().includes('-'),
            }
        },
        currencyFormatter(number) {
            return formatCurrency(this.countries[this.product?.short]?.symbol, number)
        },
        fbaWarehouseCostFunc() {
            if (!this.fbaWarehouseCost?.endsWith('.') && this.fbaWarehouseCost !== this.costs.fba?.warehouseCost) {
                this.updateWarehouseData()
            }
        },
        fbmWarehouseCostFunc() {
            if (!this.fbmWarehouseCost?.endsWith('.') && this.fbmWarehouseCost !== this.costs.fbm?.warehouseCost) {
                this.updateWarehouseData()
            }
        },
        updateWarehouseData() {
            const options = JSON.parse(JSON.stringify(this.parametersClone))
            const parameters = this.updateScanningOptions(options, this.userParameters)

            this.SET_COSTS({
                fba: {
                    warehouseCost: this.fbaWarehouseCost || '0',
                    warehouseCostOnSource: null,
                },
                fbm: {
                    warehouseCost: this.fbmWarehouseCost || '0',
                    warehouseCostOnSource: null,
                },
            })
            const updateData = {
                asin: this.$route.query.asin,
                source: this.targetCurrency !== this.product?.short ? this.targetCurrency : null,
                target: this.product?.short,
                price: this.product?.userHistory?.buyboxPrice ?? null,
                buying_price: this.cogPrice || (this.product?.userHistory?.buyingPrice ?? null),
                dimension: this.product?.dimension,
                fees: this.product?.fees,
                notes: this.notes,
                parameters: { ...parameters, ...this.product.userHistory?.parameters },
                costs: {
                    fba: {
                        warehouseCost: this.fbaWarehouseCost || '0',
                        warehouseCostOnSource: null,
                    },
                    fbm: {
                        warehouseCost: this.fbmWarehouseCost || '0',
                        warehouseCostOnSource: null,
                    },
                },
            }
            this.setCalculate(updateData)
        },
        clearFbaWarehouseCost(e) {
            if (this.fbaWarehouseCost) {
                if (this.fbaWarehouseCost.length === 1 && e.data === '.') {
                    this.fbaWarehouseCost = null
                    return
                }
                this.fbaWarehouseCost = this.fbaWarehouseCost.replace(/[^0-9.]/g, '')
                let validatedInput = this.fbaWarehouseCost.toString().replace(/^0(\d+)/, '0.$1')
                if (validatedInput.split('.').length - 1 > 1) {
                    validatedInput = validatedInput.slice(0, -1)
                }
                this.fbaWarehouseCost = validatedInput
            }
        },
        clearFbmWarehouseCost(e) {
            if (this.fbmWarehouseCost) {
                if (this.fbmWarehouseCost.length === 1 && e.data === '.') {
                    this.fbmWarehouseCost = ''
                }
                this.fbmWarehouseCost = this.fbmWarehouseCost.replace(/[^0-9.]/g, '')
                let validatedInput = this.fbmWarehouseCost.toString().replace(/^0(\d+)/, '0.$1')
                if (validatedInput.split('.').length - 1 > 1) {
                    validatedInput = validatedInput.slice(0, -1)
                }
                this.fbmWarehouseCost = validatedInput
            }
        },
        updateScanningOptions(scanningOptions, parameters) {
            const updatedOptions = {}

            for (const category in scanningOptions) {
                for (const option in scanningOptions[category]) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (parameters?.hasOwnProperty(option)) {
                        updatedOptions[option] = parameters[option]
                    } else {
                        updatedOptions[option] = scanningOptions[category][option]
                    }
                }
            }

            for (const param in parameters) {
                // eslint-disable-next-line no-prototype-builtins
                if (!updatedOptions?.hasOwnProperty(param)) {
                    updatedOptions[param] = parameters[param]
                }
            }

            return updatedOptions
        },
    },
    watch: {
        costs: {
            deep: true,
            immediate: true,
            handler(data) {
                this.fbaWarehouseCost = data?.fba?.warehouseCost > 0 ? data?.fba?.warehouseCost : this.calculate?.fba?.warehouseCost || null

                this.fbmWarehouseCost = data?.fbm?.warehouseCost > 0 ? data?.fbm?.warehouseCost : this.calculate?.fbm?.warehouseCost || null

                if (this.fbaWarehouseCost > 0 && typeof this.fbaWarehouseCost === 'number') {
                    this.fbaWarehouseCost = Math.floor(this.fbaWarehouseCost * Math.pow(10, 2)) / Math.pow(10, 2)
                }
                if (this.fbmWarehouseCost > 0 && typeof this.fbmWarehouseCost === 'number') {
                    this.fbmWarehouseCost = Math.floor(this.fbmWarehouseCost * Math.pow(10, 2)) / Math.pow(10, 2)
                }

                if (this.fbaWarehouseCost == '' || data?.fba?.warehouseCost == 0) {
                    this.fbaWarehouseCost = null
                    return
                }
                if (this.fbmWarehouseCost == '' || data?.fbm?.warehouseCost == 0) {
                    this.fbmWarehouseCost = null
                }
            },
        },
    },
}
</script>

<style scoped>
.btn-link {
    padding: 0.25rem 0.25rem;
    font-weight: 400;
    font-size: 12px;
    color: var(--fba-text);
}
</style>
