<template>
    <Popper v-if="content" v-bind="$attrs" hover arrow :class="'popp'">
        <template #content>
            <span>{{ content }}</span>
        </template>
        <i class="bi bi-info-circle-fill" style="opacity: 0.3"></i>
    </Popper>
</template>

<script>
export default {
    name: 'InfoTooltip',
    components: {},
    props: {
        content: {
            type: String,
            required: true,
            default: null,
        },
    },
}
</script>
