<template>
    <div class="container py-5 py-sm-7">
        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div class="card-body">
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signinSrEmail">User ID</label>
                        <input v-model="user_id" tabindex="1" type="number" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="1234" required="" />
                    </div>
                    <!-- End Form -->
                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="handleSubmit">
                            Gönder
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'UserLogin',
    data: () => ({
        user_id: null,
        sending: false,
    }),
    methods: {
        ...mapActions('auth', ['setToken', 'me', 'adminLogout']),
        handleSubmit() {
            if (!this.user_id) {
                this.$toast.error('Lütfen kullanıcı adınızı girin.')
                return
            }
            this.sending = true
            const $this = this
            AuthService.loginByUserId({ user_id: this.user_id })
                .then(async (response) => {
                    localStorage.setItem('customerLogin', '1')
                    await this.setToken(response.data.token)
                    await this.me()
                    this.$toast.success('Müşteri girişi yapıldı. Yönlendiriliyorsunuz...')
                    setTimeout(() => {
                        window.location.href = '/'
                        this.$store.commit('set', { k: 'loading', v: { status: false } })
                    }, 1500)
                })
                .catch((err) => {
                    const errCode = err.response?.status
                    if (errCode === 401) {
                        $this.$toast.error('Yönetici oturumu sonlanmış.')
                        $this.adminLogout()
                    } else {
                        $this.$toast.error(err.message)
                    }
                })
                .finally(() => {
                    this.sending = false
                })
        },
    },
    mounted() {},
    computed: {
        ...mapGetters(['adminToken']),
    },
}
</script>
