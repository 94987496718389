<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="container-fluid py-3 py-sm-3" style="margin-bottom: 2rem; max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-3 position-relative">
                        <div class="navbar-expand-lg navbar-vertical mb-3 mb-lg-5 position-sticky" style="top: 0">
                            <div id="navbarSettingsCardWithNav" class="card card-navbar-nav nav nav-pills nav-vertical">
                                <span class="dropdown-header">{{ $t('menu.settings.store') }}</span>
                                <router-link v-for="group in inputs" :key="group" class="nav-link" :active-class="'active'" :to="'/store/settings/' + group.name + '/' + selectedCountry">{{ $t('cards.parameters.' + group.name) }} </router-link>
                                <router-link class="nav-link" :active-class="'active'" :to="'/store/settings/exchange-rates'">{{ $t('cards.parameters.exchange_rates') }} </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="col-12" v-if="defaultParams && selectedCountry && selectedCountry === 'US'">
                            <div class="alert alert-soft-primary" v-html="$t('messages.defaultParameters')"></div>
                        </div>
                        <div class="fba-page-header position-sticky" style="top: 0rem; background-color: #fcfcff; z-index: 1">
                            <div class="fba-page-header-row">
                                <div class="fba-page-header-title-wrapper d-flex justify-content-between">
                                    <h1 class="fba-page-header-title mt-2">{{ $t('menu.settings.parameters') }}</h1>
                                    <div class="d-flex flex-row align-items-center">
                                        <MarketSelect :sources="true" :multiple="false" @onSelect="handleSelectCountry"></MarketSelect>
                                        <button class="dropdown btn btn-white ml-1" data-bs-toggle="dropdown" aria-expanded="true" style="max-height: 40px">
                                            <i class="bi bi-three-dots-vertical"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" style="">
                                            <a class="dropdown-item" href="#" @click.prevent="handleExport">{{ $t('actions.export_settings') }}</a>
                                            <a class="dropdown-item" href="#" @click.prevent="handleImport">{{ $t('actions.import_settings') }}</a>
                                            <a v-if="selectedCountry === 'US'" class="dropdown-item" href="#" @click.prevent="handleSetDefault">{{ $t('actions.set_default') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="divider-end mb-3 position-sticky" style="top: 3rem"></span>
                        <div class="d-flex justify-content-center">
                            <div class="w-100">
                                <input type="file" ref="fileInput" style="display: none" accept="application/JSON" />

                                <div v-if="selectedCountry" v-for="(group, i) in inputs" :key="group" :ref="'el' + i" :id="'el' + i">
                                    <div :style="$route.params.type === group.name ? 'display:block;' : 'display:none'">
                                        <Card>
                                            <CardSection :title="$t('cards.parameters.' + group.name)"></CardSection>
                                            <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px">
                                                <template v-for="input in group.inputs" :key="input">
                                                    <div v-if="(!input.excluded || (input.excluded && input.excluded.indexOf(selectedCountry) === -1)) && (!input.included || (input.included && input.included.indexOf(selectedCountry) !== -1))">
                                                        <div style="padding-top: 1rem">
                                                            <div class="d-flex ml-2 mr-2 flex-column position-relative" style="width: 20 rem">
                                                                <label class="form-label">{{ $t('inputs.parameters.labels.' + input.name) }} </label>
                                                                <Popper v-if="input.helpText" v-bind="$attrs" hover arrow :class="'popp'">
                                                                    <template #content>
                                                                        <span style="width: 100px">{{ $t('inputs.parameters.help_text.' + input.name) }}</span>
                                                                    </template>
                                                                    <span v-if="input.helpText" class="form-text m-0 clamp">{{ $t('inputs.parameters.help_text.' + input.name) }}</span>
                                                                </Popper>
                                                                <template v-if="input.type === 'select'">
                                                                    <div class="">
                                                                        <select v-model="values[input.name]" class="form-select">
                                                                            <option v-for="option in input.options" :key="option" :value="option.value">{{ option.label }}</option>
                                                                        </select>
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="mt-3">
                                                                        <div class="input-group mb-3">
                                                                            <div class="input-group-prepend" v-if="input.prefix">
                                                                                <span class="input-group-text">{{ convertVariables(input.prefix) }}</span>
                                                                            </div>
                                                                            <input type="number" v-model="values[input.name]" :name="input.name" aria-label="" min="0" class="form-control" />
                                                                            <div class="input-group-append" v-if="input.suffix">
                                                                                <span class="input-group-text"> {{ convertVariables(input.suffix) }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="d-flex justify-content-end bordered-top mt-1" style="padding: 1rem 2rem 0 0">
                                                <button class="btn btn-primary" @click="saveChanges">{{ $t('actions.saveChanges') }}</button>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div :style="selectedCountry && $route.params.type === 'exchange-rates' ? 'display:block' : 'display:none'" :ref="'el_exchangeRates'" id="el_exchangeRates">
                                    <Card>
                                        <div class="card-section bordered-bottom pl-2">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex align-items-center flex-1-1 justify-content-between">
                                                        <h2 class="d-flex card__title text__heading">{{ $t('cards.parameters.exchange_rates') }}</h2>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                :checked="!values['use_default_exchange'] || (values['use_default_exchange'] && values['use_default_exchange'] === '1')"
                                                                value="1"
                                                                name="use_default_exchange"
                                                                id="formSwitch1"
                                                                @change="handleFormSwitch"
                                                            />
                                                            <label class="form-check-label" for="formSwitch1">{{ $t('common.automatic') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px">
                                            <div
                                                style="padding-top: 1rem"
                                                class="ml-2 mr-2"
                                                :key="countryCode"
                                                v-for="countryCode in Object.keys(allCountries).filter((country) => country !== currentMarket.short && currentMarket.sources.find((source) => source.source === allCountries[country].short))"
                                            >
                                                <div class="input-group mb-2">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> 1 {{ countryCode }} = </span>
                                                    </div>
                                                    <template v-if="!values['use_default_exchange'] || (values['use_default_exchange'] && values['use_default_exchange'] === '1')">
                                                        <input
                                                            :disabled="true"
                                                            type="number"
                                                            v-model="defaultExchangeValues[countryCode + allCountriesOrj[currentMarket.short].symbol]"
                                                            :name="countryCode"
                                                            aria-label=""
                                                            min="0"
                                                            class="form-control"
                                                            @input.prevent="handleFormInput"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        <input type="number" v-model="exchangeValues[countryCode]" :name="countryCode" aria-label="" min="0" class="form-control" @input.prevent="handleFormInput" />
                                                    </template>

                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ allCountriesOrj[currentMarket.short].symbol }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end bordered-top mt-1" style="padding: 1rem 2rem 0 0">
                                            <button class="btn btn-primary" @click="saveExchangeRateChanges">{{ $t('actions.saveChanges') }}</button>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MarketSelect from '@/components/Inputs/MarketSelect.vue'
import Card from '@/components/Layouts/Card.vue'
import CardSection from '@/components/Layouts/CardSection.vue'
import { scanningOptionInputs } from '@/utils/formInputs'

import { countryInfo, unitsLongName } from '@/utils/helper'
import { mapActions, mapGetters } from 'vuex'

const countries = countryInfo()
const countries_ = Object.keys(countries).map((short) => ({
    short,
    symbol: countries[short].symbol,
    currency: countries[short].currency,
}))

export default {
    name: 'Parameters',
    components: { Card, CardSection, MarketSelect },
    data() {
        return {
            inputs: scanningOptionInputs,
            form_changed: false,
            dirtyFields: [],
            marketSourceId: null,
            selectedCountry: null,
            defaultParams: false,
            allCountriesOrj: countries,
            allCountries: countries_.reduce((acc, cur) => {
                acc[cur.symbol] = cur
                return acc
            }, {}),
            values: {},
            exchangeValues: {},
            defaultExchangeValues: {},
            oldValues: {},
        }
    },
    mounted() {
        if (this.selectedCountry) {
            this.marketSourceId = this.currentMarket.sources.find((market) => market.source === this.selectedCountry).idx
            this.prepareData()
        }
    },
    methods: {
        ...mapActions('settings', ['getScanningOptions', 'getMarketExchangeRates', 'getExchangeRates', 'updateScanningOptions', 'setMarketExchangeRates']),
        handleFormSwitch(e) {
            this.values[e.target.name] = e.target.checked ? '1' : '0'
            this.handleFormInput(e)
        },
        handleExport() {
            const defaultData = {}

            for (const group of this.inputs) {
                for (const input of group.inputs) {
                    defaultData[input.name] = this.values[input.name] || ''
                }
            }

            const data = JSON.stringify(defaultData)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents')
            const a = document.createElement('a')
            a.download = 'parameters__' + this.selectedCountry + '.json'
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':')
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)
        },
        handleImport() {
            this.$refs.fileInput.click()

            this.$refs.fileInput.addEventListener('change', async (e) => {
                const file = e.target.files[0]
                if (!file) return
                const reader = new FileReader()
                reader.onload = async (e) => {
                    const text = e.target.result
                    const data = JSON.parse(text)
                    if (!data) return
                    for (const group of this.inputs) {
                        for (const input of group.inputs) {
                            if (!data[input.name]) continue
                            if (!data[input.name].length) continue

                            this.values[input.name] = data[input.name] || ''
                        }
                    }

                    this.$toast.success(this.$t('messages.importSuccess'))
                }
                reader.readAsText(file)
            })
        },
        handleSelectCountry(item) {
            if (item && item.short) {
                this.selectedCountry = item.short
                this.$router.push({ name: 'store.parameters.detail_c', params: { country: item.short } })
            }
        },
        async saveExchangeRateChanges() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.updateScanningOptions({
                marketSourceId: this.marketSourceId,
                type: 'parameters',
                inputs: {
                    use_default_exchange: this.values['use_default_exchange'],
                },
            })
            await this.setMarketExchangeRates(this.exchangeValues)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        async prepareData() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            if (this.marketSourceId) {
                await this.getScanningOptions(this.marketSourceId)
                await this.getMarketExchangeRates()
                await this.getExchangeRates()
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        goTo(id) {
            const el = this.$refs[id]
            if (el.offsetTop) {
                window.scrollTo({ top: el.offsetTop - 40, behavior: 'smooth' })
            } else {
                window.scrollTo({ top: el[0].offsetTop - 60, behavior: 'smooth' })
            }
        },
        handleSetDefault() {
            if (confirm('US için varsayılan ayarlara dönmek istediğinize emin misiniz?') === false) return
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            this.$store.dispatch('settings/setDefaultParameters', { marketSourceId: this.marketSourceId }).then(() => {
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                this.$toast.success('Başarılı')
            })
        },
        convertVariables(value) {
            if (!this.selectedCountry) return value
            const country = countries[this.selectedCountry]

            if (value.indexOf('{currency}') !== -1) return value.split('{currency}').join(country.currency)
            if (value.indexOf('{weight_unit}') !== -1) return ' / lbs'
            if (value.indexOf('{weight_unit_long}') !== -1) return value.split('{weight_unit_long}').join(unitsLongName(country.weight_unit))
            return value
        },
        discardChanges() {
            this.values = this.oldValues
            this.dirtyFields = []
            this.form_changed = false
        },
        async saveChanges() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.updateScanningOptions({ marketSourceId: this.marketSourceId, inputs: this.values, type: 'parameters' })
            this.oldValues = this.values
            this.dirtyFields = []
            this.form_changed = false
            this.defaultParams = false
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        handleFormInput(e) {
            if (this.dirtyFields.indexOf(e.target.name) === -1 && e.target.value !== '') {
                this.dirtyFields.push(e.target.name)
            }
            if (this.dirtyFields.indexOf(e.target.name) > -1 && e.target.value === '' && !this.oldValues[e.target.name]) {
                this.dirtyFields = this.dirtyFields.filter((item) => item !== e.target.name)
            }
            if (this.oldValues[e.target.name] === e.target.value && this.dirtyFields.indexOf(e.target.name) > -1) {
                this.dirtyFields = this.dirtyFields.filter((item) => item !== e.target.name)
            }

            if (this.dirtyFields.length > 0) {
                this.form_changed = true
            } else {
                this.form_changed = false
            }
        },
    },
    computed: {
        ...mapGetters('market', ['currentMarket']),
        ...mapGetters('settings', ['scanningOptions', 'marketExchangeRates', 'exchangeRates']),
    },
    watch: {
        marketExchangeRates(newVal) {
            if (newVal && newVal.length > 0) {
                newVal.map((item) => {
                    this.exchangeValues[item.currency] = item.value
                })
            }
        },
        exchangeRates(newVal) {
            if (newVal && newVal.length > 0) {
                newVal.map((item) => {
                    this.defaultExchangeValues[item.from + item.to] = (+item.value).toFixed(2)
                })
            }
        },
        scanningOptions(newVal) {
            if (newVal && newVal.length > 0) {
                this.defaultParams = false
                newVal.map((item) => {
                    if (item.is_default === 1) {
                        this.defaultParams = true
                    }
                    this.values[item.key] = item.value || '0'
                    this.oldValues[item.key] = item.value
                })
            }
        },
        selectedCountry: {
            handler(newVal) {
                if (newVal) {
                    this.values = {}
                    this.oldValues = {}
                    this.form_changed = false
                    this.dirtyFields = []
                    this.marketSourceId = this.currentMarket.sources.find((market) => market.source === newVal).idx

                    if (this.marketSourceId) {
                        this.prepareData()
                    }
                }
            },
            immediate: true,
        },
        currentMarket: {
            handler(newVal) {
                if (newVal) {
                    const param = this.$route.params.country
                    console.log(param)
                    if (!param) {
                        const country = this.currentMarket.sources[0].source

                        this.$router.push({ name: 'store.parameters.detail_c', params: { country } })
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>
