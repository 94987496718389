<template>
    <div>
        <h1>Welcome to the Home page!</h1>
    </div>
</template>

<script>
export default {
    name: 'Dashboard',
}
</script>
