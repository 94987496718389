import { ApiInstance, WalletInstance } from './base'

const WalletService = {
    getAddress() {
        return WalletInstance.post('/address/Get')
    },
    createAddress(params) {
        return WalletInstance.post('/address/Create', params)
    },
    updateAddress(params) {
        return WalletInstance.post('/address/Update', params)
    },
    getUserPackage(params) {
        return WalletInstance.post('/package/UserPackage', params)
    },
    getPreviewPackageRenew() {
        return WalletInstance.post('/package/PreviewPackageRenew')
    },
    getCreditCards() {
        return WalletInstance.post('/creditCard/List')
    },
    saveCreditCard(params) {
        return WalletInstance.post('/creditCard/Create', params)
    },
    createIntent() {
        return WalletInstance.post('/stripe/CreateIntent')
    },
    confirmCard(params) {
        return WalletInstance.post('/creditCard/ConfirmCard', params)
    },
    setAsDefault(params) {
        return WalletInstance.post('/creditCard/SetAsDefault', params)
    },
    removeCard(params) {
        return WalletInstance.post('/creditCard/Delete', params)
    },
    chargeWithDifferentCard(params) {
        return WalletInstance.post('/creditCard/ChargeFreeForm', params)
    },
    chargeWithSavedCard(params) {
        return WalletInstance.post('/creditCard/Charge', params)
    },
    getPreviewPackage(params) {
        return WalletInstance.post('/package/PreviewPackageChange', params)
    },
    createSubscription(params) {
        if (params.token) {
            WalletInstance.defaults.headers.common['Authorization'] = `Bearer ${params.token}`
        }
        return WalletInstance.post('/package/Subscribe', params)
    },
    changeSubscription(params) {
        return WalletInstance.post('/package/Change', params)
    },
    cancelSubscription(params) {
        return WalletInstance.post('/package/Cancel', params)
    },
    pauseSubscription() {
        return ApiInstance.post('/subscription/pause')
    },
    unpauseSubscription() {
        return ApiInstance.post('/subscription/unpause')
    },
    checkPaymentStatus(params) {
        return WalletInstance.post('/package/CheckPaymentStatus', params)
    },
    dontCancelSubscription(params) {
        return WalletInstance.post('/stripe/DoNotCancelSubscription', params)
    },
    getBalanceSummary(params) {
        return ApiInstance.get('/subscription/summary', params)
    },
    getSubscriptionHistory(params) {
        const urlParams = new URLSearchParams(params).toString()
        return ApiInstance.get('/subscription/history?' + urlParams)
    },
    getCode() {
        return WalletInstance.get('/affiliate/GetCode')
    },
    clickAffiliate(payload) {
        return WalletInstance.post('/affiliate/ClickAffiliate', payload)
    },
    couponList() {
        return WalletInstance.post('/coupon/List')
    },
}

export default WalletService
