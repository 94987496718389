<template>
    <Card v-if="selectedPackage && selectedPackage.preview && selectedPackage.preview.newPackage">
        <div class="d-flex card-content" style="padding-top: 1rem">
            <div class="card-body pt-0">
                <div class="overflow-hidden bottom-rounded" v-if="true">
                    <div class="d-flex">
                        <div class="col-3">
                            <PackageSummary :title="$t('components.subscription.paymentMethod.newPlan')" :activePackage="{ package: selectedPackage.preview.newPackage }" />
                        </div>
                        <div class="col-3">
                            <div class="d-flex flex-column p-2 ml-1" style="width: 100%">
                                <template v-if="selectedPackage.summary.upgrade">
                                    <div class="row align-items-center">
                                        <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.remainingSubscription') }}:</span>
                                        <h4 class="col-6 fw-semibold text-end text-dark mb-0">{{ selectedPackage.summary.period_invoice }} {{ selectedPackage.summary.period_type }}</h4>
                                    </div>
                                    <hr style="margin: 1rem 0" />
                                    <div class="row align-items-center">
                                        <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.packageAmount') }}:</span>
                                        <h4 class="col-6 text-end text-dark mb-0 fw-semibold">{{ getFormattedPrice(+selectedPackage.preview.newPackage.basePrice / 100) }}</h4>
                                    </div>
                                    <template v-for="discount in selectedPackage.summary.discounts" :key="discount.name">
                                        <hr style="margin: 1rem 0" />
                                        <div class="row align-items-center">
                                            <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.discount') }}:</span>
                                            <h4 class="col-6 fw-semibold text-end text-dark mb-0">-{{ getFormattedPrice(discount.amount / 100) }}</h4>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row align-items-center">
                                        <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.period') }}:</span>
                                        <h4 class="col-6 text-end text-dark mb-0 fw-semibold">1 {{ $t('components.subscription.paymentMethod.month') }}</h4>
                                    </div>
                                    <hr style="margin: 1rem 0" />
                                    <div class="row align-items-center">
                                        <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.packageAmount') }}:</span>
                                        <h4 class="col-6 fw-semibold text-end text-dark mb-0">{{ getFormattedPrice(selectedPackage.summary.package_price / 100) }}</h4>
                                    </div>
                                    <template v-for="discount in selectedPackage.summary.discounts" :key="discount.name">
                                        <hr style="margin: 1rem 0" />
                                        <div class="row align-items-center">
                                            <span class="col-6 fw-semibold">{{ $t('components.subscription.paymentMethod.discount') }}:</span>
                                            <h4 class="col-6 fw-semibold text-end text-dark mb-0">-{{ getFormattedPrice(discount.amount / 100) }}</h4>
                                        </div>
                                    </template>
                                </template>
                                <hr style="margin: 1rem 0" />
                                <div class="row align-items-center">
                                    <span class="col-6 fw-bold" style="font-size: 1rem">{{ $t('components.subscription.paymentMethod.total') }}:</span>
                                    <h4 class="col-6 fw-bold text-end text-dark mb-0" style="font-size: 1rem">{{ getFormattedPrice(selectedPackage.summary.total_price / 100) }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex flex-column p-2 ml-2" style="width: 100%">
                                <Popper placement="top" arrow :show="showPopper" :class="'popp'">
                                    <div class="form-check form-check-inline form-check-primary">
                                        <input type="checkbox" :class="error ? 'is-invalid' : ''" v-model="acceptCheckbox" id="colorfulFormInlineCheck1" class="form-check-input" />
                                        <label class="form-check-label" :class="error && 'is-invalid'" for="colorfulFormInlineCheck1">{{ $t('components.subscription.paymentMethod.paymentAcceptText') }} </label>
                                    </div>
                                </Popper>
                                <div class="mb-3 ml-2 mr-2 d-flex flex-column">
                                    <button class="btn btn-primary mt-4" :disabled="!acceptCheckbox" @click="handleSubmit">{{ $t('components.subscription.paymentMethod.startSubscription') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Card>
    <div class="secure3D-modal" v-if="secure3D.show">
        <div class="secure3D-modal-content">
            <iframe :src="secure3D.url" :key="'secure-iframe'"></iframe>
        </div>
    </div>
</template>

<script>
import { number_format, getSetting } from '@/utils/helper'
import { mapActions, mapGetters } from 'vuex'
import PackageSummary from '../Packages/PackageSummary.vue'
import Card from '@/components/Layouts/Card'
import WalletService from '@/services/wallet'
// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
export default {
    name: 'SubscriptionPaymentHistory',
    components: {
        PackageSummary,
        Card,
    },
    props: {
        token: {
            type: Number,
            default: 0,
        },
        redirectTo: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            acceptCheckbox: false,
            showPopper: false,
            error: false,
            paymentIntentId: null,
            secure3D: {
                show: false,
                url: '',
            },
        }
    },
    computed: {
        ...mapGetters('payment', ['selectedCard', 'selectedPackage']),
        ...mapGetters('auth', ['activePackage', 'packageList']),
        ...mapGetters('system', ['settings']),
        totalToPay() {
            return number_format(this.amount * getSetting(this.settings, 'min_purchasable_token') * getSetting(this.settings, 'per_token_price'), 'money', {
                style: 'currency',
                currency: 'USD',
            })
        },
    },
    mounted() {
        this.setSelectedPackage(null)
    },
    methods: {
        ...mapActions('wallet', ['createSubscription', 'changeSubscription', 'setAsDefault']),
        ...mapActions('payment', ['setSelectedCard', 'setSelectedPackage']),
        async handleSubmit() {
            if (!this.acceptCheckbox) {
                this.error = true
                this.showPopper = true
                return
            }
            /*
                TODO: Açılacak
            */
            // if (!this.selectedCard) {
            //     this.$toast.error('Lütfen ödeme yapmak için bir kart seçin.')
            //     return
            // }
            if (!this.selectedPackage) {
                this.$toast.error(this.$t('components.subscription.warnings.selectPackage'))
                return
            }

            let upgrade = false
            if (this.activePackage && this.activePackage.package && this.activePackage.package.walletPriceId !== this.selectedPackage.externalPriceId) {
                upgrade = true
            }

            this.$store.commit('set', { k: 'loading', v: { status: true, text: this.$t('components.subscription.warnings.checkingPayment') } })
            try {
                let response, payload
                if (upgrade) {
                    payload = {
                        newExternalPriceId: this.selectedPackage.externalPriceId,
                        couponId: this.selectedPackage.selectedCoupon,
                        productId: 9,
                    }
                    response = await this.changeSubscription(payload)
                } else {
                    payload = {
                        externalPriceId: this.selectedPackage.externalPriceId,
                        couponId: this.selectedPackage.selectedCoupon,
                        productId: 9,
                    }
                    response = await this.createSubscription(payload)
                }
                if (response.status === 'succeeded' || response.status === 'active' || response.status === 'trialing') {
                    await this.successPayment()
                    return
                } else if (response.status === 'incomplete' || response.modalUrl) {
                    this.paymentIntentId = response.paymentIntentId || response.setupIntentId
                    const currentUrl = window.location.href
                    const { error } = await stripe.confirmPayment({
                        clientSecret: response.clientSecret,
                        confirmParams: {
                            payment_method: response.paymentMethod,
                            return_url: currentUrl,
                        },
                    })
                    if (error) {
                        this.$toast.error(error.message)
                        return
                    }
                    await this.successPayment()
                } else {
                    this.$toast.error('Hata:' + response.status)
                }
            } catch (e) {
                console.log(e)
                this.$toast.error(e)
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        async successPayment() {
            this.$store.commit('set', { k: 'loading', v: { status: true, text: this.$t('components.subscription.warnings.providingPackage') } })
            const timeout = setTimeout(async () => {
                await this.$store.dispatch('auth/me')
                await this.$store.dispatch('wallet/getUserPackage')
                await this.$store.dispatch('auth/setSubcribed', true)
                clearTimeout(timeout)
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                this.$toast.success(this.$t('components.subscription.warnings.subscriptionStarted'))
                this.$router.push({ name: this.redirectTo || 'settings.subscription' })
            }, 6000)
        },
        getFormattedPrice(price) {
            return number_format(price, 'money', {
                style: 'currency',
                currency: 'USD',
            })
        },
        async open3DSecureDialog(response) {
            const _this = this
            const stripeResp = JSON.parse(response.modalUrl)
            if (stripeResp.stripe_js) {
                this.secure3D.url = stripeResp.stripe_js
                this.secure3D.show = true
                document.querySelector('.card-content').scrollIntoView({ behavior: 'smooth' })
                await this.checkPaymentStatus()
            } else {
                _this.secure3D.show = false
                _this.$toast.error('⛔ Abonelik Başlatılamadı! (488) \nLütfen kredi kartı bakiye ve bilgilerinizi kontrol edin veya banka ile iletişime geçin.')
            }
        },
        checkPaymentStatus() {
            const _this = this
            const count = 0
            this.$store.commit('set', { k: 'loading', v: { status: true, text: this.$t('components.subscription.warnings.checkingPayment') } })
            const interval = setInterval(async () => {
                try {
                    const response = await WalletService.checkPaymentStatus({
                        intentId: this.paymentIntentId,
                    })
                    if (response.data.status === 'succeeded') {
                        _this.secure3D.show = false
                        clearInterval(interval)
                        _this.successPayment()
                        return
                    }
                    if (count > 150) {
                        clearInterval(interval)
                        _this.$toast.error(this.$t('components.subscription.warnings.paymentFailed'))
                    }
                } catch (e) {
                    this.$store.commit('set', { k: 'loading', v: { status: false } })
                    clearInterval(interval)
                    _this.$toast.error(this.$t('components.subscription.warnings.paymentFailed'))
                }
            }, 2000)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    watch: {
        '$route.query': {
            handler: function (val) {
                if (val && val.payment_intent) {
                    const self = this
                    self.successPayment()
                }
            },
            immediate: true,
        },
        acceptCheckbox: {
            handler: function (val) {
                if (val) {
                    this.error = false
                    this.showPopper = false
                }
            },
            immediate: true,
        },
        selectedCard: {
            handler: async function (val) {
                if (val) {
                    await this.setAsDefault({ creditCardId: val, noToast: true })
                }
            },
            immediate: false,
        },
    },
}
</script>

<style lang="scss">
.secure3D-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 50px;
    background: rgba(255, 255, 255, 90%);
    z-index: 1000;

    .secure3D-modal-content {
        margin: auto;
        max-width: 600px;

        iframe {
            border-radius: 5px;
            background: #fff;
            overflow: auto;
            border: none;
            width: 600px;
            height: 600px;
            box-shadow: 0 0 50px -20px rgba(0, 0, 0, 50%);
        }
    }
}

.secure-logo {
    border-top: solid 1px #f4f7ff;
    margin-top: 30px;
    padding-top: 10px;
    margin-bottom: -20px;

    img {
        max-width: 404px;
        margin: auto;
        display: block;
        opacity: 0.9;
    }
}
</style>
