<template>
    <div v-if="selectedProduct && selectedProduct.target">
        <div id="productDetailModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav nav-pills justify-content-center" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" :id="'nav-one-eg2-tab-1'" :href="'#nav-one-eg2-1'" data-bs-toggle="pill" :data-bs-target="'#nav-one-eg2-1'" role="tab" :aria-controls="'nav-one-eg2-1'" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-file-text" style="margin-right: 6px"></i>
                                        {{ $t('components.productDetailModal.detail') }}
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :id="'nav-one-eg2-tab-5'" :href="'#nav-one-eg2-5'" data-bs-toggle="pill" :data-bs-target="'#nav-one-eg2-5'" role="tab" :aria-controls="'nav-one-eg2-5'" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-bar-chart" style="margin-right: 6px"></i>
                                        {{ $t('components.productDetailModal.keepaCharts') }}
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :id="'nav-one-eg2-tab-2'" :href="'#nav-one-eg2-2'" data-bs-toggle="pill" :data-bs-target="'#nav-one-eg2-2'" role="tab" :aria-controls="'nav-one-eg2-2'" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-calculator" style="margin-right: 6px"></i>
                                        {{ $t('components.productDetailModal.priceAnalysis') }}
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :id="'nav-one-eg2-tab-3'" :href="'#nav-one-eg2-3'" data-bs-toggle="pill" :data-bs-target="'#nav-one-eg2-3'" role="tab" :aria-controls="'nav-one-eg2-3'" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-box-seam" style="margin-right: 6px"></i>
                                        {{ $t('components.productDetailModal.competitors') }}
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="scrollToElement" :id="'nav-one-eg2-tab-4'" :href="'#nav-one-eg2-4'" data-bs-toggle="pill" :data-bs-target="'#nav-one-eg2-4'" role="tab" :aria-controls="'nav-one-eg2-4'" aria-selected="true">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-chat-left-text" style="margin-right: 6px"></i>
                                        {{ $t('components.productDetailModal.assistant') }}
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <button type="button" style="margin-right: 5px" class="btn-close" @click="hideModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" :id="'nav-one-eg2-1'" role="tabpanel" aria-labelledby="nav-one-eg2-tab-1">
                                <div class="d-flex justify-content-between">
                                    <div class="thumbnail_wrapper">
                                        <img class="img img-responsive" :src="selectedProduct.targetProduct.image.replace('.jpg', '._SS300_.jpg')" alt="" />
                                    </div>
                                    <div style="flex: 1">
                                        <h3 style="font-weight: 600">
                                            {{ selectedProduct.targetProduct.title }}
                                        </h3>
                                        <h4 style="font-weight: 400; color: #677788">
                                            {{ selectedProduct.targetProduct.categories_flat }}
                                        </h4>
                                        <div class="mt-3">
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.asin') }}</td>
                                                        <td colspan="3">
                                                            <a :href="selectedProduct.sourceProduct.link" target="_blank">{{ selectedProduct.asin }}</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.sourceStore') }}</td>
                                                        <td>
                                                            <a href="{{selectedProduct.sourceProduct.seller_link}}" target="_blank">{{ selectedProduct.sourceProduct.seller_name }}</a>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.targetStore') }}</td>
                                                        <td>
                                                            <a href="{{selectedProduct.targetProduct.seller_link}}" target="_blank">{{ selectedProduct.targetProduct.seller_name }}</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.dimensions') }}</td>
                                                        <td>
                                                            {{ selectedProduct.dimension }}
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.weight') }}</td>
                                                        <td>{{ selectedProduct.weight }} lbs</td>
                                                    </tr>
                                                    <tr></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th colspan="2">{{ $t('components.productDetailModal.sourceStore') }}</th>
                                                <th colspan="2" class="column-border-left">{{ $t('components.productDetailModal.targetStore') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="fw-bold" style="vertical-align: middle">{{ $t('components.productDetailModal.productScore') }}</td>
                                                <td>
                                                    <star-rating
                                                        :round-start-rating="false"
                                                        :star-size="20"
                                                        :padding="3"
                                                        pointer="none"
                                                        :rating="selectedProduct.sourceProduct.rating"
                                                        :showRating="false"
                                                        :totalRating="selectedProduct.sourceProduct.ratings_total"
                                                    ></star-rating>

                                                    <small style="font-size: 11px"
                                                        ><a :href="`${selectedProduct.sourceProduct.link}?th=1&psc=1#customerReviews`" target="_blank" style="margin-top: 3px"
                                                            >({{ selectedProduct.sourceProduct.rating || 0 }}) {{ selectedProduct.sourceProduct.ratings_total || 0 }}
                                                        </a>
                                                        {{ $t('components.productDetailModal.totalEvaluation') }}</small
                                                    >
                                                </td>
                                                <td class="fw-bold column-border-left" style="vertical-align: middle">{{ $t('components.productDetailModal.productScore') }}</td>
                                                <td class="column-border-left">
                                                    <template v-if="selectedProduct.targetProduct && selectedProduct.targetProduct.rating && selectedProduct.targetProduct.ratings_total">
                                                        <star-rating
                                                            :round-start-rating="false"
                                                            :star-size="20"
                                                            :padding="3"
                                                            pointer="none"
                                                            :rating="selectedProduct.targetProduct.rating"
                                                            :showRating="false"
                                                            :totalRating="selectedProduct.targetProduct.ratings_total"
                                                        ></star-rating>

                                                        <small style="font-size: 11px"
                                                            ><a :href="`${selectedProduct.targetProduct.link}?th=1&psc=1#customerReviews`" target="_blank" style="margin-top: 3px"
                                                                >({{ selectedProduct.targetProduct.rating || 0 }}) {{ selectedProduct.targetProduct.ratings_total || 0 }}
                                                            </a>
                                                            {{ $t('components.productDetailModal.totalEvaluation') }}</small
                                                        >
                                                    </template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.salesRank') }}</td>
                                                <td>#{{ selectedProduct.sourceProduct.bestsellers_rank }} ({{ selectedProduct.sourceProduct.bsr_category }})</td>
                                                <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.salesRank') }}</td>
                                                <td class="column-border-left">#{{ selectedProduct.targetProduct.bestsellers_rank }} ({{ selectedProduct.targetProduct.bsr_category }})</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.variation') }}</td>
                                                <td>{{ selectedProduct.sourceProduct.variations }} {{ $t('components.productDetailModal.product') }}</td>
                                                <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.variation') }}</td>
                                                <td class="column-border-left">{{ selectedProduct.targetProduct.variations }} {{ $t('components.productDetailModal.product') }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.numberOfVendors') }}</td>
                                                <td>
                                                    {{ +selectedProduct.sourceProduct.fba_sellers_count + +selectedProduct.sourceProduct.fbm_sellers_count || selectedProduct.offers.filter((o) => o.short === selectedProduct.source).length }}
                                                    {{ $t('components.productDetailModal.seller') }}
                                                </td>
                                                <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.numberOfVendors') }}</td>
                                                <td class="column-border-left">
                                                    {{ +selectedProduct.targetProduct.fba_sellers_count + +selectedProduct.targetProduct.fbm_sellers_count || selectedProduct.offers.filter((o) => o.short === selectedProduct.target).length }}
                                                    {{ $t('components.productDetailModal.seller') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">FBA/FBM</td>
                                                <td>{{ selectedProduct.sourceProduct.is_fulfilled_by_amazon ? 'FBA' : 'FBM' }}</td>
                                                <td class="fw-bold column-border-left">FBA/FBM</td>
                                                <td class="column-border-left">{{ selectedProduct.targetProduct.is_fulfilled_by_amazon ? 'FBA' : 'FBM' }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">PRIME?</td>
                                                <td>{{ selectedProduct.sourceProduct.is_prime ? $t('actions.yes') : $t('actions.no') }}</td>
                                                <td class="column-border-left fw-bold">PRIME?</td>
                                                <td class="column-border-left">{{ selectedProduct.targetProduct.is_prime ? $t('actions.yes') : $t('actions.no') }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.maxCanBeOrdered') }}</td>
                                                <td>{{ selectedProduct.sourceProduct.maximum_order_quantity }}</td>
                                                <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.maxCanBeOrdered') }}</td>
                                                <td class="column-border-left">{{ selectedProduct.targetProduct.maximum_order_quantity }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.listingDate') }}</td>
                                                <td>{{ formatDate(selectedProduct.sourceProduct.first_available) }}</td>
                                                <td class="fw-bold">{{ $t('components.productDetailModal.listingDate') }}</td>
                                                <td class="column-border-left">{{ formatDate(selectedProduct.targetProduct.first_available) }}</td>
                                            </tr>
                                            <tr v-if="selectedProduct.target_keepa_data?.stats?.salesRankDrops30">
                                                <td class="fw-bold"></td>
                                                <td></td>
                                                <td class="fw-bold">DROPS</td>
                                                <td class="column-border-left">
                                                    {{ selectedProduct.target_keepa_data?.stats?.salesRankDrops30 }} | {{ selectedProduct.target_keepa_data?.stats?.salesRankDrops90 }} |
                                                    {{ selectedProduct.target_keepa_data?.stats?.salesRankDrops180 }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" :id="'nav-one-eg2-2'" role="tabpanel" aria-labelledby="nav-one-eg2-tab-2">
                                <div class="d-flex">
                                    <table class="table" id="priceAnalyse">
                                        <thead class="thead-light">
                                            <tr>
                                                <th colspan="4"></th>
                                                <th class="text-center column-border-left" colspan="2">{{ $t('components.productDetailModal.calculator') }}</th>
                                            </tr>
                                            <tr>
                                                <th class="column-border-right"></th>
                                                <th style="text-align: right">{{ $t('components.productDetailModal.minPrice') }} ({{ getCurrency(selectedProduct.targetProduct.short) }})</th>
                                                <th style="text-align: right">{{ $t('components.productDetailModal.buyboxPrice') }} ({{ getCurrency(selectedProduct.targetProduct.short) }})</th>
                                                <th style="text-align: right">{{ $t('components.productDetailModal.maxPrice') }} ({{ getCurrency(selectedProduct.targetProduct.short) }})</th>
                                                <th class="column-border-left" style="padding: 5px; max-width: 140px">
                                                    <div class="input-group">
                                                        <input v-model="priceInput" v-debounce:500="handleInputPrice" type="number" class="form-control input-sm" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">{{ getCurrency(selectedProduct.targetProduct.short) }}</span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th style="padding: 5px; max-width: 140px">
                                                    <div class="input-group">
                                                        <input v-model="roiInput" v-debounce:500="handleInputPrice" type="number" class="form-control input-sm" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="calculatedPrices">
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.salePrice') }}</td>
                                                <td>{{ calculatedPrices.min.price }}</td>
                                                <td>{{ calculatedPrices.buybox.price }}</td>
                                                <td>{{ calculatedPrices.max.price }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.price }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.price }}</td>
                                            </tr>
                                            <tr style="cursor: pointer" @click="handleToggleCosts">
                                                <td class="fw-bold column-border-right">
                                                    <span style="color: orange" v-if="toggleCosts">-</span>
                                                    <span style="color: orange" v-if="!toggleCosts">+</span>
                                                    {{ $t('components.productDetailModal.commissionTaxes') }}
                                                </td>
                                                <td>{{ calculatedPrices.min.totalFeesAndTaxes }}</td>
                                                <td>{{ calculatedPrices.buybox.totalFeesAndTaxes }}</td>
                                                <td>{{ calculatedPrices.max.totalFeesAndTaxes }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.totalFeesAndTaxes }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.totalFeesAndTaxes }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.vat') }}</td>
                                                <td>{{ calculatedPrices.min.vat }}</td>
                                                <td>{{ calculatedPrices.buybox.vat }}</td>
                                                <td>{{ calculatedPrices.max.vat }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.vat }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.vat }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.referalFee') }}</td>
                                                <td>{{ calculatedPrices.min.referralFee }}</td>
                                                <td>{{ calculatedPrices.buybox.referralFee }}</td>
                                                <td>{{ calculatedPrices.max.referralFee }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.referralFee }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.referralFee }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.taxOnReferralFee') }}</td>
                                                <td>{{ calculatedPrices.min.taxOnReferralFee }}</td>
                                                <td>{{ calculatedPrices.buybox.taxOnReferralFee }}</td>
                                                <td>{{ calculatedPrices.max.taxOnReferralFee }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.taxOnReferralFee }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.taxOnReferralFee }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">FBA FEE</td>
                                                <td>{{ calculatedPrices.min.fbaFee }}</td>
                                                <td>{{ calculatedPrices.buybox.fbaFee }}</td>
                                                <td>{{ calculatedPrices.max.fbaFee }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.fbaFee }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.fbaFee }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.taxOnFbaFee') }}</td>
                                                <td>{{ calculatedPrices.min.taxOnFbaFee }}</td>
                                                <td>{{ calculatedPrices.buybox.taxOnFbaFee }}</td>
                                                <td>{{ calculatedPrices.max.taxOnFbaFee }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.taxOnFbaFee }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.taxOnFbaFee }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.storageFee') }}</td>
                                                <td>{{ calculatedPrices.min.storageFee }}</td>
                                                <td>{{ calculatedPrices.buybox.storageFee }}</td>
                                                <td>{{ calculatedPrices.max.storageFee }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.storageFee }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.storageFee }}</td>
                                            </tr>
                                            <tr style="cursor: pointer" @click="handleToggleTotalCosts">
                                                <td class="fw-bold column-border-right">
                                                    <span style="color: orange" v-if="toggleTotalCosts">-</span>
                                                    <span style="color: orange" v-if="!toggleTotalCosts">+</span>
                                                    {{ $t('list.product_search.groups.costs') }}
                                                </td>
                                                <td>{{ calculatedPrices.min.totalCost }}</td>
                                                <td>{{ calculatedPrices.buybox.totalCost }}</td>
                                                <td>{{ calculatedPrices.max.totalCost }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.totalCost }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.totalCost }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleTotalCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.productCost') }}</td>
                                                <td>{{ calculatedPrices.min.productCost }}</td>
                                                <td>{{ calculatedPrices.buybox.productCost }}</td>
                                                <td>{{ calculatedPrices.max.productCost }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.productCost }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.productCost }}</td>
                                            </tr>
                                            <tr
                                                class="hidden-row"
                                                :class="{
                                                    'show-row show fade': toggleTotalCosts,
                                                }"
                                            >
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.warehouseCost') }}</td>
                                                <td>{{ calculatedPrices.min.warehouseCost }}</td>
                                                <td>{{ calculatedPrices.buybox.warehouseCost }}</td>
                                                <td>{{ calculatedPrices.max.warehouseCost }}</td>
                                                <td class="column-border-left">{{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.warehouseCost }}</td>
                                                <td>{{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.warehouseCost }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.grossProfit') }}</td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.min.profit < 0,
                                                        'text-success': calculatedPrices.min.profit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.min.profit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.buybox.profit < 0,
                                                        'text-success': calculatedPrices.buybox.profit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.buybox.profit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.max.profit < 0,
                                                        'text-success': calculatedPrices.max.profit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.max.profit }}
                                                </td>
                                                <td
                                                    class="column-border-left"
                                                    :class="{
                                                        'text-danger': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profit < 0,
                                                        'text-success': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profit < 0,
                                                        'text-success': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profit }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.grossProfitRatio') }} %</td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.min.profitRate < 0,
                                                        'text-success': calculatedPrices.min.profitRate > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.min.profitRate }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.buybox.profitRate < 0,
                                                        'text-success': calculatedPrices.buybox.profitRate > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.buybox.profitRate }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.max.profitRate < 0,
                                                        'text-success': calculatedPrices.max.profitRate > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.max.profitRate }}
                                                </td>
                                                <td
                                                    class="column-border-left"
                                                    :class="{
                                                        'text-danger': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitRate < 0,
                                                        'text-success': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitRate > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitRate }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitRate < 0,
                                                        'text-success': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitRate > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitRate }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.grossProfitMargin') }} %</td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.min.profitMargin < 0,
                                                        'text-success': calculatedPrices.min.profitMargin > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.min.profitMargin }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.buybox.profitMargin < 0,
                                                        'text-success': calculatedPrices.buybox.profitMargin > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.buybox.profitMargin }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.max.profitMargin < 0,
                                                        'text-success': calculatedPrices.max.profitMargin > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.max.profitMargin }}
                                                </td>
                                                <td
                                                    class="column-border-left"
                                                    :class="{
                                                        'text-danger': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitMargin < 0,
                                                        'text-success': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitMargin > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.profitMargin }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitMargin < 0,
                                                        'text-success': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitMargin > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.profitMargin }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.netProfit') }}</td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.min.netProfit < 0,
                                                        'text-success': calculatedPrices.min.netProfit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.min.netProfit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.buybox.netProfit < 0,
                                                        'text-success': calculatedPrices.buybox.netProfit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.buybox.netProfit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.max.netProfit < 0,
                                                        'text-success': calculatedPrices.max.netProfit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.max.netProfit }}
                                                </td>
                                                <td
                                                    class="column-border-left"
                                                    :class="{
                                                        'text-danger': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.netProfit < 0,
                                                        'text-success': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.netProfit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.netProfit }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.netProfit < 0,
                                                        'text-success': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.netProfit > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.netProfit }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold column-border-right">{{ $t('components.productDetailModal.netProfitRatio') }} %</td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.min.roi < 0,
                                                        'text-success': calculatedPrices.min.roi > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.min.roi }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.buybox.roi < 0,
                                                        'text-success': calculatedPrices.buybox.roi > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.buybox.roi }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.max.roi < 0,
                                                        'text-success': calculatedPrices.max.roi > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.max.roi }}
                                                </td>
                                                <td
                                                    class="column-border-left"
                                                    :class="{
                                                        'text-danger': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.roi < 0,
                                                        'text-success': calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.roi > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newCalculatedPrice && calculatedPrices.newCalculatedPrice.roi }}
                                                </td>
                                                <td
                                                    :class="{
                                                        'text-danger': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.roi < 0,
                                                        'text-success': calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.roi > 0,
                                                    }"
                                                >
                                                    {{ calculatedPrices.newRoiPrice && calculatedPrices.newRoiPrice.roi }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" :id="'nav-one-eg2-3'" role="tabpanel" aria-labelledby="nav-one-eg2-tab-3">
                                <div class="d-flex justify-content-between">
                                    <div class="thumbnail_wrapper">
                                        <img class="img img-responsive" :src="selectedProduct.targetProduct.image" alt="" />
                                    </div>
                                    <div style="flex: 1">
                                        <h3 style="font-weight: 600">
                                            {{ selectedProduct.targetProduct.title }}
                                        </h3>
                                        <h4 style="font-weight: 400; color: #677788">
                                            {{ selectedProduct.targetProduct.categories_flat }}
                                        </h4>
                                        <div class="mt-3">
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.asin') }}</td>
                                                        <td>
                                                            <a target="_blank" :href="selectedProduct.targetProduct.link + '/ref=olp-opf-redir?aod=1&f_new=true'">{{ selectedProduct.asin }}</a>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.sourceStore') }}</td>
                                                        <td>{{ selectedProduct.sourceProduct.short }}</td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.numberOfVendors') }}</td>
                                                        <td>{{ selectedProduct.offers.filter((o) => o.short === selectedProduct.target).length }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.buyboxSeller') }}</td>
                                                        <td>
                                                            <template v-if="selectedProduct.offers.find((o) => o.buybox_winner && o.short === selectedProduct.target)">
                                                                <a :href="selectedProduct.offers.find((o) => o.buybox_winner && o.short === selectedProduct.target).seller_link">{{
                                                                    selectedProduct.offers.find((o) => o.buybox_winner && o.short === selectedProduct.target).seller_name
                                                                }}</a>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.lowestSeller') }}</td>
                                                        <td>
                                                            <template v-if="offersByPrice[0]">
                                                                <a :href="offersByPrice[0].seller_link">{{ offersByPrice[0].seller_name }}</a>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.primeSeller') }}</td>
                                                        <td>
                                                            <template v-if="selectedProduct.offers.filter((o) => o.is_prime).length > 0">
                                                                <span style="color: green">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold">{{ $t('components.productDetailModal.sbaVendor') }}</td>
                                                        <td>
                                                            <template v-if="selectedProduct.offers.filter((o) => o.is_sold_by_amazon).length > 0">
                                                                <span style="color: green">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.fbaVendor') }}</td>
                                                        <td>
                                                            <template v-if="selectedProduct.offers.filter((o) => o.fulfilled_by_amazon).length > 0">
                                                                <span style="color: green">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                        <td class="fw-bold column-border-left">{{ $t('components.productDetailModal.fbmVendor') }}</td>
                                                        <td>
                                                            <template v-if="selectedProduct.offers.filter((o) => !o.fulfilled_by_amazon).length > 0">
                                                                <span style="color: green">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="color: red">
                                                                    <i class="fa fa-times"></i>
                                                                </span>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr class="fw-bold">
                                                <th>{{ $t('common.store') }}</th>
                                                <th>{{ $t('common.price') }} ({{ getCurrency(selectedProduct.targetProduct.short) }})</th>
                                                <th></th>
                                                <th>FBA/FBM/SBA</th>
                                                <th>{{ $t('common.foreignProcurement') }}</th>
                                                <th>PRIME</th>
                                                <th>{{ $t('common.new') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="offer.offer_id" v-for="(offer, idx) in offersByPrice">
                                                <td>
                                                    <a :href="offer.seller_link">{{ offer.seller_name }}</a> <br />
                                                    <star-rating :round-start-rating="false" :star-size="20" :padding="3" pointer="none" :rating="offer.seller_rating" :showRating="false" :totalRating="offer.seller_ratings_total"></star-rating>
                                                </td>
                                                <td>{{ offer.price }}</td>
                                                <td>
                                                    <span class="badge bg-secondary" style="margin-right: 5px" v-if="idx === 0">Lowest</span>
                                                    <span class="badge bg-primary" v-if="offer.buybox_winner === 1">Buybox</span>
                                                </td>
                                                <td>
                                                    <span v-if="offer.is_sold_by_amazon">SBA</span>
                                                    <span v-if="offer.fulfilled_by_amazon">FBA</span>
                                                    <span v-if="!offer.fulfilled_by_amazon">FBM</span>
                                                </td>
                                                <td>
                                                    <span v-if="offer.shipped_from_outside_country">{{ $t('common.exists') }}</span>
                                                    <span v-if="!offer.shipped_from_outside_country">{{ $t('common.not_exists') }}</span>
                                                </td>
                                                <td>
                                                    <span style="color: green" v-if="offer.is_prime">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                    <span style="color: red" v-if="!offer.is_prime">
                                                        <i class="fa fa-times"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span style="color: green" v-if="offer.is_new">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                    <span style="color: red" v-if="!offer.is_new">
                                                        <i class="fa fa-times"></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="totalOffersPage > 1" class="d-flex align-items-center justify-content-start" style="gap: 10px">
                                    <span>{{ $t('components.productDetailModal.pagination.title') }} {{ pagination.currentPage }} / {{ totalOffersPage }}</span>
                                    <button class="btn btn-sm btn-primary" @click="prevPage" :disabled="pagination.currentPage === 1"><i class="bi-chevron-left"></i> {{ $t('components.productDetailModal.pagination.prev') }}</button>
                                    <button class="btn btn-sm btn-primary" @click="nextPage" :disabled="pagination.currentPage === totalOffersPage">{{ $t('components.productDetailModal.pagination.next') }} <i class="bi-chevron-right"></i></button>
                                </div>
                            </div>
                            <div class="tab-pane fade" :id="'nav-one-eg2-4'" role="tabpanel" aria-labelledby="nav-one-eg2-tab-4">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex flex-column pr-2" style="max-width: 20rem">
                                        <div class="thumbnail_wrapper">
                                            <img class="img img-responsive" :src="selectedProduct.targetProduct.image" alt="" />
                                        </div>
                                        <div>
                                            <h3 style="font-weight: 600">
                                                {{ selectedProduct.targetProduct.title }}
                                            </h3>
                                            <h4 style="font-weight: 400; color: #677788">
                                                {{ selectedProduct.targetProduct.categories_flat }}
                                            </h4>
                                            <div class="mt-3">
                                                <table class="table table-left">
                                                    <tbody>
                                                        <tr>
                                                            <td class="fw-bold">{{ $t('components.productDetailModal.asin') }}</td>
                                                            <td>
                                                                <a :href="selectedProduct.sourceProduct.link" target="_blank">{{ selectedProduct.asin }}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold">{{ $t('components.productDetailModal.sourceStore') }}</td>
                                                            <td>
                                                                <a href="{{selectedProduct.sourceProduct.seller_link}}" target="_blank">{{ selectedProduct.sourceProduct.seller_name }}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold">{{ $t('components.productDetailModal.targetStore') }}</td>
                                                            <td>
                                                                <a href="{{selectedProduct.targetProduct.seller_link}}" target="_blank">{{ selectedProduct.targetProduct.seller_name }}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold">{{ $t('components.productDetailModal.dimensions') }}</td>
                                                            <td>
                                                                {{ selectedProduct.dimension }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="fw-bold">{{ $t('components.productDetailModal.weight') }}</td>
                                                            <td>{{ selectedProduct.weight }} lbs</td>
                                                        </tr>
                                                        <tr></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="--dark-theme" id="chat">
                                        <div class="chat__conversation-board">
                                            <span class="chat__conversation-board-powered">ChatGPT</span>
                                            <div class="chat__conversation-board__message-container">
                                                <div class="chat__conversation-board__message__person">
                                                    <span class="chat__conversation-board__message__person__nickname">{{ $t('components.productDetailModal.assistant') }}</span>
                                                </div>
                                                <div class="chat__conversation-board__message__context">
                                                    <div class="chat__conversation-board__message__bubble">
                                                        <span v-html="$t('components.productDetailModal.assistantText', { productName: selectedProduct.targetProduct.title })"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="item of productChatHistory" ref="scrollToMe" :key="item.id" class="chat__conversation-board__message-container" :class="item.question_type === 'user' && 'reversed'">
                                                <div class="chat__conversation-board__message__person">
                                                    <span class="chat__conversation-board__message__person__nickname">{{ item.question_type === 'user' ? $t('common.user') : $t('components.productDetailModal.assistant') }}</span>
                                                </div>
                                                <div class="chat__conversation-board__message__context">
                                                    <div class="chat__conversation-board__message__bubble">
                                                        <span>{{ item.message }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="typing" class="chat__conversation-board__message-container">
                                                <div class="chat__conversation-board__message__person">
                                                    <span class="chat__conversation-board__message__person__nickname">{{ $t('components.productDetailModal.assistant') }}</span>
                                                </div>
                                                <div class="chat__conversation-board__message__context">
                                                    <div class="chat__conversation-board__message__bubble">
                                                        <span>
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                            {{ $t('components.productDetailModal.isTyping') }}...
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat__conversation-panel">
                                            <div class="chat__conversation-panel__container">
                                                <div class="chat__quick_questions__container" v-if="toggleQuickQuestions">
                                                    <div class="chat__quick_questions__item" v-for="item of quickQuestions" :key="item.id" @click="handleQuickQuestion(item)">
                                                        <span>{{ item }}</span>
                                                    </div>
                                                </div>

                                                <button @click="toggleQuickQuestions = !toggleQuickQuestions" class="chat__conversation-panel__button panel-item btn-icon add-file-button">
                                                    <i class="bi bi-plus-circle"></i>
                                                </button>
                                                <input class="chat__conversation-panel__input panel-item" @keyup.enter="handleAskToAssistant" v-model="message" :placeholder="$t('components.productDetailModal.writeYourMessage')" />
                                                <button :disabled="message === ''" @click="handleAskToAssistant" class="chat__conversation-panel__button panel-item btn-icon send-message-button">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        data-reactid="1036"
                                                    >
                                                        <line x1="22" y1="2" x2="11" y2="13"></line>
                                                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <p class="mt-4" style="font-size: 11px">{{ $t('components.productDetailModal.assistantHint') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" :id="'nav-one-eg2-5'" role="tabpanel" aria-labelledby="nav-one-eg2-tab-5">
                                <div v-if="selectedProduct && !selectedProduct.target_keepa_data" style="display: flex; flex-direction: column; background-color: #f9fafc; padding: 20px">
                                    <div class="d-flex">
                                        <MarketSelect
                                            @onSelect="handleSelectDomain"
                                            :multiple="false"
                                            :all="false"
                                            :addNew="false"
                                            :sourceAndTarget="true"
                                            :sourceAndTargetItems="[
                                                {
                                                    id: 2,
                                                    short: selectedProduct.target,
                                                    domain: countryData[selectedProduct.target].domain,
                                                    image: getImage(this.selectedProduct.target + '.svg'),
                                                },
                                                {
                                                    id: 1,
                                                    short: selectedProduct.source,
                                                    domain: countryData[selectedProduct.source].domain,
                                                    image: getImage(selectedProduct.source + '.svg'),
                                                },
                                            ]"
                                        />
                                        <ul class="list-inline list-px-1 align-self-center" style="margin: 0">
                                            <li class="list-inline-item mt-2" style="margin-left: 20px">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck10" class="form-check-input" v-model="keepa.salesrank" />
                                                    <label class="form-check-label" for="formCheck10">{{ $t('components.productDetailModal.salesRank') }}</label>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck15" class="form-check-input" v-model="keepa.amazon" />
                                                    <label class="form-check-label" for="formCheck15">{{ $t('components.productDetailModal.amazonPrice') }}</label>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck11" class="form-check-input" v-model="keepa.buybox" />
                                                    <label class="form-check-label" for="formCheck11">{{ $t('components.productDetailModal.buyboxPriceText') }}</label>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck12" class="form-check-input" v-model="keepa.fba" />
                                                    <label class="form-check-label" for="formCheck12">{{ $t('components.productDetailModal.fbaPrice') }}</label>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck13" class="form-check-input" v-model="keepa.fbm" />
                                                    <label class="form-check-label" for="formCheck13">{{ $t('components.productDetailModal.fbmPrice') }}</label>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="form-check mb-3">
                                                    <input type="checkbox" id="formCheck14" class="form-check-input" v-model="keepa.ld" />
                                                    <label class="form-check-label" for="formCheck14"> {{ $t('components.productDetailModal.listingDate') }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <img @click="handleKeepaImage" style="cursor: pointer; align-self: center; width: 80%; margin-top: 20px; margin-bottom: 20px" v-show="!keepaLoading" :src="keepaImageUrl" @load="imageLoaded" alt="Keepa Graph" />
                                    <div v-if="keepaLoading" style="align-self: center; font-size: 32px; width: 80%; height: 350px; margin-top: 20px; margin-bottom: 20px; display: flex; justify-content: center">
                                        <i class="fa fa-spinner fa-spin align-self-center"></i>
                                    </div>
                                    <ul v-if="!keepaLoading" class="list-inline list-separator align-self-center">
                                        <li class="list-inline-item">
                                            <strong>{{ $t('components.productDetailModal.range') }}: </strong>
                                        </li>
                                        <li class="list-inline-item" v-for="range in [30, 60, 90, 180, 360]" :key="range">
                                            <a class="list-separator-link fw-bold" :style="keepa.range === range ? 'color:#fd9405 !important;' : '#00203f !important;'" href="#" @click.prevent="changeRange">{{ range }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <template v-else-if="showKeepaChart">
                                    <div class="keepa-header d-flex justify-content-between align-items-center mb-2 px-2">
                                        <div>
                                            <button v-for="n in [365, 180, 90, 30, 14]" :key="n" type="button" class="btn btn-link text-secondary" :style="currentDateRange === n ? 'color:#fd9405 !important;' : ''" @click="handleChangeDateRange(n)">
                                                {{ n }}
                                            </button>
                                        </div>
                                        <div>
                                            {{ chartDateRange }}
                                        </div>
                                    </div>
                                    <div v-if="keepaHasData" class="keepa-canvas" style="width: 50%; float: left">
                                        <Line
                                            :data="keepaChartData"
                                            :key="chartKey"
                                            :plugins="[
                                                {
                                                    afterDraw: (chart) => {
                                                        if (chart.tooltip?._active?.length) {
                                                            let x = chart.tooltip._active[0].element.x
                                                            let yAxis = chart.scales.y1
                                                            let ctx = chart.ctx
                                                            ctx.save()
                                                            ctx.beginPath()
                                                            ctx.moveTo(x, yAxis.top)
                                                            ctx.lineTo(x, yAxis.bottom)
                                                            ctx.lineWidth = 1
                                                            ctx.strokeStyle = '#fd9405'
                                                            ctx.stroke()
                                                            ctx.restore()
                                                        }
                                                    },
                                                },
                                            ]"
                                            :options="options"
                                        />
                                    </div>
                                    <div v-else class="alert alert-warning" style="width: 45%; float: left">{{ $t('components.productDetailModal.noKeepaPriceData') }}</div>

                                    <div v-if="keepaOfferHasData" class="keepa-canvas" style="width: 50%; float: left">
                                        <Line
                                            :data="keepaOffersChartData"
                                            :plugins="[
                                                {
                                                    afterDraw: (chart) => {
                                                        if (chart.tooltip?._active?.length) {
                                                            let x = chart.tooltip._active[0].element.x
                                                            let yAxis = chart.scales.y3
                                                            let ctx = chart.ctx
                                                            ctx.save()
                                                            ctx.beginPath()
                                                            ctx.moveTo(x, yAxis.top)
                                                            ctx.lineTo(x, yAxis.bottom)
                                                            ctx.lineWidth = 1
                                                            ctx.strokeStyle = '#fd9405'
                                                            ctx.stroke()
                                                            ctx.restore()
                                                        }
                                                    },
                                                },
                                            ]"
                                            :options="offersOptions"
                                        />
                                    </div>
                                    <div v-else class="alert alert-warning" style="margin-left: 20px; width: 45%; float: left">{{ $t('components.productDetailModal.noKeepaOfferData') }}</div>
                                </template>
                                <div v-else class="alert alert-warning">{{ $t('components.productDetailModal.noKeepaData') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap'

import { mapActions, mapGetters } from 'vuex'

import StarRating from '@/components/Star/Rating.vue'
import moment from 'moment'
import { countryInfo } from '@/utils/helper'
import MarketSelect from '@/components/Inputs/MarketSelect.vue'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'vue-chartjs'
import { subDays, formatISO } from 'date-fns'
import { formatCurrency, getCurrencySymbol } from '@/utils/format-number'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
const MINUTE_OFFSET = 21564000
const MINUTE_IN_MS = 60000

const countries = countryInfo()

export default {
    name: 'ProductDetailModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        listType: {
            type: String,
            default: 'product_search',
        },
    },
    components: {
        StarRating,
        MarketSelect,
        Line,
    },
    data() {
        const locale = this.$i18n.locale
        return {
            form_submitted: false,
            modalInstance: null,
            toggleQuickQuestions: false,
            keepaLoading: false,
            selectedDomain: null,
            offers: [],
            chartKey: 0,
            quickQuestions: ['Ürün hakkında genel bilgi almak istiyorum.', 'Ürün kullanım amacı nedir?', 'Ürün uzun süre depolanabilir mi?', 'Ürün hangi ülkede daha kolay satılabilir?'],
            typing: false,
            toggleTotalCosts: false,
            toggleCosts: false,
            calculator: null,
            message: '',
            countryData: countries,
            keepa: {
                asin: '',
                domain: null,
                range: 30,
                salesrank: true,
                buybox: true,
                fba: true,
                fbm: true,
                ld: true,
                amazon: true,
            },
            priceInput: null,
            roiInput: null,
            showKeepaChart: false,
            currentDateRange: 365,
            chartDateRange: '',
            locale: locale,
            keepaChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Sales Ranks',
                        backgroundColor: 'green',
                        data: [],
                        fill: false,
                        borderColor: 'green',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y1',
                    },
                    {
                        label: 'AMZ',
                        backgroundColor: 'orange',
                        data: [],
                        fill: true,
                        borderColor: 'orange',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'FBA',
                        backgroundColor: 'rgb(255, 87, 34)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(255, 87, 34)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'FBM',
                        backgroundColor: 'rgb(3, 155, 229)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(3, 155, 229)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'Buybox',
                        backgroundColor: 'rgb(255, 0, 75)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(255, 0, 75)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                        pointStyle: 'rectangle',
                    },
                ],
            },
            keepaOffersChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Offers',
                        backgroundColor: 'purple',
                        data: [],
                        fill: false,
                        borderColor: 'purple',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y3',
                    },
                    {
                        label: 'Reviews',
                        backgroundColor: 'green',
                        data: [],
                        fill: false,
                        borderColor: 'green',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y4',
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function (tooltipItem) {
                                return new Date(tooltipItem[0].label).toLocaleDateString(locale || 'tr-TR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })
                            },
                            label: function (tooltipItem) {
                                if (tooltipItem.dataset.yAxisID === 'y1') {
                                    return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue.toLocaleString()
                                }
                                const domainFromQuery = new URLSearchParams(window.location.search).get('domain')
                                const domain = domainFromQuery ? Object.keys(countryInfo()).find((key) => countryInfo()[key].domain === domainFromQuery) : 'US'
                                const currency = countryInfo()[domain].symbol

                                return tooltipItem.dataset.label + ': ' + formatCurrency(currency, +tooltipItem.raw / 100)
                            },
                        },
                    },
                    legend: {
                        display: true, // Efsaneyi göster
                        labels: {
                            font: {
                                size: 13, // Font boyutu
                            },
                            padding: 10, // Efsane etiketleri arasındaki dolgu
                            boxWidth: 20, // Efsane kutucuk genişliği
                            boxHeight: 5, // Efsane kutucuk yüksekliği
                            usePointStyle: true, // Efsane kutucuklarını nokta şeklinde kullan
                        },
                        onClick: function (e, legendItem, legend) {
                            const index = legendItem.datasetIndex
                            const ci = legend.chart
                            const meta = ci.getDatasetMeta(index)

                            // Meta verinin görünürlüğünü toggle et
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

                            // Grafikteki değişiklikleri göstermek için güncelle
                            ci.update()
                        },
                    },
                },
                scales: {
                    x: {
                        display: false,
                        title: {
                            display: true,
                        },
                        ticks: {
                            padding: -10,
                        },
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 40
                        },
                    },
                    y2: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0,
                        grid: {
                            drawOnChartArea: false,
                        },
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                const domainFromQuery = new URLSearchParams(window.location.search).get('domain')
                                const domain = domainFromQuery ? Object.keys(countryInfo()).find((key) => countryInfo()[key].domain === domainFromQuery) : 'US'
                                const currency = countryInfo()[domain].symbol
                                return formatCurrency(currency, +value / 100)
                            },
                            font: {
                                size: 9,
                            },
                        },
                        afterFit: function (scale) {
                            scale.width = 40
                        },
                        beginAtZero: true,
                    },
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
            },
            offersOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        width: 540,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function (tooltipItem) {
                                return new Date(tooltipItem[0].label).toLocaleDateString(locale || 'tr-TR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })
                            },
                            label: function (tooltipItem) {
                                if (tooltipItem.dataset.yAxisID === 'y3') {
                                    return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue.toLocaleString()
                                }
                            },
                        },
                        axis: 'x',
                    },
                    legend: {
                        display: true,
                        labels: {
                            font: {
                                size: 13,
                            },
                            padding: 10,
                            boxWidth: 20,
                            boxHeight: 5,
                            usePointStyle: true,
                        },
                        onClick: function (e, legendItem, legend) {
                            const index = legendItem.datasetIndex
                            const ci = legend.chart
                            const meta = ci.getDatasetMeta(index)

                            // Meta verinin görünürlüğünü toggle et
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

                            // Grafikteki değişiklikleri göstermek için güncelle
                            ci.update()
                        },
                    },
                },
                scales: {
                    x: {
                        display: false,
                        title: {
                            display: true,
                        },
                    },
                    y3: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                            stepSize: 5,
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 25
                        },
                    },
                    y4: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 50
                        },
                    },
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
            },
            countries: countryInfo(),
            formatCurrency,
            getCurrencySymbol,
            pagination: {
                currentPage: 1,
                itemsPerPage: 25,
            },
        }
    },
    mounted() {},
    computed: {
        ...mapGetters('productSearch', ['selectedProduct', 'calculatedPrices', 'productChatHistory']),
        offersByPrice() {
            if (!this.selectedProduct.offers) return []
            const allItems = this.selectedProduct.offers.filter((o) => o.short === this.selectedProduct.target)
            const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage
            const end = this.pagination.currentPage * this.pagination.itemsPerPage
            const items = allItems.sort((a, b) => a.price - b.price)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.offers = items
            return items.slice(start, end)
        },
        totalOffersPage() {
            const offers = this.selectedProduct.offers.filter((o) => o.short === this.selectedProduct.target)
            return Math.ceil(offers.length / this.pagination.itemsPerPage)
        },
        keepaHasData() {
            // map datasets data is not null
            let _return = false
            this.keepaChartData.datasets.forEach((d) => {
                if (d.data.length > 0 && d.data.some((i) => !!i)) {
                    _return = true
                }
            })
            return _return
        },
        keepaOfferHasData() {
            let _return = false
            this.keepaOffersChartData.datasets.forEach((d) => {
                if (d.data.length > 0 && d.data.some((i) => !!i)) {
                    _return = true
                }
            })
            return _return
        },
        keepaImageUrl() {
            if (this.selectedProduct && this.selectedProduct.target) {
                let domain = countries[this.selectedProduct.target].domain
                if (this.selectedDomain) {
                    domain = this.selectedDomain
                }

                let url = 'https://graph.keepa.com/fbahunter.com?width=800&height=350'
                url += this.selectedProduct.asin ? `&asin=${this.selectedProduct.asin}` : ''
                url += `&domain=${domain.replace('amazon.', '')}`
                // url += this.keepa.domain ? '&domain=com' : ''
                url += this.keepa.salesrank ? '&salesrank=1' : ''
                url += this.keepa.buybox ? '&bb=1' : ''
                url += this.keepa.fba ? '&fba=1' : ''
                url += this.keepa.range ? `&range=${this.keepa.range}` : ''
                url += this.keepa.fbm ? '&fbm=1' : ''
                url += this.keepa.ld ? '&ld=1' : '&ld=0'
                url += this.keepa.amazon ? '&amazon=1' : '&amazon=0'

                console.log(url)
                return url
            }
            return ''
        },
    },
    watch: {
        keepa: {
            handler(newValue, oldValue) {
                console.log(newValue)
            },
            deep: true,
        },
        keepaImageUrl() {
            this.keepaLoading = true
        },
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.modalActive()
                const _this = this
                const keepaData = _this.selectedProduct.target_keepa_data
                _this.processChartData(keepaData)
                _this.processOffersChartData(keepaData)
                const locale = _this.$i18n.locale
                _this.chartDateRange = `${new Date(_this.keepaChartData.labels[0]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })} - ${new Date(_this.keepaChartData.labels[_this.keepaChartData.labels.length - 1]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })}`
                this.$nextTick(() => {
                    this.showKeepaChart = false
                    this.$nextTick(() => {
                        this.showKeepaChart = true
                    })
                })
                this.chartKey += 1
            }
        },
        selectedProduct(newValue, oldValue) {
            if (!newValue) return
            this.calculatePrices({
                id: newValue.id,
                params: 'type=' + this.listType,
            })
            this.selectedItems = [
                {
                    id: 1,
                    short: newValue.target,
                    domain: newValue.target,
                    image: this.getImage(newValue.target + '.svg'),
                },
            ]
            this.getChatHistory(newValue.id)
        },
        productChatHistory: {
            handler(newValue, oldValue) {
                if (newValue.length === 0) return
                const _this = this
                _this.scrollToElement()
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('productSearch', ['calculatePrices', 'askToAssistant', 'getChatHistory']),
        handleSelectDomain(selected) {
            this.selectedDomain = selected.domain
        },
        handleKeepaImage() {
            window.open('https://keepa.com/iframe_addon.html#1-0-' + this.selectedProduct.asin, '_blank')
        },
        imageLoaded() {
            this.keepaLoading = false
        },
        changeRange(event) {
            this.keepa.range = +event.target.innerText
        },
        handleToggleCosts() {
            this.toggleCosts = !this.toggleCosts
        },
        handleInputPrice() {
            this.calculatePrices({
                id: this.selectedProduct.id,
                query: {
                    customPrice: this.priceInput,
                    customRoi: this.roiInput,
                },
                params: 'type=' + this.listType,
            })
        },
        handleQuickQuestion(item) {
            this.message = item
            this.handleAskToAssistant()
            this.toggleQuickQuestions = false
        },
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        async handleAskToAssistant() {
            this.typing = true
            const message = this.message
            if (this.message.length === 0) return
            this.productChatHistory.push({
                question_type: 'user',
                message,
                id: this.productChatHistory.length + 1,
            })

            this.message = ''
            const _this = this
            const timeout = setTimeout(() => {
                _this.scrollToElement()

                clearTimeout(timeout)
            }, 300)
            try {
                await this.askToAssistant({
                    id: this.selectedProduct.id,
                    data: {
                        message,
                    },
                })
            } catch (e) {
                this.$toast.error(e.response.data.message)

                this.productChatHistory.pop()
            } finally {
                this.typing = false
            }

            const timeout2 = setTimeout(() => {
                _this.scrollToElement()

                clearTimeout(timeout2)
            }, 300)
        },
        scrollToElement() {
            const scrollToMe = this.$refs.scrollToMe
            if (scrollToMe && scrollToMe.length > 0) {
                scrollToMe[scrollToMe.length - 1].scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
        },
        handleToggleTotalCosts() {
            this.toggleTotalCosts = !this.toggleTotalCosts
        },
        getCurrency(short) {
            return countries[short].currency + ' '
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('productDetailModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
            this.$nextTick(() => {
                window.dispatchEvent(new Event('resize'))
            })
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
            this.pagination.currentPage = 1
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
        generateLast365Days() {
            const today = new Date()
            const days = this.currentDateRange
            return Array.from({ length: days }, (_, i) => formatISO(subDays(today, i), { representation: 'date' })).reverse()
        },
        filterAndTransformDates(data, offsetIndex) {
            const dataMap = new Map()
            if (data && data[offsetIndex]) {
                for (let i = 0; i < data[offsetIndex].length; i += 2) {
                    const item = data[offsetIndex][i]
                    if (item) {
                        const date = new Date((item + MINUTE_OFFSET) * MINUTE_IN_MS)
                        const dateStr = date.toISOString().split('T')[0]
                        if (data[offsetIndex][i + 1] > 0) {
                            dataMap.set(dateStr, data[offsetIndex][i + 1])
                        }
                    }
                }
            }
            return dataMap
        },
        findDataForLabel(dataMap, label) {
            const dateString = new Date(label).toISOString().split('T')[0]
            return dataMap.get(dateString) || undefined
        },
        processChartData(data) {
            this.keepaChartData.labels = this.generateLast365Days()
            if (!data) return

            if (data.salesRankReference && data.salesRanks) {
                const dataMap = this.filterAndTransformDates(data.salesRanks, data.salesRankReference)
                let lastValidData
                this.keepaChartData.datasets[0].data = this.keepaChartData.labels.map((label) => {
                    const foundData = this.findDataForLabel(dataMap, label)
                    if (foundData !== undefined) {
                        lastValidData = foundData
                        return foundData
                    }
                    return lastValidData
                })
            }

            const dateTypes = [0, 10, 7, 18]

            dateTypes.forEach((type, idx) => {
                const dataMap = this.filterAndTransformDates(data.csv, type)
                this.keepaChartData.datasets[idx + 1].data = this.keepaChartData.labels.map((label) => {
                    return dataMap.get(label) || undefined
                })
            })
        },
        processOffersChartData(data) {
            this.keepaOffersChartData.labels = this.generateLast365Days()
            if (!data) return
            const dateTypes = [11, 17]
            if (data.csv.filter((i) => i).length > 0) {
                dateTypes.forEach((type, idx) => {
                    const dataMap = this.filterAndTransformDates(data.csv, type)
                    let lastValidData

                    this.keepaOffersChartData.datasets[idx].data = this.keepaOffersChartData.labels.map((label) => {
                        const foundData = this.findDataForLabel(dataMap, label)
                        if (foundData !== undefined) {
                            lastValidData = foundData
                            return foundData
                        }
                        return lastValidData
                    })
                })
            }
        },
        async handleChangeDateRange(n) {
            this.currentDateRange = n
            this.showKeepaChart = false
            const keepaData = this.selectedProduct.target_keepa_data

            await this.processChartData(keepaData)
            await this.processOffersChartData(keepaData)
            this.keepaChartData.datasets.forEach((dataset) => {
                dataset.data = dataset.data.slice(-n)
            })
            this.keepaOffersChartData.datasets.forEach((dataset) => {
                dataset.data = dataset.data.slice(-n)
            })
            this.$nextTick(() => {
                this.showKeepaChart = true
                const locale = this.$i18n.locale
                this.chartDateRange = `${new Date(this.keepaChartData.labels[0]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })} - ${new Date(this.keepaChartData.labels[this.keepaChartData.labels.length - 1]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })}`
            })
        },
        prevPage() {
            if (this.pagination.currentPage > 1) {
                this.pagination.currentPage--
            }
        },
        nextPage() {
            if (this.pagination.currentPage < this.totalOffersPage) {
                this.pagination.currentPage++
            }
        },
    },
}
</script>

<style scoped>
.hidden-row {
    display: none;
}
.show-row {
    display: table-row;
    background-color: #f8fafd;
}
.thumbnail_wrapper {
    min-width: 200px;
    height: 200px;
    overflow: hidden;
    margin-right: 20px;
    display: inherit;
    position: relative;
    padding: 10px;
    border: 1px solid #eff3f9;
    margin-bottom: 15px;
    border-radius: 5px;
    max-width: 200px;
}
table tr td {
    white-space: pre-line;
    max-width: 200px;
    font-size: 12px;
}
#priceAnalyse .input-group {
    height: 37px;
}
#priceAnalyse .input-group-append {
    height: 37px;
}
#priceAnalyse .input-sm {
    height: 37px;
}
#priceAnalyse td {
    font-weight: 500;
    height: 37px;
    vertical-align: middle;
    font-size: 12px;
}
#priceAnalyse input {
    max-width: 95%;
}
#priceAnalyse th {
    max-width: 200px;
}
#priceAnalyse tr td:not(:first-child) {
    text-align: right;
}
.table-left tr td {
    padding-left: 0;
    padding-right: 0;
}
</style>
