import ExtensionService from '@/services/extension'

const state = {
    availability: null,
    product: {
        updatedAt: new Date(),
        short: null,
        identifiers: null,
        detail: null,
        offers: null,
        dimension: null,
        fees: null,
        eligibility: null,
        userHistory: null,
    },
    keepaData: null,
    productRefresh: null,
    calculate: {
        fba: null,
        fbm: null,
    },
    history: [],
    compare: {
        loading: false,
        list: [],
    },
    parameters: {},
    costs: {
        fba: {
            warehouseCost: 0,
            warehouseCostOnSource: null,
        },
        fbm: {
            warehouseCost: 0,
            warehouseCostOnSource: null,
        },
    },
    warehouseCostLoading: false,
    buying_price: null,
}

const mutations = {
    SET_AVAILABLITY(state, payload) {
        state.availability = payload
    },
    SET_PRODUCT(state, payload) {
        state.product = payload
    },
    SET_CALCULATE(state, payload) {
        state.calculate = payload
    },
    SET_HISTORY(state, payload) {
        state.history = payload
    },
    SET_COMPARE_LIST(state, payload) {
        state.compare.list.push(payload)
    },
    SET_MASS_COMPARE_DATA(state, payload) {
        state.compare.list = payload
    },
    SET_COMPARE_LOADING(state, payload) {
        state.compare.loading = payload
    },
    SET_PARAMETERS(state, payload) {
        state.parameters = payload
    },
    SET_COSTS(state, payload) {
        state.costs = { ...payload }
    },
    SET_WAREHOUSECOST_LOADING(state, payload) {
        state.warehouseCostLoading = payload
    },
    SET_BUYING_PRICE(state, payload) {
        state.buying_price = payload
    },
    SET_KEEPADATA(state, payload) {
        state.keepaData = payload
    },
}

const actions = {
    availability({ commit }, domain) {
        return new Promise((resolve, reject) => {
            ExtensionService.availability(domain)
                .then((response) => {
                    resolve(response)
                    commit('SET_AVAILABLITY', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAvailability({ commit }, domain) {
        return new Promise((resolve, reject) => {
            ExtensionService.availability(domain)
                .then((response) => {
                    resolve(response)
                    commit('SET_AVAILABLITY', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.getProduct(payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_PRODUCT', response.data)
                    commit('SET_COSTS', response.data?.userHistory?.costs)
                    commit('SET_PARAMETERS', response.data?.userHistory['COG'].parameters || null)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getKeepaData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.getKeepaData(payload)
                .then((response) => {
                    if (response.data && !response.data.process) {
                        commit('SET_KEEPADATA', response.data)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    productRefresh({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.productRefresh(payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_PRODUCT', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setCalculate({ commit, dispatch }, payload) {
        commit('SET_WAREHOUSECOST_LOADING', true)
        return new Promise((resolve, reject) => {
            ExtensionService.setCalculate(payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_CALCULATE', response.data)
                    commit('SET_COSTS', {
                        fba: {
                            warehouseCost: response.data.fba?.warehouseCost,
                            warehouseCostOnSource: response.data.fba?.warehouseCostOnSource,
                        },
                        fbm: {
                            warehouseCost: response.data.fbm?.warehouseCost,
                            warehouseCostOnSource: response.data.fbm?.warehouseCostOnSource,
                        },
                    })
                    dispatch('setManualUpdate', { asin: payload.asin, domain: payload.domain, data: payload })
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    commit('SET_WAREHOUSECOST_LOADING', false)
                })
        })
    },
    postSourceDomainDetail({ commit }, { id, payload, isRefresh }) {
        commit('SET_WAREHOUSECOST_LOADING', true)
        return new Promise((resolve, reject) => {
            ExtensionService.postSourceDomainDetail(id, payload, isRefresh)
                .then((response) => {
                    resolve(response)
                    commit('SET_BUYING_PRICE', response.data.buying_price)
                    commit('SET_PARAMETERS', response.data.parameters)
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    commit('SET_WAREHOUSECOST_LOADING', false)
                })
        })
    },
    setManualUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.setManualUpdate(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeFromList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.removeFromList(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    like({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.like(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    history({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ExtensionService.history(payload.params)
                .then((response) => {
                    resolve(response)
                    commit('SET_HISTORY', response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCompareTabDetail({ state, commit }, payload) {
        commit('SET_COMPARE_LOADING', true)
        return new Promise((resolve, reject) => {
            ExtensionService.getCompareTabDetail(payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_COMPARE_LIST', response.data)
                })
                .catch((error) => {
                    reject(error)
                    const findSourceId = state.availability.targetDomain.sourceDomains.find((i) => i.id === payload.sourceId)

                    commit('SET_COMPARE_LIST', {
                        updatedAt: null,
                        asin: payload.asin,
                        short: findSourceId.short,
                        identifiers: null,
                        approvalRequired: null,
                        detail: null,
                        offers: null,
                        dimension: null,
                        fees: null,
                        eligibility: null,
                        violationData: [],
                        userHistory: null,
                        calculatedData: null,
                        parameters: null,
                        isNotFound: true,
                    })
                })
                .finally(() => {
                    commit('SET_COMPARE_LOADING', false)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
