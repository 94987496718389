<template>
    <!-- Account -->
    <div class="dropdown">
        <a class="btn btn-ghost-secondary btn-icon rounded-circle" href="javascript:;" id="accountNavbarDropdown" aria-expanded="false">
            <i class="bi bi-person" style="font-size: 1rem"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" style="width: 16rem; opacity: 1; right: 0">
            <UserCard />
            <router-link class="dropdown-item" to="/store/settings">{{ $t('profile_nav.settings') }}</router-link>
            <router-link class="dropdown-item" to="/settings/subscription">{{ $t('profile_nav.subscription') }}</router-link>
            <router-link class="dropdown-item" to="/settings/support">{{ $t('profile_nav.support') }}</router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="" @click.prevent="handleLogout()">{{ $t('profile_nav.logout') }}</a>
        </div>
    </div>
    <!-- End Account -->
</template>

<script>
import UserCard from '@/components/Header/UserCard'

export default {
    name: 'ProfileNav',
    data() {
        return {}
    },
    components: { UserCard },
    methods: {
        handleLogout() {
            this.$store.dispatch('auth/logout')
        },
        handleSendSupport(data) {
            alert('asd')
        },
        showSupportForm() {
            this.emitter.emit('handleSupportForm')
        },
    },
}
</script>
