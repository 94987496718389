import { tr } from '@formkit/i18n'
import { generateClasses } from '@formkit/themes'

const config = {
    locales: { tr },
    locale: 'tr',
    classes: generateClasses({
        text: {
            label: 'form-label',
        },
    }),
}

export default config
