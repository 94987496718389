<template>
    <div class="rating">
        <div class="star" v-for="rate in 5" :key="rate">
            <div class="empty">
                <div class="star"></div>
            </div>
            <div class="full" :style="getRatingPercentageStyle(rate - 1)">
                <div class="star"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rating',
    props: {
        rating: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        getRatingPercentage(rate) {
            const result = this.rating - rate
            return result >= 1 ? 100 : result >= 0 ? (result * 100).toFixed() : 0
        },
        getRatingPercentageStyle(rate) {
            return {
                width: this.getRatingPercentage(rate) + '%',
                maxWidth: '100%',
            }
        },
    },
}
</script>

<style scoped lang="scss">
.rating {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .star {
        position: relative;
        width: 20px;
        height: 20px;
        .empty {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .star {
                background-image: url('../../assets/star-empty.svg');
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .full {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            overflow: hidden;
            .star {
                background-image: url('../../assets/star-full.svg');
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}
</style>
