<template>
    <Card>
        <CardSection :title="$t('components.subscription.paymentMethod.title')"></CardSection>
        <Spinner v-if="loading" />
        <div class="overflow-hidden bottom-rounded" v-if="!loading">
            <div class="d-flex bordered-top" style="padding-top: 1rem">
                <div class="card-body pt-0">
                    <div class="mb-4" v-if="!payment">
                        <Alert :style="'primary'" soft :text="$t('components.subscription.paymentMethod.warningText')" />
                    </div>
                    <ul class="list-group mb-2" style="max-height: 15rem; overflow: scroll">
                        <li class="list-group-item" v-for="creditCard in savedCreditCards" :key="creditCard.stripeEntityId">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <img v-if="creditCard.firstFour" class="avatar avatar-sm" :src="getImgUrl(`brands/${cardType(creditCard.firstFour)}.svg`)" alt="Image Description" />
                                </div>

                                <div class="flex-grow-1 ms-3">
                                    <div class="row">
                                        <div class="col-sm mb-sm-0" style="padding-top: 10px">
                                            <h5 class="fw-bold" style="text-transform: uppercase">
                                                •••• {{ creditCard.lastFour }}<span v-if="creditCard.isDefault" class="badge bg-primary ms-1" style="text-transform: capitalize">{{ $t('components.subscription.paymentMethod.default') }}</span>
                                            </h5>
                                        </div>
                                        <div class="col-sm-auto">
                                            <div class="d-flex gap-3" v-if="!payment">
                                                <button
                                                    :disabled="submittedSetAsDefault === creditCard.id"
                                                    v-if="savedCreditCards.length > 1 && !creditCard.isDefault"
                                                    @click.prevent="handleSetAsDefault(creditCard.id)"
                                                    type="button"
                                                    class="btn btn-outline-primary btn-sm"
                                                >
                                                    <Spinner v-if="submittedSetAsDefault === creditCard.id" />
                                                    <span v-if="submittedSetAsDefault !== creditCard.id">{{ $t('components.subscription.paymentMethod.setDefault') }}</span>
                                                </button>
                                                <button :disabled="submittedRemoveCard === creditCard.id" type="button" class="btn btn-outline-danger btn-sm" @click.prevent="handleRemoveCard(creditCard.id)">
                                                    <Spinner v-if="submittedRemoveCard === creditCard.id" />
                                                    <span v-if="submittedRemoveCard !== creditCard.id">{{ $t('components.subscription.paymentMethod.remove') }}</span>
                                                </button>
                                            </div>
                                            <div class="d-flex gap-3" v-if="payment">
                                                <button :disabled="selectedCard === creditCard.id || (!selectedCard && creditCard.isDefault)" type="button" class="btn btn-primary btn-sm" @click.prevent="handleSelectCard(creditCard.id)">
                                                    <span v-if="selectedCard === creditCard.id || (!selectedCard && creditCard.isDefault)">{{ $t('components.subscription.paymentMethod.selected') }}</span>
                                                    <span v-else>{{ $t('components.subscription.paymentMethod.select') }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-12" v-if="!payment || savedCreditCards.length === 0">
                            <a class="card card-dashed card-centered" href="javascript:;" @click="showModal = true">
                                <div class="card-body p-2 card-dashed-body">
                                    <img class="avatar avatar-lg avatar-3x2 mb-2" src="@/assets/svg/illustrations-light/oc-add-card.svg" />
                                    <span class="fw-bold">{{ $t('components.subscription.paymentMethod.addCard') }}</span>
                                </div>
                            </a>
                        </div>
                        <div class="d-flex" style="justify-content: flex-end" v-if="payment && savedCreditCards.length > 0">
                            <div class="row col-lg-divider">
                                <div class="col-lg text-end">
                                    <a href="javascript:;" @click="showModal = true">
                                        <span> {{ $t('components.subscription.paymentMethod.addCard') }}</span>
                                    </a>
                                </div>
                                <div v-if="!subscription" class="col-lg text-start" style="width: 10rem">
                                    <a href="javascript:;" @click="handlePayWithDifferentCard">
                                        <span> Farklı kart ile öde</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul v-if="differentCard" class="list-group mb-2">
                        <h2>Ödemenin çekileceği kart</h2>
                        <li class="list-group-item">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <img class="avatar avatar-sm" :src="getImgUrl(`brands/${cardType(differentCard.number.slice(0, 4))}.svg`)" alt="Image Description" />
                                </div>

                                <div class="flex-grow-1 ms-3">
                                    <div class="row">
                                        <div class="col-sm mb-3 mb-sm-0">
                                            <h5 class="fw-bold" style="text-transform: uppercase">{{ differentCard.name }}</h5>
                                            <span class="d-block text-dark">•••• {{ differentCard.number.slice(-4) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </Card>
    <AddCreditCardModal :payWithDifferentCard="payWithDifferentCard" ref="cardForm" :showModal="showModal" @closeCreditModal="showModal = false" @handleSubmit="handleCreditCardFormSubmit" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Card from '@/components/Layouts/Card'
import CardSection from '@/components/Layouts/CardSection'
import Alert from '@/components/Alert'
import Spinner from '@/components/Spinner'
import AddCreditCardModal from '@/components/Subscription/AddCreditCardModal.vue'

export default {
    name: 'PaymentMethods',
    props: {
        payment: {
            type: Boolean,
            default: false,
        },
        subscription: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Card,
        CardSection,
        Alert,
        Spinner,
        AddCreditCardModal,
    },
    data() {
        return {
            showModal: false,
            submittedSetAsDefault: null,
            submittedRemoveCard: null,
            selectedCard: null,
            payWithDifferentCard: false,
            differentCard: null,
            loading: true,
            stripeEntityId: null,
            cardData: null,
        }
    },
    methods: {
        ...mapActions('wallet', ['getCreditCards', 'saveCreditCard', 'setAsDefault', 'removeCard', 'confirmCard']),
        ...mapActions('payment', ['setSelectedCard', 'setDifferentCard', 'clearSelectedCard', 'clearDifferentCard']),
        getImgUrl(image) {
            return require('@/assets/svg/' + image)
        },
        cardType(value) {
            const digit = value.toString()[0]
            if (value.length > 3) {
                return digit === '4' ? 'visa' : digit === '2' || digit === '5' ? 'mastercard' : digit === '6' ? 'maestro' : digit === '3' ? 'amex' : value
            }
            return value
        },
        open3dPopup(url) {
            const width = 500
            const height = 600
            const left = window.screen.width / 2 - width / 2
            const top = window.screen.height / 2 - height / 2
            const popup = window.open(url, '3d', `width=${width},height=${height},top=${top},left=${left}`)
            // check popup url and if it has authorize-card in it then get query strings and close the popup and reload the page
            // Popup yüklenince

            const self = this
            const interval = setInterval(async () => {
                try {
                    // Popup içindeki query string'i al
                    const queryStrings = new URLSearchParams(popup.location.search)

                    // İstenilen parametreleri al
                    const setup_intent_client_secret = queryStrings.get('setup_intent_client_secret')
                    const setup_intent = queryStrings.get('setup_intent')
                    if (setup_intent && setup_intent_client_secret) {
                        await self.handleConfirmCard({ setup_intent_client_secret, setup_intent, stripeEntityId: self.stripeEntityId, ...this.cardData })
                        clearInterval(interval)
                        popup.close()
                    }
                } catch (error) {}

                if (popup.closed) {
                    clearInterval(interval)
                    this.getCreditCards()
                }
            }, 1000)
        },
        async handleConfirmCard(data) {
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            try {
                const response = await this.confirmCard(data)
                if (response.status === 'succeeded') {
                    await this.getCreditCards()
                    this.emitter.emit('credit-modal:submitted', true)
                    this.$emit('closeCreditModal')
                    this.$toast.success('Kartınız kaydedildi.')
                } else {
                    this.emitter.emit('credit-modal:submitted', false)
                    this.$toast.error(response.message)
                    this.payWithDifferentCard = false
                }
                await this.getCreditCards()
            } catch (e) {
                console.log(e)
                this.emitter.emit('credit-modal:submitted', false)
                this.$toast.error('Kart kaydedilemedi.')
                this.payWithDifferentCard = false
            } finally {
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        async handleCreditCardFormSubmit() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getCreditCards()
            this.emitter.emit('credit-modal:submitted', true)
            this.$emit('closeCreditModal')
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        async handleSetAsDefault(card) {
            if (confirm('Emin misiniz?')) {
                this.submittedSetAsDefault = card
                await this.setAsDefault({ creditCardId: card })
                await this.getCreditCards()
                this.submittedSetAsDefault = false
            }
        },
        async handleRemoveCard(card) {
            if (confirm('Emin misiniz?')) {
                this.submittedRemoveCard = card
                await this.removeCard({ cardId: card })
                await this.getCreditCards()
                this.submittedRemoveCard = false
            }
        },
        async handleSelectCard(card) {
            this.selectedCard = card
            this.differentCard = null
            this.payWithDifferentCard = false
        },
        handlePayWithDifferentCard() {
            this.showModal = true
            this.payWithDifferentCard = true
        },
        async getCreditCardList() {
            this.loading = true
            console.log('asd')
            try {
                await this.getCreditCards()
            } catch (e) {
                console.log(e)
            }
            console.log('def')

            this.loading = false
        },
    },
    computed: {
        ...mapGetters('wallet', ['savedCreditCards']),
    },
    mounted() {
        this.getCreditCardList()
        //   this.selectedCard = this.savedCreditCards.find((creditCard) => creditCard.isDefault)?.id
    },
    watch: {
        savedCreditCards: {
            handler: function (val) {
                this.selectedCard = val.find((creditCard) => creditCard.isDefault)?.id
            },
            immediate: true,
            deep: true,
        },
        selectedCard: {
            handler: function (val) {
                this.clearSelectedCard()
                this.setSelectedCard(val)
            },
            immediate: true,
            deep: true,
        },
        differentCard: {
            handler: function (val) {
                this.clearDifferentCard()
                this.setDifferentCard(val)
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>

<style>
.popp {
    margin: 0 !important;
    border: none !important;
}
</style>
