<template>
    <div class="container d-flex justify-content-end">
        <LanguageSwitcher></LanguageSwitcher>
    </div>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div v-if="!sms_view" class="card-body">
                    <!-- Form -->
                    <div class="text-center">
                        <div class="mb-5">
                            <h1 class="display-5">{{ $t('views.auth.forgot_password.title') }}</h1>
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signinSrEmail">{{ $t('views.auth.labels.email') }}</label>
                        <input v-model="email" tabindex="1" type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="email@address.com" aria-label="email@address.com" required="" />
                    </div>
                    <!-- End Form -->
                    <div class="mb-4">
                        <label class="form-label w-100" for="signupSrPassword">
                            <span class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('views.auth.labels.new_password') }}</span>
                            </span>
                        </label>

                        <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                            <input
                                tabindex="2"
                                :type="passwordType"
                                v-model="password"
                                class="js-toggle-password form-control form-control-lg"
                                name="password"
                                id="signupSrPassword"
                                placeholder="********"
                                aria-label="8+ characters required"
                                required=""
                                minlength="8"
                            />
                            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                                <i id="changePassIcon" class="bi-eye-slash"></i>
                            </a>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="form-label w-100" for="signupSrPassword">
                            <span class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('views.auth.labels.new_password_confirmation') }}</span>
                            </span>
                        </label>

                        <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                            <input
                                tabindex="2"
                                :type="passwordType"
                                v-model="password_again"
                                class="js-toggle-password form-control form-control-lg"
                                name="password"
                                id="signupSrPassword"
                                placeholder="********"
                                aria-label="8+ characters required"
                                required=""
                                minlength="8"
                            />
                            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                                <i id="changePassIcon" class="bi-eye-slash"></i>
                            </a>
                        </div>
                    </div>
                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="handleSubmit">
                            {{ $t('actions.send') }}
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth'
import LanguageSwitcher from '@/components/Header/LanguageSwitcher.vue'

export default {
    name: 'ResetPassword',
    components: {
        LanguageSwitcher,
    },
    data: () => ({
        email: null,
        sending: false,
        password: null,
        password_again: null,
        reset_code: null,
        passwordType: 'password',
    }),
    methods: {
        handleSubmit() {
            this.sending = true
            AuthService.resetPassword({
                email: this.email,
                password: this.password,
                password_again: this.password_again,
                code: this.reset_code,
            })
                .then(() => {
                    this.$router.push({ name: 'login' })
                    this.$toast.success(this.$t('views.auth.forgot_password.reset_password_success'))
                })
                .catch((err) => {
                    if (err.response.data.errors.password === 'Şifreler uyuşmuyor') {
                        this.$toast.error(this.$t(`views.auth.forgot_password.${err.response.data.errors.password}`))
                    }
                    if (err.response.data.errors.password === 'Şifre en az 6 karaterden oluşmalıdır') {
                        this.$toast.error(this.$t(`views.auth.forgot_password.${err.response.data.errors.password}`))
                    }
                })
                .finally(() => {
                    this.sending = false
                })
        },
    },
    created() {
        const query = {}
        query.email = this.$route.query['email']
        query.reset_code = this.$route.query['code']

        this.email = query.email
        this.reset_code = query.reset_code
    },
    computed: {},
}
</script>
