import * as CryptoJS from 'crypto-js'

const key = 'ed081790b44fcfc910a70c1fe2854eaa'

export function encrypt(data) {
    return encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(data), key).toString())
}

export function decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(data), key).toString(CryptoJS.enc.Utf8))
}
