<template>
    <div class="container-fluid pt-3" style="max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="title" />
                <Card>
                    <transition name="slide">
                        <CreateForm @handleClose="toggleCreateForm = false" @handleCreatedList="handleCreatedList" v-if="toggleCreateForm" :params="{ type: $route.params.type, list: 'market', action: 'blacklist' }"></CreateForm>
                    </transition>
                    <div class="option-bar d-flex justify-content-between w-100 mb-3" v-if="meta">
                        <div class="option-wrapper">
                            <div class="row">
                                <div class="col">
                                    <div class="result-count">
                                        <span class="count">{{ meta.total }}</span>
                                        <small>{{ $t('list.result') }}</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <input v-model="search" class="form-control input-sm" type="text" @input="searchInput" v-debounce:300ms="handleSearchInput" :placeholder="$t('list.placeholder')" />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <select @change="changePerPage" class="form-select">
                                        <option value="10">10 {{ $t('list.showing') }}</option>
                                        <option value="25">25 {{ $t('list.showing') }}</option>
                                        <option value="50" selected>50 {{ $t('list.showing') }}</option>
                                        <option value="100">100 {{ $t('list.showing') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select @change="changeCurrentPage" class="form-select" v-if="data.last_page">
                                        <option v-if="[...Array(data.last_page).keys()].length === 0" value="">{{ $t('list.no_result') }}</option>
                                        <option :key="page" v-for="page of [...Array(data.last_page).keys()]" :value="page + 1">{{ page + 1 }}. {{ $t('list.page') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="action-wrapper text-right" style="width: 20rem">
                            <button @click="handleToggleCreateForm" class="btn btn-secondary btn-sm mr-1">{{ $t('actions.addNew') }}</button>

                            <button :disabled="selectedRows.length === 0" class="dropdown btn btn-primary btn-sm" data-bs-toggle="dropdown" aria-expanded="true">
                                {{ selectedRows.length > 0 ? `(${selectedRows.length})` : '' }} {{ $t('common.actions') }}
                            </button>
                            <div
                                class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                                aria-labelledby="accountNavbarDropdown"
                                data-popper-placement="top-end"
                                style="position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(-16px, -249px, 0px); overflow: auto; right: 0"
                            >
                                <a class="dropdown-item" href="#" @click="handleRemoveItem">{{ $t('actions.remove_from_list') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 pb-1">
                        <div class="col-12 relative">
                            <div style="overflow-x: auto; overflow-y: auto; position: sticky; top: 0; z-index: 1">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="align-middle">
                                                <input v-model="isSelectAllActive" class="form-check-input" @change="handleCheckboxChange" type="checkbox" />
                                            </th>
                                            <th class="text-left" scope="col">
                                                <span>{{ colTitle }}</span>
                                            </th>
                                            <th class="text-center" scope="col">
                                                <span>{{ $t('list.restricted_list.columns.created_by') }}</span>
                                            </th>
                                            <th class="text-right">
                                                <label>{{ $t('list.restricted_list.columns.created_at') }}</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr></tr>
                                        <tr v-for="item in data" :key="item.id">
                                            <td class="align-middle">
                                                <input ref="checkbox" :checked="selectedRows.indexOf(item.id) > -1" @change="handleCheckboxSingleChange" :value="item.id" class="form-check-input" type="checkbox" />
                                            </td>
                                            <td class="align-middle text-left" scope="row">
                                                {{ item.value }}
                                            </td>
                                            <td class="align-middle text-center" scope="row">
                                                {{ item.created_by }}
                                            </td>
                                            <td class="align-middle text-right" scope="row">
                                                {{ formatDate(item.created_at) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
        <div v-if="isShowModal" class="security-filter-modal" style="position: fixed; inset: 0; z-index: 500">
            <div class="modal-back" @click="isShowModal = !isShowModal"></div>
            <div class="modal-body">
                <div class="modal-title">
                    <span class="title">{{ $t('actions.add_to_my_stores') }}</span>
                    <div class="modal-close" @click="isShowModal = !isShowModal">
                        <i class="fa-solid fa-xmark fa-2xl"></i>
                    </div>
                </div>
                <div class="modal-content">
                    <div v-for="market in markets" style="cursor: pointer; display: block; padding-bottom: 5px; border-bottom: 1px solid #eff3f9" :key="market">
                        <input :checked="currentMarket.short === market.short" :disabled="currentMarket.short === market.short" @change="handleSelectMarket" type="checkbox" class="check" :id="`market_${market.id}`" :value="market.id" />
                        <label :for="`market_${market.id}`" style="cursor: pointer">
                            <img style="margin-top: -7px" width="30" :src="require('@/assets/svg-flag/' + market.short + '.svg')" alt="" />
                            <span style="margin-left: 10px; font-weight: 500; font-size: 14px">{{ market.name }}</span></label
                        >
                    </div>
                    <div class="btn btn-primary btn-sm" @click="handleAddToStores">{{ $t('actions.add_to_my_stores') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Layouts/Card.vue'
import { convertObjectToQueryString } from '@/utils/helper'
import { mapActions, mapGetters } from 'vuex'
import CreateForm from '@/components/RestrictedList/CreateForm.vue'
import moment from 'moment'
import PageHeader from '@/components/Layouts/PageHeader.vue'

export default {
    name: 'RestrictedList',
    components: {
        Card,
        CreateForm,
        PageHeader,
    },
    data() {
        return {
            data: null,
            loading: false,
            total: 0,
            isShowModal: false,
            search: '',
            meta: null,
            page: 1,
            toggleCreateForm: false,
            selectedRows: [],
            selectedMarkets: [],
            toggleActions: false,
            querystring: {
                page: 1,
                perPage: 50,
                search: '',
            },
            isSelectAllActive: false,
        }
    },
    mounted() {
        this.prepareData()
    },
    methods: {
        ...mapActions('restrictedList', ['getRestrictedList', 'deleteRestrictedList']),
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        async prepareData() {
            this.loading = true
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })
            const response = await this.getRestrictedList({
                type: this.$route.params.type,
                params: convertObjectToQueryString(this.querystring),
            })
            this.data = response.data
            this.meta = {
                current_page: response.current_page,
                last_page: response.last_page,
                item_count: response.item_count,
                per_page: response.per_page,
                total: response.total,
            }
            this.total = response.total
            this.loading = false
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
        },
        changePerPage(e) {
            this.querystring.perPage = e.target.value
            this.prepareData()
        },
        changeCurrentPage(e) {
            this.querystring.page = e.target.value
            this.prepareData()
        },
        handleSelectMarket(e) {
            if (e.target.checked) {
                this.selectedMarkets.push(e.target.value)
            } else {
                this.selectedMarkets = this.items.filter((item) => item !== e.target.value)
            }
        },
        searchInput() {
            this.querystring.search = this.search
            this.querystring.page = 1
            this.page = 1
        },
        handleSearchInput() {
            this.prepareData()
        },
        handleToggleCreateForm() {
            this.toggleCreateForm = !this.toggleCreateForm
        },
        handleCreatedList() {
            this.toggleCreateForm = false
            this.prepareData()
        },
        async handleRemoveItem() {
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })
            await this.deleteRestrictedList(this.selectedRows)
            this.prepareData()
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
            this.selectedRows = []
        },
        async handleAddToStores() {
            console.log(this.selectedMarkets)
        },
        handleCheckboxChange: function (e) {
            const checked = e.target.checked
            const allInputs = this.$refs.checkbox

            if (!allInputs) return

            if (checked) {
                allInputs.forEach((input) => {
                    if (input.value && input.value !== 'on') {
                        if (!this.selectedRows.includes(input.value)) {
                            this.selectedRows.push(input.value)
                            input.checked = true
                        }
                    }
                })
            } else {
                this.selectedRows = []
                allInputs.forEach((input) => {
                    input.checked = false
                })
            }
        },
        handleCheckboxSingleChange: function (e) {
            const value = e.target.value
            const checked = e.target.checked

            if (checked) {
                this.selectedRows.push(value)
            } else {
                this.selectedRows = this.selectedRows.filter((item) => item !== value)
            }
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
    },
    computed: {
        ...mapGetters('market', ['markets', 'currentMarket']),
        title() {
            if (this.$route.params.type === 'products') {
                return this.$t('navbar.restricted_list.products')
            } else if (this.$route.params.type === 'brands') {
                return this.$t('navbar.restricted_list.brands')
            } else if (this.$route.params.type === 'sellers') {
                return this.$t('navbar.restricted_list.sellers')
            }
            return ''
        },
        colTitle() {
            if (this.$route.params.type === 'products') {
                return this.$t('list.restricted_list.columns.asin')
            } else if (this.$route.params.type === 'brands') {
                return this.$t('list.restricted_list.columns.brandName')
            } else if (this.$route.params.type === 'sellers') {
                return this.$t('list.restricted_list.columns.sellerId')
            }
            return ''
        },
    },
    watch: {
        page() {
            this.prepareData()
        },
        '$route.params.type'() {
            this.prepareData()
            this.selectedRows = []
            this.isSelectAllActive = false
        },
    },
}
</script>
<style>
.security-filter-modal .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 100%;
    min-width: 280px;
    width: 95%;
    max-width: 340px;
    max-height: 400px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 1000;
    overflow: hidden;
}
.security-filter-modal .modal-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.security-filter-modal .modal-title {
    height: 60px;
    background-color: #edf1f7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.security-filter-modal .modal-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 335px;
    padding: 10px;
    overflow-y: auto;
}
.security-filter-modal .modal-title span {
    font-size: 15px;
    padding: 0 25px;
}
.security-filter-modal .modal-close {
    height: 100%;
    cursor: pointer;
}
</style>
