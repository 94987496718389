<template>
    <div class="d-flex align-items-center gap-2">
        <label for="fileInput" class="btn btn-soft-secondary btn-sm">{{ $tc('common.choose_file_button', multiple ? 2 : 1) }}</label>
        <input type="file" id="fileInput" class="d-none" ref="fileInput" @change="handleFileChange" :accept="accept" :multiple="multiple" />
        <small :title="showTitle">{{ $tc('common.choose_file_count', filePlural) }}</small>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedFiles: [],
            text: 'Choose files',
        }
    },
    computed: {
        filePlural() {
            return this.selectedFiles.length === 0 ? 0 : this.selectedFiles.length === 1 ? { name: this.selectedFiles[0].name } : { count: this.selectedFiles.length }
        },
        showTitle() {
            return this.selectedFiles.length > 0 ? this.selectedFiles.map((i) => i.name).join('\n') : ''
        },
    },
    methods: {
        handleFileChange() {
            if (this.multiple) {
                const files = this.$refs.fileInput.files
                for (let i = 0; i < files.length; i++) {
                    this.selectedFiles.push(files[i])
                }
            } else {
                this.selectedFiles = []
                this.selectedFiles.push(this.$refs.fileInput.files[0])
            }

            this.$refs.fileInput.value = ''
            this.$emit('update:modelValue', this.selectedFiles)
        },
        removeFile(index) {
            this.selectedFiles.splice(index, 1)
            this.$emit('update:modelValue', this.selectedFiles)
        },
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: Array,
        multiple: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
            default: '',
        },
    },
}
</script>
