<template>
    <div class="container pt-3" style="max-width: 80rem">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <Card>
                    <iframe id="buyshippingIframe" scrolling="no" style="height: 100vh" :src="iframeUrl + '&from=' + from + '&to=' + to + '&lang=' + lang"></iframe>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Layouts/Card.vue'
import PrepRequestService from '@/services/prep_requests'
import { mapGetters } from 'vuex'

export default {
    name: 'PrepRequest',
    components: {
        Card,
    },
    data() {
        return {
            prepRequests: [],
            from: '',
            to: '',
            lang: 'tr',
        }
    },
    mounted() {
        window.addEventListener('message', this.onmessage)

        if (this.currentMarket && this.currentMarket.idx) {
            this.findAll()
        }
    },
    methods: {
        findAll() {
            PrepRequestService.findAll(this.currentMarket.idx).then((response) => {
                if (response.data && response.data.length > 0) {
                    this.prepRequests = response.data[0]
                    this.from = response.data[0].from
                    this.to = response.data[0].to
                }
            })
        },
        onmessage(event) {
            const { height, key } = event.data
            if (key == 'iframeHeight') {
                console.log(height)
                document.getElementById('buyshippingIframe').setAttribute('style', `height: ${height}px`)
            }
        },
    },
    computed: {
        ...mapGetters('market', ['markets', 'currentMarket']),
        iframeUrl() {
            return `https://app.buyshipping.com/calculator/?idx=${this.currentMarket.idx}`
        },
    },
    watch: {
        page() {
            this.prepareData()
        },
        '$route.params.type'() {
            this.prepareData()
        },
    },
}
</script>
