import SettingsService from '@/services/settings'
import { useToast } from 'vue-toast-notification'
import i18n from '../i18n'

const $toast = useToast()

const state = {
    scanningOptions: [],
    marketExchangeRates: [],
    exchangeRates: [],
}
const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}

const getters = {
    scanningOptions: (state) => state.scanningOptions,
    marketExchangeRates: (state) => state.marketExchangeRates,
    exchangeRates: (state) => state.exchangeRates,
}

const mutations = {
    SET_SCANNING_OPTIONS(state, payload) {
        state.scanningOptions = payload
    },
    SET_MARKET_EXCHANGE_RATES(state, payload) {
        state.marketExchangeRates = payload
    },
    SET_EXCHANGE_RATES(state, payload) {
        state.exchangeRates = payload
    },
}

const actions = {
    updateScanningOptions({ commit }, payload) {
        SettingsService.updateScanningOptions(payload)
            .then((response) => {
                $toast.success(i18n.global.t('store.updateScanningOptions.success'), toastOptions)
            })
            .catch(() => {
                $toast.error(i18n.global.t('store.updateScanningOptions.error'), toastOptions)
            })
    },
    getScanningOptions({ commit }, payload) {
        try {
            SettingsService.getScanningOptions({ type: 'parameters', marketSourceId: payload }).then((response) => {
                commit('SET_SCANNING_OPTIONS', response.data)
            })
        } catch (e) {
            console.log(e)
        }
    },
    getMarketExchangeRates({ commit }) {
        try {
            SettingsService.getMarketExchangeRates().then((response) => {
                commit('SET_MARKET_EXCHANGE_RATES', response.data)
            })
        } catch (e) {
            console.log(e)
        }
    },
    getExchangeRates({ commit }) {
        try {
            SettingsService.getExchangeRates().then((response) => {
                commit('SET_EXCHANGE_RATES', response.data)
            })
        } catch (e) {
            console.log(e)
        }
    },
    setMarketExchangeRates({ commit }, payload) {
        try {
            SettingsService.setMarketExchangeRates(payload)
        } catch (e) {
            console.log(e)
        }
    },
    async setDefaultParameters({ commit }, payload) {
        try {
            SettingsService.setDefaultParameters(payload).then((response) => {
                commit('SET_SCANNING_OPTIONS', response.data)
            })
        } catch (e) {
            console.log(e)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
