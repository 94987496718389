<template>
    <div class="btn-group">
        <button type="button" class="btn btn-link p-0" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-search fs-6" :class="[header ? '' : 'ms-1']"></i></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" :href="`https://www.google.com/search?q=${text}`" target="_blank">Google</a>
            <a class="dropdown-item" :href="`https://www.google.com/search?tbm=shop&q=${text}`" target="_blank">Google Shopping</a>
            <a class="dropdown-item" :href="`https://www.ebay.com/sch/?_nkw=${text}`" target="_blank">eBay</a>
            <a class="dropdown-item" :href="`https://www.walmart.com/search?q=${text}`" target="_blank">Walmart</a>
            <a class="dropdown-item" :href="`https://www.target.com/s?searchTerm=${text}`" target="_blank">Target</a>
            <a class="dropdown-item" :href="`https://www.webstaurantstore.com/search/${text}`" target="_blank">Webstaurant</a>
            <a class="dropdown-item" :href="`https://www.katom.com/search?w=${text}`" target="_blank">Katom</a>
            <a class="dropdown-item" :href="`https://www.homedepot.com/s/${text}`" target="_blank">The Home Depot</a>
            <a class="dropdown-item" :href="`https://www.alibaba.com/trade/search?SearchText=${text}`" target="_blank">Alibaba</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExtensionSearch',
    props: {
        text: {
            type: [String, Number],
            default: '',
        },
        header: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    methods: {},
}
</script>

<style scoped>
.btn-link {
    padding: 0.25rem 0.25rem;
    font-weight: 400;
    color: var(--fba-extension-secondary);
}
</style>
