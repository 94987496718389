<template>
    <div class="container-fluid py-3 py-sm-3 mb-2" style="max-width: 1600px">
        <div class="maintenance-modal" v-if="false">
            <div class="modal-container">
                <div class="title">Geliştirme Devam Ediyor</div>
                <p>Wholesale ürün arama özelliği henüz tamamlanmadı. Geliştirme sürecine katkı sağlamak, talep ve önerileri iletmek isterseniz aşağıdaki formdan bize ulaşabilirsiniz.</p>
                <hr />
                <form action="">
                    <label>
                        <span>Ad Soyad</span>
                        <input type="text" class="form-control" v-model="contactForm.user" />
                    </label>
                    <label>
                        <span>E-Posta</span>
                        <input type="email" class="form-control" v-model="contactForm.email" />
                    </label>
                    <label>
                        <span>Telefon</span>
                        <input type="text" class="form-control" v-model="contactForm.phone" />
                    </label>
                    <label>
                        <span>Konu Başlığı</span>
                        <input type="text" v-model="contactForm.subject" class="form-control" />
                    </label>
                    <label>
                        <span>Mesaj</span>
                        <textarea class="form-control" v-model="contactForm.message"></textarea>
                    </label>
                    <button type="button" class="btn btn-primary" @click="handleFeedbackForm">Gönder</button>
                </form>
            </div>
        </div>

        <div class="row justify-content-lg-center">
            <div class="col-lg-5 position-relative">
                <div class="position-sticky" style="top: 10px">
                    <Card>
                        <CardSection :title="$t('navbar.wholesale_search.title')"> </CardSection>
                        <div class="bottom-rounded">
                            <div class="pl-2 pr-2 mt-4">
                                <div class="mb-3">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.wholesale.labels.type') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="input-group">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="formInlineRadio1" value="ASIN" class="form-check-input" v-model="wholesale.identifierType" />
                                            <label class="form-check-label" for="formInlineRadio1">ASIN</label>
                                        </div>
                                        <!-- End Form Check -->

                                        <!-- Form Check -->
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="formInlineRadio2" value="UPC" v-model="wholesale.identifierType" class="form-check-input indeterminate-checkbox" />
                                            <label class="form-check-label" for="formInlineRadio2">UPC</label>
                                        </div>
                                        <!-- End Form Check -->
                                        <!-- Form Check -->
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="formInlineRadio4" value="EAN" v-model="wholesale.identifierType" class="form-check-input indeterminate-checkbox" />
                                            <label class="form-check-label" for="formInlineRadio4">EAN</label>
                                        </div>
                                        <!-- End Form Check -->

                                        <!-- Form Check -->
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="formInlineRadio3" value="GTIN" v-model="wholesale.identifierType" class="form-check-input indeterminate-checkbox" />
                                            <label class="form-check-label" for="formInlineRadio3">GTIN</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.wholesale.labels.excel') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="input-group justify-content-between">
                                        <FileUpload v-model="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="handleFileChange" />
                                        <button class="btn btn-secondary ml-2" :disabled="!data" @click="showModal = true">{{ $t('actions.edit_template') }}</button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.country') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <MarketSelect @onSelect="handleSelectMarketSource" :preselectFirst="false" :multiple="false" :all="true" :selectCountry="true" />
                                    <span class="invalid-feedback" v-if="errors.sources"
                                        ><small>{{ $t('integration.updateMarketModal.selectCountry') }}</small></span
                                    >
                                </div>
                                <div class="mb-3">
                                    <label for="min_stock" class="align-self-center">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.search_code') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <input id="sku_code" type="text" placeholder="FH001" v-model="wholesale.sku_code" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="bordered-top mt-3">
                            <div class="d-flex justify-content-end mt-3 mr-2">
                                <button class="btn btn-primary" @click="handleShowDialog">{{ $t('actions.start_search') }}</button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-12">
                        <Card>
                            <CardSection :title="$t('cards.parameters.title')" :collapsed="true"></CardSection>
                            <div v-if="showParemeters" style="padding-top: 1rem">
                                <div class="d-flex ml-2 mr-2 flex-column position-relative" style="width: 20 rem">
                                    <div class="mt-3">
                                        <div class="form-check form-switch mb-4">
                                            <input type="checkbox" @change="prepServiceFeeIncluded = !prepServiceFeeIncluded" class="form-check-input" id="formSwitch1" />
                                            <label class="form-check-label" for="formSwitch1">{{ $t('cards.wholesale.toggle_parameters') }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showParemeters" style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px">
                                <template v-for="group in inputs" :key="group">
                                    <div>
                                        <CardSection :title="$t('cards.parameters.' + group.name)"></CardSection>

                                        <template v-for="input in group.inputs" :key="input">
                                            <div v-if="(!input.excluded || (input.excluded && input.excluded.indexOf(selectedCountry) === -1)) && (!input.included || (input.included && input.included.indexOf(selectedCountry) !== -1))">
                                                <div style="padding-top: 1rem">
                                                    <div class="d-flex ml-2 mr-2 flex-column position-relative" style="width: 20 rem">
                                                        <label class="form-label">{{ $t('inputs.parameters.labels.' + input.name) }} </label>
                                                        <Popper v-if="input.helpText" v-bind="$attrs" hover arrow :class="'popp'">
                                                            <template #content>
                                                                <span style="width: 100px">{{ $t('inputs.parameters.help_text.' + input.name) }}</span>
                                                            </template>
                                                            <span v-if="input.helpText" class="form-text m-0 clamp">{{ $t('inputs.parameters.help_text.' + input.name) }}</span>
                                                        </Popper>
                                                        <template v-if="input.type === 'select'">
                                                            <div class="">
                                                                <select v-model="values[input.name]" class="form-select">
                                                                    <option v-for="option in input.options" :key="option" :value="option.value">{{ option.label }}</option>
                                                                </select>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="mt-3">
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend" v-if="input.prefix">
                                                                        <span class="input-group-text">{{ convertVariables(input.prefix) }}</span>
                                                                    </div>
                                                                    <input
                                                                        :disabled="(group.name === 'fba_warehouse' || group.name === 'fbm_warehouse') && prepServiceFeeIncluded"
                                                                        type="number"
                                                                        v-model="values[input.name]"
                                                                        :name="input.name"
                                                                        aria-label=""
                                                                        min="0"
                                                                        class="form-control"
                                                                    />
                                                                    <div class="input-group-append" v-if="input.suffix">
                                                                        <span class="input-group-text"> {{ convertVariables(input.suffix) }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <div v-if="selectedMarketSources && currentMarket && currentMarket.short !== selectedMarketSources.short">
                                    <div class="card-section bordered-bottom pl-2">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex align-items-center">
                                                <div class="d-flex align-items-center flex-1-1 justify-content-between">
                                                    <h2 class="d-flex card__title text__heading">{{ $t('cards.parameters.exchange_rates') }}</h2>
                                                    <div class="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            v-model="values['use_default_exchange']"
                                                            :checked="values['use_default_exchange'] && (values['use_default_exchange'] === '1' || values['use_default_exchange'])"
                                                            value="1"
                                                            name="use_default_exchange"
                                                            id="formSwitch1"
                                                        />
                                                        <label class="form-check-label" for="formSwitch1">{{ $t('common.automatic') }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4"></div>
                                    <div
                                        class="ml-2 mr-2"
                                        :key="countryCode"
                                        v-for="countryCode in Object.keys(allCountries).filter((country) => country !== currentMarket.short && selectedMarketSources && allCountries[country].short === selectedMarketSources.short)"
                                    >
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> 1 {{ countryCode }} = </span>
                                            </div>
                                            <template v-if="values['use_default_exchange'] && (values['use_default_exchange'] === '1' || values['use_default_exchange'])">
                                                <input
                                                    :disabled="true"
                                                    ref="exchangeRate"
                                                    type="number"
                                                    v-model="defaultExchangeValues[countryCode + allCountriesOrj[currentMarket.short].symbol]"
                                                    :name="countryCode"
                                                    aria-label=""
                                                    min="0"
                                                    class="form-control"
                                                    @input.prevent="handleFormInput"
                                                />
                                            </template>
                                            <template v-else>
                                                <input ref="exchangeRate" type="number" v-model="exchangeValues[countryCode]" :name="countryCode" aria-label="" min="0" class="form-control" @input.prevent="handleFormInput" />
                                            </template>

                                            <div class="input-group-append">
                                                <span class="input-group-text">{{ allCountriesOrj[currentMarket.short].symbol }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div class="col-12">
                        <Card>
                            <div class="card-section bordered-bottom pl-2 pb-2">
                                <div class="d-flex flex-column">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center flex-1-1 justify-content-between">
                                            <h2 class="d-flex card__title text__heading">
                                                {{ $t('cards.filters.title') }}
                                            </h2>
                                            <div class="d-flex">
                                                <select
                                                    v-model="filter_id"
                                                    @change="handleFilterChange"
                                                    :placeholder="$t('inputs.product_search.labels.select_filter')"
                                                    class="form-select form-select-sm mr-1"
                                                    :class="errors.filter_name && 'is-invalid'"
                                                >
                                                    <option value="">{{ $t('inputs.product_search.labels.select_filter') }}</option>
                                                    <option v-for="filter in filters" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
                                                </select>
                                                <div>
                                                    <button class="dropdown btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                                        {{ $t('actions.actions') }}
                                                    </button>
                                                    <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                                                        <a class="dropdown-item" href="#" :class="!filter_id && 'disabled'" @click="handleSaveOnSelectedFilter">{{ $t('actions.save_on_selected_filter') }}</a>
                                                        <a class="dropdown-item" href="#" :class="!filter_id && 'disabled'" @click="handleRemoveSelectedFilter">{{ $t('actions.delete_selected_filter') }}</a>
                                                        <a class="dropdown-item bordered-top" href="#" @click="handleNewFilter">{{ $t('actions.add_filter') }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Filters :selectedFilter="filter_id" @handleFilterFormChange="handleFilterFormChange" />
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TemplateOptions :data="data" :identifierType="wholesale.identifierType" :showModal="showModal" @closeModal="showModal = false" />
    <Dialog
        :options="{
            title: $t('dialogs.product_search_create.title'),
            description: $t('dialogs.wholesale_search_create.description'),
        }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleFormSubmit"
    />
</template>
<script>
import Card from '@/components/Layouts/Card.vue'
import Filters from '@/components/Filters'
import CardSection from '@/components/Layouts/CardSection.vue'
import { mapActions, mapGetters } from 'vuex'
import UserFilterService from '@/services/user-filter'
import * as XLSX from 'xlsx'
import TemplateOptions from '@/components/WholesaleSearch/TemplateOptions.vue'
import MarketSelect from '@/components/Inputs/MarketSelect.vue'
import { scanningOptionInputs } from '@/utils/formInputs'
import Dialog from '@/components/Variations/Dialog.vue'
import FileUpload from '@/components/FileUpload.vue'

import { countryInfo, unitsLongName } from '@/utils/helper'
import AuthService from '@/services/auth'

const countries = countryInfo()
const countries_ = Object.keys(countries).map((short) => ({
    short,
    symbol: countries[short].symbol,
    currency: countries[short].currency,
}))

export default {
    name: 'ProductSearchCreate',
    data() {
        return {
            inputs: scanningOptionInputs,
            showModal: false,
            selectedCountry: null,
            selectedMarketSources: [],
            showParemeters: false,
            prepServiceFeeIncluded: false,
            currentQuota: null,
            allCountriesOrj: countries,
            allCountries: countries_.reduce((acc, cur) => {
                acc[cur.symbol] = cur
                return acc
            }, {}),
            marketSourceId: null,
            data: null,
            file: null,
            errors: {},
            selectedFilter: null,
            headerIndex: 0,
            search_type: 'wholesale',
            filter_name: '',
            openFilterOptions: false,
            filter_id: '',
            filter_options: {},
            values: {},
            showDialog: false,
            oldValues: {},
            exchangeValues: {},
            defaultExchangeValues: {},
            selectedParsers: [],
            contactForm: {
                user: '',
                email: '',
                phone: '',
                subject: 'Wholesale ürün arama hakkında.',
                message: '',
            },
            wholesale: {
                identifierType: 'ASIN',
                asinCount: 0,
                sku_code: null,
                options: {},
                parameters: {},
            },
        }
    },
    methods: {
        ...mapActions('productSearch', ['getFilters', 'setDefaultFilter']),
        ...mapActions('settings', ['getScanningOptions', 'getMarketExchangeRates', 'getExchangeRates', 'updateScanningOptions', 'setMarketExchangeRates']),
        ...mapActions('wallet', ['getUserPackage']),
        handleSelectMarketSource(market) {
            this.selectedMarketSources = market
        },
        async prepareData() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getUserPackage()
            if (this.marketSourceId) {
                await this.getScanningOptions(this.marketSourceId)
                await this.getMarketExchangeRates()
                await this.getExchangeRates()
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        convertVariables(value) {
            let country = ''

            if (!this.selectedMarketSources || (this.selectedMarketSources && !this.selectedMarketSources.short)) {
                country = countries[this.currentMarket.short]
            } else {
                country = countries[this.selectedMarketSources.short]
            }

            if (value.indexOf('{currency}') !== -1) return value.split('{currency}').join(country.currency)
            if (value.indexOf('{weight_unit}') !== -1) return ' / lbs'
            if (value.indexOf('{weight_unit_long}') !== -1) return value.split('{weight_unit_long}').join(unitsLongName(country.weight_unit))
            return value
        },
        handleFileChange() {
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })

            const $this = this
            if (this.file[0]) {
                const reader = new FileReader()
                // check file type
                if (!this.file[0].name.endsWith('.xlsx')) {
                    this.$toast.error(this.$t('common.invalid_file_type'))
                    this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
                    return
                }
                reader.onload = (e) => {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_json(ws, { header: $this.headerIndex + 1 })
                    $this.data = data
                    $this.showModal = true
                    $this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
                }

                reader.readAsBinaryString(this.file[0])
            }
        },
        onInputEventHandler: async function (item) {
            this.selectedFilter = null
        },
        selectItemEventHandler: function (item) {
            this.selectedFilter = item.value
        },
        handleFilterChange: function (e) {
            this.filter_id = e.target.value
        },
        handleFilterFormChange: function (data) {
            this.filter_options = data
        },
        handleNewFilter: async function () {
            // confirm with input
            const generated4Digit = Math.floor(1000 + Math.random() * 9000)
            const filter_name = prompt(this.$t('common.set_filter_name'), this.$t('actions.new_filter') + ' ' + generated4Digit)
            if (filter_name) {
                this.$store.commit('set', { k: 'loading', v: { status: true, text: 'Filtre kaydediliyor' } })
                const createdFilter = await UserFilterService.create('inventory', {
                    name: filter_name,
                    options: this.filter_options,
                })
                await this.getFilters()

                this.filter_id = createdFilter.data.id
                this.openFilterOptions = false
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleRemoveSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true } })
                await UserFilterService.delete(this.filter_id)
                this.filter_id = ''
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleSaveOnSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true } })

                await UserFilterService.update(this.filter_id, {
                    options: this.filter_options,
                })
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleShowDialog() {
            this.errors = {}
            const _package = this.walletPackage
            if (_package && _package.status !== 0 && _package.status !== 1 && _package.status !== 3) {
                this.$toast.error(_package.status === 8 ? this.$t('inputs.product_search.errors.package_paused') : this.$t('inputs.product_search.errors.package_not_active'))
                return
            }
            if (this.selectedMarketSources.length === 0) {
                this.errors.sources = this.$t('inputs.product_search.errors.select_domain')
            }
            if (!this.data) {
                this.errors.url = 'Veri yok.'
            }
            if (Object.keys(this.errors).length > 0) {
                return
            }
            this.showDialog = true
        },
        async handleFeedbackForm() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await AuthService.postFeedbackForm(this.contactForm)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.$toast.success('Mesajınız iletildi.')
            this.contactForm.message = ''
        },
        handleFormSubmit: async function () {
            this.errors = {}
            if (this.selectedMarketSources.length === 0) {
                this.errors.sources = this.$t('inputs.product_search.errors.select_domain')
            }
            if (!this.data) {
                this.errors.url = 'Veri yok.'
            }
            if (Object.keys(this.errors).length > 0) {
                window.scrollTo(0, 70)
                return
            }
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            if (this.selectedParsers.length === 0) {
                this.selectedParsers = [{ value: 'oxylabs', label: 'Oxylabs' }]
            }
            try {
                const sourceCurrency = Object.keys(this.allCountries).find((country) => this.selectedMarketSources && this.allCountries[country].short === this.selectedMarketSources.short)
                const targetCurrency = this.allCountriesOrj[this.currentMarket.short].symbol

                let exchange_rate = 1
                if (this.values['use_default_exchange'] && (this.values['use_default_exchange'] === '1' || this.values['use_default_exchange'])) {
                    exchange_rate = this.defaultExchangeValues[sourceCurrency + targetCurrency]
                } else {
                    exchange_rate = this.exchangeValues[sourceCurrency]
                }
                await this.$store.dispatch('productSearch/createProductSearch', {
                    scan: JSON.stringify({
                        ...this.wholesale,
                        search_type: 'wholesale',
                        sources: [this.selectedMarketSources],
                        parameters: {
                            ...this.values,
                            exchange_rate: exchange_rate ? exchange_rate : 1,
                        },
                        identifierType: this.wholesale.identifierType,
                        parsers: this.selectedParsers.map((parser) => parser.value),
                    }),
                    file: this.file[0],
                    filter: JSON.stringify({
                        filter_id: this.filter_id,
                        filter_options: this.filter_options,
                    }),
                    type: 'wholesale',
                    marketId: this.currentMarket.id,
                    to: this.currentMarket.short,
                })
                this.$router.push({ name: 'wholesale-search.history' })
            } catch (e) {
                console.log(e)
            } finally {
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                this.showDialog = false
            }
        },
    },
    computed: {
        ...mapGetters('wallet', ['requestLimit', 'requestUsed', 'walletPackage']),
        ...mapGetters('auth', ['user']),
        ...mapGetters('market', ['currentMarket']),
        ...mapGetters('productSearch', ['filters']),
        ...mapGetters('settings', ['scanningOptions', 'marketExchangeRates', 'exchangeRates']),
    },
    watch: {
        prepServiceFeeIncluded(newVal) {
            if (newVal) {
                Object.keys(this.values).map((key) => {
                    if (
                        key === 'fba_shipping_per_pound' ||
                        key === 'fba_labeling_cost' ||
                        key === 'fba_pouching_wrapping_cost' ||
                        key === 'fba_product_acceptance_cost' ||
                        key === 'fba_min_prep_service_cost' ||
                        key === 'fba_bubble_wrapping_cost' ||
                        key === 'fba_custom_rate' ||
                        key === 'fbm_shipping_per_pound' ||
                        key === 'fbm_labeling_cost' ||
                        key === 'fbm_pouching_wrapping_cost' ||
                        key === 'fbm_bubble_wrapping_cost' ||
                        key === 'fbm_custom_rate' ||
                        key === 'fba_min_prep_service_cost' ||
                        key === 'fbm_pouching_wrapping_cost' ||
                        key === 'fbm_product_acceptance_cost'
                    ) {
                        this.values[key] = 0
                    }
                })
            } else {
                Object.keys(this.oldValues).map((key) => {
                    if (
                        key === 'fba_shipping_per_pound' ||
                        key === 'fba_labeling_cost' ||
                        key === 'fba_pouching_wrapping_cost' ||
                        key === 'fba_product_acceptance_cost' ||
                        key === 'fba_min_prep_service_cost' ||
                        key === 'fba_bubble_wrapping_cost' ||
                        key === 'fba_custom_rate' ||
                        key === 'fbm_shipping_per_pound' ||
                        key === 'fbm_labeling_cost' ||
                        key === 'fbm_pouching_wrapping_cost' ||
                        key === 'fbm_bubble_wrapping_cost' ||
                        key === 'fbm_custom_rate' ||
                        key === 'fbm_min_prep_service_cost' ||
                        key === 'fbm_pouching_wrapping_cost' ||
                        key === 'fbm_product_acceptance_cost'
                    ) {
                        this.values[key] = this.oldValues[key]
                    }
                })
            }
        },
        scanningOptions(newVal) {
            if (newVal && newVal.length > 0) {
                this.defaultParams = false
                newVal.map((item) => {
                    if (item.is_default === 1) {
                        this.defaultParams = true
                    }
                    this.values[item.key] = item.value
                    this.oldValues[item.key] = item.value
                })
            }
        },
        marketExchangeRates(newVal) {
            if (newVal && newVal.length > 0) {
                newVal.map((item) => {
                    this.exchangeValues[item.currency] = item.value
                })
            }
        },
        exchangeRates(newVal) {
            if (newVal && newVal.length > 0) {
                newVal.map((item) => {
                    this.defaultExchangeValues[item.from + item.to] = (+item.value).toFixed(2)
                })
            }
        },
        user: {
            handler: function (val) {
                if (val) {
                    console.log(val)
                    this.contactForm.user = val.name + ' ' + val.surname
                    this.contactForm.email = val.email
                    this.contactForm.phone = val.phoneCountry + '' + val.mobileNo
                }
            },
            immediate: true,
            deep: true,
        },
        selectedMarketSources: {
            handler(newVal) {
                if (newVal && newVal.short) {
                    this.values = {}
                    this.oldValues = {}
                    this.form_changed = false
                    this.dirtyFields = []
                    this.selectedCountry = newVal.short

                    const marketSource = this.currentMarket.sources.find((market) => market.source === newVal.short)

                    if (!marketSource) return

                    this.marketSourceId = marketSource.idx

                    if (this.marketSourceId) {
                        this.prepareData()
                    }
                }
            },
            immediate: true,
        },
    },
    beforeUnmount() {
        this.emitter.off('saveTemplateOptions')
        this.emitter.off('toggleCollapse')
    },
    mounted() {
        this.setDefaultFilter(null)

        this.emitter.on('saveTemplateOptions', (data) => {
            this.wholesale.options = data
        })

        this.emitter.on('toggleCollapse', (data) => {
            this.showParemeters = data
        })
    },
    components: { Card, CardSection, Filters, TemplateOptions, MarketSelect, Dialog, FileUpload },
}
</script>

<style>
.filter-item-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}
.asin-list {
    height: 100px !important;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    min-height: 250px !important;
    margin-bottom: 5px !important;
    max-height: 600px !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffe3be47), color-stop(50%, #fff));
    background-image: linear-gradient(#ffe3be47 50%, #fff 0);
    background-attachment: local;
    background-size: 100% 50px !important;
    line-height: 25px !important;
    font-size: 14px !important;
    padding: 0 8px !important;
}
.filter-item-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
    align-items: flex-start;
    line-height: normal;
}
.filter-item-row input[type='checkbox'] {
    margin-right: 8px;
}
input.check {
    display: none;
}
input.check[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 20px;
    padding-left: 30px;
    padding-top: 3px;
}
input.check[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
}
input.check[type='checkbox']:checked + label:before {
    background-color: #fd9405;
    -webkit-box-shadow: inset 0 0 0 1px #fd9405;
    box-shadow: inset 0 0 0 1px #fd9405;
}
input.check[type='checkbox']:checked + label:after {
    content: '';
    width: 11px;
    height: 6px;
    display: block;
    position: absolute;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 5px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.star-select-box {
    position: relative;
    max-width: 190px;
    height: 45px;
    width: 100%;
}
.star-select-box .star-box {
    width: 100%;
    height: 45px;
    padding: 5px 8px;
    background-color: #fff;
    border: 1px solid #dbe3ef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.star-select-box ul {
    padding-left: 0;
}
.star-select-box ul.star-list {
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    border: 1px solid #dbe3ef;
    border-radius: 5px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.star-select-box .active-star-list {
    opacity: 1 !important;
    pointer-events: auto !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.star-select-box ul.star-list li {
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ecf0f7;
    font-size: 14px;
}
</style>
