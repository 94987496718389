<template>
    <div class="container d-flex justify-content-end">
        <LanguageSwitcher></LanguageSwitcher>
    </div>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div v-if="!sms_view" class="card-body">
                    <!-- Form -->
                    <div class="text-center">
                        <div class="mb-5">
                            <h1 class="display-5">{{ $t('views.auth.login.title') }}</h1>
                            <p>
                                {{ $t('views.auth.login.description') }} <router-link class="link" to="/register">{{ $t('views.auth.labels.register') }}</router-link>
                            </p>
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signinSrEmail">{{ $t('views.auth.labels.email') }}</label>
                        <input v-model="email" tabindex="1" type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="email@address.com" aria-label="email@address.com" required="" />
                    </div>
                    <!-- End Form -->

                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label w-100" for="signupSrPassword">
                            <span class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('views.auth.labels.password') }}</span>
                                <a class="form-label-link mb-0" href="/password-recovery">{{ $t('views.auth.labels.forgot_password') }}</a>
                            </span>
                        </label>

                        <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                            <input
                                tabindex="2"
                                :type="passwordType"
                                v-model="password"
                                class="js-toggle-password form-control form-control-lg"
                                name="password"
                                id="signupSrPassword"
                                placeholder="********"
                                aria-label="8+ characters required"
                                required=""
                                minlength="8"
                            />
                            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                                <i id="changePassIcon" class="bi-eye-slash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="smsViewStart">
                            {{ $t('views.auth.labels.login') }}
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguageSwitcher from '@/components/Header/LanguageSwitcher.vue'

export default {
    name: 'Login',
    components: {
        LanguageSwitcher,
    },
    data: () => ({
        frameHeight: '700px',
        token: null,
        logged: false,
        passwordType: 'password',
        email: null,
        password: null,
        remember_me: false,
        sms_view: false,
        sms_code: null,
        sms_time: 120,
        sms_time_text: '2:00',
        verificationToken: '',
        sending: false,
    }),
    methods: {
        ...mapActions('auth', ['login', 'me', 'setToken']),
        smsViewStart() {
            this.sms_time = 120
            this.sending = true
            this.login({
                email: this.email,
                username: this.email,
                password: this.password,
                remember_token: localStorage.getItem('rom_remember_token'),
            })
                .then(async (response) => {
                    if (response.verify) {
                        this.sms_view = false
                        this.sending = false
                        this.token = response.token
                        this.$store.commit('set', { k: 'loading', v: { status: true } })
                        await this.setToken(response.token)
                        await this.me()
                        setTimeout(() => {
                            this.$router.push({ name: 'dashboard' })
                            this.$store.commit('set', { k: 'loading', v: { status: false } })
                        }, 1500)
                        return
                    }
                    this.sending = true
                    this.sending = false
                    this.sms_view = true
                    this.masked_phone = response.masked_phone
                    this.verificationToken = response.token
                    const _this = this
                    setInterval(() => {
                        _this.sms_time--
                    }, 1000)
                })
                .catch(() => {
                    this.sending = false
                    this.$toast.error(this.$t('views.auth.login.error'))
                })
        },
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
    },
    watch: {
        sms_time() {
            if (this.sms_time <= 0) {
                this.sms_time_text = '0:00'
                return
            }
            const minutes = Math.floor(this.sms_time / 60)
            const seconds = this.sms_time - minutes * 60
            this.sms_time_text = `${minutes}:${seconds}`
        },
        isAuthenticated: {
            immediate: true,
            handler() {
                if (this.isAuthenticated) {
                    this.$router.push({ name: 'dashboard' })
                }
            },
        },
    },
}
</script>
