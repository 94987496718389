<template>
    <div class="create-form-wrapper d-flex flex-column">
        <div class="d-flex" style="justify-content: flex-end">
            <button @click="handleClose" class="btn btn-secondary btn-sm">X</button>
        </div>
        <div class="d-flex">
            <div class="form-wrapper mr-2">
                <div class="title-wrapper" v-html="$t('components.restrictedList.createForm.title', { title })"></div>
                <div class="input-wrapper">
                    <textarea v-model="itemInput" class="form-control" rows="5" :placeholder="$t('components.restrictedList.createForm.inputPlaceholder', { title })"></textarea>
                </div>
                <div class="action-wrapper">
                    <button @click="handleSubmit" class="btn btn-primary">{{ $t('components.restrictedList.createForm.submit') }}</button>
                </div>
            </div>
            <div class="market-select-wrapper" style="width: 18rem">
                <div class="title-wrapper">
                    <h3 class="title">{{ $t('components.restrictedList.createForm.add') }}</h3>
                    <small></small>
                </div>
                <div class="input-wrapper" style="margin-top: 2.35rem; background-color: #fff; padding: 10px; border: 0.0625rem solid rgba(231, 234, 243, 0.7)">
                    <div v-for="market in markets" style="cursor: pointer; display: block; padding-bottom: 5px; padding-top: 10px; border-bottom: 1px solid #eff3f9" :key="market">
                        <input :checked="currentMarket.short === market.short" :disabled="currentMarket.short === market.short" @change="handleSelectMarket" type="checkbox" class="check" :id="`market_${market.id}`" :value="market.id" />
                        <label :for="`market_${market.id}`" style="cursor: pointer">
                            <img style="margin-top: -7px" width="25" :src="require('@/assets/svg-flag/' + market.short + '.svg')" alt="" />
                            <span style="margin-left: 10px; font-weight: 500; font-size: 14px">{{ market.name }}</span></label
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'CreateForm',
    components: {},
    props: {
        params: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            itemInput: '',
            items: [],
            selectedMarkets: [],
        }
    },
    computed: {
        ...mapGetters('market', ['markets', 'currentMarket']),
        title() {
            if (this.params.type === 'brands') {
                return this.$t('components.restrictedList.createForm.brand')
            } else if (this.params.type === 'sellers') {
                return this.$t('components.restrictedList.createForm.seller')
            } else if (this.params.type === 'products') {
                return this.$t('components.restrictedList.createForm.product')
            }

            return ''
        },
    },
    methods: {
        ...mapActions('restrictedList', ['createRestrictedList']),
        handleSelectMarket(e) {
            if (e.target.checked) {
                this.selectedMarkets.push(e.target.value)
            } else {
                this.selectedMarkets = this.items.filter((item) => item !== e.target.value)
            }
        },
        async handleSubmit() {
            this.items = []
            this.itemInput
                .trim()
                .split('\n')
                .forEach((item, index) => {
                    this.items.push(item)
                })

            this.items = [...new Set(this.items)]

            this.$store.commit('set', { k: 'loading', v: { status: true } })

            if (!this.selectedMarkets.length) this.selectedMarkets.push(this.currentMarket.id)

            await this.createRestrictedList({
                items: this.items,
                markets: [...this.selectedMarkets, this.currentMarket.id],
                type: this.params.type,
            })
            this.$emit('handleCreatedList')
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        handleClose() {
            this.$emit('handleClose')
        },
    },
}
</script>
<style>
.create-form-wrapper {
    position: relative;
    padding: 20px;
    background-color: #f8fafd;
    border-radius: 5px;
    margin-bottom: 2rem;
}
.title-wrapper h3 {
    font-weight: 600;
}
.input-wrapper {
    margin-top: 1rem;
}
.input-wrapper textarea {
    height: 100px !important;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    min-height: 250px !important;
    margin-bottom: 5px !important;
    max-height: 600px !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffe3be47), color-stop(50%, #fff));
    background-image: linear-gradient(#ffe3be47 50%, #fff 0);
    background-attachment: local;
    background-size: 100% 50px !important;
    line-height: 25px !important;
    font-size: 14px !important;
    padding: 0 8px !important;
}
.create-form-wrapper.action-wrapper {
    margin-top: 1rem;
}

input.check {
    display: none;
}
input.check[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 20px;
    padding-left: 30px;
    padding-top: 3px;
}
input.check[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
}
input.check[type='checkbox']:checked + label:before {
    background-color: #fd9405;
    -webkit-box-shadow: inset 0 0 0 1px #fd9405;
    box-shadow: inset 0 0 0 1px #fd9405;
}
input.check[type='checkbox']:checked + label:after {
    content: '';
    width: 11px;
    height: 6px;
    display: block;
    position: absolute;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 5px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
</style>
