<template>
    <div class="container-fluid py-3 py-sm-3" style="margin-bottom: 2rem; max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-3 position-relative">
                        <div class="navbar-expand-lg navbar-vertical mb-3 mb-lg-5 position-sticky" style="top: 0rem">
                            <SettingsNav />
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingsNav from '@/components/Layouts/SettingsNav.vue'

export default {
    name: 'Settings',
    components: {
        SettingsNav,
    },
}
</script>
