<template>
    <div>
        <div v-if="isLogged && user">Email: {{ user.email }}</div>
        <div v-else>Not logged in</div>
    </div>
</template>

<script>
export default {
    name: 'Me',
    components: {},
    computed: {
        isLogged() {
            return this.$store.getters['auth/isAuthenticated']
        },
        user() {
            return this.$store.getters['auth/user']
        },
    },
}
</script>
