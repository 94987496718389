import { ApiInstance } from './base'

const UserFilterService = {
    findAll(payload) {
        return ApiInstance.get('/filters/' + payload)
    },
    create(type, payload) {
        return ApiInstance.post('/filters/' + type, payload)
    },
    update(filter_id, payload) {
        return ApiInstance.put('/filters/' + filter_id, payload)
    },
    delete(filter_id) {
        return ApiInstance.delete('/filters/' + filter_id)
    },
}

export default UserFilterService
