<template>
    <div v-if="!hideBanner" class="extension-banner">
        <a href="https://chromewebstore.google.com/detail/fbahunter-extension/pdlkipdelhnincphjpkcoiaafohdpjbl" target="_blank">
            <img src="@/assets/img/banners/extension.png" alt="" />
        </a>
        <button class="btn btn-link" type="button" @click="closeBanner">
            <i class="bi bi-x"></i>
        </button>
    </div>
    <header id="header" class="navbar navbar-expand-lg navbar-bordered bg-white">
        <div class="container">
            <nav class="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
                <Logo></Logo>
                <SecondaryContent></SecondaryContent>
                <Toggler></Toggler>
                <NavBar></NavBar>
            </nav>
        </div>
    </header>
</template>

<script>
import Logo from '@/components/Header/Logo.vue'
import SecondaryContent from '@/components/Header/SecondaryContent.vue'
import Toggler from '@/components/Header/Toggler.vue'
import NavBar from '@/components/Header/NavBar.vue'

export default {
    name: 'Header',
    components: {
        Logo,
        SecondaryContent,
        Toggler,
        NavBar,
    },
    data() {
        return {
            hideBanner: false,
        }
    },
    mounted() {
        if (localStorage.getItem('hide-banner')) {
            this.hideBanner = true
        }
    },
    methods: {
        closeBanner() {
            this.hideBanner = true
            window.localStorage.setItem('hide-banner', 'true')
        },
    },
}
</script>

<style lang="scss" scoped>
.extension-banner {
    display: flex;
    position: relative;
    background-color: #1e2022;

    img {
        width: 100%;
        height: 80px;
        object-fit: contain;
    }

    button {
        background-color: #ffffff1a;
        border-radius: 50%;
        line-height: 20px;
        color: #fff;
        position: absolute;
        top: 25px;
        right: 15%;
        font-size: 20px;
        padding: 5px;

        &:hover {
            background-color: #fff;
            color: #1e2022;
        }
    }
}
</style>
