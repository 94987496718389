<template>
    <div class="d-flex align-items-center justify-content-between mb-3">
        <img src="@/assets/logo.svg" @click="goTo" style="cursor: pointer" width="90" height="16" alt="Logo" />
    </div>
    <div class="card card-lg pt-1" style="border: none">
        <div class="card-body p-2">
            <div class="text-center">
                <div class="mb-5">
                    <p>
                        {{ $t('views.auth.login.description') }} <a class="link" href="/register" target="_blank">{{ $t('views.auth.labels.register') }}</a>
                    </p>
                </div>
            </div>

            <div class="mb-4">
                <label class="form-label" for="signinSrEmail">{{ $t('views.auth.labels.email') }}</label>
                <input v-model="loginForm.email" tabindex="1" type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="email@address.com" aria-label="email@address.com" required="" />
            </div>

            <div class="mb-4">
                <label class="form-label w-100" for="signupSrPassword">
                    <span class="d-flex justify-content-between align-items-center">
                        <span>{{ $t('views.auth.labels.password') }}</span>
                        <a class="form-label-link mb-0" href="/password-recovery" target="_blank">{{ $t('views.auth.labels.forgot_password') }}</a>
                    </span>
                </label>

                <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                    <input
                        tabindex="2"
                        :type="passwordType"
                        v-model="loginForm.password"
                        class="js-toggle-password form-control form-control-lg"
                        name="password"
                        id="signupSrPassword"
                        placeholder="********"
                        aria-label="8+ characters required"
                        required=""
                        minlength="8"
                    />
                    <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                        <i class="bi-eye-slash"></i>
                    </a>
                </div>
            </div>

            <div class="d-grid">
                <button :disabled="isButtonLoading" type="button" class="btn btn-primary btn-lg" @click="submitLoginForm">
                    {{ $t('views.auth.labels.login') }}
                    <span v-show="isButtonLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'ExtensionLoginForm',
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
            },
            passwordType: 'password',
            isButtonLoading: false,
        }
    },
    mounted() {
        this.observeOffsetHeight()
    },
    methods: {
        ...mapActions('auth', ['login', 'me', 'setToken']),
        ...mapActions('extension', ['availability', 'getProduct']),
        submitLoginForm() {
            this.isButtonLoading = true

            this.login({
                email: this.loginForm.email,
                username: this.loginForm.email,
                password: this.loginForm.password,
            })
                .then(async (response) => {
                    if (response.verify) {
                        window.parent.postMessage({ token: response.token }, '*')
                        await this.setToken(response.token)
                        window.location.reload()
                    }
                })
                .catch(() => {
                    this.isButtonLoading = false
                    this.$toast.error(this.$t('views.auth.login.error'))
                })
        },
        goTo() {
            window.open('https://fbahunter.com', '_blank')
        },
        observeOffsetHeight() {
            const observer = new ResizeObserver(() => {
                window.parent.postMessage({ fbaScrollHeight: document.body.offsetHeight }, '*')
            })
            observer.observe(document.body)
        },
    },
}
</script>
