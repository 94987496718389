import store from '@/store'
import axios from 'axios'

const baseAccountURL = process.env.VUE_APP_ACCOUNTS_DOMAIN || 'https://api-paneltest.extenship.com'
const baseApiUrl = process.env.VUE_APP_API_DOMAIN || 'https://api-dev.fbahunter.com'
const baseWalletUrl = process.env.VUE_APP_WALLET_DOMAIN || 'https://wallet-develop.extenship.com/api'
const baseFcaUri = 'https://fca.extenship.com'

const AccountInstance = axios.create({
    baseURL: baseAccountURL,
    headers: {
        'Content-Type': 'application/json',
        'x-application-name': 'fbahunter-dev',
    },
})
AccountInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
AccountInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401 && window.location.pathname !== '/login' && window.location.pathname !== '/super-login/user' && window.location.pathname !== '/extension' && !window.localStorage.getItem('token')) {
            store.dispatch('auth/logout')
        }
        return Promise.reject(error)
    },
)

const ApiInstance = axios.create({
    baseURL: baseApiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})
ApiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    const currentMarket = localStorage.getItem('currentMarket')
    if (currentMarket) {
        config.headers['x-market-id'] = JSON.parse(currentMarket).idx
    }

    return config
})

const WalletInstance = axios.create({
    baseURL: baseWalletUrl,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
})
WalletInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

const FcaInstance = axios.create({
    baseURL: baseFcaUri,
    headers: {
        'Content-Type': 'application/json',
    },
})
export { AccountInstance, ApiInstance, WalletInstance, FcaInstance }
