<template>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div v-if="!sms_view" class="card-body">
                    <!-- Form -->
                    <div class="text-center">
                        <div class="mb-5">
                            <h1 class="display-5">Yönetici Girişi Yap</h1>
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signinSrEmail">E-posta adresi</label>
                        <input v-model="email" tabindex="1" type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="email@address.com" aria-label="email@address.com" required="" />
                    </div>
                    <!-- End Form -->

                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label w-100" for="signupSrPassword">
                            <span class="d-flex justify-content-between align-items-center">
                                <span>Parola</span>
                                <a class="form-label-link mb-0" href="/password-recovery">Parolamı unuttum</a>
                            </span>
                        </label>

                        <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                            <input
                                tabindex="2"
                                :type="passwordType"
                                v-model="password"
                                class="js-toggle-password form-control form-control-lg"
                                name="password"
                                id="signupSrPassword"
                                placeholder="********"
                                aria-label="8+ characters required"
                                required=""
                                minlength="8"
                            />
                            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                                <i id="changePassIcon" class="bi-eye-slash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="smsViewStart">
                            Giriş yap
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Login',
    data: () => ({
        frameHeight: '700px',
        token: null,
        logged: false,
        passwordType: 'password',
        email: null,
        password: null,
        remember_me: false,
        sms_view: false,
        sms_code: null,
        sms_time: 120,
        sms_time_text: '2:00',
        verificationToken: '',
        sending: false,
    }),
    methods: {
        ...mapActions('auth', ['login', 'me', 'setAdminToken']),
        smsViewStart() {
            this.sms_time = 120
            this.sending = true
            this.login({
                email: this.email,
                username: this.email,
                password: this.password,
                type: 'staff',
            })
                .then(async (response) => {
                    this.sms_view = false
                    this.sending = false
                    this.token = response.token
                    this.$store.commit('set', { k: 'loading', v: { status: true } })
                    await this.setAdminToken(response.token)
                    setTimeout(() => {
                        this.$router.push({ name: 'admin-login.user' })
                        this.$store.commit('set', { k: 'loading', v: { status: false } })
                    }, 1500)
                })
                .catch(() => {
                    this.sending = false
                    this.$toast.error('Giriş başarısız oldu. Lütfen bilgilerinizi kontrol edin.')
                })
        },
    },
    mounted() {},
    computed: {
        ...mapGetters('auth', ['adminToken']),
    },
    watch: {
        adminToken: {
            handler: function () {
                if (this.adminToken) {
                    this.$router.push({ name: 'admin-login.user' })
                }
            },
            immediate: true,
        },
    },
}
</script>
