<!-- eslint-disable max-len -->
<template>
    <div class="row g-0">
        <div class="col-7 section-row">
            <table>
                <tr>
                    <td class="text-secondary fw-semibold">{{ $t('views.extension.data') }}:</td>
                    <td class="text-secondary">{{ productRefreshData.domain }}</td>
                    <td>
                        <Popper class="link-secondary" hover placement="bottom" arrow>
                            <template #content> {{ $t('views.extension.compare.lastUpdate') }} {{ printDateTime(product.updatedAt, 'HH:mm') }} </template
                            ><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target=".product-refresh-modal" :disabled="clickedProductRefresh">
                                <span v-if="clickedProductRefresh" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="width: 0.875em; height: 0.875em; margin-left: 0.3rem"></span>
                                <i v-else class="bi bi-arrow-repeat ms-1"></i></button
                        ></Popper>
                    </td>
                </tr>
                <tr v-if="product.detail?.parentAsin">
                    <td class="text-secondary fw-semibold">{{ $t('views.extension.parent') }}:</td>
                    <td class="text-secondary">{{ product.detail?.parentAsin }}</td>
                    <td>
                        <ExtensionClipboard :text="product.detail?.parentAsin" />
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content><p v-html="$t('views.extension.asinTooltip')"></p></template>{{ $t('views.extension.asin') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ productRefreshData.asin }}</td>
                    <td>
                        <ExtensionClipboard :text="productRefreshData.asin" />
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content><p v-html="$t('views.extension.upcTooltip')"></p></template>UPC:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ product.identifiers?.upc && product.identifiers?.upc != 0 ? product.identifiers?.upc : '-' }}</td>
                    <td v-if="product.identifiers?.upc">
                        <ExtensionClipboard :text="product.identifiers?.upc && product.identifiers?.upc != 0 ? product.identifiers?.upc : '-'" />
                        <ExtensionSearch :text="product.identifiers?.upc && product.identifiers?.upc != 0 ? product.identifiers?.upc : '-'" />
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content><p v-html="$t('views.extension.eanTooltip')"></p></template>EAN:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ product.identifiers?.ean && product.identifiers?.ean != 0 ? product.identifiers?.ean : '-' }}</td>
                    <td v-if="product.identifiers?.ean">
                        <ExtensionClipboard :text="product.identifiers?.ean && product.identifiers?.ean != 0 ? product.identifiers?.ean : '-'" />
                        <ExtensionSearch :text="product.identifiers?.ean && product.identifiers?.ean != 0 ? product.identifiers?.ean : '-'" />
                    </td>
                </tr>
                <tr v-if="product.detail?.bsr" class="position-relative">
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.bsrTooltip')"></div> </template>{{ $t('views.extension.bsr') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary limited-text-size">{{ product.detail?.bsr }} | {{ product.detail?.bsrCategory }}</td>
                </tr>
                <tr v-if="keepaData && keepaData.stats" class="position-relative">
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.dropsTooltip')"></div> </template>Drops:</Popper
                        >
                    </td>
                    <td class="text-secondary limited-text-size">
                        {{ keepaData?.stats?.salesRankDrops30 }} | {{ keepaData?.stats?.salesRankDrops90 }} | {{ keepaData?.stats?.salesRankDrops180 }}
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('common.providedByKeepa')"></div> </template>
                            <KeepaLogo
                                :color="'#256a93'"
                                :style="{
                                    marginTop: '-2px',
                                }"
                                :width="15"
                                :height="15"
                            />
                        </Popper>
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.stockTooltip')"></div> </template>{{ $t('views.extension.stock') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">
                        <span v-if="!stockLoading && rawProductStockDetail && !rawProductStockDetail.limited">{{ rawProductStockDetail ? rawProductStockDetail.stock : '' }}</span>
                        <Popper v-if="!stockLoading && rawProductStockDetail && rawProductStockDetail.limited" class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.stockLimitedTooltip', { amount: rawProductStockDetail ? rawProductStockDetail.stock : '' })"></div> </template
                            ><span>{{ rawProductStockDetail ? rawProductStockDetail.stock + '+' : '' }}</span></Popper
                        >
                        <span v-if="stockLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="width: 0.875em; height: 0.875em"></span>
                        <span v-if="!rawProductStockDetail && !stockLoading">-</span>
                    </td>
                </tr>
                <tr v-if="product.detail?.category">
                    <td class="text-secondary fw-semibold">{{ t('views.extension.cat') }}:</td>
                    <td class="text-secondary">{{ product.detail?.category }}</td>
                </tr>
                <!--                 <tr>
                    <td
                        class="text-secondary fw-semibold"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-html="true"
                        data-bs-content="<p> <b>Best Sellers Rank</b> or Sales Rank is a score that Amazon assigns the product based on historical sales data. </p> <p>FBAHunter App shows <b>current and 90 day average BSR.</b></p> <p><b>* Average BSR is provided by Keepa.</b></p>"
                    >
                        BSR:
                    </td>
                    <td class="text-secondary">{{ product.detail?.bsr }}</td>
                </tr> -->
                <!--                 <tr>
                    <td class="text-secondary fw-semibold" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="<p> The amount of goods available for sale. </p>">Stock:</td>
                    <td class="text-primary">
                        {{ product.detail?.stock }}
                    </td>
                </tr> -->
            </table>
        </div>
        <div class="col-5 section-row">
            <table>
                <tr v-if="variants.length > 0">
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover arrow>
                            <template #content>
                                <div class="popper-table" style="max-height: 425px; overflow-y: scroll; overflow-x: hidden">
                                    <p>{{ $t('views.extension.variations') }}</p>
                                    <table>
                                        <tr style="font-weight: 500" class="cbb" v-for="variant in variants" :key="variant.asin">
                                            <td style="padding: 10px 10px 10px 0px">
                                                <a :href="variant.link" target="_blank">{{ variant.asin }}</a>
                                            </td>
                                            <td style="padding: 10px 10px 10px 0px">{{ variant.title }}</td>
                                        </tr>
                                    </table>
                                </div> </template
                            >{{ $t('views.extension.variations') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ variants.length }}</td>
                </tr>
                <tr v-if="product.detail?.brand">
                    <td class="text-secondary fw-semibold">{{ $t('views.extension.brand') }}:</td>
                    <td class="text-secondary">{{ product.detail?.brand }}</td>
                    <td>
                        <ExtensionSearch :text="product.detail?.brand" />
                    </td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper link-secondary" hover placement="bottom" arrow>
                            <template #content>
                                <div class="popper-table">
                                    <p>{{ $t('views.extension.productSizeAndWeight') }}</p>
                                    <div class="d-flex">
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.system') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.size') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.volume') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.weight') }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.imperial') }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial.dimensionFlat }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial.volumeFlat }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial.weightFlat }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbb">{{ $t('views.extension.metric') }}</div>
                                            <div class="c-item">{{ product.dimension?.metric.dimensionFlat }}</div>
                                            <div class="c-item">{{ product.dimension?.metric.volumeFlat }}</div>
                                            <div class="c-item">0{{ product.dimension?.metric.weightFlat }}</div>
                                        </div>
                                    </div>
                                </div> </template
                            >{{ $t('views.extension.weight') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ product.dimension?.imperial?.weight }} {{ product.dimension?.imperial?.weightUnit }}</td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper link-secondary" placement="bottom" hover arrow>
                            <template #content>
                                <div class="popper-table">
                                    <p>{{ $t('views.extension.productSizeAndWeight') }}</p>
                                    <div class="d-flex">
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.system') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.size') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.volume') }}</div>
                                            <div class="c-item cbr">{{ $t('views.extension.weight') }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbr cbb">{{ $t('views.extension.imperial') }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial?.dimensionFlat }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial?.volumeFlat }}</div>
                                            <div class="c-item cbr">{{ product.dimension?.imperial?.weightFlat }}</div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="c-item cbb">{{ $t('views.extension.metric') }}</div>
                                            <div class="c-item">{{ product.dimension?.metric?.dimensionFlat }}</div>
                                            <div class="c-item">{{ product.dimension?.metric?.volumeFlat }}</div>
                                            <div class="c-item">{{ product.dimension?.metric?.weightFlat }}</div>
                                        </div>
                                    </div>
                                </div> </template
                            >{{ $t('views.extension.dim') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ product.dimension?.imperial?.dimWeight }} {{ product.dimension?.imperial?.dimWeightUnit }}</td>
                </tr>
                <tr>
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper link-secondary fs-normal" placement="bottom" hover arrow>
                            <template #content> <span class="fw-normal" v-html="$t('views.extension.compare.tooltips.maxQ')"></span> </template>{{ $t('views.extension.maxQ') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary">{{ product.dimension?.maxItemOnPackage }}</td>
                </tr>
                <tr v-if="keepaData && keepaData.monthlySold" class="position-relative">
                    <td class="text-secondary fw-semibold">
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('views.extension.recommendedCountTooltip', { count: keepaData.monthlySold })"></div> </template>{{ $t('views.extension.recommendedCount') }}:</Popper
                        >
                    </td>
                    <td class="text-secondary limited-text-size">
                        {{ Math.floor(keepaData.monthlySold / (product.detail?.fbaOfferCount + 1)) }}
                        <Popper class="light-popper" hover placement="bottom" arrow>
                            <template #content> <div v-html="$t('common.providedByKeepa')"></div> </template>
                            <KeepaLogo
                                :color="'#256a93'"
                                :style="{
                                    marginTop: '-2px',
                                }"
                                :width="15"
                                :height="15"
                            />
                        </Popper>
                    </td>
                </tr>
                <!--                 <tr>
                    <td
                        class="text-secondary fw-semibold"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-html="true"
                        data-bs-content="<p><b>Product size tier.</b> Product size tiers are measurement categories based on the unit weight, product dimensions, and dimensional weight of a packaged item.</p>"
                    >
                        Size:
                    </td>
                    <td class="text-secondary">
                        SIZE
                        <Popper class="light-popper link-secondary" style="padding-left: 0.4rem" click placement="bottom" arrow>
                            <template #content
                                ><div class="d-flex flex-column gap-2">
                                    <div class="d-flex align-items-center gap-2">
                                        <span>Size:</span>
                                        <input type="number" min="0" step="0.1" value="0" class="form-control form-control-sm" style="width: 40px; padding: 0.25rem !important; min-height: 16px !important; height: 24px" />
                                        <span>x</span>
                                        <input type="number" min="0" step="0.1" value="0" class="form-control form-control-sm" style="width: 40px; padding: 0.25rem !important; min-height: 16px !important; height: 24px" />
                                        <span>x</span>
                                        <input type="number" min="0" step="0.1" value="0" class="form-control form-control-sm" style="width: 40px; padding: 0.25rem !important; min-height: 16px !important; height: 24px" />
                                        <span>in</span>
                                    </div>
                                    <div>Volume: 0.021 cu ft</div>
                                    <div class="d-flex align-items-center gap-2">
                                        <span>Weight</span>
                                        <input type="number" min="0" step="0.1" value="0" class="form-control form-control-sm" style="width: 40px; padding: 0.25rem !important; min-height: 16px !important; height: 24px" />
                                        <span>lbs, Ship: 1 lbs</span>
                                    </div>
                                </div></template
                            ><i class="bi bi-pencil-square"></i
                        ></Popper>
                    </td>
                </tr> -->
                <!--                 <tr>
                    <td class="text-secondary fw-semibold" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="<p><b>Package Quantity</b> shows the amount of items in the package.</p>">Package:</td>
                    <td class="text-secondary">
                        PACKAGE
                        <Popper class="light-popper link-secondary" style="padding-left: 0.4rem" click placement="bottom" arrow>
                            <template #content
                                ><div style="display: grid; grid-template-columns: repeat(3, 1fr); justify-content: center">
                                    <button type="button" class="btn btn-link" v-for="n in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" :key="n" style="font-size: 12px; width: 50px; text-align: center; padding: 5px">{{ n }}</button>
                                </div></template
                            ><i class="bi bi-pencil-square"></i
                        ></Popper>
                    </td>
                </tr> -->
            </table>
            <div class="d-flex justify-content-between mt-1" style="background-color: var(--bs-gray-200); border-radius: 5px; padding: 5px">
                <div class="d-flex gap-2">
                    <Popper class="light-popper" hover placement="bottom" arrow>
                        <template #content>{{ $t('views.extension.fbmOfferCount') }}</template
                        >FBM:</Popper
                    >
                    <a :href="`https://www.${productRefreshData.domain}/gp/offer-listing/${productRefreshData.asin}/ref=dp_olp_new_mbc?ie=UTF8&condition=new`" target="_blank"
                        >{{ product.detail?.fbmOfferCount }}{{ product.detail?.totalOfferCount > 20 ? '+' : '' }}</a
                    >
                    <!--                     <Popper class="light-popper text-primary" style="cursor: pointer" hover placement="bottom" arrow>
                        <template #content>Count of FBM offers within lowest price</template
                        ><a :href="`https://www.${productRefreshData.domain}/gp/offer-listing/${productRefreshData.asin}/ref=dp_olp_new_mbc?ie=UTF8&condition=new`" target="_blank">{{ product.detail?.lowestFbmOffers }}</a></Popper
                    > -->
                    <!--                     <span>Est: 0</span>
 -->
                    <!--                     <span class="text-down">€0</span>
 -->
                </div>
                <div class="line-gray" style="height: 15px"></div>
                <div class="d-flex gap-2">
                    <Popper class="light-popper text-secondary cursor-pointer" style="cursor: pointer" hover placement="bottom" arrow>
                        <template #content>{{ $t('views.extension.fbaOfferCount') }}</template
                        >FBA:</Popper
                    >
                    <a :href="`https://www.${productRefreshData.domain}/gp/offer-listing/${productRefreshData.asin}/ref=olp_f_primeEligible?ie=UTF8&f_new=true&f_primeEligible=true`" target="_blank"
                        >{{ product.detail?.fbaOfferCount }}{{ product.detail?.totalOfferCount > 20 ? '+' : '' }}</a
                    >
                    <!--                     <Popper class="light-popper text-primary" style="cursor: pointer" hover placement="bottom" arrow>
                        <template #content>Count of FBA offers within lowest price (BuyBox +5%)</template
                        ><a :href="`https://www.${productRefreshData.domain}/gp/offer-listing/${productRefreshData.asin}/ref=olp_f_primeEligible?ie=UTF8&f_new=true&f_primeEligible=true`" target="_blank">{{ product.detail?.lowestFbaOffers }}</a></Popper
                    > -->
                    <!--                     <span>Est: <span class="text-success">8.889</span></span>
 -->
                    <!--                     <span class="text-success">€216.713,82</span>
 -->
                </div>
            </div>
        </div>
    </div>
    <template v-if="availability?.auth">
        <hr class="my-2" />

        <div class="row g-0">
            <div class="col-6">
                <div class="input-group">
                    <span class="input-group-text">
                        <img v-if="product?.short" class="svg-flag" :src="require('@/assets/svg-flag/' + product?.short + '.svg')" width="16" height="16" alt="" />
                        {{ $t('common.price') }}
                    </span>
                    <input v-model="buyboxPrice" v-debounce:1000="buyboxPriceFunc" @input="clearBuyboxPrice" ref="buyboxPriceInp" type="text" class="form-control form-control-sm" :aria-label="$t('common.price')" />
                    <span class="input-group-text" style="font-size: 11px !important">{{ product?.short && getCurrencySymbol(countries[product?.short]?.symbol, buyboxPrice) }}</span>
                </div>
                <!-- <div class="d-flex justify-content-between align-items-center pe-2">
                <span class="text-primary">{{ productRefreshData.domain }}</span>
                <div class="btn-group">
                    <button
                        class="btn btn-link text-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="background-color: var(--bs-gray-200); font-size: 11px; padding: 0.3rem 0.5rem"
                        data-bs-dropdown-animation
                    >
                        Change
                    </button>
                    <div class="dropdown-menu p-0" style="width: 3.5rem; min-width: 3.5rem">
                        <a class="dropdown-item with-border d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target=".confirm-change-country-modal"
                            ><img class="svg-flag" src="@/assets/svg-flag/DE.svg" width="16" height="16" alt="" /> <span>DE</span></a
                        >
                        <a class="dropdown-item with-border d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target=".confirm-change-country-modal"
                            ><img class="svg-flag" src="@/assets/svg-flag/US.svg" width="16" height="16" alt="" /> <span>US</span></a
                        >
                        <a class="dropdown-item with-border d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target=".confirm-change-country-modal"
                            ><img class="svg-flag" src="@/assets/svg-flag/CA.svg" width="16" height="16" alt="" /> <span>CA</span></a
                        >
                    </div>
                </div>
            </div> -->
            </div>
            <div class="col-6">
                <div class="input-group ps-2">
                    <span class="input-group-text">
                        <img v-if="targetDomainDetail?.short" class="svg-flag" :src="require('@/assets/svg-flag/' + targetDomainDetail?.short + '.svg')" width="16" height="16" alt="" />
                        <i v-else class="bi bi-globe-americas me-1"></i>
                        {{ targetDomainDetail?.short || 'COG' }}
                    </span>
                    <input v-model="buyingPrice" v-debounce:1000="buyingPriceFunc" @input="clearBuyingPrice" type="text" class="form-control form-control-sm" placeholder="" :aria-label="$t('common.price')" :disabled="isBuyingPriceInputDisabled" />
                    <span class="input-group-text" style="font-size: 11px !important">{{
                        targetDomainDetail?.short ? getCurrencySymbol(countries[targetDomainDetail?.short]?.symbol, 1) : product?.short ? getCurrencySymbol(countries[product?.short]?.symbol, 1) : null
                    }}</span>
                </div>
                <div v-if="availability?.targetDomain?.sourceDomains" class="d-flex justify-content-between align-items-center ps-2">
                    <span class="text-primary" style="font-size: 10px; min-width: 76px; cursor: pointer" @click="handleGoTo(countries[targetDomainDetail?.short]?.domain + '/dp/' + productRefreshData.asin)">{{
                        countries[targetDomainDetail?.short]?.domain
                    }}</span>
                    <div class="btn-group">
                        <Popper v-if="targetDomainDetail?.short" class="link-secondary" hover placement="bottom" arrow>
                            <template #content>{{ $t('views.extension.undo') }}</template
                            ><button type="button" class="btn btn-link text-secondary" style="font-size: 11px; padding: 0.3rem 0.5rem" @click="undoChanges(false)"><i class="fa-solid fa-rotate-left"></i></button
                        ></Popper>

                        <button
                            class="btn btn-link text-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="background-color: var(--bs-gray-200); font-size: 11px; padding: 0.3rem 0.5rem"
                            data-bs-dropdown-animation
                            :disabled="isBuyingPriceInputDisabled"
                        >
                            {{ $t('actions.change') }}
                        </button>
                        <div class="dropdown-menu p-0" :class="{ invisible: isBuyingPriceInputDisabled }" style="width: 3.5rem; min-width: 3.5rem; max-height: 200px; overflow: auto">
                            <button
                                type="button"
                                v-for="target in availability.targetDomain?.sourceDomains"
                                :key="target.id"
                                class="dropdown-item with-border d-flex align-items-center"
                                style="border-radius: 0"
                                href="#"
                                @click="postSourceDomain(target)"
                            >
                                <img class="svg-flag" :src="require(`@/assets/svg-flag/${target.short}.svg`)" width="16" height="16" alt="" /> <span>{{ target.short }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="my-2" />

        <ExtensionInputs @change-price="onChangePrice" :target-currency="targetDomainDetail?.short || product?.short" :cog-price="buyingPrice" :user-parameters="userParameters" :notes="notes" :parameters-clone="scanningOptionInputsObjectData" />
    </template>
    <div v-else class="text-center p-2 my-4 fs-6" style="background-color: var(--bs-gray-200)" v-html="$t('views.extension.loginRedirect')"></div>

    <template v-if="availability?.auth">
        <hr class="my-2" />

        <div class="d-flex justify-content-between align-items-center gap-2">
            <div class="w-100">
                <input v-model="notes" class="form-control form-control-sm" :placeholder="$t('list.extensionWatchlist.columns.notes')" style="width: 100% !important" />
            </div>
            <button class="btn btn-xs btn-primary" :disabled="isNotSavedText" @click="saveNote">
                <span v-if="isNotSaved" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><template v-else>{{ isNotSavedText ? $t('actions.saved') : $t('actions.save') }}</template>
            </button>
        </div>

        <template v-if="productRefreshData.domain !== 'amazon.com.au'">
            <hr class="my-2" />
            <template v-if="keepaLoading">
                <div class="d-flex justify-content-center align-items-center" style="height: 150px">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="showKeepaChart" class="keepa-container">
                    <div class="keepa-header d-flex justify-content-between align-items-center mb-2 px-2">
                        <div>
                            <button type="button" class="btn btn-link" @click="handleChangeDateRange(365)">{{ $t('views.extension.all') }}</button>
                            <button v-for="n in [365, 180, 90, 30, 14]" :key="n" type="button" class="btn btn-link" :class="{ 'text-primary': currentDateRange === n }" @click="handleChangeDateRange(n)">{{ n }}</button>
                        </div>
                        <div>
                            {{ chartDateRange }}
                        </div>
                    </div>
                    <div class="keepa-canvas">
                        <Line
                            v-if="showKeepaChart"
                            :data="keepaChartData"
                            :plugins="[
                                {
                                    afterDraw: (chart) => {
                                        if (chart.tooltip?._active?.length) {
                                            let x = chart.tooltip._active[0].element.x
                                            let yAxis = chart.scales.y1
                                            let ctx = chart.ctx
                                            ctx.save()
                                            ctx.beginPath()
                                            ctx.moveTo(x, yAxis.top)
                                            ctx.lineTo(x, yAxis.bottom)
                                            ctx.lineWidth = 1
                                            ctx.strokeStyle = '#fd9405'
                                            ctx.stroke()
                                            ctx.restore()
                                        }
                                    },
                                },
                            ]"
                            :options="options"
                        />
                    </div>

                    <div class="keepa-canvas">
                        <Line
                            v-if="showKeepaChart"
                            :data="keepaOffersChartData"
                            :plugins="[
                                {
                                    afterDraw: (chart) => {
                                        if (chart.tooltip?._active?.length) {
                                            let x = chart.tooltip._active[0].element.x
                                            let yAxis = chart.scales.y3
                                            let ctx = chart.ctx
                                            ctx.save()
                                            ctx.beginPath()
                                            ctx.moveTo(x, yAxis.top)
                                            ctx.lineTo(x, yAxis.bottom)
                                            ctx.lineWidth = 1
                                            ctx.strokeStyle = '#fd9405'
                                            ctx.stroke()
                                            ctx.restore()
                                        }
                                    },
                                },
                            ]"
                            :options="offersOptions"
                        />
                    </div>
                </div>
                <div v-if="!keepaLoading && !showKeepaChart && keepaAvailable" class="keepa-container">
                    <div class="keepa-header d-flex justify-content-between align-items-center mb-2 px-2">
                        <div>
                            <button type="button" class="btn btn-link">{{ $t('views.extension.all') }}</button>
                            <button v-for="n in [365, 180, 90, 30, 14]" :key="n" type="button" class="btn btn-link" :class="{ 'text-primary': keepa.range === n }" @click="keepa.range = n">{{ n }}</button>
                        </div>
                        <div>
                            <button
                                v-for="n in ['FBA', 'FBM', 'SBA', 'BSR']"
                                :key="n"
                                type="button"
                                class="btn btn-link"
                                :class="{ 'text-primary': keepa[n.toLocaleLowerCase()] }"
                                @click="keepa[n.toLocaleLowerCase()] = keepa[n.toLocaleLowerCase()] ? 0 : 1"
                            >
                                {{ n }}
                            </button>
                        </div>
                    </div>
                    <div class="keepa-canvas">
                        <a :href="`https://keepa.com/iframe_addon.html#1-0-${productRefreshData.asin}`" target="_blank">
                            <img :src="keepaUrl" class="img-fluid" alt="" />
                        </a>
                    </div>
                </div>
            </template>
        </template>
    </template>

    <div class="modal fade warehouse-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mx-auto" role="document" style="width: 350px">
            <div class="modal-content shadow-lg">
                <div class="modal-body p-0" style="background-color: var(--bs-gray-200); border-radius: 0.75rem; padding: 5px">
                    <div class="d-flex justify-content-between align-items-center px-3 py-2">
                        <!--                         <span class="text-down"><i class="bi bi-graph-down-arrow"></i> -1.96%</span>
 -->
                        <span
                            >{{ $t('views.extension.price') }}: <span class="text-primary">{{ product?.userHistory?.buyboxPrice ? formatCurrency(countries[product?.short]?.symbol, product?.userHistory?.buyboxPrice) : 0 }}</span></span
                        >
                        <span
                            >{{ $t('views.extension.buyingPriceShort') }}: <span class="text-primary">{{ countries[targetDomainDetail?.short]?.currency }}{{ buyingPrice || 0 }}</span></span
                        >
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between align-items-center px-3 py-2 text-secondary">
                        <div class="btn-group">
                            <button class="btn btn-link dropdown-toggle p-0 text-secondary" type="button" id="dropdownMenuButtonGhostSecondary" data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 11px !important">
                                {{ $t(`cards.parameters.${currentInput}`) }}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width: 55px">
                                <template v-for="input in inputs">
                                    <a v-if="input.name !== 'calculation_settings'" :key="input.name" class="dropdown-item" href="#" @click="currentInput = input.name"> {{ $t(`cards.parameters.${input.name}`) }}</a>
                                </template>
                            </div>
                        </div>
                        <div>
                            <Popper v-if="targetDomainDetail?.short && isShowSetDefaultParametersButton" class="link-secondary" hover placement="bottom" arrow>
                                <template #content>{{ $t('views.extension.setDefaultParameters') }}</template
                                ><button type="button" class="btn btn-link text-secondary p-0" @click="undoChanges(true)" style="font-size: 12px; margin-right: 5px"><i class="fa-solid fa-rotate-left"></i></button
                            ></Popper>
                            <button type="button" class="btn btn-link link-secondary p-0" data-bs-dismiss="modal"><i class="bi bi-x-lg"></i></button>
                        </div>
                    </div>

                    <template v-for="input in inputs">
                        <template v-if="input.name === currentInput">
                            <template v-for="item in input.inputs" :key="item.name">
                                <div
                                    v-if="!['fba_pouching_wrapping_cost', 'fbm_pouching_wrapping_cost', 'fba_bubble_wrapping_cost', 'fbm_bubble_wrapping_cost', 'calculation_settings'].includes(item.name)"
                                    class="d-flex justify-content-between align-items-center gap-1 px-3 py-1"
                                >
                                    <span style="width: 175px">{{ $t(`inputs.parameters.labels.${item.name}`) }}</span>
                                    <div class="d-flex align-items-center gap-2">
                                        <input v-model="scanningOptionInputsObject[input.name][item.name]" v-debounce:1000="scanningOptionInputsObjectFunc" type="number" class="warehouse-input form-control form-control-sm" />
                                        <span class="text-right" style="width: 15px">
                                            {{
                                                ['fba_custom_rate', 'fbm_custom_rate', 'vat', 'referal_fee_tax', 'bank_transfer_fee', 'estimated_refund_amount', 'fba_fee_tax', 'fbm_fee_tax', 'state_sales_tax'].includes(item.name)
                                                    ? '%'
                                                    : targetDomainDetail?.short
                                                    ? getCurrencySymbol(countries[targetDomainDetail?.short]?.symbol, 1)
                                                    : product?.short && getCurrencySymbol(countries[product?.short]?.symbol, 1)
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>

                    <ExtensionInputs
                        is-warehouse-component
                        @change-price="onChangePrice"
                        :target-currency="targetDomainDetail?.short || product?.short"
                        :cog-price="buyingPrice"
                        :user-parameters="userParameters"
                        :notes="notes"
                        :parameters-clone="scanningOptionInputsObjectData"
                    />
                </div>
            </div>
        </div>
    </div>

    <ExtensionConfirmChangeCountryModal :source-domain-detail="targetDomainDetail" />
    <ExtensionProductRefresh :product-refresh-data="productRefreshData" @isFinally="productRefreshed" />
    <ExtensionKeepaData :isAuto="keepaData && !availability?.settings?.always_use_keepa" :keepa-payload="keepaPayload" @isLoading="handleKeepaLoading" @isFinally="keepaDataLoaded" />
    <ExtensionKeepaDisable @isDisabled="keepaDisabled" />
    <ExtensionKeepaError :message="keepaErrorMessage" />
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ExtensionClipboard from '@/components/Extension/components/Clipboard.vue'
import ExtensionSearch from '@/components/Extension/components/Search.vue'
import ExtensionInputs from '@/components/Extension/components/Inputs.vue'
import KeepaLogo from '@/components/KeepaLogo.vue'
import ExtensionConfirmChangeCountryModal from '@/components/Extension/modals/ConfirmChangeCountry.vue'
import ExtensionProductRefresh from '@/components/Extension/modals/ProductRefresh.vue'
import ExtensionKeepaData from '@/components/Extension/modals/KeepaData.vue'
import ExtensionKeepaError from '@/components/Extension/modals/KeepaError.vue'
import ExtensionKeepaDisable from '@/components/Extension/modals/KeepaDisable.vue'
import { countryInfo, printDateTime } from '@/utils/helper'
import { scanningOptionInputs } from '@/utils/formInputs'
import { formatCurrency, getCurrencySymbol } from '@/utils/format-number'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'vue-chartjs'
import { subDays, formatISO } from 'date-fns'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
const MINUTE_OFFSET = 21564000
const MINUTE_IN_MS = 60000

export default {
    name: 'ExtensionTarget',
    components: {
        ExtensionClipboard,
        ExtensionSearch,
        ExtensionInputs,
        ExtensionConfirmChangeCountryModal,
        ExtensionProductRefresh,
        ExtensionKeepaData,
        ExtensionKeepaDisable,
        ExtensionKeepaError,
        Line,
        KeepaLogo,
    },
    props: {
        productRefreshData: {
            type: Object,
            default: () => {},
        },
        keepaPayload: {
            type: Object,
            default: () => {},
        },
        keepaAvailable: {
            type: Boolean,
            default: false,
        },
        clonedCalculateData: {
            type: Object,
            default: () => {},
        },
        isContinue: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const locale = this.$i18n.locale
        return {
            clickedProductRefresh: false,
            clickedKeepaData: true,
            keepaLoading: false,
            keepaErrorMessage: null,
            keepa: {
                range: 365,
                salesrank: 1,
                fba: 1,
                fbm: 1,
                sba: 1,
                bsr: 0,
                ld: 1,
            },
            showKeepaError: false,
            showKeepaChart: false,
            currentDateRange: 365,
            chartDateRange: '',
            keepaChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Sales Ranks',
                        backgroundColor: 'green',
                        data: [],
                        fill: false,
                        borderColor: 'green',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y1',
                    },
                    {
                        label: 'AMZ',
                        backgroundColor: 'orange',
                        data: [],
                        fill: true,
                        borderColor: 'orange',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'FBA',
                        backgroundColor: 'rgb(255, 87, 34)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(255, 87, 34)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'FBM',
                        backgroundColor: 'rgb(3, 155, 229)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(3, 155, 229)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                    },
                    {
                        label: 'Buybox',
                        backgroundColor: 'rgb(255, 0, 75)',
                        data: [],
                        fill: false,
                        borderColor: 'rgb(255, 0, 75)',
                        tension: 0.1,
                        pointRadius: 2,
                        borderWidth: 0,
                        yAxisID: 'y2',
                        pointStyle: 'rectangle',
                    },
                ],
            },
            keepaOffersChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Offers',
                        backgroundColor: 'purple',
                        data: [],
                        fill: false,
                        borderColor: 'purple',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y3',
                    },
                    {
                        label: 'Reviews',
                        backgroundColor: 'green',
                        data: [],
                        fill: false,
                        borderColor: 'green',
                        tension: 0,
                        pointRadius: 0,
                        borderWidth: 1,
                        yAxisID: 'y4',
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function (tooltipItem) {
                                return new Date(tooltipItem[0].label).toLocaleDateString(locale || 'tr-TR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })
                            },
                            label: function (tooltipItem) {
                                if (tooltipItem.dataset.yAxisID === 'y1') {
                                    return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue.toLocaleString()
                                }
                                const domainFromQuery = new URLSearchParams(window.location.search).get('domain')
                                const domain = domainFromQuery ? Object.keys(countryInfo()).find((key) => countryInfo()[key].domain === domainFromQuery) : 'US'
                                const currency = countryInfo()[domain].symbol

                                return tooltipItem.dataset.label + ': ' + formatCurrency(currency, +tooltipItem.raw / 100)
                            },
                        },
                    },
                    legend: {
                        display: true, // Efsaneyi göster
                        labels: {
                            font: {
                                size: 13, // Font boyutu
                            },
                            padding: 10, // Efsane etiketleri arasındaki dolgu
                            boxWidth: 20, // Efsane kutucuk genişliği
                            boxHeight: 5, // Efsane kutucuk yüksekliği
                            usePointStyle: true, // Efsane kutucuklarını nokta şeklinde kullan
                        },
                        onClick: function (e, legendItem, legend) {
                            const index = legendItem.datasetIndex
                            const ci = legend.chart
                            const meta = ci.getDatasetMeta(index)

                            // Meta verinin görünürlüğünü toggle et
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

                            // Grafikteki değişiklikleri göstermek için güncelle
                            ci.update()
                        },
                    },
                },
                scales: {
                    x: {
                        display: false,
                        title: {
                            display: true,
                        },
                        ticks: {
                            padding: -10,
                        },
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 40
                        },
                    },
                    y2: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0,
                        grid: {
                            drawOnChartArea: false,
                        },
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                const domainFromQuery = new URLSearchParams(window.location.search).get('domain')
                                const domain = domainFromQuery ? Object.keys(countryInfo()).find((key) => countryInfo()[key].domain === domainFromQuery) : 'US'
                                const currency = countryInfo()[domain].symbol
                                return formatCurrency(currency, +value / 100)
                            },
                            font: {
                                size: 9,
                            },
                        },
                        afterFit: function (scale) {
                            scale.width = 40
                        },
                        beginAtZero: true,
                    },
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
            },
            offersOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        width: 540,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function (tooltipItem) {
                                return new Date(tooltipItem[0].label).toLocaleDateString(locale || 'tr-TR', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })
                            },
                            label: function (tooltipItem) {
                                if (tooltipItem.dataset.yAxisID === 'y3') {
                                    return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue.toLocaleString()
                                }
                            },
                        },
                        axis: 'x',
                    },
                    legend: {
                        display: true,
                        labels: {
                            font: {
                                size: 13,
                            },
                            padding: 10,
                            boxWidth: 20,
                            boxHeight: 5,
                            usePointStyle: true,
                        },
                        onClick: function (e, legendItem, legend) {
                            const index = legendItem.datasetIndex
                            const ci = legend.chart
                            const meta = ci.getDatasetMeta(index)

                            // Meta verinin görünürlüğünü toggle et
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

                            // Grafikteki değişiklikleri göstermek için güncelle
                            ci.update()
                        },
                    },
                },
                scales: {
                    x: {
                        display: false,
                        title: {
                            display: true,
                        },
                    },
                    y3: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                            stepSize: 5,
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 25
                        },
                    },
                    y4: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        title: {
                            display: true,
                        },
                        ticks: {
                            callback: function (value) {
                                return value.toLocaleString()
                            },
                            font: {
                                size: 9,
                            },
                        },
                        beginAtZero: true,
                        afterFit: function (scale) {
                            scale.width = 50
                        },
                    },
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
            },
            countries: countryInfo(),
            formatCurrency,
            getCurrencySymbol,
            printDateTime,
            targetDomainDetail: {
                id: null,
                short: null,
            },
            notes: null,
            showKeepaContainer: true,
            isNotSaved: false,
            isNotSavedText: false,
            scanningOptionInputsObjectData: {
                fba_warehouse: {
                    fba_shipping_per_pound: 0,
                    fba_labeling_cost: 0,
                    fba_pouching_wrapping_cost: 0,
                    fba_bubble_wrapping_cost: 0,
                    fba_product_acceptance_cost: 0,
                    fba_min_prep_service_cost: 0,
                    fba_custom_rate: 0,
                },
                fbm_warehouse: {
                    fbm_shipping_per_pound: 0,
                    fbm_labeling_cost: 0,
                    fbm_pouching_wrapping_cost: 0,
                    fbm_bubble_wrapping_cost: 0,
                    fbm_product_acceptance_cost: 0,
                    fbm_min_prep_service_cost: 0,
                    fbm_custom_rate: 0,
                },
                sales_fees: {
                    referal_fee: 0,
                    fba_fee: 0,
                    storage_fee: 0,
                },
                taxes: {
                    vat: 0,
                    referal_fee_tax: 0,
                    fba_fee_tax: 0,
                },
                costs: {
                    bank_transfer_fee: 0,
                    estimated_refund_amount: 0,
                },
                fulfillment: {
                    state_sales_tax: 0,
                    cashback: 0,
                },
            },
            inputs: scanningOptionInputs,
            currentInput: 'fba_warehouse',
            buyboxPrice: null,
            buyingPrice: null,
            userParameters: null,
            isBuyingPriceInputDisabled: false,
            isShowSetDefaultParametersButton: false,
            rawProductStockDetail: null,
            stockLoading: true,
            variants: [],
        }
    },
    computed: {
        ...mapState('extension', ['product', 'keepaData', 'availability', 'calculate', 'costs', 'warehouseCostLoading', 'buying_price', 'parameters', 'hubCalculateData']),
        keepaUrl() {
            const url = new URL('https://graph.keepa.com/fbahunter.com')
            url.searchParams.set('asin', this.productRefreshData.asin)
            url.searchParams.set('domain', this.productRefreshData.domain.split('.')[1])
            url.searchParams.set('salesrank', this.keepa.salesrank)
            url.searchParams.set('bb', this.keepa.bb)
            url.searchParams.set('range', this.keepa.range)
            url.searchParams.set('width', 800)
            url.searchParams.set('height', 350)
            url.searchParams.set('fba', this.keepa.fba)
            url.searchParams.set('fbm', this.keepa.fbm)
            url.searchParams.set('ld', this.keepa.lb)
            return url
        },
        scanningOptionInputsObject: {
            get() {
                const options = JSON.parse(JSON.stringify(this.scanningOptionInputsObjectData))
                const parameters = this.updateScanningOptions(options, this.parameters)
                return parameters
            },
        },
    },
    methods: {
        ...mapActions('extension', ['getAvailability', 'getKeepaData', 'productRefresh', 'setCalculate', 'postSourceDomainDetail']),
        ...mapMutations('extension', ['SET_COSTS']),
        getKeepaImage() {
            return require('@/assets/img/keepa-logo.webp')
        },
        async keepaDisabled(e) {
            await this.getAvailability(this.$route.query.domain)
        },
        handleKeepaLoading(e) {
            this.keepaLoading = e
        },
        async keepaDataLoaded(e) {
            this.showKeepaChart = e
            if (e) {
                await this.getAvailability(this.$route.query.domain)
                this.keepaLoading = false
                this.showKeepaChart = true
            }
        },
        productRefreshed(e) {
            this.clickedProductRefresh = e
            this.buyboxPrice = this.product?.userHistory?.buyboxPrice
            this.buyboxPriceFunc()
        },
        isHyphenClass(text) {
            if (!text) return
            return {
                'text-down': text.toString().includes('-'),
                'text-success': !text.toString().includes('-'),
            }
        },
        loadStockData(e) {
            this.stockLoading = true
            window.parent.postMessage({ load_stock: true }, '*')
        },
        async postSourceDomain(source) {
            this.isBuyingPriceInputDisabled = true
            this.targetDomainDetail = { ...source, asin: this.productRefreshData.asin }
            await this.postSourceDomainDetail({ id: this.targetDomainDetail.id, payload: { asin: this.targetDomainDetail.asin } })
                .then(async (response) => {
                    this.SET_COSTS({
                        fba: {
                            warehouseCost: null,
                            warehouseCostOnSource: null,
                        },
                        fbm: {
                            warehouseCost: null,
                            warehouseCostOnSource: null,
                        },
                    })
                    this.buyingPrice = response.data.buying_price > 0 ? +response.data.buying_price.toFixed(2) : null
                    this.userParameters = response.data.parameters
                    await this.handlePriceDataHub(true)
                })
                .catch(() => {})
                .finally(() => {
                    this.isBuyingPriceInputDisabled = false
                })
        },
        onChangePrice(data) {
            this.buyboxPrice = data
            this.buyboxPriceFunc()
            const input = this.$refs.buyboxPriceInp
            const parent = input.parentElement
            parent.classList.add('blink-border') // Animasyonu başlat

            parent.addEventListener('animationend', function () {
                parent.classList.remove('blink-border')
            })
        },
        saveNote() {
            this.isNotSaved = true
            this.isNotSavedText = true
            this.buyboxPriceFunc()
            setTimeout(() => {
                this.isNotSaved = false
                setTimeout(() => {
                    this.isNotSavedText = false
                }, 2000)
            }, 2000)
        },
        handleGoTo(url) {
            window.open(`https://${url}`, '_blank')
        },
        async undoChanges(isRefresh) {
            if (isRefresh) {
                await this.postSourceDomainDetail({ id: this.targetDomainDetail.id, payload: { asin: this.targetDomainDetail.asin }, isRefresh: true })
                    .then(async (response) => {
                        this.SET_COSTS({
                            fba: {
                                warehouseCost: null,
                                warehouseCostOnSource: null,
                            },
                            fbm: {
                                warehouseCost: null,
                                warehouseCostOnSource: null,
                            },
                        })
                        this.buyingPrice = response.data.buying_price > 0 ? +response.data.buying_price.toFixed(2) : null
                        this.userParameters = response.data.parameters
                        await this.handlePriceDataHub()
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.isShowSetDefaultParametersButton = false
                    })
            } else {
                this.targetDomainDetail = {
                    id: null,
                    short: null,
                }
                this.buyingPrice = null
                this.setCalculate(this.clonedCalculateData)
            }
        },
        buyboxPriceFunc() {
            if (+this.buyboxPrice >= 0) {
                this.handlePriceDataHub()
            }
        },
        async buyingPriceFunc() {
            if (this.buyingPrice) {
                await this.handlePriceDataHub()
            }
        },
        async handlePriceDataHub(withoutCost = false) {
            if (this.isContinue) {
                const updateData = {
                    asin: this.$route.query.asin,
                    source: this.targetDomainDetail?.short,
                    target: this.product?.short,
                    price: +this.buyboxPrice >= 0 ? +this.buyboxPrice : this.product?.userHistory?.buyboxPrice,
                    buying_price: this.buyingPrice ?? this.product?.userHistory?.buyingPrice,
                    dimension: this.product?.dimension,
                    fees: this.product?.fees,
                    notes: this.notes,
                    parameters: {
                        ...this.product?.userHistory?.parameters,
                        ...this.userParameters,
                        ...this.scanningOptionInputsObject.fba_warehouse,
                        ...this.scanningOptionInputsObject.fbm_warehouse,
                        ...this.scanningOptionInputsObject.sales_fees,
                        ...this.scanningOptionInputsObject.taxes,
                        ...this.scanningOptionInputsObject.costs,
                        ...this.scanningOptionInputsObject.fulfillment,
                    },
                }
                if (!withoutCost) {
                    updateData.costs = {
                        fba: {
                            warehouseCost: null,
                            warehouseCostOnSource: null,
                        },
                        fbm: {
                            warehouseCost: null,
                            warehouseCostOnSource: null,
                        },
                    }
                }
                await this.setCalculate(updateData)
            }
        },
        clearBuyingPrice(e) {
            if (this.buyingPrice.length === 1 && e.data === '.') {
                this.buyingPrice = ''
            }
            this.buyingPrice = this.buyingPrice.replace(/[^0-9.]/g, '')
            let validatedInput = this.buyingPrice.toString().replace(/^0(\d+)/, '0.$1')
            if (validatedInput.split('.').length - 1 > 1) {
                validatedInput = validatedInput.slice(0, -1)
            }
            this.buyingPrice = validatedInput
        },
        clearBuyboxPrice(e) {
            if (this.buyboxPrice.length === 1 && e.data === '.') {
                this.buyboxPrice = ''
            }
            this.buyboxPrice = this.buyboxPrice.replace(/[^0-9.]/g, '')
            let validatedInput = this.buyboxPrice.toString().replace(/^0(\d+)/, '0.$1')
            if (validatedInput.split('.').length - 1 > 1) {
                validatedInput = validatedInput.slice(0, -1)
            }
            this.buyboxPrice = validatedInput
        },
        scanningOptionInputsObjectFunc() {
            if (this.buying_price === this.buyingPrice && this.product?.short && this.scanningOptionInputsObject) {
                this.isShowSetDefaultParametersButton = true
                this.buyboxPriceFunc()
            }
        },
        updateScanningOptions(scanningOptions, parameters) {
            this.isShowSetDefaultParametersButton = false

            for (const category in scanningOptions) {
                for (const option in scanningOptions[category]) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (parameters.hasOwnProperty(option)) {
                        scanningOptions[category][option] = parameters[option]
                    }
                }
            }
            return scanningOptions
        },
        generateLast365Days() {
            const today = new Date()
            const days = this.currentDateRange
            return Array.from({ length: days }, (_, i) => formatISO(subDays(today, i), { representation: 'date' })).reverse()
        },
        filterAndTransformDates(data, offsetIndex) {
            const dataMap = new Map()
            if (data && data[offsetIndex]) {
                for (let i = 0; i < data[offsetIndex].length; i += 2) {
                    const item = data[offsetIndex][i]
                    if (item) {
                        const date = new Date((item + MINUTE_OFFSET) * MINUTE_IN_MS)
                        const dateStr = date.toISOString().split('T')[0]
                        if (data[offsetIndex][i + 1] > 0) {
                            dataMap.set(dateStr, data[offsetIndex][i + 1])
                        }
                    }
                }
            }

            return dataMap
        },
        findDataForLabel(dataMap, label) {
            const dateString = new Date(label).toISOString().split('T')[0]
            return dataMap.get(dateString) || undefined
        },
        processChartData(data) {
            this.keepaChartData.labels = this.generateLast365Days()
            if (data.salesRankReference) {
                const dataMap = this.filterAndTransformDates(data.salesRanks, data.salesRankReference)
                let lastValidData
                this.keepaChartData.datasets[0].data = this.keepaChartData.labels.map((label) => {
                    const foundData = this.findDataForLabel(dataMap, label)
                    if (foundData !== undefined) {
                        lastValidData = foundData
                        return foundData
                    }
                    return lastValidData
                })
            }

            const dateTypes = [0, 10, 7, 18]
            dateTypes.forEach((type, idx) => {
                const dataMap = this.filterAndTransformDates(data.csv, type)
                this.keepaChartData.datasets[idx + 1].data = this.keepaChartData.labels.map((label) => {
                    return dataMap.get(label) || undefined
                })
            })
        },
        processOffersChartData(data) {
            this.keepaOffersChartData.labels = this.generateLast365Days()

            const dateTypes = [11, 17]
            dateTypes.forEach((type, idx) => {
                const dataMap = this.filterAndTransformDates(data.csv, type)
                let lastValidData

                this.keepaOffersChartData.datasets[idx].data = this.keepaOffersChartData.labels.map((label) => {
                    const foundData = this.findDataForLabel(dataMap, label)
                    if (foundData !== undefined) {
                        lastValidData = foundData
                        return foundData
                    }
                    return lastValidData
                })
            })
        },
        async handleChangeDateRange(n) {
            this.currentDateRange = n
            this.showKeepaChart = false
            await this.processChartData(this.keepaData)
            await this.processOffersChartData(this.keepaData)
            this.keepaChartData.datasets.forEach((dataset) => {
                dataset.data = dataset.data.slice(-n)
            })
            this.keepaOffersChartData.datasets.forEach((dataset) => {
                dataset.data = dataset.data.slice(-n)
            })
            this.$nextTick(() => {
                this.showKeepaChart = true
                const locale = this.$i18n.locale
                this.chartDateRange = `${new Date(this.keepaChartData.labels[0]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })} - ${new Date(this.keepaChartData.labels[this.keepaChartData.labels.length - 1]).toLocaleDateString(locale || 'tr-TR', {
                    day: 'numeric',
                    month: 'short', // Ay ismini yazı olarak gösterir
                    year: 'numeric',
                })}`
            })
        },
        async handleKeepaData() {
            this.keepaLoading = true
            await this.getKeepaData({ asin: this.productRefreshData.asin, domain: this.productRefreshData.domain, alwaysUseKeepa: this.availability?.settings?.always_use_keepa })
            this.keepaLoading = false
            this.showKeepaChart = true
        },
    },
    created() {
        window.addEventListener('message', ({ data }) => {
            if (data.action && data.action === 'buybox_stock') {
                this.rawProductStockDetail = data.response
                this.stockLoading = false
            }
            if (data.action && data.action === 'variants') {
                console.log({ variants: data.response })
                this.variants = data.response || []
            }
        })
    },
    watch: {
        showKeepaError: {
            handler(data) {
                if (data) {
                    // show bootstrap modal by classname with bootstrap methods
                    const modal = document.querySelector('.keepa-error-modal')
                    if (modal) {
                        modal.classList.add('show')
                        modal.style.display = 'block'
                    }
                }
            },
            immediate: true,
        },
        product: {
            deep: true,
            immediate: true,
            handler(data) {
                if (data.asin) {
                    this.buyboxPrice = this.buyboxPrice ?? this.product?.userHistory?.buyboxPrice
                    this.notes = this.product?.userHistory?.notes

                    if (this.buyingPrice > 0) {
                        this.handlePriceDataHub()
                    }
                }
            },
        },
        keepaData: {
            deep: true,
            immediate: true,
            handler(data) {
                this.showKeepaError = false
                if (data && !data.error) {
                    this.processChartData(data)
                    this.processOffersChartData(data)
                    this.showKeepaChart = true
                    const locale = this.$i18n.locale
                    this.chartDateRange = `${new Date(this.keepaChartData.labels[0]).toLocaleDateString(locale || 'tr-TR', {
                        day: 'numeric',
                        month: 'short', // Ay ismini yazı olarak gösterir
                        year: 'numeric',
                    })} - ${new Date(this.keepaChartData.labels[this.keepaChartData.labels.length - 1]).toLocaleDateString(locale || 'tr-TR', {
                        day: 'numeric',
                        month: 'short', // Ay ismini yazı olarak gösterir
                        year: 'numeric',
                    })}`
                } else {
                    // reset graph
                    this.keepaChartData.labels = []
                    this.keepaChartData.datasets.forEach((dataset) => {
                        dataset.data = []
                    })
                    this.keepaOffersChartData.labels = []
                    this.keepaOffersChartData.datasets.forEach((dataset) => {
                        dataset.data = []
                    })
                    if (data && data.error) {
                        setTimeout(() => {
                            this.showKeepaError = true
                            this.keepaErrorMessage = data.error
                            this.showKeepaChart = false
                        }, 600)
                    }
                }
            },
        },
        scanningOptionInputsObject: {
            handler(data) {
                const isAllValuesZero = Object.values(data).every((subObj) => Object.values(subObj).every((value) => value === 0))

                if (this.buying_price === this.buyingPrice && this.product?.short && isAllValuesZero) {
                    this.isShowSetDefaultParametersButton = true
                    this.buyboxPriceFunc()
                }
            },
            deep: true,
            immediate: true,
        },
        availability: {
            deep: true,
            immediate: true,
            handler(newData, oldData) {
                if (newData && newData.settings && newData.settings.always_use_keepa && this.keepaAvailable && !oldData) {
                    this.handleKeepaData()
                }
            },
        },
    },
}
</script>

<style lang="scss">
.form-control-sm {
    font-size: 11px;
}
.form-control-number {
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 15px;
    padding: 1px 4px !important;
}
.section-row {
    table {
        tr {
            height: 25px;
        }
    }
    .btn-link {
        padding: 0.25rem 0.25rem;
        font-weight: 400;
        color: var(--fba-extension-secondary);
    }
}
.keepa-container {
    button,
    a {
        padding: 0.25rem 0.25rem;
        font-weight: 400;
        font-size: 11px;
        color: var(--fba-extension-secondary);
    }
    button {
        &:first-child {
            padding-left: 0;
        }
    }
}
.column-warehouse {
    display: flex;
    flex-direction: column;

    span {
        display: inline-block;
        height: 25px;
    }
}
.popper {
    margin: -5px !important;
}
.popper-table {
    .c-item {
        text-align: center;
        padding: 5px 10px !important;
        text-align: left;
    }
    .cbr {
        border-right: 1px solid var(--bs-gray-300);
    }
    .cbb {
        border-bottom: 1px solid var(--bs-gray-300);
    }
}
/* .light-popper {
    border: none !important;
    margin: 0 !important;
} */
.warehouse-input {
    width: 54px;
    height: 22px;
    min-height: 22px !important;
}
.limited-text-size {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 4px;
}
@media (max-width: 500px) {
    .limited-text-size {
        max-width: 140px;
    }
}
/* Yanıp sönme animasyonu */
@keyframes blink {
    0%,
    100% {
        border-color: #fd9605;
    }
    50% {
        border-color: transparent;
    }
}
@keyframes textBlink {
    0%,
    100% {
        color: black;
    } /* Normal metin rengi */
    50% {
        color: #fd9605;
    } /* Yanıp sönme efektinde metin turuncu olacak */
}

/* Animasyonu etkinleştirecek class */
.blink-border {
    animation: blink 1s; /* 2 saniyelik bir animasyon süresi */
    border: 1px solid #fd9605; /* İlk ve son durumda görünecek border rengi */
}
/* Animasyonu etkinleştirecek class */
.text-blink {
    animation: textBlink 1s 2; /* 1 saniyelik bir animasyon süresi ve sonsuz tekrar */
}
</style>
