<template>
    <div class="container-fluid py-3 py-sm-3 mb-2" style="max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-5 position-relative">
                <div class="position-sticky" style="top: 10px">
                    <Card>
                        <CardSection :title="$t('cards.product_search.title')"></CardSection>
                        <div class="bottom-rounded">
                            <div class="d-flex justify-content-between bordered-bottom">
                                <h3 class="d-none d-sm-block align-self-center pl-2">
                                    {{ $t('cards.product_search.tabs.label') }}
                                </h3>
                                <ul class="nav nav-tabs" style="border-bottom: none">
                                    <li class="nav-item">
                                        <a class="nav-link" :class="search_type === 'asin' && 'active'" href="#" @click.prevent="search_type = 'asin'">
                                            {{ $t('cards.product_search.tabs.search_with_asin') }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="search_type === 'url' && 'active'" href="#" @click.prevent="search_type = 'url'">
                                            {{ $t('cards.product_search.tabs.search_with_url') }}
                                        </a>
                                    </li>
                                    <!-- <li class="nav-item d-none d-sm-block">
                                        <a class="nav-link" :class="search_type === 'category' && 'active'" href="#" @click.prevent="search_type = 'category'">
                                            {{ $t('cards.product_search.tabs.search_with_category') }}
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                            <div class="pl-2 pr-2">
                                <div class="mb-3 mt-4" v-if="appEnv === 'development' && search_type === 'asin'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>Parser</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <multiselect
                                        :show-labels="false"
                                        v-model="selectedParsers"
                                        track-by="value"
                                        label="label"
                                        :searchable="true"
                                        :multiple="true"
                                        :placeholder="'Parser seçin'"
                                        :options="parsers"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="mb-3 mt-4" v-if="search_type === 'asin'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.source') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <MarketSelect @onSelect="handleSelectMarketSource" :preselectFirst="false" :sources="true" />
                                    <span class="invalid-feedback" v-if="errors.sources"
                                        ><small>{{ $t('integration.updateMarketModal.selectDomain') }}</small></span
                                    >
                                </div>
                                <!-- <div class="mb-3 mt-4" v-if="search_type === 'category'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.source') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <MarketSelect @onSelect="handleSelectMarketSource" :preselectFirst="false" :multiple="false" :sources="true" />
                                    <span class="invalid-feedback" v-if="errors.sources"
                                        ><small>{{ $t('integration.updateMarketModal.selectDomain') }}</small></span
                                    >
                                </div> -->
                                <!-- <div class="mb-3 mt-4" v-if="search_type === 'category'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.category_type') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <select class="form-select" v-model="scan.category_options.category_type" @change="handleCategoryTypeChange" :placeholder="$t('inputs.product_search.labels.category_type')">
                                        <option value="">{{ $t('inputs.product_search.labels.category_type') }}</option>
                                        <option
                                            :disabled="(selectedMarket && !countries[selectedMarket].standard_category && category_type.value === 'standard') || !selectedMarket"
                                            :value="category_type.value"
                                            v-for="category_type in category_types"
                                            :key="category_type.value"
                                        >
                                            {{ $t('inputs.product_search.labels.category_types.' + category_type.value) }}
                                        </option>
                                    </select>
                                    <span class="invalid-feedback" v-if="errors.category_type"
                                        ><small>{{ errors.category_type }}</small></span
                                    >
                                </div> -->
                                <!-- <div class="mb-3" v-if="search_type === 'category' && (isCategoryLoading || (categories && categories.categories && categories.categories.length > 0))">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.category') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="category_list_wrapper">
                                        <div v-if="isCategoryLoading">
                                            <div class="d-flex" style="justify-content: center; padding: 40px">
                                                <div class="spinner-border text-primary" style="--bs-spinner-width: 1rem; --bs-spinner-height: 1rem; --bs-spinner-border-width: 0.2em" role="status">
                                                    <span class="visually-hidden">Yükleniyor...</span>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="!isCategoryLoading">
                                            <div class="category_list_item category_list_header" v-if="categories && categories.current_category" @click="handleChangeCategory(categories.current_category, true)">
                                                <span class="category_list_title" style="color: #1e2022 !important; font-size: 10px"> <i class="bi bi-arrow-left-short"></i>{{ categories.current_category.path }} </span>
                                                <span class="category_list_icon" style="color: #1e2022 !important; font-size: 10px" v-if="scan.category_options.category_id === categories.current_category.id"> {{ $t('common.selected') }} </span>
                                            </div>
                                            <div class="category_list_content">
                                                <template v-for="category in categories.categories.sort((a, b) => a.name.localeCompare(b.name))" :key="category.id">
                                                    <div class="category_list_item" :class="scan.category_options.category_id === category.id && 'selected'" @click="handleChangeCategory(category)">
                                                        <span class="category_list_title"
                                                            >{{ category.name }}
                                                            <a :href="category.link" @click.prevent="goTo(category.link)" target="_blank"> <i class="bi bi-link-45deg"></i> </a>
                                                        </span>
                                                        <span class="category_list_icon" v-if="category.has_children">
                                                            <i class="bi bi-arrow-right-short"></i>
                                                        </span>
                                                        <span class="category_list_icon" style="font-size: 10px" v-if="scan.category_options.category_id === category.id"> {{ $t('common.selected') }} </span>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                    <span class="invalid-feedback" v-if="errors.category"
                                        ><small>{{ errors.category }}</small></span
                                    >
                                </div> -->
                                <!-- <div v-if="search_type === 'category'" class="mb-3">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.page') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="input-group">
                                        <input type="number" v-model="scan.category_options.page" class="form-control" :class="errors.pages && 'is-invalid'" placeholder="Page" />
                                        <input type="number" v-model="scan.category_options.maxPage" class="form-control" :class="errors.pages && 'is-invalid'" placeholder="Max. Page" />
                                    </div>
                                    <span class="invalid-feedback" v-if="errors.pages"
                                        ><small>{{ errors.pages }}</small></span
                                    >
                                </div> -->
                                <!-- <div class="mb-3 mt-4" v-if="search_type === 'category' && scan.category_options.category_type === 'standard'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.sort_by') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <select class="form-select" v-model="scan.category_options.sort_by" :placeholder="$t('inputs.product_search.labels.sort_by')">
                                        <option value="">{{ $t('inputs.product_search.labels.sort_by') }}</option>
                                        <option value="price_low_to_high">{{ $t('inputs.product_search.labels.sort_bys.price_low_to_high') }}</option>
                                        <option value="price_high_to_low">{{ $t('inputs.product_search.labels.sort_bys.price_high_to_low') }}</option>
                                        <option value="featured">{{ $t('inputs.product_search.labels.sort_bys.featured') }}</option>
                                        <option value="average_review">{{ $t('inputs.product_search.labels.sort_bys.average_review') }}</option>
                                        <option value="most_recent">{{ $t('inputs.product_search.labels.sort_bys.most_recent') }}</option>
                                    </select>
                                </div> -->
                                <!-- <div class="mb-3 mt-4" v-if="search_type === 'category' && scan.category_options.category_type === 'deals'">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.additional') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="input-group">
                                        <select class="form-select" v-model="scan.category_options.sort_by" :placeholder="$t('inputs.product_search.labels.sort_by')">
                                            <option value="">{{ $t('inputs.product_search.labels.sort_by') }}</option>
                                            <option value="price_low_to_high">{{ $t('inputs.product_search.labels.sort_bys.price_low_to_high') }}</option>
                                            <option value="price_high_to_low">{{ $t('inputs.product_search.labels.sort_bys.price_high_to_low') }}</option>
                                            <option value="featured">{{ $t('inputs.product_search.labels.sort_bys.featured') }}</option>
                                            <option value="average_review">{{ $t('inputs.product_search.labels.sort_bys.average_review') }}</option>
                                            <option value="most_recent">{{ $t('inputs.product_search.labels.sort_bys.most_recent') }}</option>
                                        </select>
                                        <select class="form-select" v-model="scan.category_options.deal_types" :placeholder="$t('inputs.product_search.labels.deal_type')">
                                            <option value="">{{ $t('inputs.product_search.labels.deal_type') }}</option>
                                            <option value="deal_of_the_day">{{ $t('inputs.product_search.labels.deal_types.deal_of_the_day') }}</option>
                                            <option value="lightning_deal">{{ $t('inputs.product_search.labels.deal_types.lightning_deal') }}</option>
                                            <option value="best_deal">{{ $t('inputs.product_search.labels.deal_types.best_deal') }}</option>
                                        </select>
                                    </div>

                                    <div class="input-group mt-2">
                                        <select class="form-select" v-model="scan.category_options.deal_states" :placeholder="$t('inputs.product_search.labels.deal_states')">
                                            <option value="">{{ $t('inputs.product_search.labels.deal_states') }}</option>
                                            <option value="available">{{ $t('inputs.product_search.labels.deal_states_types.available') }}</option>
                                            <option value="upcoming">{{ $t('inputs.product_search.labels.deal_states_types.upcoming') }}</option>
                                            <option value="expired">{{ $t('inputs.product_search.labels.deal_states_types.expired') }}</option>
                                        </select>
                                        <select class="form-select" v-model="scan.category_options.minimum_rating" :placeholder="$t('inputs.product_search.labels.minimum_rating')">
                                            <option value="">{{ $t('inputs.product_search.labels.minimum_rating') }}</option>
                                            <option value="1_and_up">{{ $t('inputs.product_search.labels.minimum_ratings.1_and_up') }}</option>
                                            <option value="2_and_up">{{ $t('inputs.product_search.labels.minimum_ratings.2_and_up') }}</option>
                                            <option value="3_and_up">{{ $t('inputs.product_search.labels.minimum_ratings.3_and_up') }}</option>
                                            <option value="4_and_up">{{ $t('inputs.product_search.labels.minimum_ratings.4_and_up') }}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="mb-1" v-if="search_type === 'asin'">
                                    <div class="filter-item-col">
                                        <textarea
                                            :value="scan.textarea"
                                            @input="updateAsinCount"
                                            @keyup="updateAsinCount"
                                            :class="errors.asin && 'is-invalid'"
                                            class="form-control"
                                            style="min-height: 200px"
                                            :placeholder="$t('inputs.product_search.labels.asin_list')"
                                        ></textarea>
                                        <div class="d-flex justify-content-between w-100" v-if="currentQuota">
                                            <small
                                                ><template v-if="asinCount > 0">{{ $t('views.productSearch.validAsin', { validAsin: formatThousandsDecimal(scan.list.length) }) }} - </template>
                                                {{ $t('views.productSearch.maxAsin', { currentQuota: formatThousandsDecimal(currentQuota) }) }}
                                            </small>
                                            <router-link to="/settings/subscription/payment/token" target="_blank"
                                                ><small>{{ $t('views.productSearch.quota') }}</small></router-link
                                            >
                                        </div>
                                        <span class="invalid-feedback" v-if="errors.asin"
                                            ><small>{{ errors.asin }}</small></span
                                        >
                                    </div>
                                </div>
                                <div v-if="search_type === 'url'" class="mb-3 mt-4">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.amazon_url') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <input v-model="scan.url" class="form-control" :class="errors.url && 'is-invalid'" :placeholder="$t('inputs.product_search.labels.amazon_url')" />
                                    <span class="form-text m-0 clamp">{{ $t('inputs.product_search.help_text.url_warning') }}</span>
                                    <span class="invalid-feedback" v-if="errors.url"
                                        ><small>{{ errors.url }}</small></span
                                    >
                                </div>
                                <div v-if="search_type === 'url'" class="mb-3">
                                    <label class="align-self-center mb-2">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.page') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <div class="input-group">
                                        <input type="number" v-model="scan.url_options.page" class="form-control" :class="errors.pages && 'is-invalid'" placeholder="Page" />
                                        <input type="number" v-model="scan.url_options.maxPage" class="form-control" :class="errors.pages && 'is-invalid'" placeholder="Max. Page" />
                                    </div>
                                    <span class="invalid-feedback" v-if="errors.pages"
                                        ><small>{{ errors.pages }}</small></span
                                    >
                                </div>

                                <div class="mb-3">
                                    <label for="min_stock" class="align-self-center">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.product_search.labels.search_code') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <input id="sku_code" type="text" placeholder="FH001" v-model="scan.sku_code" class="form-control" maxlength="30" />
                                </div>
                                <div class="mb-3" v-if="keepaAvailable">
                                    <div class="d-flex">
                                        <input id="keepa_active" v-model="keepa_active" type="checkbox" class="form-check-input" />
                                        <label class="ml-05" for="keepa_active" style="width: 95%" v-html="$t('inputs.product_search.labels.keepa_active', { image: getKeepaImage() })" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bordered-top mt-3">
                            <div class="d-flex justify-content-end mt-3 mr-2">
                                <button class="btn btn-primary" @click="handleShowDialog">{{ $t('actions.start_search') }}</button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div class="col-lg-7">
                <Card>
                    <div class="card-section bordered-bottom pl-2 pb-2">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center flex-1-1 justify-content-between">
                                    <h2 class="d-flex card__title text__heading">
                                        {{ $t('cards.filters.title') }}
                                    </h2>
                                    <div class="d-flex">
                                        <select v-model="filter_id" @change="handleFilterChange" :placeholder="$t('inputs.product_search.labels.select_filter')" class="form-select form-select-sm mr-1" :class="errors.filter_name && 'is-invalid'">
                                            <option value="">{{ $t('inputs.product_search.labels.select_filter') }}</option>
                                            <option v-for="filter in filters" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
                                        </select>
                                        <div>
                                            <button class="dropdown btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                                {{ $t('actions.actions') }}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown">
                                                <a class="dropdown-item" href="#" :class="!filter_id && 'disabled'" @click="handleSaveOnSelectedFilter">{{ $t('actions.save_on_selected_filter') }}</a>
                                                <a class="dropdown-item" href="#" :class="!filter_id && 'disabled'" @click="handleRemoveSelectedFilter">{{ $t('actions.delete_selected_filter') }}</a>
                                                <a class="dropdown-item bordered-top" href="#" @click="handleNewFilter">{{ $t('actions.add_filter') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Filters :selectedFilter="filter_id" :keepaDomainAvailable="keepaAvailable" :keepaAvailable="keepaAvailable && keepa_active" @handleFilterFormChange="handleFilterFormChange" />
                </Card>
            </div>
        </div>
    </div>
    <Dialog
        :options="{
            title: $t('dialogs.product_search_create.title'),
            description:
                search_type === 'url' || search_type === 'category'
                    ? $t('dialogs.product_search_create.' + search_type + '_description')
                    : $t('dialogs.product_search_create.description', { token: formatThousandsDecimal(scan.list.length * this.selectedMarketSources.length * (keepa_active ? 3 : 1)) }),
        }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleFormSubmit"
    />
</template>
<script>
import MarketSelect from '@/components/Inputs/MarketSelect.vue'
import Card from '@/components/Layouts/Card.vue'
import Filters from '@/components/Filters'
import CardSection from '@/components/Layouts/CardSection.vue'
import { mapActions, mapGetters } from 'vuex'
import UserFilterService from '@/services/user-filter'
import Dialog from '@/components/Variations/Dialog.vue'
import Multiselect from 'vue-multiselect'
import { countryInfo, number_format } from '@/utils/helper'

export default {
    name: 'ProductSearchCreate',
    data() {
        return {
            showDialog: false,
            selectedMarketSources: [],
            selectedMarket: null,
            easyshipping_active: true,
            currentQuota: null,
            keepa_active: false,
            errors: {},
            showNewBadge: false,
            showExistsBadge: false,
            selectedFilter: null,
            search_type: 'asin',
            filter_name: '',
            countries: countryInfo(),
            openFilterOptions: false,
            filter_id: '',
            selectedParsers: [
                {
                    value: 'oxylabs',
                    label: 'Oxylabs',
                },
            ],
            category_types: [
                {
                    type: 'category',
                    value: 'standard',
                },
                {
                    type: 'bestsellers',
                    value: 'bestsellers',
                },
                {
                    type: 'bestsellers',
                    value: 'gift_ideas',
                },
                {
                    type: 'bestsellers',
                    value: 'most_wished_for',
                },
                {
                    type: 'bestsellers',
                    value: 'new_releases',
                },
                {
                    type: 'deals',
                    value: 'deals',
                },
            ],
            isCategoryLoading: false,
            parsers: [
                {
                    value: 'easyparser',
                    label: 'EasyParser',
                },
                {
                    value: 'oxylabs',
                    label: 'OxyLabs',
                },
                {
                    value: 'rainforest',
                    label: 'Rainforest',
                },
            ],
            filter_options: {},
            scan: {
                asinCount: 0,
                sku_code: null,
                textarea: '',
                url: '',
                url_options: {
                    page: null,
                    maxPage: null,
                    k: null,
                    rh: null,
                    qid: null,
                    seller: null,
                    amazon_domain: null,
                },
                category_options: {
                    page: null,
                    maxPage: null,
                    category_id: null,
                    sort_by: '',
                    link: null,
                    category_type: '',
                    amazon_domain: null,
                    deal_types: '',
                    deal_states: '',
                    minimum_rating: '',
                    type: null,
                },
                list: [],
            },
        }
    },
    methods: {
        ...mapActions('productSearch', ['getFilters', 'setDefaultFilter', 'getCategories']),
        ...mapActions('wallet', ['getUserPackage']),
        getKeepaImage() {
            return require('@/assets/img/keepa-logo.webp')
        },
        handleSelectMarketSource(market) {
            this.selectedMarketSources = market
            if (this.search_type === 'category') {
                this.selectedMarket = market.short
                if (market.short && this.countries[market.short]) {
                    this.selectedMarketSources = [market]
                    const domain = this.countries[market.short].domain
                    this.scan.category_options.amazon_domain = domain
                } else {
                    this.scan.category_options.amazon_domain = null
                    this.selectedMarketSources = []
                }
            }
        },
        goTo(url) {
            window.open(url, '_blank')
        },
        async handleCategoryTypeChange() {
            this.isCategoryLoading = true
            this.categories = []
            if (this.scan.category_options.category_type) {
                await this.getCategories({
                    short: this.selectedMarket,
                    type: this.scan.category_options.category_type,
                })
                this.scan.category_options.type = this.category_types.find((type) => type.value === this.scan.category_options.category_type).type
            }
            this.isCategoryLoading = false
        },
        async handleChangeCategory(category, parent = false) {
            if (category.has_children) {
                this.isCategoryLoading = true
                await this.getCategories({
                    short: this.selectedMarket,
                    type: this.scan.category_options.category_type,
                    parent_id: parent ? category.parent_id : category.id,
                })
                this.isCategoryLoading = false
            } else {
                this.scan.category_options.category_id = category.id
                this.scan.category_options.link = category.link
            }
        },
        formatThousandsDecimal(value) {
            return number_format(value, 'money', {
                style: 'decimal',
                decimal: '',
                thousand: '.',
            })
        },
        updateAsinCount(event) {
            const lines = event.target.value.trim().split('\n')
            const uniqueAsins = new Set()
            let validLines = []

            for (let i = 0; i < lines.length; i++) {
                const line = lines[i].trim().toUpperCase()

                if (line.length === 10 && line.startsWith('B0')) {
                    validLines.push(line)
                    uniqueAsins.add(line)
                } else if (line.length > 10) {
                    // create new line
                    validLines.push(line.substring(0, 10))
                    validLines.push(line.substring(10, line.length))
                    uniqueAsins.add(line.substring(0, 10))
                } else {
                    validLines.push(line)
                }
            }

            this.scan.textarea = validLines.join('\n')
            this.asinCount = uniqueAsins.size
            this.scan.list = Array.from(uniqueAsins)
        },

        onInputEventHandler: async function (item) {
            this.selectedFilter = null
        },
        selectItemEventHandler: function (item) {
            this.selectedFilter = item.value
        },
        handleFilterChange: function (e) {
            this.filter_id = e.target.value
        },
        handleFilterFormChange: function (data) {
            this.filter_options = data
        },
        prepareData: async function () {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getFilters()
            await this.getUserPackage()
            this.$store.dispatch('productSearch/setCategories', null)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        handleNewFilter: async function () {
            // confirm with input
            const generated4Digit = Math.floor(1000 + Math.random() * 9000)
            const filter_name = prompt(this.$t('common.set_filter_name'), this.$t('actions.new_filter') + ' ' + generated4Digit)
            if (filter_name) {
                this.$store.commit('set', { k: 'loading', v: { status: true } })
                const createdFilter = await UserFilterService.create('inventory', {
                    name: filter_name,
                    options: this.filter_options,
                })
                await this.getFilters()

                this.filter_id = createdFilter.data.id
                this.openFilterOptions = false
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleRemoveSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true } })
                await UserFilterService.delete(this.filter_id)
                this.filter_id = ''
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleSaveOnSelectedFilter: async function () {
            if (this.filter_id) {
                this.$store.commit('set', { k: 'loading', v: { status: true } })

                await UserFilterService.update(this.filter_id, {
                    options: this.filter_options,
                })
                this.openFilterOptions = false
                await this.getFilters()
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleShowDialog() {
            this.errors = {}
            const _package = this.walletPackage
            if (_package && _package.status !== 0 && _package.status !== 1 && _package.status !== 3) {
                this.$toast.error(_package.status === 8 ? this.$t('inputs.product_search.errors.package_paused') : this.$t('inputs.product_search.errors.package_not_active'))
                return
            }
            if (this.selectedMarketSources.length === 0) {
                this.errors.sources = this.$t('inputs.product_search.errors.select_domain')
                this.errors.url = this.$t('inputs.product_search.errors.select_domain')
                return
            }
            if (this.selectedMarketSources.filter((source) => this.currentMarket.short === source).length > 0) {
                this.errors.url = this.$t('inputs.product_search.errors.target_domain')
                this.errors.sources = this.$t('inputs.product_search.errors.target_domain')
                return
            }
            if (this.search_type === 'url') {
                let check = true
                this.selectedMarketSources = this.selectedMarketSources.map((source) => {
                    const sourceDomain = this.currentMarket.sources.find((s) => s.source === source || s.source === source.short)
                    if (!sourceDomain) {
                        check = false
                    }
                    return {
                        short: source,
                    }
                })
                if (!check) {
                    this.errors.url = this.$t('inputs.product_search.errors.source_not_exists')
                    return
                }
            }
            if (this.search_type === 'asin' && this.scan.list.length === 0 && this.scan.textarea.length === 0) {
                this.errors.asin = this.$t('inputs.product_search.errors.asin_required')
            }
            if (this.search_type === 'url' && this.scan.url.length === 0) {
                this.errors.url = this.$t('inputs.product_search.errors.url_required')
            }
            if (this.search_type === 'category' && this.scan.category_options.category_type === '') {
                this.errors.category_type = this.$t('inputs.product_search.errors.category_type_required')
            }
            if (this.search_type === 'category' && !this.scan.category_options.category_id) {
                this.errors.category = this.$t('inputs.product_search.errors.category_required')
            }
            if (this.search_type === 'url' && !this.scan.url_options.page && !this.scan.url_options.maxPage) {
                this.errors.pages = this.$t('inputs.product_search.errors.options_required')
            }
            if (this.search_type === 'category' && !this.scan.category_options.page && !this.scan.category_options.maxPage) {
                this.errors.pages = this.$t('inputs.product_search.errors.options_required')
            }
            if (this.url_options && this.url_options.k.length === 0) {
                this.errors.url = this.$t('inputs.product_search.errors.invalid_url')
            }
            if (this.url_options && this.url_options.amazon_domain.length === 0) {
                this.errors.url = this.$t('inputs.product_search.errors.invalid_url')
            }
            if (+this.scan.list.length > +this.currentQuota) {
                this.errors.asin = this.$t('inputs.product_search.errors.unsufficient_token')
                return
            }
            if (this.scan.sku_code && this.scan.sku_code.length > 30) {
                this.errors.sku_code = 'Tarama kodu 30 karakterden uzun olamaz.'
                return
            }
            if (Object.keys(this.errors).length > 0) {
                return
            }
            this.showDialog = true
        },
        handleFormSubmit: async function () {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                if (this.selectedParsers.length === 0) {
                    this.selectedParsers = [{ value: 'oxylabs', label: 'Oxylabs' }]
                }
                await this.$store.dispatch('productSearch/createProductSearch', {
                    scan: {
                        asins: this.scan.list,
                        search_type: this.search_type,
                        keepa_active: this.keepa_active,
                        sources: this.selectedMarketSources,
                        url: this.scan.url,
                        sku_code: this.scan.sku_code,
                        url_options: this.scan.url_options,
                        category_options: this.scan.category_options,
                        parsers: this.selectedParsers.map((parser) => parser.value),
                    },
                    filter: {
                        filter_name: this.filter_name,
                        filter_options: this.filter_options,
                    },
                    marketId: this.currentMarket.id,
                    to: this.currentMarket.short,
                })
                this.$router.push({ name: 'product-search.history' })
            } catch (e) {
                console.log(e)
            } finally {
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                this.showDialog = false
            }
        },
    },
    computed: {
        ...mapGetters('wallet', ['requestLimit', 'requestUsed', 'currentBalance', 'walletPackage']),
        ...mapGetters('market', ['currentMarket']),
        ...mapGetters('productSearch', ['filters', 'categories']),
        appEnv() {
            return process.env.VUE_APP_ENV
        },
        keepaAvailable() {
            const short = this.currentMarket?.short
            if (short) {
                const domain = countryInfo()[short].domain
                const availableDomains = ['amazon.com', 'amazon.co.uk', 'amazon.de', 'amazon.fr', 'amazon.co.jp', 'amazon.ca', 'amazon.it', 'amazon.es', 'amazon.in', 'amazon.com.mx']
                return availableDomains.includes(domain)
            }
            return false
        },
    },
    watch: {
        appEnv: {
            immediate: true,
            handler() {
                this.selectedParsers = process.env.VUE_APP_ENV !== 'development' ? [{ value: 'oxylabs', label: 'Oxylabs' }] : []
            },
        },
        categories: {
            handler: function (val) {
                console.log(val)
                if (val && val && val.current_category) {
                    this.scan.category_options.category_id = val.current_category.id
                    this.scan.category_options.link = val.current_category.link
                } else {
                    this.scan.category_options.category_id = null
                    this.scan.category_options.link = null
                }
            },
            immediate: false,
            deep: true,
        },
        selectedMarketSources: {
            handler: function (val) {
                if (val.length > 0) this.currentQuota = +(this.currentBalance / val.length).toFixed(0)

                const value = this.scan.textarea.replace(/ /g, '')
                this.scan.textarea = value
                this.asinCount = this.scan.list.length
            },
            immediate: false,
        },
        'scan.url': {
            handler: function (val) {
                this.selectedMarketSources = []
                const url = val
                // parse query strings
                const queryString = url.split('?')[1]
                this.scan.url_options = {
                    k: null,
                    qid: null,
                    rf: null,
                    seller_id: null,
                    amazon_domain: null,
                }
                // Sorgu dizesini parametrelerine ayırma
                const params = new URLSearchParams(queryString)
                params.forEach((value, key) => {
                    if (key === 'k') {
                        this.scan.url_options.k = value
                    } else if (key === 'rh') {
                        this.scan.url_options.qid = value
                    } else if (key === 'rf') {
                        this.scan.url_options.rf = value
                    } else if (key === 'seller') {
                        this.scan.url_options.seller_id = value
                    } else if (key === 'me') {
                        this.scan.url_options.seller_id = value
                    }
                })
                const baseurl = url.split('?')[0]
                if (url.split('?').length === 0) return

                if (baseurl.split('/').length < 2) return

                const domain = baseurl.split('/')[2].replace('www.', '')

                this.scan.url_options.amazon_domain = domain
                const countries = this.countries
                Object.keys(countries).forEach((key) => {
                    if (countries[key].domain === domain) {
                        this.selectedMarketSources.push(key)
                    }
                })
                console.log(this.scan.url_options, this.selectedMarketSources)
            },
            immediate: false,
        },
    },
    mounted() {
        this.prepareData()
    },
    components: { Card, CardSection, MarketSelect, Filters, Dialog, Multiselect },
}
</script>

<style>
.filter-item-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}
.asin-list {
    height: 100px !important;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    min-height: 250px !important;
    margin-bottom: 5px !important;
    max-height: 600px !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffe3be47), color-stop(50%, #fff));
    background-image: linear-gradient(#ffe3be47 50%, #fff 0);
    background-attachment: local;
    background-size: 100% 50px !important;
    line-height: 25px !important;
    font-size: 14px !important;
    padding: 0 8px !important;
}
.filter-item-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
    align-items: flex-start;
    line-height: normal;
}
.filter-item-row input[type='checkbox'] {
    margin-right: 8px;
}
input.check {
    display: none;
}
input.check[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 20px;
    padding-left: 30px;
    padding-top: 3px;
}
input.check[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
}
input.check[type='checkbox']:checked + label:before {
    background-color: #fd9405;
    -webkit-box-shadow: inset 0 0 0 1px #fd9405;
    box-shadow: inset 0 0 0 1px #fd9405;
}
input.check[type='checkbox']:checked + label:after {
    content: '';
    width: 11px;
    height: 6px;
    display: block;
    position: absolute;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 5px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.star-select-box {
    position: relative;
    max-width: 190px;
    height: 45px;
    width: 100%;
}
.star-select-box .star-box {
    width: 100%;
    height: 42px;
    padding: 5px 8px;
    background-color: #fff;
    border: 1px solid #dbe3ef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.star-select-box ul {
    padding-left: 0;
}
.star-select-box ul.star-list {
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    border: 1px solid #dbe3ef;
    border-radius: 5px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.star-select-box .active-star-list {
    opacity: 1 !important;
    pointer-events: auto !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.star-select-box ul.star-list li {
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ecf0f7;
    font-size: 14px;
}
.multiselect {
    min-width: 220px !important;
}
.option__image {
    width: 20px;
}
.multiselect__option {
    display: flex !important;
    align-items: center;
}
.multiselect__option--highlight {
    background-color: #fff !important;
    color: #000 !important;
}
.multiselect__option--selected {
    background-color: #f8fafd !important;
    color: #000 !important;
}
.category_list_wrapper {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    border-radius: 0.3125rem;
    position: relative;
    .category_list_header {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
    }
    .category_list_content {
        position: relative;
        max-height: 200px;
        overflow-y: auto;
    }
}
.category_list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    cursor: pointer;
    .category_list_title {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding-left: 10px;
        color: #fd9405 !important;
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .category_list_icon {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding-right: 10px;

        color: #fd9405 !important;
    }
}
.category_list_item.selected {
    background-color: #fd94051f;
}
.category_list_item:hover {
    background-color: #fd94051f;
}
.category_list_item:last-child {
    border-bottom: 0px;
}
</style>
