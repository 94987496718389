import { ApiInstance } from './base'

const RestrictedListService = {
    findAll(type, query) {
        return ApiInstance.get('/restricted-list/' + type + '?' + query)
    },
    create(payload) {
        return ApiInstance.post('/restricted-list', payload)
    },
    update(id, payload) {
        return ApiInstance.put('/restricted-list/' + id, payload)
    },
    delete(ids) {
        return ApiInstance.post('/restricted-list/delete', { ids })
    },
    findAllViolationPool(type, query) {
        return ApiInstance.get('/violation-pool/' + type + '?' + query)
    },
    addToRestrictedList(payload) {
        return ApiInstance.post('/violation-pool/add-to/restricted-list', payload)
    },
}

export default RestrictedListService
