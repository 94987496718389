<!-- eslint-disable max-len -->
<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="keepa-logo" :style="style" :width="width" :height="height" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
            <path
                d="M2305 4869 c-608 -64 -1171 -373 -1572 -864 -143 -176 -299 -461
-378 -695 -90 -265 -154 -703 -127 -870 6 -36 16 -110 22 -165 42 -392 207
-797 463 -1135 86 -113 271 -302 382 -390 318 -251 679 -413 1077 -482 115
-20 166 -23 383 -23 213 1 269 4 380 23 374 65 709 208 1015 435 125 92 367
336 462 463 235 317 382 672 439 1059 24 165 26 471 4 642 -33 251 -104 482
-220 718 -350 710 -1027 1188 -1811 1280 -137 16 -386 18 -519 4z m745 -1251
l0 -548 550 0 550 0 0 -500 0 -500 -542 -2 -543 -3 -3 -547 -2 -547 -498 -3
c-273 -2 -500 -2 -504 0 -5 2 -8 251 -8 553 l0 550 -547 -2 -548 -1 0 498 0
499 548 3 547 2 0 550 0 550 500 -2 501 -3 -1 -547z"
            />
            <path d="M2120 2565 l0 -445 435 0 435 0 0 445 0 445 -435 0 -435 0 0 -445z" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'KeepaLogo',
    props: {
        color: {
            type: String,
            default: '#FFFFFF',
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 100,
        },
        style: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>
