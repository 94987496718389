import { encrypt } from '@/utils/encryption'
import moment from 'moment'

const countryInfo = () => ({
    US: {
        seller_central: 'sellercentral.amazon.com',
        domain: 'amazon.com',
        price_format: false,
        price_integer: false,
        symbol: 'USD',
        currency: 'USD$',
        currency_text: 'USD',
        rate: 1.0,
        rate_min: 1.0,
        url: '.com',
        marketplaceID: 'ATVPDKIKX0DER',
        developer_id: '176001719560',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'lbs',
        region: 'NA',
        regionFull: 'North America',
        preferred: true,
        standard_category: true,
    },
    CA: {
        seller_central: 'sellercentral.amazon.ca',
        domain: 'amazon.ca',
        price_format: false,
        price_integer: false,
        symbol: 'CAD',
        currency: 'CDN$',
        currency_text: 'CAD',
        rate: 1.25,
        rate_min: 1.2,
        url: '.ca',
        marketplaceID: 'A2EUQ1WTGCTBG2',
        developer_id: '176001719560',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'NA',
        regionFull: 'North America',
        preferred: true,
        standard_category: false,
    },
    MX: {
        seller_central: 'sellercentral.amazon.com.mx',
        domain: 'amazon.com.mx',
        price_format: false,
        price_integer: false,
        symbol: 'MXN',
        currency: 'MXN$',
        currency_text: 'MXN',
        rate: 17.0,
        rate_min: 17.0,
        url: '.com.mx',
        marketplaceID: 'A1AM78C64UM0Y8',
        developer_id: '176001719560',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'NA',
        regionFull: 'North America',
        standard_category: false,
    },
    BR: {
        seller_central: 'sellercentral.amazon.com.br',
        domain: 'amazon.com.br',
        price_format: false,
        price_integer: false,
        symbol: 'BRL',
        currency: 'BRL$',
        currency_text: 'BRL',
        rate: 4.5,
        rate_min: 4.5,
        url: '.com.br',
        marketplaceID: 'A2Q3Y263D00KWC',
        developer_id: '176001719560',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'NA',
        regionFull: 'North America',
        standard_category: false,
    },
    AE: {
        seller_central: 'sellercentral.amazon.ae',
        domain: 'amazon.ae',
        price_format: false,
        price_integer: false,
        symbol: 'AED',
        currency: 'AED',
        currency_text: 'AED',
        rate: 3.6,
        rate_min: 3.6,
        url: '.ae',
        marketplaceID: 'A2VIGQ35RCS4UG',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'ME',
        regionFull: 'Middle East',
        standard_category: false,
    },
    DE: {
        seller_central: 'sellercentral.amazon.de',
        domain: 'amazon.de',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.8,
        url: '.de',
        marketplaceID: 'A1PA6795UKMFR9',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        preferred: true,
        standard_category: true,
    },
    FR: {
        seller_central: 'sellercentral.amazon.fr',
        domain: 'amazon.fr',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.8,
        url: '.fr',
        marketplaceID: 'A13V1IB3VIYZZH',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: true,
    },
    NL: {
        seller_central: 'sellercentral.amazon.nl',
        domain: 'amazon.nl',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.8,
        url: '.nl',
        marketplaceID: 'A1805IZSGTT6HS',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    ES: {
        seller_central: 'sellercentral.amazon.es',
        domain: 'amazon.es',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.8,
        url: '.es',
        marketplaceID: 'A1RKKUPIHCS9HS',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: true,
    },
    IT: {
        seller_central: 'sellercentral.amazon.it',
        domain: 'amazon.it',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.8,
        url: '.it',
        marketplaceID: 'APJ6JRA9NG5V4',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: true,
    },
    UK: {
        seller_central: 'sellercentral.amazon.co.uk',
        domain: 'amazon.co.uk',
        price_format: false,
        price_integer: false,
        symbol: 'GBP',
        currency: 'GBP£',
        currency_text: 'GBP',
        rate: 0.77,
        rate_min: 0.75,
        url: '.co.uk',
        marketplaceID: 'A1F83G8C2ARO7P',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        preferred: true,
        standard_category: true,
    },
    SE: {
        seller_central: 'sellercentral.amazon.se',
        domain: 'amazon.se',
        price_format: true,
        price_integer: false,
        symbol: 'SEK',
        currency: 'kr',
        currency_text: 'SEK',
        rate: 9.3,
        rate_min: 9.15,
        url: '.se',
        marketplaceID: 'A2NODRKZP88ZB9',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    EG: {
        seller_central: 'sellercentral.amazon.eg',
        domain: 'amazon.eg',
        price_format: false,
        price_integer: false,
        symbol: 'EGP',
        currency: 'GBP£',
        currency_text: 'EGP',
        rate: 15.7,
        rate_min: 15.7,
        url: '.eg',
        marketplaceID: 'ARBP9OOSHTCHU',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    TR: {
        seller_central: 'sellercentral.amazon.com.tr',
        domain: 'amazon.com.tr',
        price_format: true,
        price_integer: false,
        symbol: 'TRY',
        currency: 'TL₺',
        currency_text: 'TL',
        rate: 14.5,
        rate_min: 14.45,
        url: '.com.tr',
        marketplaceID: 'A33AVAJ2PDY3EV',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    SA: {
        seller_central: 'sellercentral.amazon.sa',
        domain: 'amazon.sa',
        price_format: false,
        price_integer: false,
        symbol: 'SAR',
        currency: 'SAR',
        currency_text: 'SAR',
        rate: 3.78,
        rate_min: 3.72,
        url: '.sa',
        marketplaceID: 'A17E79C6D8DWNP',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    IN: {
        seller_central: 'sellercentral.amazon.in',
        domain: 'amazon.in',
        price_format: false,
        price_integer: false,
        symbol: 'INR',
        currency: 'INR₹',
        currency_text: 'INR',
        rate: 70.5,
        rate_min: 70.5,
        url: '.in',
        marketplaceID: 'A21TJRUUN4KGV',
        developer_id: '856673120245',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: true,
    },
    SG: {
        seller_central: 'sellercentral.amazon.sg',
        domain: 'amazon.sg',
        price_format: false,
        price_integer: false,
        symbol: 'SGD',
        currency: 'S$',
        currency_text: 'S',
        rate: 1.35,
        rate_min: 1.3,
        url: '.sg',
        marketplaceID: 'A19VAU5U5O7RUS',
        developer_id: '046499843343',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'FE',
        regionFull: 'Far East',
        standard_category: false,
    },
    AU: {
        seller_central: 'sellercentral.amazon.com.au',
        domain: 'amazon.com.au',
        price_format: false,
        price_integer: false,
        symbol: 'AUD',
        currency: 'AUD$',
        currency_text: 'AUD',
        rate: 1.34,
        rate_min: 1.29,
        url: '.com.au',
        marketplaceID: 'A39IBJ37TRP1C6',
        developer_id: '046499843343',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'FE',
        regionFull: 'Far East',
        standard_category: false,
    },
    JP: {
        seller_central: 'sellercentral-japan.amazon.com',
        domain: 'amazon.co.jp',
        price_format: false,
        price_integer: true,
        symbol: 'JPY',
        currency: 'JPY¥',
        currency_text: 'JPY',
        rate: 127.93,
        rate_min: 120.0,
        url: '.co.jp',
        marketplaceID: 'A1VC38T7YXB528',
        developer_id: '046499843343',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'FE',
        regionFull: 'Far East',
        standard_category: false,
    },
    PL: {
        seller_central: 'sellercentral.amazon.pl',
        domain: 'amazon.pl',
        price_format: true,
        price_integer: false,
        symbol: 'PLN',
        currency: 'PLNł',
        currency_text: 'PLN',
        rate: 4.72,
        rate_min: 4.7,
        url: '.pl',
        marketplaceID: 'A1C3SOZRARQ6R3',
        developer_id: '046499843343',
        mws_active: true,
        repricer_custom_run_time: null,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
    BE: {
        seller_central: 'sellercentral.amazon.com.be',
        domain: 'amazon.com.be',
        price_format: true,
        price_integer: false,
        symbol: 'EUR',
        currency: 'EUR€',
        currency_text: 'EUR',
        rate: 0.85,
        rate_min: 0.85,
        url: '.com.be',
        marketplaceID: 'AMEN7PMS3EDWL',
        developer_id: '046499843343',
        mws_active: true,
        repricer_custom_run_time: 20,
        weight_unit: 'kg',
        region: 'EU',
        regionFull: 'Europe',
        standard_category: false,
    },
})
const unitsLongName = (value) => {
    if (value === 'lbs') return 'pound'
    if (value === 'oz') return 'ounce'
    if (value === 'g') return 'gram'
    if (value === 'kg') return 'kilogram'
}
const spApiAuth = (domain, state = null) => {
    const env = process.env.VUE_APP_ENVIROMENT

    window.location.href = `https://arbitracer.com/redirect-sp-api.html?redirect_to_amz=true&domain=${domain}&environment=${env}&state=${encrypt(state)}`
    // window.location.href = 'https://sellercentral.amazon' + domain + '/apps/authorize/consent?application_id=amzn1.sp.solution.fc47e453-d555-43c2-abdc-8490802e1cf0&state=' + encrypt(state)
}
function mergeDeep(...objects) {
    const isObject = (obj) => obj && typeof obj === 'object'

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach((key) => {
            const pVal = prev[key]
            const oVal = obj[key]

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal)
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal)
            } else {
                prev[key] = oVal
            }
        })

        return prev
    }, {})
}

function convertObjectToQueryString(obj, prefix) {
    let str = [],
        k,
        v
    for (let p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(p)) {
            continue
        } // skip things from the prototype
        if (~p.indexOf('[')) {
            k = prefix ? prefix + '[' + p.substring(0, p.indexOf('[')) + ']' + p.substring(p.indexOf('[')) : p
            // only put whatever is before the bracket into new brackets; append the rest
        } else {
            k = prefix ? prefix + '[' + p + ']' : p
        }
        v = obj[p]
        str.push(typeof v == 'object' ? convertObjectToQueryString(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v))
    }
    str = str.filter((item) => item.length)
    return str.join('&')
}

function convertQueryStringToObject(querystring) {
    return Object.fromEntries(new URLSearchParams(querystring))
}

function dateDiff(dateFirst) {
    const dateString = dateFirst.date

    const date1 = Date.parse(dateString)
    const date2 = Date.now()
    const diffInMs = date2 - date1
    let number = diffInMs / 1000 / 60 / 60 / 24

    number = number > 100 ? 100 : Math.ceil(number)
    number = number < 0 ? 0 : Math.ceil(number)

    return number
}
function strLimit(str, limit) {
    return str.length > limit ? str.substring(0, limit) + '...' : str
}
function printDateTime(datetime, format) {
    return moment(datetime).format(format)
}

function printDuration(input_seconds, years = true, months = true, days = true, hours = true, minutes = true, seconds = true, milliseconds = false) {
    const duration = moment.duration(input_seconds, 'seconds')

    let result = ''

    if (duration._data.years > 0 && years) {
        result += ' ' + duration._data.years + ' yıl'
    }
    if (duration._data.months > 0 && months) {
        result += ' ' + duration._data.months + ' ay'
    }
    if (duration._data.days > 0 && days) {
        result += ' ' + duration._data.days + ' gün'
    }
    if (duration._data.hours > 0 && hours) {
        result += ' ' + duration._data.hours + ' saat'
    }
    if (duration._data.minutes > 0 && minutes) {
        result += ' ' + duration._data.minutes + ' dakika'
    }
    if (duration._data.seconds > 0 && seconds) {
        result += ' ' + duration._data.seconds + ' saniye'
    }
    if (duration._data.milliseconds > 0 && milliseconds) {
        result += ' ' + duration._data.milliseconds + ' milisaniye'
    }

    return result.trim()
}

// https://flaviocopes.com/how-to-list-object-methods-javascript/
const getObjectMethods = (obj) => {
    let properties = new Set()
    let currentObj = obj
    do {
        Object.getOwnPropertyNames(currentObj).map((item) => properties.add(item))
    } while ((currentObj = Object.getPrototypeOf(currentObj)))
    return [...properties.keys()]
        .filter((value) => !['caller', 'callee', 'arguments'].includes(value)) // For strict mode
        .filter((item) => typeof obj[item] === 'function')
}

function limitContentAndShowModal(text, limit) {
    if (text === null) {
        return ''
    }
    return text.toString().substring(0, limit)
}

function number_format(data, format = 'number', format_options, locale = 'tr-TR') {
    if (format === 'number' || format === 'money') {
        // Örnek : number {maximumSignificantDigits: 3}
        // Örnek : money {style: 'currency', currency: 'TRY'}
        return new Intl.NumberFormat(locale, format_options).format(data)
    }
    return data
}

function downloadableData({ data, filename, format = 'xlsx' }) {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename ? filename + '___' + moment().format('YYYY-MM-DD___HH-mm-ss') : moment().format('YYYY-MM-DD___HH-mm-ss') + '.xls')
    document.body.appendChild(link)
    link.click()
}

function getSetting(settings, key) {
    const found = settings.find((item) => item.key === key)
    if (found) {
        return settings.value
    }
    return null
}
export {
    getSetting,
    countryInfo,
    unitsLongName,
    spApiAuth,
    mergeDeep,
    convertObjectToQueryString,
    convertQueryStringToObject,
    dateDiff,
    strLimit,
    printDateTime,
    printDuration,
    getObjectMethods,
    limitContentAndShowModal,
    number_format,
    downloadableData,
}

export default {
    install: (app) => {
        app.config.globalProperties.GeneralHelper = {
            unitsLongName,
            convertObjectToQueryString,
            mergeDeep,
            dateDiff,
            printDateTime,
            getObjectMethods,
            convertQueryStringToObject,
            printDuration,
            limitContentAndShowModal,
            number_format,
            downloadableData,
            strLimit,
        }
    },
}
