<template>
    <div id="AddtoFollowingListModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold" id="exampleModalLabel">{{ $t('cards.product_search.add_to_following_list') }}</h5>
                    <button type="button" style="margin-right: 5px" class="btn-close" @click="hideModal"></button>
                </div>
                <div class="modal-body">
                    <div v-if="scanningType === 'wholesale'" class="alert alert-soft-primary" role="alert">{{ $t('cards.following_list.modal_description') }}</div>
                    <div v-if="followingList && followingList.data && !create">
                        <div v-for="fList in followingList.data" style="cursor: pointer; display: block; padding-bottom: 5px; margin-bottom: 10px; border-bottom: 1px solid #eff3f9" :key="fList.id">
                            <input ref="followingChecks" @change="handleSelectFollowingList" type="checkbox" class="check" :id="`market_${fList.id}`" :value="fList.id" />
                            <label :for="`market_${fList.id}`" style="cursor: pointer">
                                <span style="margin-left: 10px; font-weight: 500; font-size: 14px">{{ fList.name }}</span></label
                            >
                        </div>
                    </div>
                    <div v-if="create || (!create && followingList && followingList.data.length === 0)">
                        <div class="filter-item-col">
                            <label for="name">Liste adı</label>
                            <input v-model="form.name" class="form-control" placeholder="Liste Adı" />
                        </div>
                        <div class="mb-3">
                            <label for="min_stock" class="align-self-center">
                                <span class="flex-row">
                                    <div>{{ $t('inputs.following_list.labels.type') }}</div>
                                </span>
                                <small class="text-muted"></small>
                            </label>
                            <select id="type" v-model="form.type" class="form-select">
                                <option value="manual">{{ $t('common.manual') }}</option>
                                <option value="daily">{{ $t('common.automatic') }}</option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="form.type === 'daily'">
                            <label for="min_stock" class="align-self-center">
                                <span class="flex-row">
                                    <div>{{ $t('inputs.following_list.labels.frequency') }}</div>
                                </span>
                                <small class="text-muted"></small>
                            </label>
                            <select v-model="form.selectedFrequency" class="form-select">
                                <option v-for="list in frequencyList" :key="list.value" :value="list.value">{{ list.label }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="!create" class="btn btn-secondary btn-sm" @click="create = true">{{ $t('actions.create_new') }}</button>
                    <button v-if="create" class="btn btn-secondary btn-sm" @click="create = false">{{ $t('actions.list_currents') }}</button>
                    <button class="btn btn-primary btn-sm" @click="handleAddToFollowing">{{ $t('actions.save') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'AddtoFollowingListModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        scanningType: {
            type: String,
            default: null,
        },
    },
    components: {},
    data: () => ({
        form_submitted: false,
        modalInstance: null,
        create: false,
        selectedFollowingList: [],
        frequencyList: [],
        form: {
            selectedFrequency: 6,
            type: 'manual',
            name: '',
            notify_discount: false,
            notify_out_of_stock: false,
            notify_out_of_stock_count: 0,
        },
    }),
    mounted() {
        this.frequencyList = [
            {
                value: 6,
                label: this.$t('common.hourly', { count: 6 }),
            },
            {
                value: 12,
                label: this.$t('common.hourly', { count: 12 }),
            },
            {
                value: 24,
                label: this.$t('common.hourly', { count: 24 }),
            },
            {
                value: 36,
                label: this.$t('common.hourly', { count: 36 }),
            },
            {
                value: 48,
                label: this.$t('common.hourly', { count: 48 }),
            },
            {
                value: 72,
                label: this.$t('common.hourly', { count: 72 }),
            },
        ]
        this.refresh()
    },
    computed: {
        ...mapGetters('followingList', ['followingList']),
    },
    watch: {
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.modalActive()
            }
        },
    },
    methods: {
        ...mapActions('followingList', ['getFollowingList']),
        ...mapActions('productSearch', ['addToFollowingList']),
        refresh() {
            this.name = ''
            this.notify_discount = false
            this.notify_out_of_stock = false
            this.notify_out_of_stock_count = 0
            this.type = 'manual'
            this.selectedFrequency = 6
            this.getFollowingList()
        },
        handleSelectFollowingList: function (e) {
            const { value, checked } = e.target
            if (checked) {
                this.selectedFollowingList.push(value)
            } else {
                const index = this.selectedFollowingList.indexOf(value)
                if (index > -1) {
                    this.selectedFollowingList.splice(index, 1)
                }
            }
        },
        async handleAddToFollowing() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                const response = await this.addToFollowingList({
                    followingLists: this.selectedFollowingList,
                    items: this.selectedItems,
                    scanningType: this.scanningType,
                    form: {
                        ...this.form,
                        frequency: this.form.selectedFrequency,
                    },
                })
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                if (response.success) {
                    this.$toast.success(this.$t('inputs.following_list.success.create'))
                    this.refresh()
                    this.hideModal()
                } else {
                    this.$toast.success(this.$t('inputs.following_list.errors.already_exists'))
                }
            } catch (e) {
                this.$toast.success(this.$t('toast.error_accured'))
            }
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('AddtoFollowingListModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
            this.refresh()
        },
    },
}
</script>
