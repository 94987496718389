<template>
    <div class="container py-5 py-sm-7">
        <div class="mx-auto" style="max-width: 50rem">
            <PaymentMethods payment subscription />

            <PackageList />

            <SubscriptionPaymentSummary :redirectTo="'setup'" />
        </div>
    </div>
</template>

<script>
import PackageList from '@/components/Packages/PackageList.vue'
import PaymentMethods from '@/components/Subscription/PaymentMethods.vue'
import SubscriptionPaymentSummary from '@/components/Subscription/SubscriptionPaymentSummary.vue'

export default {
    name: 'Packages',
    data: () => ({
        token: null,
        frameLoaded: false,
    }),
    methods: {
        login() {},
    },
    created() {
        // this.$store.commit('set', { k: 'loading', v: { status: true } })
    },
    mounted() {},
    computed: {},
    components: { PackageList, SubscriptionPaymentSummary, PaymentMethods },
}
</script>
