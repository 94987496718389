import WalletService from '@/services/wallet'
import { useToast } from 'vue-toast-notification'
import i18n from '../i18n'

const $toast = useToast()
const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}

const state = {
    walletPackage: null,
    summary: null,
    requestLimit: null,
    requestUsed: null,
    currentBalance: null,
    previewPackageRenew: null,
    savedCreditCards: [],
    subscriptionHistory: [],
    address: null,
    affiliateCode: null,
    coupons: [],
}

const getters = {
    requestLimit: (state) => state.requestLimit,
    subscriptionHistory: (state) => state.subscriptionHistory,
    summary: (state) => state.summary,
    requestUsed: (state) => state.requestUsed,
    walletPackage: (state) => state.walletPackage,
    previewPackageRenew: (state) => state.previewPackageRenew,
    savedCreditCards: (state) => state.savedCreditCards,
    address: (state) => state.address,
    currentBalance: (state) => state.currentBalance,
    affiliateCode: (state) => state.affiliateCode,
    coupons: (state) => state.coupons,
}

const mutations = {
    SET_SUMMARY(state, payload) {
        state.summary = payload
    },
    SET_REQUEST_LIMIT(state, payload) {
        state.requestLimit = payload
    },
    SET_REQUEST_USED(state, payload) {
        state.requestUsed = payload
    },
    SET_WALLET_PACKAGE(state, payload) {
        state.walletPackage = payload
    },
    SET_PREVIEW_PACKAGE_RENEW(state, payload) {
        state.previewPackageRenew = payload
    },
    SET_SAVED_CREDIT_CARDS(state, payload) {
        state.savedCreditCards = payload
    },
    SET_ADDRESS(state, payload) {
        state.address = payload
    },
    SET_CURRENT_BALANCE(state, payload) {
        state.currentBalance = payload
    },
    SET_AFFILIATE_CODE(state, payload) {
        state.affiliateCode = payload
    },
    SET_COUPONS(state, payload) {
        state.coupons = payload
    },
    SET_SUBSCRIPTION_HISTORY(state, payload) {
        state.subscriptionHistory = payload
    },
}

const actions = {
    getBalanceSummary({ commit }) {
        return new Promise((resolve, reject) => {
            WalletService.getBalanceSummary()
                .then((response) => {
                    const balance = response.data
                    if (balance) {
                        commit('SET_SUMMARY', balance)
                        commit('SET_REQUEST_LIMIT', balance.remainingToken)
                        commit('SET_REQUEST_USED', Math.abs(balance.usedToken))
                        commit('SET_CURRENT_BALANCE', balance.remainingToken)
                    }
                    resolve(balance)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getSubscriptionHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.getSubscriptionHistory(payload)
                .then((response) => {
                    commit('SET_SUBSCRIPTION_HISTORY', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAddress({ commit }) {
        return new Promise((resolve, reject) => {
            WalletService.getAddress()
                .then((response) => {
                    commit('SET_ADDRESS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createAddress({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.createAddress(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateAddress({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.updateAddress(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getUserPackage({ commit }) {
        return new Promise((resolve, reject) => {
            WalletService.getUserPackage({ detailed: true })
                .then((response) => {
                    const userPackages = response.data.filter((p) => p.product.id === 9)
                    commit('SET_WALLET_PACKAGE', userPackages.length > 0 ? userPackages[0] : null)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getPreviewPackageRenew({ commit }) {
        return new Promise((resolve, reject) => {
            WalletService.getPreviewPackageRenew()
                .then((response) => {
                    const packagePreview = response.data
                    if (packagePreview.subscriptions.filter((s) => s.product_id === 9).length > 0) {
                        commit('SET_PREVIEW_PACKAGE_RENEW', packagePreview)
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    saveCreditCard({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.saveCreditCard(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    confirmCard({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.confirmCard(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setAsDefault({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.setAsDefault(payload)
                .then((response) => {
                    if (!payload.noToast) {
                        $toast.success(i18n.global.t('store.setAsDefault.success'), toastOptions)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.setAsDefault.error')
                    if (!payload.noToast) {
                        $toast.error(message, toastOptions)
                    }
                    reject(error)
                })
        })
    },
    removeCard({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.removeCard(payload)
                .then((response) => {
                    if (!response.data.deleted) {
                        $toast.error(i18n.global.t('store.removeCard.successError'), toastOptions)
                    } else {
                        $toast.success(i18n.global.t('store.removeCard.success'), toastOptions)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.removeCard.error')
                    $toast.error(message, toastOptions)
                    reject(error)
                })
        })
    },
    getCreditCards({ commit }) {
        return new Promise((resolve, reject) => {
            WalletService.getCreditCards()
                .then((response) => {
                    commit('SET_SAVED_CREDIT_CARDS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    chargeWithDifferentCard({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.chargeWithDifferentCard(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.paymentFailure')
                    reject(message)
                })
        })
    },
    chargeWithSavedCard({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.chargeWithSavedCard(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.paymentFailure')
                    reject(message)
                })
        })
    },
    createSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.createSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBeCreated')
                    reject(message)
                })
        })
    },
    changeSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.changeSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBeChanged')
                    reject(message)
                })
        })
    },
    cancelSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.cancelSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBeCanceled')
                    reject(message)
                })
        })
    },
    pauseSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.pauseSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBePaused')
                    reject(message)
                })
        })
    },
    unpauseSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.unpauseSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBeUnpaused')
                    reject(message)
                })
        })
    },
    dontCancelSubscription({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.dontCancelSubscription(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    const message = error.response && error.response.data ? error.response.data.message : i18n.global.t('store.subscriptionCouldNotBeCanceled')
                    reject(message)
                })
        })
    },
    getCode({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.getCode()
                .then((response) => {
                    commit('SET_AFFILIATE_CODE', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCoupons({ commit }, payload) {
        return new Promise((resolve, reject) => {
            WalletService.couponList()
                .then((response) => {
                    commit('SET_COUPONS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
