import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HelperGeneral from '@/utils/helper'

import axios from 'axios'
import VueAxios from 'vue-axios'

import i18n from './i18n'

// ----------------------------------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------------------------------
import { Money3Component } from 'v-money3'
import { vue3Debounce } from 'vue-debounce'
import { plugin, defaultConfig } from '@formkit/vue'
import Popper from 'vue3-popper'

import config from '@/formkit.config.js'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@formkit/themes/genesis'
import SimpleTypeahead from 'vue3-simple-typeahead'

// ----------------------------------------------------------------------------------------------------
// Font Awesome
// ----------------------------------------------------------------------------------------------------
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import ToastPlugin from 'vue-toast-notification'
import 'vue-multiselect/dist/vue-multiselect.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import StarRating from '@/components/Star/Rating.vue'
import ExchangablePrice from '@/components/Variations/ExchangablePrice.vue'
import CalculatedPrices from '@/components/Variations/CalculatedPrices.vue'
import Dialog from '@/components/Variations/Dialog.vue'

import mitt from 'mitt'

library.add(fas)
library.add(fab)
library.add(far)
dom.watch()

// ----------------------------------------------------------------------------------------------------

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0)
    next()
})

const app = createApp(App)

const emitter = mitt()
app.config.globalProperties.emitter = emitter
// directives
app.directive(
    'debounce',
    vue3Debounce({
        lock: true,
        listenTo: 'keyup',
        defaultTime: '800ms',
        fireOnEmpty: false,
        trim: false,
    }),
)
// components

app.component('Popper', Popper)
app.component('Dialog', Dialog)
app.component('ExchangablePrice', ExchangablePrice)
app.component('CalculatedPrices', CalculatedPrices)
app.component('StarRating', StarRating)
app.component('money3', Money3Component)
app.component(require('vue-moment'))
app.component('font-awesome-icon', FontAwesomeIcon)
// plugins
app.use(i18n)
app.use(HelperGeneral)
app.use(VueAxios, axios)
app.use(ToastPlugin, {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
})
app.use(SimpleTypeahead)
app.use(plugin, defaultConfig(config))
app.use(router)
app.use(store)
app.mount('#app')
store.dispatch('auth/autoLogin')
store.dispatch('market/autoRetrieve')
