<template>
    <span class="badge" :class="'bg' + (soft ? '-soft' : '') + (pill ? '-pill' : '') + '-' + style">{{ text }}</span>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        text: {
            type: String,
            required: true,
        },
        style: {
            type: String,
            default: 'primary' /* primary, secondary, success, danger, warning, info, light, dark */,
        },
        soft: {
            type: Boolean,
            default: false,
        },
        pill: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
