const scanningOptionInputs = [
    {
        name: 'fba_warehouse',
        inputs: [
            {
                name: 'fba_shipping_per_pound',
                label: 'Kargo ücreti birim ağırlık',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ {weight_unit}',
                helpText: true,
            },
            {
                name: 'fba_labeling_cost',
                label: 'Etiketleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fba_pouching_wrapping_cost',
                label: 'Poşetleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fba_bubble_wrapping_cost',
                label: 'Balonlu Paketleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fba_product_acceptance_cost',
                label: 'Ürün Kabul Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fba_min_prep_service_cost',
                label: 'Min. Prep Service Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fba_custom_rate',
                label: 'Gümrük Vergisi',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },
    {
        name: 'fbm_warehouse',
        inputs: [
            {
                name: 'fbm_shipping_per_pound',
                label: 'Kargo ücreti birim ağırlık',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ {weight_unit}',
                helpText: true,
            },
            {
                name: 'fbm_labeling_cost',
                label: 'Etiketleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fbm_pouching_wrapping_cost',
                label: 'Poşetleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fbm_bubble_wrapping_cost',
                label: 'Balonlu Paketleme Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fbm_product_acceptance_cost',
                label: 'Ürün Kabul Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fbm_min_prep_service_cost',
                label: 'Min. Prep Service Ücreti',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
            {
                name: 'fbm_custom_rate',
                label: 'Gümrük Vergisi',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },

    {
        name: 'sales_fees',
        inputs: [
            {
                name: 'referal_fee',
                label: 'Referral Fee',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },

            {
                name: 'fba_fee',
                label: 'FBA Komisyonu',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },

            {
                name: 'storage_fee',
                label: 'Storage Fee',
                type: 'number',
                prefix: '{currency}',
                suffix: '/ item',
                helpText: true,
            },
        ],
    },
    {
        name: 'taxes',
        inputs: [
            {
                name: 'vat',
                label: 'VAT / GST',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
            {
                name: 'referal_fee_tax',
                label: 'Referral Fee Vergisi',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
            {
                name: 'fba_fee_tax',
                label: 'FBA Komisyonu Vergisi',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },
    {
        name: 'costs',
        inputs: [
            {
                name: 'bank_transfer_fee',
                label: 'Bank Transfer Fee',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
            {
                name: 'estimated_refund_amount',
                label: 'Öngörülen iade oranı',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },

    {
        name: 'calculation_settings',
        inputs: [
            {
                name: 'calc_min_price',
                label: 'Min Fiyat',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
            {
                name: 'calc_max_price',
                label: 'Max Fiyat',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },
    {
        name: 'fulfillment',
        inputs: [
            {
                name: 'state_sales_tax',
                label: 'Eyalet Satış Vergisi',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
            {
                name: 'cashback',
                label: 'Cashback',
                type: 'number',
                prefix: null,
                suffix: '%',
                helpText: true,
            },
        ],
    },
]
export { scanningOptionInputs }
