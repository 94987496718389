<template>
    <div class="container-fluid pt-3">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="$t('cards.following_list.detail')" />

                <Card>
                    <div class="option-bar d-flex justify-content-between w-100 mb-3" v-if="meta">
                        <div class="option-wrapper">
                            <div class="row">
                                <div class="col">
                                    <div class="result-count">
                                        <span class="count">{{ meta.total }}</span>
                                        <small>{{ $t('list.result') }}</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <input v-model="searchText" class="form-control input-sm" type="text" @input="searchInput" v-debounce:300ms="handleSearchInput" :placeholder="$t('list.placeholder')" />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <select v-model="requests.perPage" @change="changePerPage" class="form-select">
                                        <option value="10">10 {{ $t('list.showing') }}</option>
                                        <option value="25">25 {{ $t('list.showing') }}</option>
                                        <option value="50" selected>50 {{ $t('list.showing') }}</option>
                                        <option value="100">100 {{ $t('list.showing') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="requests.page" @change="changeCurrentPage" class="form-select" v-if="meta.last_page">
                                        <option v-if="[...Array(meta.last_page).keys()].length === 0" value="">{{ $t('list.no_result') }}</option>
                                        <option :key="page" v-for="page of [...Array(meta.last_page).keys()]" :value="page + 1">{{ page + 1 }}. {{ $t('list.page') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="action-wrapper">
                            <div class="d-flex flex-row" style="width: 25rem; justify-content: flex-end">
                                <a href="#" @click.prevent="showExcelExportModal = true" data-tooltip="Excel'e Aktar" class="btn mr-1 btn-sm btn-white"><i class="fa-solid fa-file-excel"></i></a>

                                <button v-if="followingList.type === 'manual'" :disabled="!data || (data && data.length === 0)" @click="handleRefreshData" data-tooltip="Yenile" class="btn btn-white btn-sm mr-1">
                                    <i class="fa-solid fa-refresh"></i>
                                </button>
                                <button id="dropdownMenuClickableInside" class="dropdown btn btn-white btn-sm d-flex align-items-center mr-1" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style="min-height: 37.5px">
                                    <i class="fa-solid fa-columns"></i>
                                </button>
                                <div
                                    class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                                    aria-labelledby="dropdownMenuClickableInside"
                                    data-popper-placement="top-end"
                                    style="position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(-16px, -249px, 0px); overflow: auto; height: 300px; right: 0"
                                >
                                    <template :key="column" v-for="column in Object.keys(columns)">
                                        <li style="padding-bottom: 10px">
                                            <input class="input form-check-input" style="height: 15px !important; width: 15px !important; vertical-align: middle" type="checkbox" :id="'li_' + column" v-model="columns[column]" /><label
                                                :for="'li_' + column"
                                                style="margin-left: 5px; cursor: pointer; vertical-align: middle"
                                                >{{ $t('list.product_search.columns.' + column) }}</label
                                            >
                                        </li>
                                    </template>
                                </div>
                                <button :disabled="selectedList.length === 0" class="dropdown btn btn-secondary btn-sm d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="true" style="min-height: 37.5px">
                                    <span>{{ $t('common.actions') }}</span>
                                    <span style="margin-left: 2px; font-weight: 600" v-if="selectedList.length > 0">({{ selectedList.length }})</span>
                                </button>
                                <div
                                    class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                                    aria-labelledby="accountNavbarDropdown"
                                    data-popper-placement="top-end"
                                    style="position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(-16px, -249px, 0px)"
                                >
                                    <a class="dropdown-item" href="#" @click.prevent="isShowMoveToModal = true">{{ $t('actions.move_to_another_list') }}</a>
                                    <a class="dropdown-item" href="#" @click.prevent="showDialog = true">{{ $t('actions.remove_from_list') }}</a>
                                    <a class="dropdown-item" href="#" @click.prevent="handleCreatePrepRequest">{{ $t('actions.create_package') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="selected-filters d-flex w-100 mb-3" style="padding-left: 2rem" v-if="this.requests && this.requests.filtration && this.requests.filtration.length > 0">
                        <span>Filtrelenmiş</span>
                        <div style="font-size: 16px; padding-left: 1rem">
                            <span class="badge bg-soft-primary text-primary mr-05" v-for="filtration in this.requests.filtration" :key="filtration.key">
                                <a href="#" @click.prevent="handleRemoveFilteration(filtration.key)">
                                    <i class="fa-solid fa-times"></i>
                                </a>
                                {{ getFilterName(filtration.key) }}: {{ getFilterValue(filtration.value) }}</span
                            >
                        </div>
                    </div>
                    <div class="col-12 relative" v-if="data">
                        <div class="table-container">
                            <table class="table table-sticky">
                                <thead class="thead-light">
                                    <tr>
                                        <th colspan="3" class="sticky-left"></th>
                                        <th colspan="3"></th>
                                        <th v-if="getColumnsCount(['title', 'price']) > 0" :colspan="getColumnsCount(['title', 'price'])"></th>
                                        <th v-if="getColumnsCount(['product_cost', 'warehouse_cost', 'total_cost']) > 0" :colspan="getColumnsCount(['product_cost', 'warehouse_cost', 'total_cost'])" class="align-middle text-center column-border">
                                            {{ $t('list.product_search.groups.costs') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fba__profit', 'fba__profit_rate', 'fba__profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fba__profit', 'fba__profit_rate', 'fba__profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fba_gross_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fba__net_profit', 'fba__roi', 'fba__net_profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fba__net_profit', 'fba__roi', 'fba__net_profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fba_net_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fbm__profit', 'fbm__profit_rate', 'fbm__profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fbm__profit', 'fbm__profit_rate', 'fbm__profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fbm_gross_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fbm__net_profit', 'fbm__roi', 'fbm__net_profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fbm__net_profit', 'fbm__roi', 'fbm__net_profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fbm_net_profit') }}
                                        </th>
                                        <th colspan="7"></th>
                                        <th colspan="2" class="align-middle text-center column-border">
                                            {{ $t('list.product_search.groups.seller_count') }}
                                        </th>
                                        <th colspan="2" class="align-middle text-center column-border">
                                            {{ $t('list.product_search.groups.buybox') }}
                                        </th>
                                        <th colspan="5" class="align-middle text-center column-border">
                                            {{ $t('list.product_search.groups.dimensions') }}
                                        </th>
                                        <th colspan="2" class="align-middle text-center column-border">
                                            {{ $t('list.product_search.groups.estimated_amount') }}
                                        </th>
                                        <th colspan="2"></th>
                                    </tr>
                                    <tr>
                                        <th class="align-middle sticky-left">
                                            <input class="form-check-input" @change="handleCheckboxChange" type="checkbox" />
                                        </th>
                                        <th class="sticky-left" style="left: 47.5px !important" scope="col">
                                            {{ $t('list.product_search.columns.image') }}
                                        </th>
                                        <th class="sticky-left" style="left: 118px !important" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" type="text" data-column="srp.asin" id="col_asin" autofocus :placeholder="$t('list.product_search.columns.asin')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('asin')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.asin') }}
                                        </th>
                                        <th class="" scope="col">
                                            {{ $t('list.product_search.columns.source') }}
                                        </th>
                                        <th class="" scope="col">
                                            {{ $t('list.product_search.columns.notify_on_discount') }}
                                        </th>
                                        <th class="" scope="col">
                                            {{ $t('list.product_search.columns.notify_on_stock') }}
                                        </th>
                                        <th v-if="columns.title" class="position-relative fixed-width-column" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" type="text" data-column="details.title" id="col_title" autofocus :placeholder="$t('list.product_search.columns.title')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('title')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.title') }}
                                        </th>
                                        <th v-if="columns.price" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_price_min" placeholder="Min" />
                                                        <input class="form-control" id="col_price_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('price', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'price',
                                                        event: $event,
                                                        sort: {
                                                            column: 'srp.price',
                                                            dir: columnSorting.price ? columnSorting.price.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.price') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.price && columnSorting.price.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.product_cost" class="position-relative text-center" style="border-left: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_product_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_product_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('product_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'product_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'product_cost',
                                                            dir: columnSorting.product_cost ? columnSorting.product_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.product_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.product_cost && columnSorting.product_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.warehouse_cost" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_warehouse_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_warehouse_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('warehouse_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'warehouse_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'warehouse_cost',
                                                            dir: columnSorting.warehouse_cost ? columnSorting.warehouse_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.warehouse_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.warehouse_cost && columnSorting.warehouse_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.total_cost" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_total_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_total_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('total_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'total_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'total_cost',
                                                            dir: columnSorting.total_cost ? columnSorting.total_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.total_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.total_cost && columnSorting.total_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit',
                                                            dir: columnSorting.profit ? columnSorting.profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit && columnSorting.profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit_rate',
                                                            dir: columnSorting.profit_rate ? columnSorting.profit_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit_rate && columnSorting.profit_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit_margin',
                                                            dir: columnSorting.profit_margin ? columnSorting.profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit_margin && columnSorting.profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__net_profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_net_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_net_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('net_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'net_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'net_profit',
                                                            dir: columnSorting.net_profit ? columnSorting.net_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.net_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.net_profit && columnSorting.net_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__roi" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_roi_min" placeholder="Min" />
                                                        <input class="form-control" id="col_roi_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('roi', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'roi',
                                                        event: $event,
                                                        sort: {
                                                            column: 'roi',
                                                            dir: columnSorting.roi ? columnSorting.roi.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.roi') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.roi && columnSorting.roi.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__net_profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_net_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_net_profit_margin_min" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('net_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'net_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'net_profit_margin',
                                                            dir: columnSorting.net_profit_margin ? columnSorting.net_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.net_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.net_profit_margin && columnSorting.net_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <!-- FBM Profits-->
                                        <th v-if="columns.fbm__profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit',
                                                            dir: columnSorting.fbm_profit ? columnSorting.fbm_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit && columnSorting.fbm_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__profit_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit_rate',
                                                            dir: columnSorting.fbm_profit_rate ? columnSorting.fbm_profit_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit_rate && columnSorting.fbm_profit_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit_margin',
                                                            dir: columnSorting.fbm_profit_margin ? columnSorting.fbm_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit_margin && columnSorting.fbm_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__net_profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_net_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_net_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_net_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_net_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_net_profit',
                                                            dir: columnSorting.fbm_net_profit ? columnSorting.fbm_net_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_net_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_net_profit && columnSorting.fbm_net_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__roi" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_roi_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_roi_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_roi', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_roi',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_roi',
                                                            dir: columnSorting.fbm_roi ? columnSorting.fbm_roi.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_roi') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_roi && columnSorting.fbm_roi.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__net_profit_margin" class="position-relative text-center column-border-right" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_net_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_net_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_net_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_net_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_net_profit_margin',
                                                            dir: columnSorting.fbm_roi ? columnSorting.fbm_net_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_net_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_net_profit_margin && columnSorting.fbm_net_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.price_diff_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_price_diff_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_price_diff_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('price_diff_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'price_diff_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'price_diff_rate',
                                                            dir: columnSorting.price_diff_rate ? columnSorting.price_diff_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.price_diff_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.price_diff_rate && columnSorting.price_diff_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.variations" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_variations_min" placeholder="Min" />
                                                        <input class="form-control" id="col_variations_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('variations', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'variations',
                                                        event: $event,
                                                        sort: {
                                                            column: 'variations',
                                                            dir: columnSorting.variations ? columnSorting.variations.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.variations') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.variations && columnSorting.variations.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.bestsellers_rank" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_bestsellers_rank_min" placeholder="Min" />
                                                        <input class="form-control" id="col_bestsellers_rank_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('bestsellers_rank', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'bestsellers_rank',
                                                        event: $event,
                                                        sort: {
                                                            column: 'bestsellers_rank',
                                                            dir: columnSorting.bestsellers_rank ? columnSorting.bestsellers_rank.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.bestsellers_rank') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.bestsellers_rank && columnSorting.bestsellers_rank.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.category" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_category" type="text" data-column="details.category" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('category')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.category') }}
                                        </th>
                                        <th v-if="columns.main_category" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_main_category" data-column="details.main_category" type="text" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('main_category')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.main_category') }}
                                        </th>
                                        <th v-if="columns.brand" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_brand" type="text" data-column="details.brand" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('brand')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.brand') }}
                                        </th>
                                        <th v-if="columns.is_amazon_seller" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.is_amazon_seller') }}
                                        </th>
                                        <th v-if="columns.fba_sellers_count" class="position-relative text-center column-border-left" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fba_sellers_count_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fba_sellers_count_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fba_sellers_count', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fba_sellers_count',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fba_sellers_count',
                                                            dir: columnSorting.fba_sellers_count ? columnSorting.fba_sellers_count.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fba_sellers_count') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fba_sellers_count && columnSorting.fba_sellers_count.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm_sellers_count" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_sellers_count_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_sellers_count_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_sellers_count', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_sellers_count',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_sellers_count',
                                                            dir: columnSorting.fbm_sellers_count ? columnSorting.fbm_sellers_count.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_sellers_count') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_sellers_count && columnSorting.fbm_sellers_count.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.buybox_status" class="position-relative text-center column-border-left" scope="col">
                                            {{ $t('list.product_search.columns.buybox_status') }}
                                        </th>
                                        <th v-if="columns.buybox_seller" class="position-relative text-center column-border-right" scope="col">
                                            {{ $t('list.product_search.columns.buybox_seller') }}
                                        </th>
                                        <th v-if="columns.width" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.width') }}
                                        </th>
                                        <th v-if="columns.height" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.height') }}
                                        </th>
                                        <th v-if="columns.length" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.length') }}
                                        </th>
                                        <th v-if="columns.weight" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.weight') }}
                                        </th>
                                        <th v-if="columns.volume" class="position-relative text-center column-border-right" scope="col">
                                            {{ $t('list.product_search.columns.volume') }}
                                        </th>
                                        <th v-if="columns.max_item_on_package_weight" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_max_item_on_package_weight_min" placeholder="Min" />
                                                        <input class="form-control" id="col_max_item_on_package_weight_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('max_item_on_package_weight', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'max_item_on_package_weight',
                                                        event: $event,
                                                        sort: {
                                                            column: 'max_item_on_package_weight',
                                                            dir: columnSorting.max_item_on_package_weight ? columnSorting.max_item_on_package_weight.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.max_item_on_package_weight') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.max_item_on_package_weight && columnSorting.max_item_on_package_weight.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.max_item_on_package_dim" class="position-relative text-center column-border-right" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_max_item_on_package_dim_min" placeholder="Min" />
                                                        <input class="form-control" id="col_max_item_on_package_dim_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('max_item_on_package_dim', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'max_item_on_package_dim',
                                                        event: $event,
                                                        sort: {
                                                            column: 'max_item_on_package_dim',
                                                            dir: columnSorting.max_item_on_package_dim ? columnSorting.max_item_on_package_dim.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.max_item_on_package_dim') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.max_item_on_package_dim && columnSorting.max_item_on_package_dim.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.first_available" class="position-relative text-center" scope="col">
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'first_available',
                                                        event: $event,
                                                        sort: {
                                                            column: 'first_available',
                                                            dir: columnSorting.first_available ? columnSorting.first_available.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.first_available') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.first_available && columnSorting.first_available.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.search_other_stores" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.search_other_stores') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr class="bgwhite" v-for="item in data" :key="item.id">
                                        <td class="align-middle sticky-left">
                                            <input ref="checkbox" :checked="selectedList.indexOf(item.id) > -1" @change="handleCheckboxSingleChange" :value="item.id" class="form-check-input" type="checkbox" />
                                        </td>
                                        <td class="align-middle sticky-left" style="left: 47.5px !important" scope="row">
                                            <span v-if="item.image" class="avatar avatar-sm">
                                                <a href="#" @click.prevent="openModal(item.id)"> <img :src="item.image" class="avatar-img" /></a>
                                            </span>
                                            <span v-else>-</span>
                                        </td>
                                        <td class="align-middle sticky-left fw-bold" style="left: 118px !important" scope="row">
                                            <a :href="item.targetLink" target="_blank">{{ item.asin }}</a>
                                        </td>
                                        <td class="align-middle fw-bold text-center" scope="row">
                                            <img @click.prevent="goToSource(item.sourceLink)" :src="getImage(item.source + '.svg')" class="img-fluid" style="max-width: 20px; max-height: 20px; cursor: pointer" />
                                        </td>
                                        <td class="align-middle fw-bold text-center" scope="row">
                                            <div class="input-group" style="z-index: 0">
                                                <input type="number" class="form-control input-sm" v-model="item.notify_on_discount" :placeholder="$t('common.price_with_currency', { currency: countryData[item.source].symbol })" />
                                                <button @click="handleNotifyOnDiscountChange(item)" class="btn btn-soft-primary btn-sm">
                                                    <font-awesome-icon :icon="['fas', 'check']" />
                                                </button>
                                            </div>
                                        </td>
                                        <td class="align-middle fw-bold text-center" scope="row">
                                            <div class="input-group" style="z-index: 0">
                                                <input type="number" class="form-control input-sm" v-model="item.notify_on_stock" :placeholder="$t('common.amount')" />
                                                <button @click="handleNotifyOnStockChange(item)" class="btn btn-soft-primary btn-sm">
                                                    <font-awesome-icon :icon="['fas', 'check']" />
                                                </button>
                                            </div>
                                        </td>
                                        <td v-if="columns.title" class="align-middle fw-bold fixed-width-column two-line-text" scope="row">
                                            <div class="input-group" style="z-index: 0">
                                                <a style="width: 120px" v-if="item.title" target="_blank" :href="item.link">
                                                    {{ strLimit(item.title, 35) }}
                                                </a>
                                                <span v-else>-</span>
                                                <i
                                                    @click.prevent="handleShowNoteModal(item)"
                                                    style="position: absolute; color: orange; font-size: 25px; vertical-align: middle; margin-top: 10px; right: -16px; cursor: pointer"
                                                    class="bi bi-journal-plus"
                                                ></i>
                                            </div>
                                        </td>
                                        <td v-if="columns.price" class="align-middle fw-bold text-center" scope="row">
                                            <span v-if="!item.price">-</span>
                                            <ExchangablePrice
                                                v-else
                                                :oldValue="getOldValues(item) && getOldValues(item).price"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :price="item.price"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                        </td>
                                        <td v-if="columns.product_cost" class="align-middle text-center" scope="row">
                                            <span v-if="!item.product_cost">-</span>
                                            <CalculatedPrices
                                                v-else-if="item.product_cost_detail"
                                                :oldValue="getOldValues(item) && getOldValues(item).product_cost"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(item.product_cost_detail).map((pcd) => ({ key: pcd, value: item.product_cost_detail[pcd] }))"
                                                :price="item.product_cost"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                            <Popper v-if="item.type === 'wholesale'" placement="left" v-bind="$attrs" hover arrow :class="'popp'" style="position: absolute">
                                                <template #content>
                                                    <span>{{ $t('list.product_search.warnings.wholesale_warning') }}</span></template
                                                >
                                                <span class="text-danger" style="cursor: pointer; font-weight: 600"><i class="fas fa-exclamation-circle"></i></span>
                                            </Popper>
                                        </td>
                                        <td v-if="columns.warehouse_cost" class="align-middle text-center" scope="row">
                                            <span v-if="!item.warehouse_cost">-</span>
                                            <CalculatedPrices
                                                v-else-if="item.warehouse_cost_detail"
                                                :oldValue="getOldValues(item) && getOldValues(item).warehouse_cost"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(item.warehouse_cost_detail).map((pcd) => ({ key: pcd, value: item.warehouse_cost_detail[pcd] }))"
                                                :price="item.warehouse_cost"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                            <Popper v-if="item.type === 'wholesale'" placement="left" v-bind="$attrs" hover arrow :class="'popp'" style="position: absolute">
                                                <template #content>
                                                    <span>{{ $t('list.product_search.warnings.wholesale_warning') }}</span></template
                                                >
                                                <span class="text-danger" style="cursor: pointer; font-weight: 600"><i class="fas fa-exclamation-circle"></i></span>
                                            </Popper>
                                        </td>
                                        <td v-if="columns.total_cost" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.total_cost">-</span>
                                            <ExchangablePrice
                                                v-else
                                                :oldValue="getOldValues(item) && getOldValues(item).total_cost"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :price="item.total_cost"
                                                :to="item.target"
                                                :from="item.source"
                                            />

                                            <Popper v-if="item.type === 'wholesale'" placement="left" v-bind="$attrs" hover arrow :class="'popp'" style="position: absolute; top: 12px; right: 0">
                                                <template #content>
                                                    <span>{{ $t('list.product_search.warnings.wholesale_warning') }}</span></template
                                                >
                                                <span class="text-danger" style="cursor: pointer; font-weight: 600"><i class="fas fa-exclamation-circle"></i></span>
                                            </Popper>
                                        </td>
                                        <td v-if="columns.fba__profit" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.profit">-</span>
                                            <CalculatedPrices
                                                v-else-if="item.profit_detail"
                                                :oldValue="getOldValues(item) && getOldValues(item).profit"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(item.profit_detail).map((pcd) => ({ key: pcd, value: item.profit_detail[pcd] }))"
                                                :price="item.profit"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                        </td>
                                        <td v-if="columns.fba__profit_rate" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.profit_rate">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.profit_rate < 0,
                                                    'text-success': item.profit_rate > 0,
                                                }"
                                                >{{ item.profit_rate.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.profit_rate" :oldValue="getOldValues(item) && getOldValues(item) && getOldValues(item).profit_rate" />
                                        </td>
                                        <td v-if="columns.fba__profit_margin" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.profit_margin">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.profit_margin < 0,
                                                    'text-success': item.profit_margin > 0,
                                                }"
                                                >{{ item.profit_margin.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.profit_margin" :oldValue="getOldValues(item) && getOldValues(item).profit_margin" />
                                        </td>
                                        <td v-if="columns.fba__net_profit" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.net_profit">-</span>
                                            <CalculatedPrices
                                                v-else
                                                :oldValue="getOldValues(item) && getOldValues(item).net_profit"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(getNetProfit(item)).map((pcd) => ({ key: pcd, value: getNetProfit(item)[pcd] }))"
                                                :price="item.net_profit"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                        </td>
                                        <td v-if="columns.fba__roi" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.roi">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.roi < 0,
                                                    'text-success': item.roi > 0,
                                                }"
                                                >{{ item.roi.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.roi" :oldValue="getOldValues(item) && getOldValues(item).roi" />
                                        </td>
                                        <td v-if="columns.fba__net_profit_margin" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.net_profit_margin">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.net_profit_margin < 0,
                                                    'text-success': item.net_profit_margin > 0,
                                                }"
                                                >{{ item.net_profit_margin.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.net_profit_margin" :oldValue="getOldValues(item) && getOldValues(item).net_profit_margin" />
                                        </td>
                                        <!-- fbm-->
                                        <td v-if="columns.fbm__profit" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_profit">-</span>
                                            <CalculatedPrices
                                                v-else-if="item.fbm_profit_detail || item.fbm_profit"
                                                :oldValue="getOldValues(item) && getOldValues(item).fbm_profit"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(item.fbm_profit_detail).map((pcd) => ({ key: pcd, value: item.fbm_profit_detail[pcd] }))"
                                                :price="item.fbm_profit"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                        </td>
                                        <td v-if="columns.fbm__profit_rate" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_profit_rate">-</span>
                                            <strong
                                                :class="{
                                                    'text-danger': item.fbm_profit_rate < 0,
                                                    'text-success': item.fbm_profit_rate > 0,
                                                }"
                                                >{{ (+item.fbm_profit_rate).toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.fbm_profit_rate" :oldValue="getOldValues(item) && getOldValues(item).fbm_profit_rate" />
                                        </td>
                                        <td v-if="columns.fbm__profit_margin" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_profit_margin">-</span>
                                            <strong
                                                :class="{
                                                    'text-danger': item.fbm_profit_margin < 0,
                                                    'text-success': item.fbm_profit_margin > 0,
                                                }"
                                                >{{ (+item.fbm_profit_margin).toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.fbm_profit_margin" :oldValue="getOldValues(item) && getOldValues(item).fbm_profit_margin" />
                                        </td>
                                        <td v-if="columns.fbm__net_profit" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_net_profit">-</span>
                                            <CalculatedPrices
                                                v-else-if="item.fbm_net_profit"
                                                :oldValue="getOldValues(item) && getOldValues(item).fbm_net_profit"
                                                :rate="item.parameters ? item.parameters.exchange_rate : null"
                                                :items="Object.keys(getFbmNetProfit(item)).map((pcd) => ({ key: pcd, value: getFbmNetProfit(item)[pcd] }))"
                                                :price="item.fbm_net_profit"
                                                :to="item.target"
                                                :from="item.source"
                                            />
                                        </td>
                                        <td v-if="columns.fbm__roi" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_roi">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.fbm_roi < 0,
                                                    'text-success': item.fbm_roi > 0,
                                                }"
                                                >{{ item.fbm_roi.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.fbm_roi" :oldValue="getOldValues(item) && getOldValues(item).fbm_roi" />
                                        </td>
                                        <td v-if="columns.fbm__net_profit_margin" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.fbm_net_profit_margin">-</span>
                                            <strong
                                                v-else
                                                :class="{
                                                    'text-danger': item.fbm_net_profit_margin < 0,
                                                    'text-success': item.fbm_net_profit_margin > 0,
                                                }"
                                                >{{ item.fbm_net_profit_margin.toFixed(2) }}%</strong
                                            >
                                            <ComparePrices :value="item.fbm_net_profit_margin" :oldValue="getOldValues(item) && getOldValues(item).fbm_net_profit_margin" />
                                        </td>
                                        <td v-if="columns.price_diff_rate" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.price_diff_rate">-</span>
                                            <strong v-else>{{ item.price_diff_rate.toFixed(2) }}</strong>
                                            <ComparePrices :value="item.price_diff_rate" :oldValue="getOldValues(item) && getOldValues(item).price_diff_rate" />
                                        </td>
                                        <td v-if="columns.variations" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.variations">-</span>
                                            <strong v-else>{{ item.variations }}</strong>
                                        </td>
                                        <td v-if="columns.bestsellers_rank" class="align-middle text-center fw-bold position-relative" scope="row">
                                            <span v-if="!item.is_available || !item.bestsellers_rank || item.bestsellers_rank === '-'">-</span>
                                            <Popper v-else placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                <template #content> {{ item.bsr_category }} </template>
                                                <span class="text-warning" style="cursor: pointer; font-weight: 600">{{ item.bestsellers_rank }}</span>
                                            </Popper>
                                        </td>
                                        <td v-if="columns.category" class="align-middle text-center fw-bold" scope="row">
                                            <span v-if="!item.is_available || !item.category">-</span>
                                            <Popper v-else placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                <template #content> {{ item.categories_flat }} </template>
                                                <span class="text-warning" style="cursor: pointer; font-weight: 600">{{ item.category }}</span>
                                            </Popper>
                                        </td>
                                        <td v-if="columns.main_category" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.main_category">-</span>
                                            <strong v-else>{{ item.main_category }}</strong>
                                        </td>
                                        <td v-if="columns.brand" class="align-middle text-center" scope="row">
                                            <strong>{{ item.brand }}</strong>
                                        </td>
                                        <td v-if="columns.is_amazon_seller" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available">-</span>
                                            <strong v-else>{{ item.is_amazon_seller ? $t('actions.yes') : $t('actions.no') }}</strong>
                                        </td>
                                        <td v-if="columns.fba_sellers_count" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available">-</span>
                                            <strong v-else>{{ item.fba_sellers_count }}</strong>
                                        </td>
                                        <td v-if="columns.fbm_sellers_count" class="align-middle text-center position-relative" scope="row">
                                            <span v-if="!item.is_available">-</span>
                                            <strong v-else>{{ item.fbm_sellers_count }}</strong>
                                        </td>
                                        <td v-if="columns.buybox_status" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available">-</span>
                                            <strong v-else v-html="getBuyboxStatus(item)" />
                                        </td>
                                        <td v-if="columns.buybox_seller" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.buybox_seller">-</span>
                                            <a v-else :href="item.seller_link" target="_blank"
                                                ><strong>{{ item.buybox_seller }}</strong></a
                                            >
                                        </td>
                                        <td v-if="columns.width" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.width">-</span>
                                            <strong v-else>{{ item.width.toFixed(2) }} in</strong>
                                        </td>
                                        <td v-if="columns.height" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.height">-</span>
                                            <strong v-else>{{ item.height.toFixed(2) }} in</strong>
                                        </td>
                                        <td v-if="columns.length" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.length">-</span>
                                            <strong v-else>{{ item.length.toFixed(2) }} in</strong>
                                        </td>
                                        <td v-if="columns.weight" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.weight">-</span>
                                            <strong v-else>{{ item.weight.toFixed(2) }} lbs</strong>
                                        </td>
                                        <td v-if="columns.volume" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.volume">-</span>
                                            <strong v-else>{{ item.volume.toFixed(2) }} lbs</strong>
                                        </td>
                                        <td v-if="columns.max_item_on_package_weight" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.max_item_on_package_weight">-</span>
                                            <strong>{{ item.max_item_on_package_weight }} {{ $t('common.amount') }}</strong>
                                        </td>
                                        <td v-if="columns.max_item_on_package_dim" class="align-middle text-center" scope="row">
                                            <span v-if="!item.is_available || !item.max_item_on_package_dim">-</span>
                                            <strong>{{ item.max_item_on_package_dim }} {{ $t('common.amount') }}</strong>
                                        </td>
                                        <td v-if="columns.first_available" class="align-middle" scope="row">
                                            <span v-if="!item.is_available || !item.first_available">-</span>
                                            <strong v-else>{{ formatDate(item.first_available) }}</strong>
                                        </td>
                                        <td v-if="columns.search_other_stores" class="align-middle" scope="row">
                                            <span v-if="!item.title">-</span>
                                            <template v-else>
                                                <a :href="'https://www.alibaba.com/trade/search?tab=all&searchText=' + item.title" target="_blank" style="margin-right: 3px"
                                                    ><img :src="require('@/assets/svg/stores/alibaba.svg')" alt="" width="25" height="20"
                                                /></a>
                                                <a :href="'https://www.walmart.com/search?q=' + item.title" target="_blank" style="margin-right: 3px"><img :src="require('@/assets/svg/stores/walmart.svg')" alt="" width="25" height="20" /></a>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
    <AddtoFollowingListModal v-if="isShowModal" :selectedItems="selectedList" @closeModal="isShowModal = false" />
    <ProductDetailModal :showModal="showModal" @closeModal="showModal = false" :listType="'following_list'" />
    <Dialog
        :options="{
            title: $t('dialogs.remove_from_following_list.title'),
            description: $t('dialogs.remove_from_following_list.description'),
        }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleRemoveFromFollowingList"
    />
    <AddNoteModal :defaultNote="defaultNote" :showModal="showNoteModal" :noteSelectedItem="noteSelectedItem" @closeModal="handleCloseNoteModal" />

    <ExcelExportModal
        :id="$route.params.id"
        :entityType="'following_list'"
        :query="querystring"
        :columns="
            Object.keys(columns)
                .filter((column) => column !== 'search_other_stores' && column !== 'status')
                .map((column) => {
                    return {
                        key: column,
                        label: $t('list.product_search.columns.' + column),
                        isDefault: defaultColumns.indexOf(column) > -1,
                    }
                })
        "
        :selectedItems="selectedList"
        :showModal="showExcelExportModal"
        @closeModal="showExcelExportModal = false"
    />

    <div v-if="isShowMoveToModal" class="security-filter-modal" style="position: fixed; inset: 0; z-index: 500">
        <div class="modal-back" @click="isShowMoveToModal = !isShowMoveToModal"></div>
        <div class="modal-body">
            <div class="modal-title">
                <span class="title">{{ $t('actions.move_to_another_list') }}</span>
                <div class="modal-close" @click="isShowMoveToModal = !isShowMoveToModal">
                    <i class="fa-solid fa-xmark fa-2xl"></i>
                </div>
            </div>
            <div class="modal-content">
                <div>
                    <select v-model="moveToList" class="form-select">
                        <option value="">{{ $t('inputs.following_list.labels.select_list') }}</option>
                        <option v-for="item in followingLists" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="btn btn-primary btn-sm" @click="handleMoveToAnotherList">{{ $t('actions.move') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Layouts/Card.vue'
import { convertObjectToQueryString, countryInfo } from '@/utils/helper'
import { mapActions } from 'vuex'
import ProductDetailModal from '@/components/ProductSearch/ProductDetailModal.vue'
import AddtoFollowingListModal from '@/components/ProductSearch/AddtoFollowingListModal.vue'
import AddNoteModal from '@/components/ProductSearch/AddNoteModal.vue'
import moment from 'moment'
import ComparePrices from '@/components/Variations/ComparePrices'
import PageHeader from '@/components/Layouts/PageHeader.vue'
import PrepRequestService from '@/services/prep_requests'
import ExcelExportModal from '@/components/ProductSearch/ExcelExportModal.vue'

const countries = countryInfo()

export default {
    name: 'FollowingListDetail',
    components: {
        Card,
        PageHeader,
        ProductDetailModal,
        AddtoFollowingListModal,
        ComparePrices,
        ExcelExportModal,
        AddNoteModal,
    },
    data: function () {
        return {
            showDialog: false,
            completed: false,
            show_filters: false,
            toggleActions: false,
            toggleColumns: false,
            moveToList: '',
            isShowMoveToModal: false,
            selectedList: [],
            columnFilters: {},
            columnSorting: {},
            data: null,
            reportParameters: null,
            stats: null,
            querystring: '',
            followingLists: [],
            loading: false,
            showModal: false,
            selectedFilters: null,
            isShowModal: false,
            searchText: '',
            showExcelExportModal: false,
            noteSelectedItem: null,
            defaultNote: null,
            showNoteModal: false,
            countryData: countries,
            defaultColumns: [
                'asin',
                'title',
                'price',
                'product_cost',
                'warehouse_cost',
                'total_cost',
                'fba__profit',
                'fba__profit_rate',
                'fba__profit_margin',
                'fba__net_profit',
                'fba__roi',
                'fba__net_profit_margin',
                'price_diff_rate',
                'bestsellers_rank',
                'is_amazon_seller',
                'fba_sellers_count',
                'fbm_sellers_count',
                'buybox_status',
                'buybox_seller',
                'width',
                'height',
                'length',
                'weight',
                'volume',
                'max_item_on_package_weight',
                'max_item_on_package_dim',
            ],
            requests: {
                page: 1,
                perPage: 50,
                sort: [],
                filtration: [],
            },
            columns: {
                asin: true,
                title: true,
                price: true,
                product_cost: true,
                warehouse_cost: true,
                total_cost: true,
                fba__profit: true,
                fba__profit_rate: true,
                fba__profit_margin: true,
                fba__net_profit: true,
                fba__roi: true,
                fba__net_profit_margin: true,
                fbm__profit: true,
                fbm__profit_rate: true,
                fbm__profit_margin: true,
                fbm__net_profit: true,
                fbm__roi: true,
                fbm__net_profit_margin: true,
                price_diff_rate: true,
                variations: true,
                bestsellers_rank: true,
                category: true,
                main_category: true,
                brand: true,
                is_amazon_seller: true,
                fba_sellers_count: true,
                fbm_sellers_count: true,
                buybox_status: true,
                buybox_seller: true,
                width: true,
                height: true,
                length: true,
                weight: true,
                volume: true,
                max_item_on_package_weight: true,
                max_item_on_package_dim: true,
                first_available: true,
                search_other_stores: true,
                status: true,
            },
        }
    },
    mounted() {
        this.prepareData()
    },
    methods: {
        ...mapActions('followingList', ['getFollowingList', 'findFollowingList', 'moveToAnotherList', 'getProduct', 'removeFromFollowingList', 'updateFollowingListItem', 'refreshFollowingList']),
        ...mapActions('productSearch', ['getFollowingProduct']),
        handleToggleAcions() {
            this.toggleActions = !this.toggleActions
            this.isShowModal = true
        },
        handleShowNoteModal(item) {
            this.noteSelectedItem = item.id
            this.defaultNote = item.note
            this.showNoteModal = true
        },
        async handleCloseNoteModal() {
            this.showNoteModal = false
            await this.prepareData()
        },
        goToSource(link) {
            window.open(link, '_blank')
        },
        async handleNotifyOnStockChange(item) {
            const id = item.id

            await this.updateFollowingListItem({
                id,
                data: {
                    notify_on_stock: item.notify_on_stock,
                },
            })
            this.$toast.success('Güncellendi')
        },
        async handleRemoveFromFollowingList() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            await this.removeFromFollowingList(this.selectedList)
            await this.prepareData()
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.selectedList = []
            this.toggleActions = false
        },
        async handleMoveToAnotherList() {
            if (this.listMergeTo === '') return

            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })
            await this.moveToAnotherList({
                moveTo: this.moveToList,
                items: this.selectedList,
            })
            this.selectedList = []
            await this.prepareData()
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
            this.isShowMoveToModal = false
            this.toggleActions = false
        },
        async handleRefreshData() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.refreshFollowingList(this.followingList.id)
            await this.prepareData()
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.selectedList = []
            this.toggleActions = false
        },
        async handleNotifyOnDiscountChange(item) {
            const id = item.id

            await this.updateFollowingListItem({
                id,
                data: {
                    notify_on_discount: item.notify_on_discount,
                },
            })
            this.$toast.success('Güncellendi')
        },
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        search: function () {
            this.page = 1
            this.requests.page = 1
            this.querystringChanged(this.requests)
        },
        handleFilterColumn(id, condition = '=') {
            let value = ''
            let column = ''

            if (condition === 'between') {
                const value_1 = document.getElementById(`col_${id}_min`).value
                const value_2 = document.getElementById(`col_${id}_max`).value

                column = document.getElementById(`col_${id}_min`).getAttribute('data-column') || 'srp.' + id

                value = [value_1, value_2]
            } else {
                value = document.getElementById(`col_${id}`).value
                column = document.getElementById(`col_${id}`).getAttribute('data-column') || 'srp.' + id
            }
            const findSelectedFilterIndex = this.requests.filtration.findIndex((item) => {
                return item.key.toString() === column
            })

            if (value.length > 0) {
                if (findSelectedFilterIndex === -1) {
                    this.requests.filtration.push({
                        key: column,
                        value,
                        condition,
                    })
                } else {
                    this.requests.filtration[findSelectedFilterIndex].value = value
                }
            } else {
                this.requests.filtration = this.requests.filtration.filter((item) => item.key !== column)
            }
            this.search()
        },
        sortAble: function () {
            return {
                changeSortDir: (item) => {
                    const sort = [
                        {
                            dir: this.sortAble().dirToggle(item.index, item.sort.dir),
                            column: item.sort.column,
                        },
                    ]
                    this.requests.sort = sort
                    this.page = 1
                    this.requests.page = 1
                    this.querystringChanged(this.requests)
                },
                dirExists: (dir) => {
                    if (typeof dir == 'undefined') dir = 'asc'
                    return dir
                },
                dirToggle: (index, dir) => {
                    let newDir = this.sortAble().dirExists(dir)
                    newDir = newDir === 'asc' ? 'desc' : 'asc'
                    if (typeof this.columnSorting[index] === 'undefined') this.columnSorting[index] = {}

                    this.columnSorting[index].dir = newDir

                    console.log(this.columnSorting)
                    return newDir
                },
            }
        },
        handleCheckboxChange: function (e) {
            const checked = e.target.checked
            const allInputs = this.$refs.checkbox

            if (!allInputs) return

            if (checked) {
                allInputs.forEach((input) => {
                    if (input.value && input.value !== 'on') {
                        console.log(input.value)
                        if (!this.selectedList.includes(input.value)) {
                            this.selectedList.push(input.value)
                            input.checked = true
                        }
                    }
                })
            } else {
                this.selectedList = []
                allInputs.forEach((input) => {
                    input.checked = false
                })
            }
        },
        handleCheckboxSingleChange: function (e) {
            const value = e.target.value
            const checked = e.target.checked

            if (checked) {
                this.selectedList.push(value)
                // eslint-disable-next-line vue/no-mutating-props
            } else {
                this.selectedList = this.selectedList.filter((item) => item !== value)
                // eslint-disable-next-line vue/no-mutating-props
            }
        },
        searchInput: function (e) {
            this.requests.search = e.target.value
        },
        handleSearchInput: function () {
            this.page = 1
            this.requests.page = 1
            this.querystringChanged(this.requests)
        },
        async openModal(id) {
            if (!id) return
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getFollowingProduct(id)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.showModal = true
        },
        strLimit(str, limit = 50) {
            return str.length > limit ? str.substring(0, limit) + '...' : str
        },
        async prepareData() {
            this.loading = true
            const id = this.$route.params.id
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            const response = await this.findFollowingList({
                id,
                params: this.querystring,
            })
            this.data = response.data.data
            this.meta = {
                current_page: response.data.current_page,
                last_page: response.data.last_page,
                item_count: response.data.item_count,
                per_page: response.data.per_page,
                total: response.data.total,
            }
            this.followingList = response.followingList

            const followingLists = await this.getFollowingList()

            this.followingLists = followingLists.data.filter((list) => +list.id !== +id)
            this.loading = false
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
        changePerPage: function (e) {
            this.page = 1
            this.requests.page = 1
            this.requests.perPage = e.target.value
            this.querystringChanged(this.requests)
        },
        changeCurrentPage: function (e) {
            this.page = e.target.value
            this.requests.page = e.target.value
            this.querystringChanged(this.requests)
        },
        querystringChanged(data) {
            this.querystring = convertObjectToQueryString(data)
            this.prepareData()
        },
        getCurrency(short) {
            return countries[short].currency
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
        getNetProfit(item) {
            return {
                profit: item.profit,
                bank_transfer_fee: item.bank_transfer_fee,
                estimated_refund_amount: item.estimated_refund_amount,
            }
        },
        getFbmNetProfit(item) {
            return {
                profit: item.fbm_profit,
                bank_transfer_fee: item.bank_transfer_fee,
                estimated_refund_amount: item.estimated_refund_amount,
            }
        },
        getBuyboxStatus(item) {
            const parse = item.buybox_status

            let buybox = 'Yok'
            if (parse.amazon) buybox = 'Amazon'
            else if (parse.fba) buybox = 'FBA'
            else if (parse.fbm) buybox = 'FBM'

            return '<strong>' + buybox + '</strong>'
        },
        getStatuses(item) {
            const statuses = item.statuses
            const uniqueStatuses = [...new Set(statuses)]

            return uniqueStatuses
        },
        getFailedFilters(item) {
            const failedFilters = item.failed_filters
            return failedFilters
        },
        getColumnsCount(arr) {
            let count = 0
            arr.forEach((column) => {
                if (this.columns[column]) count++
            })
            console.log(count)
            return count
        },
        getOldValues(item) {
            if (!item.old_values) return null

            const oldValues = item.old_values
            return oldValues
        },
        async handleRemoveFilteration(key) {
            // remove filtration
            this.requests.filtration = this.requests.filtration.filter((item) => item.key !== key)

            await this.search()
        },
        getFilterName(key) {
            const filterName = key.replace('srp.', '').replace('sp.', '').replace('details.', '')

            return this.$t('list.product_search.columns.' + filterName)
        },
        async handleCreatePrepRequest() {
            const response = await PrepRequestService.create('following-list', {
                product_ids: this.selectedList,
            })
            if (response.data) {
                this.$router.push({
                    name: 'create-prep-request',
                })
            }
        },
        getFilterValue(value) {
            if (typeof value === 'string') {
                return value
            }

            if (value && value.filter((v) => !!v).length === 2) {
                return value.join(' - ')
            }

            if (value && value.length > 0) {
                const min = value[0]
                const max = value[1]

                if (min && !max) {
                    return `>= ${min}`
                }

                if (!min && max) {
                    return `<= ${max}`
                }
            }
        },
    },
    created() {
        const columns = localStorage.getItem('following_list_columns') || null
        if (columns) {
            this.columns = JSON.parse(columns)
        }
    },
    watch: {
        page() {
            this.prepareData()
        },
        columns: {
            handler: function (to, from) {
                console.log(to)
                localStorage.setItem('following_list_columns', JSON.stringify(to))
            },
            deep: true,
        },
    },
}
</script>

<style>
.span-skeleton {
    width: 100%;
    height: 13px;
    display: block;
    animation: pulse-bg 1s infinite;
    border-radius: 2px;
}
@keyframes pulse-bg {
    0% {
        background-color: #fafafb;
    }
    50% {
        background-color: #eaeaea;
    }
    100% {
        background-color: #fafafb;
    }
}
.fixed-width-column {
    max-width: 170px;
    min-width: 170px;
}
.two-line-text {
    display: table-cell; /* İçeriği iki satıra bölmek için */
    word-break: break-all;
    white-space: normal;
    -webkit-line-clamp: 2; /* İki satıra bölme */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.table tr:first-child > * {
    border-bottom-width: 0 !important;
}
</style>
