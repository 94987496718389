import { ApiInstance } from './base'

const ExtensionService = {
    availability(domain) {
        return ApiInstance.get(`/extension/availability?domain=${domain}`)
    },
    getProduct(payload) {
        return ApiInstance.get(`/extension/product?asin=${payload.asin}&domain=${payload.domain}`)
    },
    productRefresh(payload) {
        return ApiInstance.post('/extension/product/refresh', payload)
    },
    setCalculate(payload) {
        return ApiInstance.post('/extension/calculate', payload)
    },
    postSourceDomainDetail(id, payload, isRefresh) {
        return ApiInstance.post(`/extension/source/${id}${isRefresh ? '?refresh=true' : ''}`, payload)
    },
    setManualUpdate(payload) {
        return ApiInstance.put('extension/update', payload)
    },
    like(payload) {
        return ApiInstance.post('/extension/like', payload)
    },
    history(payload) {
        return ApiInstance.get(`/extension/history?${payload}`)
    },
    removeFromList(payload) {
        return ApiInstance.post('/extension/history/remove', payload)
    },
    getCompareTabDetail(payload, isRefresh) {
        return ApiInstance.post(`/extension/product/source${isRefresh ? '?refresh=true' : ''}`, payload)
    },
    getKeepaData(payload) {
        return ApiInstance.get('/extension/product/stats', {
            params: payload,
        })
    },
}

export default ExtensionService
