<template>
    <div class="container d-flex justify-content-end">
        <LanguageSwitcher></LanguageSwitcher>
    </div>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5" :class="validated && 'was-validated'">
                <div class="card-body">
                    <!-- Form -->
                    <div class="text-center">
                        <div class="mb-5">
                            <h1 class="display-5">{{ $t('views.auth.register.title') }}</h1>
                            <p>
                                {{ $t('views.auth.register.description') }} <router-link class="link" to="/login">{{ $t('views.auth.labels.login') }}</router-link>
                            </p>
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="fullNameSrEmail">{{ $t('views.auth.labels.name') }}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    tabindex="1"
                                    :class="v$.first_name.$error && 'is-invalid'"
                                    v-model="first_name"
                                    type="text"
                                    class="form-control form-control-lg"
                                    name="fullName"
                                    id="fullNameSrEmail"
                                    :placeholder="$t('views.auth.labels.name')"
                                />
                                <span v-if="v$.first_name.$error" class="invalid-feedback">{{ $t('views.auth.register.name_required') }}</span>
                            </div>

                            <div class="col-sm-6">
                                <input tabindex="2" :class="v$.last_name.$error && 'is-invalid'" v-model="last_name" type="text" class="form-control form-control-lg" :placeholder="$t('views.auth.labels.surname')" />
                                <span v-if="v$.last_name.$error" class="invalid-feedback">{{ $t('views.auth.register.surname_required') }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- End Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signupSrEmail">{{ $t('views.auth.labels.email') }}</label>
                        <input tabindex="3" autocomplete="off" :class="v$.email.$error && 'is-invalid'" v-model="email" type="email" class="form-control form-control-lg" name="email" id="signupSrEmail" placeholder="email@domain.com" />
                        <span v-if="v$.email.$error" class="invalid-feedback">{{ $t('views.auth.register.email_invalid') }}</span>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label w-100" for="signupSrPassword">
                            <span class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('views.auth.labels.password') }}</span>
                            </span>
                        </label>

                        <div class="input-group input-group-merge" data-hs-validation-validate-class="">
                            <input
                                tabindex="4"
                                autocomplete="off"
                                :class="v$.password.$error && 'is-invalid'"
                                :type="passwordType"
                                v-model="password"
                                class="js-toggle-password form-control form-control-lg"
                                name="password"
                                id="signupSrPassword"
                                placeholder="********"
                                minlength="8"
                            />
                            <a id="changePassTarget" class="input-group-append input-group-text" href="javascript:;" @click="passwordType = passwordType === 'text' ? 'password' : 'text'">
                                <i id="changePassIcon" class="bi-eye-slash"></i>
                            </a>
                        </div>

                        <span v-if="v$.password.$error" class="invalid-feedback">{{ $t('views.auth.register.password_invalid') }}</span>
                    </div>
                    <div class="mb-4">
                        <label class="form-label" for="signupSrEmail">{{ $t('views.auth.labels.phone') }}</label>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="d-flex">
                                    <label :class="(v$.phone_country.$error || v$.phone_number.$error) && 'is-invalid'" for="phn_cntry" class="input-icon">
                                        <CountryCode @onSelect="handlePhoneCountrySelect" :preferredCountries="['tr', 'us', 'ca', 'de', 'az', 'ru']" defaultCountry="" enabledFlags />
                                    </label>
                                    <label for="phn_onmbr" style="width: 100%">
                                        <input
                                            tabindex="5"
                                            :class="(v$.phone_country.$error || v$.phone_number.$error) && 'is-invalid'"
                                            v-maska
                                            :data-maska="phone_number_mask"
                                            type="text"
                                            id="phn_onmbr"
                                            :placeholder="$t('views.auth.labels.phone')"
                                            autocomplete="off"
                                            v-model="phone_number"
                                            class="js-toggle-password form-control form-control-lg"
                                        />
                                    </label>
                                </div>
                                <span v-if="v$.phone_country.$error || v$.phone_number.$error" class="invalid-feedback">
                                    <small>{{ $t('views.auth.register.phone_invalid') }}</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="form-label" for="fullNameSrEmail">{{ $t('views.auth.labels.ref_code') }}</label>
                        <div class="row">
                            <div class="col-sm-12">
                                <input tabindex="6" v-model="reference_code" type="text" class="form-control form-control-lg" :placeholder="$t('views.auth.labels.ref_code')" />
                            </div>
                        </div>
                    </div>
                    <div class="mb-2" v-if="reference_code_validate">
                        <div class="alert alert-soft-warning text-warning">{{ $t('views.auth.register.ref_code_description') }}</div>
                    </div>
                    <!-- <div class="mb-4">
                        <div class="form-check mb-4">
                            <input tabindex="7" :class="v$.contract_tou.$error && 'is-invalid'" class="form-check-input" type="checkbox" value="" v-model="contract_tou" id="termsCheckbox" />
                            <label class="form-check-label" for="termsCheckbox"> <a href="#">Kullanım Sözleşmesi</a>'ni okudum ve onaylıyorum. </label>
                        </div>
                    </div> -->
                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="submit">
                            {{ $t('views.auth.labels.register') }}
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { mapActions, mapGetters } from 'vuex'
import LanguageSwitcher from '@/components/Header/LanguageSwitcher.vue'

import CountryCode from '@/components/Inputs/CountryCode'
import libphonenumbers from 'libphonenumbers'
import { formatNumber } from 'libphonenumber-js'
import { email, minLength, required } from 'vuelidate/lib/validators'
import { vMaska } from 'maska'
import WalletService from '@/services/wallet'
export default {
    name: 'Login',
    setup() {
        return { v$: useVuelidate() }
    },
    components: {
        CountryCode,
        LanguageSwitcher,
    },
    directives: {
        maska: vMaska,
    },
    data: () => ({
        first_name: null,
        last_name: null,
        passwordType: 'password',
        sms_modal: false,
        email: null,
        ref: null,
        password: null,
        phone_country: '90',
        phone_country_code: 'TR',
        phone_number: null,
        phone_number_mask: '(###) ### ## ##',
        contract_dropshipping: null,
        contract_tou: true,
        contract_popd: null,
        reference_code: null,
        reference_code_validate: null,
        token: null,
    }),
    validations() {
        return {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(6),
            },
            phone_country: {
                required,
            },
            phone_number: {
                required,
                valid: function (val) {
                    if (!val) return false
                    if (val.length < 4) return false
                    val = val.replace(/#/g, '[0-9]').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '')
                    val = '+' + this.phone_country + val
                    const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance()
                    const number = phoneUtil.parse(val)
                    return this.phone_country && phoneUtil.isPossibleNumber(number)
                },
            },
        }
    },
    methods: {
        ...mapActions('auth', ['login', 'me', 'setToken', 'register', 'setRegisterForm']),
        ...mapActions('wallet', ['createSubscription']),

        handlePhoneCountrySelect(country) {
            this.phone_country = country.dialCode
            this.phone_country_code = country.iso2
            this.v$.phone_country.$touch()
        },
        async submit() {
            await this.v$.$validate()
            if (this.v$.$errors.length > 0) return

            const params = {
                name: this.first_name.charAt(0).toUpperCase() + this.first_name.slice(1),
                surname: this.last_name.charAt(0).toUpperCase() + this.last_name.slice(1),
                email: this.email,
                password: this.password,
                phoneCountry: this.phone_country,
                phoneCountryCode: this.phone_country_code,
                mobileNo: this.phone_number.replace(/#/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, ''),
                contract_dropshipping: true,
                contract_tou: this.contract_tou,
                contract_popd: true,
                referenceCode: this.reference_code,
            }
            this.setRegisterForm(params)
            this.$router.push({ name: 'otp' })
        },
    },
    mounted() {
        const _this = this
        this.emitter.on('otp', async (response) => {
            if (response.token) {
                _this.register(response)
            } else {
                const resp = await _this.register(response)

                if (resp) {
                    await _this.setToken(resp.token)
                    await _this.me()
                    setTimeout(() => {
                        _this.$router.push({ name: 'dashboard' })
                        this.$store.commit('set', { k: 'loading', v: { status: false } })
                    }, 1500)
                }
            }
        })

        WalletService.clickAffiliate({ code: this.$route.query.affiliate }).then((resp) => {
            resp = resp.data
            this.reference_code_validate = resp.status
        })
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
    },
    watch: {
        '$route.query': {
            handler: function (query) {
                if (query.ref) {
                    this.reference_code = query.ref
                    this.reference_code_validate = true
                }
                if (query.affiliate) {
                    this.reference_code = query.affiliate
                    this.reference_code_validate = true
                }
            },
            immediate: true,
        },
        phone_country(e) {
            const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance()
            const number = phoneUtil.getExampleNumberForType(this.phone_country_code, libphonenumbers.PhoneNumberType.MOBILE)
            const exampleNumber = number.values_
            const formattedNumber = formatNumber('+' + e + exampleNumber[2].toString(), 'INTERNATIONAL')
            const mask = formattedNumber.replace(/\d/g, '#')
            this.phone_number_mask = mask.replace('+' + mask.match(/\+(.*?) /)[1], '')
            if (this.phone_country_code === 'AT') {
                this.phone_number_mask = '### ### ####'
            }
        },
        sms_time() {
            if (this.sms_time <= 0) {
                this.sms_time_text = '0:00'
                return
            }
            const minutes = Math.floor(this.sms_time / 60)
            const seconds = this.sms_time - minutes * 60
            this.sms_time_text = `${minutes}:${seconds}`
        },
        isAuthenticated: {
            immediate: true,
            handler() {
                if (this.isAuthenticated) {
                    this.$router.push({ name: 'dashboard' })
                }
            },
        },
    },
}
</script>
<style lang="scss">
.vue-country-select {
    border-radius: 5px 0 0 5px !important;
    display: inline-block;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
    text-align: left;
    height: 47px;
    &:focus-within {
        border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
        -webkit-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25) !important;
        box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25) !important;
    }
    .dropdown-list {
        border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
        max-height: 250px !important;
        width: 280px !important;
    }
    .dropdown-item {
        padding: 4px 5px;
        font-size: 13px;
    }
    .vti__flag {
        box-shadow: none;
    }
    .vti__flag > * {
        height: 14px;
    }
}

.error > .vue-country-select {
    border: 1px solid red !important;
}
.warning > .vue-country-select {
    border: 1px solid #f27802 !important;
}
.invalid-feedback {
    display: block !important;
}
</style>
