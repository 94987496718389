<template>
    <div id="productDetailModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
            <div class="modal-content" v-if="data">
                <div class="modal-header" style="padding: 10px">
                    <button type="button" style="margin-right: 5px" class="btn-close" @click="hideModal"></button>
                </div>
                <div class="modal-body">
                    <div class="tab-content">
                        <div style="position: relative; overflow: auto; width: 100%; max-height: 350px">
                            <table v-if="data && data.length > 0" class="table table-striped table-bordered table-responsive">
                                <thead class="thead-light">
                                    <th :key="th" v-for="th in data[headingRow - 1]">{{ th }}</th>
                                </thead>
                                <tbody>
                                    <tr :key="tr" v-for="tr in dataRows">
                                        <td :key="td" v-for="td in tr">{{ td }}</td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: right; font-weight: 600">{{ $t('common.total') }}:</td>
                                        <td style="text-align: right; font-weight: 600">{{ data.length - 1 }} {{ $t('common.line') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6">
                                <div class="d-flex justify-content-between">
                                    <label style="align-self: center; min-width: 150px" for="">{{ identifierType }}</label>
                                    <select class="form-select" v-model="defaults.asin">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.price') }}</label>
                                    <select class="form-select" v-model="defaults.product_cost">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>

                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.productName') }}</label>
                                    <select class="form-select" v-model="defaults.title">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.description') }}</label>
                                    <select class="form-select" v-model="defaults.description">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-between">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.brand') }}</label>
                                    <select class="form-select" v-model="defaults.brand">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.category') }}</label>
                                    <select class="form-select" v-model="defaults.category">
                                        <option value="" selected>{{ $t('common.select') }}</option>

                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.stock') }}</label>
                                    <select class="form-select" v-model="defaults.stock">
                                        <option value="" selected>{{ $t('common.select') }}</option>

                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                    <label style="align-self: center; min-width: 150px" for="">{{ $t('components.templateOptions.fixedSalePrice') }}</label>
                                    <select class="form-select" v-model="defaults.price">
                                        <option value="" selected>{{ $t('common.select') }}</option>
                                        <option :value="column" v-for="column of columns" :key="column">{{ column }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-end mt-4" style="max-width: 31.5rem">
                            <label style="min-width: 150px; align-self: center">{{ $t('components.templateOptions.titleLine') }}:</label>
                            <input type="text" class="form-control" v-model="headingRowInput" />
                            <button @click="handleSubmit" class="btn btn-sm btn-primary ml-2">{{ $t('actions.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap'

import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'
import { countryInfo } from '@/utils/helper'

const countries = countryInfo()

export default {
    name: 'ProductDetailModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default: () => [],
        },
        identifierType: {
            type: String,
            default: 'ASIN',
        },
    },
    components: {},
    data: () => ({
        form_submitted: false,
        modalInstance: null,
        headingRow: 1,
        headingRowInput: 1,
        columns: [],
        defaults: {
            brand: '',
            product_cost: '',
            title: '',
            asin: '',
            stock: '',
            description: '',
            category: '',
            price: '',
        },
    }),
    mounted() {},
    computed: {
        ...mapGetters('productSearch', ['selectedProduct', 'calculatedPrices']),
        offersByPrice() {
            if (!this.selectedProduct.offers) return []
            const offers = this.selectedProduct.offers

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.offers = offers
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return offers.sort((a, b) => a.price - b.price)
        },
        dataRows() {
            if (this.headingRow === '') return []
            return this.data.slice(this.headingRow, this.headingRow + 5)
        },
    },
    watch: {
        headingRow: {
            handler(newValue, oldValue) {
                if (newValue === '') return
                this.columns = this.data[newValue - 1]

                this.defaults = {
                    brand: '',
                    product_cost: '',
                    title: '',
                    asin: '',
                    stock: '',
                    description: '',
                    category: '',
                    price: '',
                }
            },
        },
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.modalActive()
                if (this.headingRow && this.headingRow > 0) {
                    this.columns = this.data[this.headingRow - 1]
                }
            }
        },
        selectedProduct(newValue, oldValue) {
            if (!newValue) return
            this.calculatePrices({
                id: newValue.idx,
            })
        },
        headingRowInput(newValue, oldValue) {
            if (+newValue > 0) {
                this.headingRow = +newValue
            } else {
                this.headingRow = 1
            }
        },
    },
    methods: {
        ...mapActions('productSearch', ['calculatePrices']),
        handleToggleCosts() {
            this.toggleCosts = !this.toggleCosts
        },
        handleInputPrice(e) {
            this.$refs.roiInput.value = ''

            this.calculatePrices({
                id: this.selectedProduct.idx,
                query: {
                    customPrice: e.target.value,
                },
            })
        },
        handleInputRoi(e) {
            this.$refs.priceInput.value = ''
            this.calculatePrices({
                id: this.selectedProduct.idx,
                query: {
                    customRoi: e.target.value,
                },
            })
        },
        handleToggleTotalCosts() {
            this.toggleTotalCosts = !this.toggleTotalCosts
        },
        getCurrency(short) {
            return countries[short].currency + ' '
        },
        handleSubmit() {
            if (this.defaults.asin === '') {
                this.$toast.error(this.identifierType + ' alanı boş bırakılamaz')
                return
            }

            this.emitter.emit('saveTemplateOptions', {
                headingRow: this.headingRow,
                defaults: this.defaults,
            })
            this.hideModal()
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('productDetailModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
    },
}
</script>

<style scoped>
.modal-body .table {
    width: 100%; /* Tablo genişliğini % olarak belirleyin */
}
.modal-body .table th {
    text-align: center;
}
.modal-body .table td {
    white-space: nowrap; /* Uzun metinleri kesmek için white-space: nowrap kullanın */
    overflow: hidden;
    text-overflow: ellipsis;
}
.thead-light th {
    background-color: #f8fafd !important;
}
.table > thead {
    vertical-align: bottom;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table > tbody {
    vertical-align: inherit;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hidden-row {
    display: none;
}
.show-row {
    display: table-row;
    background-color: #f8fafd;
}
.nav-pills .nav-link {
    border-radius: 0;
}
.thumbnail_wrapper {
    min-width: 200px;
    height: 200px;
    overflow: hidden;
    margin-right: 20px;
    display: inherit;
    position: relative;
    padding: 10px;
    border: 2px solid #eff3f9;
    margin-bottom: 15px;
    border-radius: 5px;
    max-width: 200px;
}
table tr td {
    white-space: pre-line;
    max-width: 200px;
}
#priceAnalyse td {
    font-weight: 600;
    height: 35px;
    vertical-align: middle;
    font-size: 12px;
}
#priceAnalyse input {
    height: 35px;
    max-width: 150px;
}
#priceAnalyse th {
    max-width: 200px;
}
#priceAnalyse tr td:not(:first-child) {
    text-align: right;
}
</style>
