<template>
    <div class="user-card" v-if="user">
        <i class="user-card__icon bi-person-circle nav-icon"></i>
        <div class="user-card__body">
            <span>{{ `${user.name} ${user.surname}` }}</span>
            <span>{{ user.email }}</span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'UserCard',
    methods: {},
    computed: {
        ...mapGetters('auth', ['user']),
    },
}
</script>
<style lang="scss" scoped>
.user-card {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 0.875rem;
    border-bottom: 0.6px solid #e7eaf3;

    &__icon {
        color: #13214480;
        font-size: 36px;
    }

    &__body {
        display: flex;
        flex-direction: column;

        span:first-child {
            font-size: 14px;
            font-weight: 600;
            color: #132144;
        }

        span:last-child {
            color: #13214480;
            font-size: 12px;
        }
    }
}
</style>
