<template>
    <div v-if="oldValue && value && oldValue !== value && Math.abs((value / oldValue) * 100 - 100).toFixed(0) > 0" :style="!singleRow && `position: absolute; right: 30%; top: 22px; background-color: white; font-size: 12px; font-weight: 500`">
        <i class="fa fa-solid" :class="oldValue < value ? 'text-success  fa-arrow-up' : oldValue > value ? 'text-danger fa-arrow-down' : ''"></i>
        <span :class="oldValue < value ? 'text-success' : oldValue > value ? 'text-danger' : ''">{{ Math.abs((value / oldValue) * 100 - 100).toFixed(0) }}%</span>
    </div>
</template>
<script>
export default {
    props: {
        singleRow: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Number,
            default: null,
        },
        oldValue: {
            type: Number,
            default: null,
        },
        rate: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
