<template>
    <div class="container pt-3" style="max-width: 70rem">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="$t('cards.setup.title')" :description="$t('cards.setup.description')" />
                <div class="row justify-content-lg-center pt-4">
                    <div class="col-lg-3 pt-1">
                        <ul class="step">
                            <li class="step-item">
                                <div class="step-content-wrapper">
                                    <span
                                        class="step-icon"
                                        :class="{
                                            'step-icon-soft-success': completedSteps.includes(1),
                                            'step-icon-primary': currentStep === 1,
                                        }"
                                    >
                                        {{ marketExists ? '✓' : '1' }}
                                    </span>
                                    <div class="step-content align-self-center">
                                        <span
                                            class="step-title"
                                            :class="{
                                                'text-primary': currentStep === 1,
                                            }"
                                            >{{ $t('cards.setup.labels.connect_store') }}</span
                                        >
                                    </div>
                                </div>
                            </li>

                            <li class="step-item">
                                <div class="step-content-wrapper">
                                    <span
                                        class="step-icon"
                                        :class="{
                                            'step-icon-soft-success': completedSteps.includes(2),
                                            'step-icon-primary': currentStep === 2,
                                            'step-icon-soft-primary': currentStep !== 2 && !completedSteps.includes(2),
                                        }"
                                    >
                                        {{ completedSteps.includes(2) ? '✓' : '2' }}
                                    </span>
                                    <div class="step-content align-self-center">
                                        <span
                                            class="step-title"
                                            :class="{
                                                'text-primary': currentStep === 2,
                                            }"
                                            >{{ $t('cards.setup.labels.select_domain') }}</span
                                        >
                                    </div>
                                </div>
                            </li>

                            <li class="step-item">
                                <div class="step-content-wrapper">
                                    <span
                                        class="step-icon"
                                        :class="{
                                            'step-icon-soft-success': completedSteps.includes(3),
                                            'step-icon-primary': currentStep === 3,
                                            'step-icon-soft-primary': currentStep !== 3 && !completedSteps.includes(3),
                                        }"
                                    >
                                        {{ completedSteps.includes(3) ? '✓' : '3' }}
                                    </span>
                                    <div class="step-content align-self-center">
                                        <span
                                            class="step-title"
                                            :class="{
                                                'text-primary': currentStep === 3,
                                            }"
                                            >{{ $t('cards.setup.labels.set_parameters') }}</span
                                        >
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-9 pl-2">
                        <template v-if="currentStep === 1">
                            <div class="card">
                                <div class="card-header bordered-bottom">
                                    <h3 class="card-header-title" style="font-size: 15px">{{ $t('cards.setup.labels.connect_store') }}</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p>{{ $t('cards.setup.help_text.connect_store_1') }}</p>
                                            <p>{{ $t('cards.setup.help_text.connect_store_2') }}</p>

                                            <div class="input-group mt-4">
                                                <input type="text" placeholder="Mağaza Adı" v-model="market_name" class="form-control" style="max-width: 15rem" />
                                                <MarketSelect @onSelect="handleSelectMarketplace" :multiple="false" :all="true" :addNew="true" />
                                                <button @click="startSpApiAuth" class="btn btn-primary btn-sm">{{ $t('actions.connect') }}</button>
                                            </div>
                                        </div>
                                        <div class="col-3 text-right">
                                            <img :src="getImgUrl('undraw_online_resume_re')" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer" v-if="marketExists">
                                    <a class="card-link" style="float: right" href="#" @click.prevent="currentStep = 2">{{ $t('actions.next_step') }} <i class="bi-chevron-right small ms-1"></i></a>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="currentStep === 2">
                            <div class="card">
                                <div class="card-header bordered-bottom">
                                    <h3 class="card-header-title" style="font-size: 15px">{{ $t('cards.setup.labels.select_domain') }}</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p>{{ $t('cards.setup.help_text.select_domain') }}</p>
                                            <div class="input-group mt-4">
                                                <MarketSelect @onSelect="handleSelectedSource" :multiple="true" :all="true" />
                                                <button @click="handleSelectSource" class="btn btn-primary btn-sm">{{ $t('common.select') }}</button>
                                            </div>
                                        </div>
                                        <div class="col-3 text-right">
                                            <img :src="getImgUrl('undraw_my_answer')" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer" v-if="completedSteps.includes(2)">
                                    <a class="card-link" style="float: right" href="#" @click.prevent="currentStep = 3">{{ $t('actions.next_step') }} <i class="bi-chevron-right small ms-1"></i></a>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="currentStep === 3">
                            <div class="card">
                                <div class="card-header bordered-bottom">
                                    <h3 class="card-header-title" style="font-size: 15px">{{ $t('cards.setup.labels.set_parameters') }}</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <button
                                                @click="
                                                    $router.push({
                                                        name: 'store.parameters',
                                                    })
                                                "
                                                class="btn btn-primary btn-sm"
                                            >
                                                {{ $t('actions.set_parameters') }}
                                            </button>
                                        </div>
                                        <div class="col-3 text-right">
                                            <img :src="getImgUrl('undraw_personal_settings')" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <a class="card-link" href="#" @click.prevent="currentStep = 2"
                                        ><i class="bi-chevron-left small ms-1"></i>
                                        {{ $t('actions.previous_step') }}
                                    </a>
                                    <router-link class="card-link" v-if="completedSteps.includes(3)" style="float: right" to="/product-search">
                                        {{ $t('actions.get_started') }}
                                        <i class="bi-chevron-right small ms-1"></i
                                    ></router-link>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { countryInfo, spApiAuth } from '@/utils/helper'
import { decrypt } from '@/utils/encryption'
import { mapGetters } from 'vuex'
import PageHeader from '@/components/Layouts/PageHeader.vue'
import MarketSelect from '@/components/Inputs/MarketSelect.vue'

const countries = countryInfo()
export default {
    name: 'Setup',
    components: {
        PageHeader,
        MarketSelect,
    },
    data() {
        return {
            selectedValues: [],
            market: null,
            market_name: null,
            selectedSources: [],
            selectedMarketplace: null,
            totalSteps: 4,
            completedSteps: [],
            currentStep: 1,
            steps: [
                {
                    id: 2,
                    title: 'Mağazanı SP-API ile bağla',
                    description:
                        // eslint-disable-next-line max-len
                        '',
                    image: 'undraw_version_control.svg',
                    groupAction: {
                        input: [
                            {
                                type: 'text',
                                placeholder: 'Mağaza Adı',
                                onChange: (e) => {
                                    this.market_name = e.target.value
                                },
                            },
                            {
                                type: 'multiselect',
                                placeholder: 'Pazaryeri Seçin',
                                multiple: false,
                                name: 'marketplace',
                                onSelect: (selected) => {
                                    this.selectedMarketplace = selected
                                },
                            },
                        ],
                        button: {
                            text: 'Bağlan',
                            style: 'primary',
                            callback: this.startSpApiAuth,
                        },
                    },
                    expanded: true,
                    completed: false,
                },
                {
                    id: 3,
                    title: 'Kaynak Domain Seç',
                    description:
                        // eslint-disable-next-line max-len
                        '',
                    image: 'undraw_data_extraction.svg',
                    groupAction: {
                        input: [
                            {
                                type: 'multiselect',
                                placeholder: 'Kaynak domain seçin',
                                onSelect: (selected) => {
                                    this.selectedSources = selected
                                },
                            },
                        ],
                        button: {
                            text: 'Seç',
                            style: 'primary',
                            callback: this.handleSelectSource,
                        },
                    },
                    expanded: false,
                    completed: false,
                    disabled: true,
                },
                {
                    id: 4,
                    title: 'Parametre Tanımlamalarını Yap',
                    description:
                        // eslint-disable-next-line max-len
                        '',
                    image: 'undraw_preferences.svg',
                    button: {
                        text: 'Ayarları yapın',
                        style: 'primary',
                        callback: () => {
                            this.$router.push({
                                name: 'store.parameters',
                            })
                        },
                    },
                    rightAction: {
                        text: 'Parametreler',
                        callback: () => {
                            this.$router.push({
                                name: 'store.parameters',
                            })
                        },
                    },
                    expanded: false,
                    completed: false,
                    disabled: true,
                },
            ],
        }
    },
    methods: {
        getImgUrl(image) {
            return require('@/assets/svg/' + image + '.svg')
        },
        handleSelectMarketplace(selected) {
            this.selectedMarketplace = selected
        },
        handleSelectedSource(selected) {
            this.selectedSources = selected
        },
        startSpApiAuth() {
            const marketplace = this.selectedMarketplace
            if (!marketplace) {
                this.$toast.error(this.$t('toast.marketplace_required'))
                return
            }
            const url = countries[marketplace.short || 'US'].url
            const region = countries[marketplace.short].region || null
            const state = {
                short: marketplace.short,
                region,
                name: this.market_name,
                redirectTo: '/setup',
            }
            spApiAuth(url, state)
        },
        async finishSpApiAuth(params) {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                await this.$store.dispatch('market/getCredentials', params)
                const response = await this.$store.dispatch('market/findAll')
                this.$store.dispatch('market/setCurrentMarket', response[0])
            } catch (e) {
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                return
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.$router.replace({
                name: 'setup',
            })
        },
        async handleSelectSource() {
            const selectedDomains = this.selectedSources.map((item) => item.short)
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            if (this.currentMarket && this.currentMarket.idx) {
                await this.$store.dispatch('market/setSources', {
                    data: {
                        sources: selectedDomains,
                        defaultParameters: true,
                    },
                    marketId: this.currentMarket.idx,
                })
                const response = await this.$store.dispatch('market/findAll')
                this.$store.dispatch('market/setCurrentMarket', response[0])
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    computed: {
        ...mapGetters('market', ['currentMarket']),
        marketExists() {
            return this.$store.getters['market/markets'].length > 0
        },
        sources() {
            return this.$store.getters['market/markets'].length > 0 ? this.$store.getters['market/markets'][0].sources : null
        },
        scanningOptions() {
            const sources = this.currentMarket && this.currentMarket.sources && this.currentMarket.sources.length > 0 ? this.currentMarket.sources : null
            if (!sources) return null

            const scanningOptions = this.currentMarket.sources[0].scanningOptions

            return scanningOptions && scanningOptions.length > 0 ? scanningOptions : null
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.state) {
                    const state = decrypt(newVal.state)
                    this.finishSpApiAuth({
                        spapi_oauth_code: newVal.spapi_oauth_code,
                        selling_partner_id: newVal.selling_partner_id,
                        ...state,
                    })
                }
            },
        },
        marketExists: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.completedSteps.push(1)
                    if (!this.completedSteps.includes(2)) {
                        this.currentStep = 2
                    } else {
                        this.currentStep = 3
                    }
                }
            },
        },
        sources: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.length > 0) {
                    this.completedSteps.push(2)
                    this.currentStep = 3
                }
            },
        },
        scanningOptions: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (newVal) {
                    this.completedSteps.push(3)
                }
            },
        },
    },
}
</script>
