<template>
    <div class="container-fluid pt-3" style="margin-bottom: 2rem; max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="$t('cards.product_search.history')" />

                <Card>
                    <div class="option-bar d-flex flex-column flex-sm-row justify-content-between w-100 mb-3" v-if="meta">
                        <div class="option-wrapper">
                            <div class="row">
                                <div class="col d-none d-sm-block">
                                    <div class="result-count">
                                        <span class="count">{{ meta.total }}</span>
                                        <small>{{ $t('list.result') }}</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <input v-model="searchText" class="form-control sm-input" type="text" @input="searchInput" v-debounce:300ms="handleSearchInput" :placeholder="$t('list.search')" />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <select @change="changePerPage" class="form-select">
                                        <option value="10" selected>10 {{ $t('list.showing') }}</option>
                                        <option value="25">25 {{ $t('list.showing') }}</option>
                                        <option value="50">50 {{ $t('list.showing') }}</option>
                                        <option value="100">100 {{ $t('list.showing') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="page" @change="changeCurrentPage" class="form-select" v-if="meta && meta.last_page">
                                        <option v-if="[...Array(meta.last_page).keys()].length === 0" value="">{{ $t('list.no_result') }}</option>
                                        <option :key="page" v-for="page of [...Array(meta.last_page).keys()]" :value="page + 1">{{ page + 1 }}. {{ $t('list.page') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="action-wrapper" style="width: 15rem">
                            <button :disabled="!selectedRows.length" @click="showDialog = true" class="btn btn-secondary btn-sm">{{ selectedRows.length > 0 ? `(${selectedRows.length})` : '' }} {{ $t('actions.delete_product_search_list') }}</button>
                        </div>
                    </div>
                    <div class="row mt-2 pb-1">
                        <div class="col-12 relative">
                            <div style="overflow-x: auto; overflow-y: auto; position: sticky; top: 0; z-index: 1">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="align-middle">
                                                <input class="form-check-input" @change="handleCheckboxChange" type="checkbox" />
                                            </th>
                                            <th class="text-left" scope="col">
                                                <span> {{ $t('list.product_search_history.columns.name') }}</span>
                                            </th>
                                            <th class="text-center" scope="col">
                                                <span> {{ $t('list.product_search_history.columns.type') }}</span>
                                            </th>
                                            <th class="text-center" scope="col">
                                                <span> {{ $t('list.product_search_history.columns.source') }}</span>
                                            </th>
                                            <th class="text-center" scope="col">
                                                <span> {{ $t('list.product_search_history.columns.product_count') }}</span>
                                            </th>
                                            <th class="text-center" scope="col">
                                                <span> {{ $t('list.product_search_history.columns.status') }}</span>
                                            </th>
                                            <th class="text-right">
                                                <label> {{ $t('list.product_search_history.columns.created_at') }}</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr></tr>
                                        <tr v-for="item in data" :key="item.id">
                                            <td style="width: 3%" class="align-middle">
                                                <input
                                                    ref="checkbox"
                                                    :checked="selectedRows.indexOf(item.id) > -1"
                                                    @change="handleCheckboxSingleChange"
                                                    :value="item.id"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :disabled="item.status !== 1 && item.status !== 0"
                                                />
                                            </td>
                                            <td style="width: 22%" class="align-middle text-left position-relative" scope="row">
                                                <router-link class="d-inline-block" :to="urlPath + '/' + item.id" style="white-space: normal; padding-right: 1rem; max-width: 85%"> {{ item.report_sku }}</router-link>
                                                <img v-if="item.keepa_active" :src="getKeepaImage()" width="55" style="position: absolute; right: 0; top: 25px" />
                                            </td>
                                            <td style="width: 15%" class="align-middle text-center" scope="row">
                                                {{ getSearchType(item.search_type) }}
                                                <Popper v-if="item.search_type === 'url'" placement="top" v-bind="$attrs" arrow :class="'popp'">
                                                    <template #content>
                                                        <span>{{ item.url }}</span></template
                                                    >
                                                    <a :href="item.url" target="_blank">
                                                        <span class="text-info" style="cursor: pointer; font-weight: 600"><i class="fas fa-question-circle"></i></span>
                                                    </a>
                                                </Popper>
                                                <Popper v-if="item.search_type === 'category'" placement="top" v-bind="$attrs" arrow :class="'popp'">
                                                    <template #content>
                                                        <span>{{ item.category_options.link }}</span></template
                                                    >
                                                    <a :href="item.category_options.link" target="_blank">
                                                        <span class="text-info" style="cursor: pointer; font-weight: 600"><i class="fas fa-question-circle"></i></span>
                                                    </a>
                                                </Popper>
                                            </td>
                                            <td style="width: 10%" class="align-middle text-center" scope="row">
                                                <img width="20" :src="getImage(item.source + '.svg')" />
                                            </td>
                                            <td style="width: 15%" class="align-middle text-center" scope="row">
                                                {{ item.status === 3 ? '-' : formatThousandsDecimal(item.total_products) }}
                                            </td>
                                            <td style="width: 15%" class="align-middle text-center" scope="row">
                                                <span v-if="item.status === 0" class="badge bg-danger">{{ $t('statuses.failed') }}</span>
                                                <div v-else-if="item.status == 3" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-info" role="progressbar" :style="'width:100%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('statuses.starting') }}
                                                    </div>
                                                </div>
                                                <div v-else-if="item.status == 1" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-success" role="progressbar" :style="'width:100%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('statuses.completed') }}
                                                    </div>
                                                </div>
                                                <div v-else-if="+item.scanCompletionPercentage == 0 && !item.scanningPercentege" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-info" role="progressbar" :style="'width:100%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('statuses.starting') }}
                                                    </div>
                                                </div>
                                                <div v-else-if="+item.scanningPercentege && +item.scanningPercentege < 100" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-info" role="progressbar" :style="'width:100%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('statuses.scanning') }} (%{{ item.scanningPercentege }})
                                                    </div>
                                                </div>
                                                <div v-else-if="+item.scanCompletionPercentage >= 100 && +item.filteringCompletionPercentage < 100" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-info" role="progressbar" :style="'width:100%'" :aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('statuses.filtering') }} (%{{ item.filteringCompletionPercentage }})
                                                    </div>
                                                </div>
                                                <div v-else-if="+item.scanCompletionPercentage >= 100 && +item.filteringCompletionPercentage >= 100" class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-warning" role="progressbar" :style="'width:100%'" :aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{{ $t('statuses.completing') }}</div>
                                                </div>
                                                <div v-else class="progress" style="height: 20px">
                                                    <div class="progress-bar bg-info" role="progressbar" :style="'width:100%'" :aria-valuenow="item.scanCompletionPercentage" aria-valuemin="0" aria-valuemax="100">
                                                        {{ $t('common.getting_results') }}{{ +item.scanCompletionPercentage > 0 ? `(%${item.scanCompletionPercentage})` : '' }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style="width: 20%" class="align-middle text-right" scope="row" :innerHTML="getCreatedAt(item.created_at)"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
    <Dialog
        :options="{
            title: $t('dialogs.product_search_delete.title'),
            description: $t('dialogs.product_search_delete.description'),
        }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleRemoveItem"
    />
</template>

<script>
import Card from '@/components/Layouts/Card.vue'

import { convertObjectToQueryString, printDateTime, number_format } from '@/utils/helper'
import { mapActions } from 'vuex'
import moment from 'moment'
import PageHeader from '@/components/Layouts/PageHeader.vue'

export default {
    name: 'ProductSearchHistory',
    components: {
        Card,
        PageHeader,
    },
    data() {
        return {
            data: null,
            loading: true,
            interval: null,
            showDialog: false,
            total: 0,
            listMergeTo: '',
            isShowModal: false,
            searchText: '',
            page: 1,
            toggleCreateForm: false,
            selectedRows: [],
            selectedMarkets: [],
            toggleActions: false,
            querystring: '',
            requests: {
                page: 1,
                per_page: 10,
                sort: [],
                filtration: [],
            },
            meta: null,
        }
    },
    mounted() {
        this.prepareData()
        const self = this
        this.interval = setInterval(() => {
            self.prepareData()
            if (self.data && self.data.data) {
                console.log(self.data.data.filter((item) => item.status == 2).length)
            }
            if (self.data && self.data.data && self.data.data.filter((item) => item.status == 2).length === 0) {
                clearInterval(this.interval)
            }
        }, 5000)
    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
    methods: {
        ...mapActions('productSearch', ['getScanningReports', 'deleteScanningReports']),
        formatThousandsDecimal(value) {
            return number_format(value, 'money', {
                style: 'decimal',
                decimal: '',
                thousand: '.',
            })
        },
        getCreatedAt(item) {
            const ago = moment(item).locale(this.getLocale).fromNow()
            return '<strong>' + ago + '</strong><br>' + printDateTime(item, 'DD.MM.YYYY HH:mm:ss')
        },
        getSearchType(type) {
            if (type == 'asin') return this.$t('common.asin')
            if (type == 'wholesale') return this.$t('common.wholesale')
            if (type == 'category') return this.$t('common.category')
            if (type == 'url') return 'URL'
        },
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        getKeepaImage() {
            return require('@/assets/img/keepa-logo.webp')
        },
        async prepareData() {
            this.$store.commit('set', { k: 'loading', v: { status: this.loading, text: '' } })
            const response = await this.getScanningReports({
                params: this.querystring + (this.urlPath === '/wholesale-search/history' ? '&search_type=wholesale' : ''),
            })
            this.data = response.data
            this.total = response.total
            this.meta = {
                current_page: response.current_page,
                last_page: response.last_page,
                item_count: response.item_count,
                per_page: response.per_page,
                total: response.total,
            }
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })

            this.loading = false
        },
        changePerPage: function (e) {
            this.page = 1
            this.requests.page = 1
            this.requests.perPage = e.target.value
            this.querystringChanged(this.requests)
        },
        changeCurrentPage: function (e) {
            this.page = e.target.value
            this.requests.page = e.target.value
            this.querystringChanged(this.requests)
        },
        querystringChanged(data) {
            this.querystring = convertObjectToQueryString(data)
            this.prepareData()
        },
        searchInput: function (e) {
            this.requests.search = e.target.value
        },
        handleSearchInput() {
            this.page = 1
            this.requests.page = 1
            console.log(this.requests)
            this.querystringChanged(this.requests)
        },
        async handleRemoveItem() {
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })
            await this.deleteScanningReports(this.selectedRows)
            this.$toast.success(this.$t('cards.product_search.messages.history_deleted'))
            window.location.reload()
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
            this.selectedRows = []
        },
        handleCheckboxChange: function (e) {
            const checked = e.target.checked
            const allInputs = this.$refs.checkbox

            if (!allInputs) return
            if (checked) {
                allInputs.forEach((input) => {
                    if (input.value && input.value !== 'on' && input.disabled === false) {
                        if (!this.selectedRows.includes(input.value)) {
                            this.selectedRows.push(input.value)
                            input.checked = true
                        }
                    }
                })
            } else {
                this.selectedRows = []
                allInputs.forEach((input) => {
                    input.checked = false
                })
            }
        },
        handleCheckboxSingleChange: function (e) {
            const value = e.target.value
            const checked = e.target.checked

            if (checked) {
                this.selectedRows.push(value)
            } else {
                this.selectedRows = this.selectedRows.filter((item) => item !== value)
            }
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
    },
    computed: {
        urlPath: function () {
            return this.$route.path
        },
        getLocale: function () {
            return localStorage.getItem('lang') || 'tr'
        },
    },
    watch: {
        page() {
            this.prepareData()
        },
        '$route.path'() {
            this.prepareData()
        },
    },
}
</script>
