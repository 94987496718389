import sha256 from 'crypto-js/sha256'
import { FcaInstance } from './base'

export default {
    sendData(me) {
        const fbpCookie = getCookie('_fbp')
        const fbcCookie = getCookie('_fbc')

        const userAgent = window.navigator.userAgent
        const currentURL = window.location.href

        const FirstName = me ? '' + sha256(me.name) : ''
        const LastName = me ? '' + sha256(me.surname) : ''
        const Email = me ? '' + sha256(me.email) : ''

        const params = {
            siteData: {
                domain: 'fbahunter.com',
                FirstName,
                LastName,
                Email,
                fbp: fbpCookie || '',
                fbc: fbcCookie || '',
                SourceUrl: currentURL,
                userAgent: userAgent || '',
            },
        }

        function getCookie(name) {
            const nameEQ = name + '='
            const ca = document.cookie.split(';')
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]
                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length)
                }
                if (c.indexOf(nameEQ) === 0) {
                    return c.substring(nameEQ.length, c.length)
                }
            }
            return null
        }

        return FcaInstance.post('getData', params)
            .then((resp) => {})
            .catch((err) => {
                console.log(err)
            })
    },
}
