<template>
    <div class="alert" :class="'alert' + (soft ? '-soft' : '') + '-' + style + (dismissable ? ' alert-dismissible' : '')" role="alert">
        <template v-if="icon">
            <div class="d-flex">
                <div class="flex-shrink-0">
                    <i :class="icon"></i>
                </div>
                <div class="flex-grow-1 ms-2">{{ text }}</div>
            </div>
        </template>
        <template v-else>{{ text }}</template>
    </div>
</template>
<script>
export default {
    name: 'Alert',
    props: {
        text: {
            type: String,
            required: true,
        },
        style: {
            type: String,
            default: 'primary' /* primary, secondary, success, danger, warning, info, light, dark */,
        },
        icon: {
            type: String,
            default: null,
        },
        soft: {
            type: Boolean,
            default: false,
        },
        dismissable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
