<template>
    <div class="fba-page-header">
        <div class="fba-page-header-row">
            <div class="fba-page-header-title-wrapper d-flex" style="justify-content: space-between">
                <div class="d-flex">
                    <router-link v-if="backRoute" :to="backRoute" class="btn btn-outline-secondary btn-sm back-button">
                        <i class="fas fa-arrow-left"></i>
                    </router-link>
                    <h1 class="fba-page-header-title" style="margin-top: 0.5rem">{{ title }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="fba-divider"></div>
</template>
<script>
export default {
    name: 'PageHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        backRoute: {
            type: String,
            default: null,
        },
    },
}
</script>

<style>
.back-button {
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border: 0.0625rem solid #babfc3;
    border-radius: 0.25rem;
    color: inherit;
    color: #6d7175;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: center;
    line-height: inherit;
    margin: 0;
    min-height: 2.25rem;
    min-width: 2.25rem;
    padding: 0;
    padding: 0.125rem;
    position: relative;
    text-decoration: none;
    margin-right: 1rem;
}
.back-button:hover {
    align-items: center;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border: 0.0625rem solid #babfc3;
    border-radius: 0.25rem;
    color: inherit;
    color: #6d7175;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: center;
    line-height: inherit;
    margin: 0;
    min-height: 2.25rem;
    min-width: 2.25rem;
    padding: 0;
    padding: 0.125rem;
    position: relative;
    text-decoration: none;
    margin-right: 1rem;
}
</style>
