import RestrictedListService from '@/services/restricted-list'

const state = {
    restrictedList: null,
}

const getters = {
    restrictedList: (state) => state.restrictedList,
}

const mutations = {
    SET_RESTRICTED_LIST(state, payload) {
        state.restrictedList = payload
    },
}

const actions = {
    getViolationPool({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.findAllViolationPool(payload.type, payload.params)
                .then((response) => {
                    commit('SET_RESTRICTED_LIST', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getRestrictedList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.findAll(payload.type, payload.params)
                .then((response) => {
                    commit('SET_RESTRICTED_LIST', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createRestrictedList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.create(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateRestrictedList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.update(payload.id, payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteRestrictedList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.delete(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addToRestrictedList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            RestrictedListService.addToRestrictedList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
