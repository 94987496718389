<template>
    <div class="container-fluid pt-3">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="$t('list.extensionWatchlist.title')" />
                <Card>
                    <div class="option-bar d-flex justify-content-between w-100 mb-3" v-if="meta">
                        <div class="option-wrapper">
                            <div class="row">
                                <div class="col">
                                    <div class="result-count">
                                        <span class="count">{{ meta.total }}</span>
                                        <small>{{ $t('list.result') }}</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <input v-model="requests.search" class="form-control input-sm" type="text" @input="searchInput" v-debounce:300ms="handleSearchInput" :placeholder="$t('list.extensionWatchlist.search')" />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <select @change="changePerPage" class="form-select">
                                        <option value="10">10 {{ $t('list.showing') }}</option>
                                        <option value="25">25 {{ $t('list.showing') }}</option>
                                        <option value="50" selected>50 {{ $t('list.showing') }}</option>
                                        <option value="100">100 {{ $t('list.showing') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select @change="changeCurrentPage" class="form-select" v-if="meta.last_page">
                                        <option v-if="[...Array(meta.last_page).keys()].length === 0" value="">{{ $t('list.no_result') }}</option>
                                        <option :key="page" v-for="page of [...Array(meta.last_page).keys()]" :value="page + 1">{{ page + 1 }}. {{ $t('list.page') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <select @change="handleIsLikedFilter" class="form-select input-sm" style="width: 170px">
                                            <option value="">{{ $t('common.all_liked') }}</option>
                                            <option value="true">{{ $t('common.liked') }}</option>
                                            <option value="false">{{ $t('common.not_liked') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action-wrapper">
                            <div class="d-flex flex-row" style="width: 25rem; justify-content: flex-end">
                                <button :disabled="selectedList.length === 0" class="dropdown btn btn-secondary btn-sm d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="true" style="min-height: 37.5px">
                                    <span>{{ $t('common.actions') }}</span>
                                    <span style="margin-left: 2px; font-weight: 600" v-if="selectedList.length > 0">({{ selectedList.length }})</span>
                                </button>
                                <div
                                    class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                                    aria-labelledby="accountNavbarDropdown"
                                    data-popper-placement="top-end"
                                    style="position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(-16px, -249px, 0px)"
                                >
                                    <a class="dropdown-item" href="#" @click.prevent="showDialog = true">{{ $t('actions.remove_from_list') }}</a>
                                    <!-- <a class="dropdown-item" href="#" @click.prevent="handleCreatePrepRequest">{{ $t('actions.create_package') }}</a> -->
                                    <a class="dropdown-item" :class="!hasSources && 'disabled'" href="#" @click.prevent="handleToggleAcions">{{ $t('actions.add_to_following_list') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 relative" v-if="data">
                        <div class="table-container extension-watchlist">
                            <table class="table table-sticky table-fixed">
                                <thead class="thead-light">
                                    <tr>
                                        <template v-for="(_, column) in columns" :key="column">
                                            <th v-if="!['select', 'isLiked', 'image', 'asin'].includes(column)" :class="{ 'fixed-width-column': column === 'notes' }">
                                                {{ $t(`list.extensionWatchlist.columns.${column}`) }}
                                            </th>
                                            <th v-if="column === 'select'" class="align-middle sticky-left">
                                                <input class="form-check-input" @change="handleCheckboxChange" type="checkbox" />
                                            </th>
                                            <th v-if="column === 'isLiked'" class="sticky-left" style="left: 47.5px !important; z-index: 1" scope="col">
                                                {{ $t('list.extensionWatchlist.columns.isLiked') }}
                                            </th>
                                            <th v-if="column === 'image'" class="sticky-left" style="left: 47.5px !important; z-index: 1" scope="col">
                                                {{ $t('list.extensionWatchlist.columns.image') }}
                                            </th>
                                            <th v-if="column === 'asin'" class="sticky-left" style="left: 118px !important" scope="col">
                                                {{ $t('list.extensionWatchlist.columns.asin') }}
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr v-for="(item, index) in data" :key="index" class="bg-white">
                                        <template v-for="(columnKey, column) in columns" :key="column">
                                            <td v-if="column === 'select'" class="align-middle sticky-left">
                                                <input ref="checkbox" :checked="selectedList.indexOf(item.id) > -1" @change="handleCheckboxSingleChange" :value="item.id" class="form-check-input" type="checkbox" />
                                            </td>
                                            <td v-if="column === 'isLiked'">
                                                <div class="icon-container" :class="[item.isLiked ? 'liked' : '']">
                                                    <i class="bi bi-hand-thumbs-up-fill"></i>
                                                </div>
                                            </td>
                                            <td v-if="column === 'asin'" class="align-middle sticky-left fw-bold" style="left: 118px !important">
                                                <a :href="getAmazonUrl(item.target, item.asin)" class="text-primary" target="_blank">{{ item.asin }}</a>
                                            </td>
                                            <td v-if="column === 'updatedAt'" scope="row">
                                                {{ formatDate(item.updatedAt) }}
                                            </td>
                                            <td v-if="column === 'image'" class="align-middle sticky-left" style="left: 47.5px !important" scope="row">
                                                <span v-if="item.image" class="avatar avatar-sm"><img :src="item.image" class="avatar-img" /> </span>
                                                <span v-else>-</span>
                                            </td>
                                            <td v-if="column === 'target'"><img :src="getImage(item.target)" width="24" height="24" alt="" /></td>
                                            <td v-if="column === 'source'">
                                                <img v-if="item.source && item.source !== 'COG'" :src="getImage(item.source)" width="24" height="24" alt="" /><span v-else>
                                                    <MarketSelect :title="$t('actions.select_source')" :preselect-first="false" :sources="true" :multiple="false" :item-id="item.id" @onSelect="handleSelectMarketSource"></MarketSelect>
                                                </span>
                                            </td>
                                            <td v-if="column === 'buyboxPrice'">
                                                <template v-if="item.buyboxPrice">
                                                    <small>{{ getCurrency(item.target) }}</small
                                                    >{{ (item.buyboxPrice || 0).toFixed(2) }}
                                                </template>
                                                <template v-else>-</template>
                                            </td>
                                            <td v-if="column === 'buyingPrice'">
                                                <small>{{ getCurrency(item.source === 'COG' || !item.source ? item.target : item.source) }}</small
                                                >{{ (item.buyingPrice || 0).toFixed(2) }}
                                            </td>
                                            <td v-if="!mutationColumns.includes(column) && ['warehouseCost', 'productCost', 'fbaProfit', 'fbmProfit'].includes(column)">
                                                <small>{{ getCurrency(item.target) }}</small
                                                >{{ getColumnData(item, columnKey).toFixed(2) }}
                                            </td>
                                            <td v-else-if="!mutationColumns.includes(column) && ['fbaRoi', 'fbmRoi', 'fbaMargin', 'fbmMargin'].includes(column)">
                                                <small>{{ getCurrency(item.target) }}</small
                                                >{{ getColumnData(item, columnKey).toFixed(2) }}
                                            </td>
                                            <td v-else-if="!mutationColumns.includes(column)">
                                                {{ getColumnData(item, columnKey) }}
                                            </td>
                                            <td v-if="column === 'notes'" class="align-middle fw-bold text-center" scope="row">
                                                <div class="input-group" style="z-index: 0">
                                                    <input v-model="item.notes" class="form-control input-sm" :placeholder="$t('list.extensionWatchlist.columns.notes')" />
                                                    <button @click="updateNote(item)" class="btn btn-soft-primary btn-sm" style="z-index: 0">
                                                        <font-awesome-icon :icon="['fas', 'check']" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td v-if="column === 'approvalRequired'">
                                                <div v-if="!item.approvalRequired && !item.buyboxPrice && !item.isMeltable">{{ $t('list.extensionWatchlist.noApprovalRequired') }}</div>
                                                <Popper v-if="item.isMeltable" class="light-popper" placement="left" hover arrow :content="$t('views.extension.meltableColumn')">
                                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="14.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="rgb(237, 76, 120)" stroke="none">
                                                            <path
                                                                d="M0 4216 l0 -223 173 -6 c193 -7 246 -17 433 -81 165 -57 247 -70 394
-63 129 7 172 17 345 77 164 56 282 73 465 66 161 -6 246 -23 405 -81 125 -46
223 -64 345 -64 122 0 220 18 345 63 162 59 245 76 405 82 179 7 301 -9 450
-61 176 -62 239 -76 360 -82 147 -7 231 6 395 62 196 67 241 76 433 82 l172 6
0 223 0 224 -2560 0 -2560 0 0 -224z"
                                                            />
                                                            <path
                                                                d="M0 3390 l0 -300 83 0 c180 0 291 -62 349 -195 10 -24 14 -208 18
-860 5 -825 5 -830 27 -877 84 -183 324 -235 471 -103 28 25 61 67 74 93 l23
47 5 830 c5 821 5 830 26 876 111 240 435 246 551 11 l28 -57 5 -380 c5 -370
6 -381 28 -422 58 -110 149 -167 266 -167 119 -1 216 59 274 169 l27 50 5 380
c4 332 7 385 22 418 31 69 76 115 145 149 54 27 77 32 133 32 120 0 228 -70
278 -181 16 -35 17 -116 22 -1018 l5 -980 28 -57 c66 -134 224 -200 365 -154
64 22 143 91 174 154 l23 47 5 980 c5 975 5 980 26 1026 89 191 323 244 477
109 51 -44 83 -103 96 -174 7 -38 11 -261 11 -611 0 -623 -2 -608 76 -694 60
-67 127 -95 224 -96 60 0 85 5 124 24 61 30 116 86 147 149 l24 47 5 605 c4
467 8 612 18 635 58 133 169 195 349 195 l83 0 0 300 0 300 -112 0 c-157 0
-230 -13 -397 -69 -209 -70 -240 -76 -446 -76 -205 0 -235 5 -442 75 -283 96
-448 95 -734 -4 -292 -102 -568 -103 -854 -1 -279 98 -472 100 -732 7 -186
-67 -244 -77 -448 -77 -205 0 -257 9 -458 80 -141 50 -232 65 -388 65 l-109 0
0 -300z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </Popper>
                                                <Popper v-if="item.approvalRequired" class="light-popper" hover placement="bottom" arrow>
                                                    <template #content> <div v-html="$t('views.extension.approvalRequired')"></div></template>
                                                    <a :href="item.approvalRequired[0].links[0].resource" class="btn btn-link p-0" target="_blank"> <i class="bi bi-lock-fill fs-5" style="color: #f5512d"></i></a
                                                ></Popper>
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
    <Dialog
        :options="{
            title: $t('dialogs.remove_from_following_list.title'),
            description: $t('dialogs.remove_from_following_list.description'),
        }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleRemoveFromList"
    />
    <AddtoFollowingListModal :showModal="isShowModal" :selectedItems="selectedList" :scanningType="'extension'" @closeModal="isShowModal = false" />
</template>

<script>
import Card from '@/components/Layouts/Card.vue'
import { convertObjectToQueryString, countryInfo } from '@/utils/helper'
import { mapActions } from 'vuex'
import moment from 'moment'
import PageHeader from '@/components/Layouts/PageHeader.vue'
import PrepRequestService from '@/services/prep_requests'
import AddtoFollowingListModal from '@/components/ProductSearch/AddtoFollowingListModal.vue'
import MarketSelect from '@/components/Inputs/MarketSelect.vue'

const countries = countryInfo()

export default {
    name: 'ProductSearchHistory',
    components: {
        Card,
        PageHeader,
        AddtoFollowingListModal,
        MarketSelect,
    },
    data: function () {
        return {
            requests: {
                page: 1,
                per_page: 10,
                sort: [],
                search: '',
                filtration: [],
                isLiked: '',
            },
            columns: {
                select: '',
                image: 'image',
                isLiked: 'isLiked',
                asin: 'asin',
                updatedAt: 'updatedAt',
                target: 'target',
                source: 'source',
                buyboxPrice: 'buyboxPrice',
                buyingPrice: 'buyingPrice',
                warehouseCost: 'calculatedData.fba.warehouseCostOnSource',
                productCost: 'calculatedData.fba.productCost',
                fbaProfit: 'calculatedData.fba.profit',
                fbmProfit: 'calculatedData.fbm.profit',
                fbaRoi: 'calculatedData.fba.roi',
                fbmRoi: 'calculatedData.fbm.roi',
                fbaMargin: 'calculatedData.fba.profitMargin',
                fbmMargin: 'calculatedData.fbm.profitMargin',
                dimension: 'dimension.metric.dimensionFlat',
                dimWeight: 'dimension.metric.dimWeight',
                amount: 'dimension.maxItemOnPackage',
                category: 'bsrCategory',
                brand: 'brand',
                bsr: 'bsr',
                notes: 'notes',
                approvalRequired: 'approvalRequired',
            },
            mutationColumns: ['select', 'image', 'asin', 'updatedAt', 'target', 'source', 'buyboxPrice', 'buyingPrice', 'notes', 'approvalRequired', 'isLiked'],
            selectedList: [],
            querystring: '',
            showDialog: false,
            toggleActions: false,
            isShowModal: false,
            hasSources: false,
            data: null,
            itemSources: [],
        }
    },
    computed: {},
    mounted() {
        this.prepareData()
    },
    methods: {
        ...mapActions('extension', ['history', 'setManualUpdate', 'removeFromList']),
        getImage(src) {
            return require('@/assets/svg-flag/' + src + '.svg')
        },
        getCurrency(short) {
            if (countries[short]) {
                return countries[short].symbol + ' '
            }
            return short
        },
        getSymbol(short) {
            return countries[short].symbol
        },
        handleSelectMarketSource(market) {
            if (!market.id && !market.short) return
            this.setManualUpdate({ id: market.itemId, data: { source: market.short } }).then(() => {
                this.prepareData()
                this.selectedList = []
                this.$toast.success(this.$t('list.extensionWatchlist.messages.updated'))
            })
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
        handleIsLikedFilter(e) {
            this.requests.isLiked = e.target.value
            this.querystringChanged(this.requests)
        },
        handleToggleAcions() {
            this.toggleActions = !this.toggleActions
            this.isShowModal = true
        },
        getColumnData(obj, key) {
            let value = obj
            for (const property of key.split('.')) {
                // eslint-disable-next-line no-prototype-builtins
                if (value?.hasOwnProperty(property)) {
                    value = value[property]
                } else {
                    return undefined
                }
            }

            return value
        },
        getAmazonUrl(target, asin) {
            return `https://www.${countries[target].domain}/dp/${asin}`
        },
        updateNote(data) {
            this.setManualUpdate({ asin: data.asin, domain: countries[data.target].domain, data: { notes: data.notes } }).then(() => {
                this.$toast.success(this.$t('list.extensionWatchlist.messages.updated'))
            })
        },
        handleCheckboxChange: function (e) {
            const checked = e.target.checked
            const allInputs = this.$refs.checkbox

            if (!allInputs) return

            if (checked) {
                allInputs.forEach((input) => {
                    if (input.value && input.value !== 'on') {
                        console.log(input.value)
                        if (!this.selectedList.includes(input.value)) {
                            this.selectedList.push(input.value)
                            input.checked = true
                        }
                    }
                })
            } else {
                this.selectedList = []
                allInputs.forEach((input) => {
                    input.checked = false
                })
            }

            this.hasSources = true
            for (const selected of this.selectedList) {
                const item = this.data.find((item) => item.id === selected)
                if (!item.source || (item.source && item.source === 'COG')) {
                    this.hasSources = false
                    return
                }
            }
            console.log(this.hasSources)
        },
        handleCheckboxSingleChange: function (e) {
            const value = e.target.value
            const checked = e.target.checked

            if (checked) {
                this.selectedList.push(value)
                // eslint-disable-next-line vue/no-mutating-props
            } else {
                this.selectedList = this.selectedList.filter((item) => item !== value)
                // eslint-disable-next-line vue/no-mutating-props
            }

            // check if data of id has source
            this.hasSources = true
            for (const selected of this.selectedList) {
                const item = this.data.find((item) => item.id === selected)
                if (!item.source || (item.source && item.source === 'COG')) {
                    this.hasSources = false
                    return
                }
            }
            console.log(this.hasSources)
        },
        searchInput: function (e) {
            this.requests.search = e.target.value
        },
        handleSearchInput: function () {
            this.page = 1
            this.requests.page = 1
            this.querystringChanged(this.requests)
        },
        querystringChanged(data) {
            this.querystring = convertObjectToQueryString(data)
            this.prepareData()
        },
        changePerPage: function (e) {
            this.page = 1
            this.requests.page = 1
            this.requests.perPage = e.target.value
            this.querystringChanged(this.requests)
        },
        changeCurrentPage: function (e) {
            this.page = e.target.value
            this.requests.page = e.target.value
            this.querystringChanged(this.requests)
        },
        async handleCreatePrepRequest() {
            const response = await PrepRequestService.create('following-list', {
                product_ids: this.selectedList,
            })
            if (response.data) {
                this.$router.push({
                    name: 'create-prep-request',
                })
            }
        },
        async handleRemoveFromList() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            await this.removeFromList(this.selectedList)
            await this.prepareData()
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.selectedList = []
            this.toggleActions = false
            this.showDialog = false
        },
        async prepareData() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })

            const response = await this.history({
                params: this.querystring,
            })
            this.data = response.data.data
            this.meta = {
                current_page: response.data.current_page,
                last_page: response.data.last_page,
                item_count: response.data.item_count,
                per_page: response.data.per_page,
                total: response.data.total,
            }

            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    watch: {
        page() {
            this.prepareData()
        },
    },
}
</script>

<style lang="scss">
.extension-watchlist {
    td {
        vertical-align: middle;
        height: 85px;
        font-weight: 700;
        z-index: 0;
        background-color: #ffffff;
    }
    .multiselect {
        min-width: 190px !important;
    }
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    background-color: #ccc;
}
.icon-container.liked {
    background-color: rgb(253, 148, 5);
}
</style>
