<template>
    <Spinner v-if="!activePackage || (activePackage && !activePackage.package)" />
    <div v-else class="card card-lg form-check form-check-select-stretched h-100 zi-1">
        <div class="card-header text-center">
            <span class="card-subtitle">{{ title || $t('cards.package.current_package') }}</span>
            <h3 class="card-title display-4 text-dark m-0" style="font-size: 22px">{{ $t(`components.subscription.overview.packages.${activePackage.package.name}`) }}</h3>
            <p class="card-text">
                <strong>{{ getFormattedPrice(activePackage.package.basePrice / 100) }}</strong
                ><small> / {{ $t('common.monthly') }}</small>
            </p>
        </div>

        <div class="card-body d-flex justify-content-center p-3">
            <ul class="list-checked list-checked-primary mb-0">
                <li class="list-checked-item" v-for="feature in activePackage.package.features" :key="feature">
                    {{ feature.value === 0 && feature.productFeature.name !== 'request_limit' ? $t('common.unlimited') : formatThousandsDecimal(feature.value) }} {{ getFeatureName(feature.productFeature.name) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { number_format } from '@/utils/helper'
import Spinner from '../Spinner.vue'

export default {
    name: 'PackageSummary',
    components: { Spinner },
    props: {
        activePackage: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
    },
    methods: {
        getFeatureName(key) {
            if (key === 'request_limit') {
                return 'Token'
            } else if (key === 'store_limit') {
                return this.$t('common.store')
            }
        },
        getFormattedPrice(price) {
            return number_format(price, 'money', {
                style: 'currency',
                currency: 'USD',
            })
        },
        formatThousandsDecimal(value) {
            return number_format(value, 'money', {
                style: 'decimal',
                decimal: '',
                thousand: '.',
            })
        },
    },
}
</script>
