<template>
    <div>
        <div id="creditCardModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 v-if="payWithDifferentCard" class="modal-title" id="accountAddCardModalLabel">{{ $t('components.subscription.paymentMethod.payWithDifferentCard') }}</h2>
                        <h2 v-else class="modal-title" id="accountAddCardModalLabel">{{ $t('components.subscription.paymentMethod.addCard') }}</h2>
                        <button type="button" class="btn-close" @click="hideModal"></button>
                    </div>
                    <div class="modal-body">
                        <form id="payment-form">
                            <div id="payment-element" ref="paymentElement">
                                <!-- Elements will create form elements here -->
                            </div>
                            <div id="error-message">
                                <!-- Display error message to your customers here -->
                            </div>
                        </form>
                        <!-- <form ref="newCardForm">
                            <div class="mb-4">
                                <label for="cardNameLabel" class="form-label">{{ $t('components.subscription.paymentMethod.cardOwner') }}</label>
                                <input
                                    v-model="card_name"
                                    :class="v$.card_name.$error && 'is-invalid'"
                                    v-maska:[options]
                                    data-maska="A A A"
                                    type="text"
                                    class="form-control"
                                    id="cardNameLabel"
                                    :placeholder="$t('components.subscription.paymentMethod.fullName')"
                                    aria-label=""
                                    :vuelidate="v$"
                                />
                                <span class="invalid-feedback" v-if="v$.card_name.$error"
                                    ><small>{{ $t('components.subscription.paymentMethod.validName') }}</small></span
                                >
                            </div>
                            <div class="mb-4">
                                <label for="cardNumberLabel" class="form-label">{{ $t('components.subscription.paymentMethod.cardNumber') }}</label>
                                <input
                                    v-model="card_number"
                                    :class="v$.card_name.$error && 'is-invalid'"
                                    class="form-control"
                                    v-maska
                                    :data-maska="cardType === 'amex' ? '#### ###### #####' : '#### #### #### ####'"
                                    id="cardNumberLabel"
                                    :placeholder="cardType === 'amex' ? 'xxxx xxxxxx xxxxx' : 'xxxx xxxx xxxx xxxx'"
                                    type="text"
                                    :vuelidate="v$"
                                />
                                <span class="invalid-feedback" v-if="v$.card_number.$error"
                                    ><small>{{ $t('components.subscription.paymentMethod.validCC') }}</small></span
                                >
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-4">
                                        <label for="expirationDateLabel" class="form-label">{{ $t('components.subscription.paymentMethod.expirationDate') }}</label>
                                        <input :class="v$.card_name.$error && 'is-invalid'" v-model="card_expired" class="form-control" v-maska data-maska="##/##" id="cardNumberLabel" :placeholder="'MM/YY'" type="text" :vuelidate="v$" />
                                        <span class="invalid-feedback" v-if="v$.card_expired.$error"
                                            ><small>{{ $t('components.subscription.paymentMethod.validNExp') }}</small></span
                                        >
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="mb-4">
                                        <label for="securityCodeLabel" class="form-label"
                                            >{{ $t('components.subscription.paymentMethod.cvv') }}
                                            <i class="bi-question-circle text-body ms-1" data-toggle="tooltip" data-placement="top" title="A 3 - digit number, typically printed on the back of a card."></i
                                        ></label>
                                        <input
                                            :class="v$.card_name.$error && 'is-invalid'"
                                            v-model="card_cvc"
                                            type="text"
                                            v-maska
                                            :data-maska="cardType === 'amex' ? '####' : '###'"
                                            class="form-control"
                                            id="securityCodeLabel"
                                            placeholder="xxx"
                                            :vuelidate="v$"
                                        />
                                        <span class="invalid-feedback" v-if="v$.card_cvc.$error"
                                            ><small>{{ $t('components.subscription.paymentMethod.validCVV') }}</small></span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="form-check mb-4" v-if="payWithDifferentCard">
                                <input v-model="save_card" type="checkbox" class="form-check-input" id="makePrimaryCheckbox2" />
                                <label class="form-check-label" for="makePrimaryCheckbox2">{{ $t('components.subscription.paymentMethod.saveCard') }}</label>
                            </div>
                            <div class="form-check mb-4">
                                <input v-model="default_card" type="checkbox" class="form-check-input" id="makePrimaryCheckbox1" />
                                <label class="form-check-label" for="makePrimaryCheckbox1">{{ $t('components.subscription.paymentMethod.setDefault') }}</label>
                            </div>
                            <div class="d-flex justify-content-end gap-3">
                                <button type="button" class="btn btn-white" @click="hideModal">{{ $t('actions.cancel') }}</button>
                                <button type="button" class="btn btn-primary" @click="handleSubmit">
                                    <Spinner v-if="form_submitted" />
                                    <span v-if="!form_submitted">{{ $t('actions.save') }}</span>
                                </button>
                            </div>
                        </form> -->
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-end gap-3">
                            <button type="button" class="btn btn-white" @click="hideModal">Vazgeç</button>
                            <button type="button" class="btn btn-primary" @click="handleSubmit">
                                <Spinner v-if="form_submitted" />
                                <span v-if="!form_submitted">Kaydet</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap'
import { useVuelidate } from '@vuelidate/core'

import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { vMaska } from 'maska'
import WalletService from '@/services/wallet'
import Spinner from '../Spinner.vue'

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
export default {
    name: 'AddCreditCardModal',
    setup() {
        return { v$: useVuelidate() }
    },
    components: { Spinner },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        payWithDifferentCard: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        maska: vMaska,
    },
    data: () => ({
        form_submitted: false,
        modalInstance: null,
        form_id: Math.round(Math.random() * 1000),
        me: null,
        card_number: '',
        card_name: '',
        card_expired: '',
        card_cvc: '',
        default_card: false,
        paymentElement: null,
        elements: null,
        save_card: true,
        options: {
            tokens: {
                A: {
                    pattern: /[A-Z]/,
                    multiple: true,
                    transform: (chr) => chr.toUpperCase(),
                },
            },
        },
    }),
    validations() {
        return {
            card_number: {
                required,
                minLength: minLength(18),
                maxLength: maxLength(19),
            },
            card_name: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(26),
            },
            card_expired: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
            card_cvc: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(4),
            },
        }
    },
    created() {
        this.elements = null
        this.paymentElement = null
    },
    unmounted() {
        this.elements = null
        this.paymentElement = null
        console.log({
            elements: this.elements,
        })
    },
    mounted() {
        this.emitter.on('credit-modal:submitted', (response) => {
            console.log(response)
            if (response) {
                this.hideModal()
            }
            this.form_submitted = false
        })
    },
    computed: {
        cardType() {
            const digit = this.card_number.toString()[0]
            if (this.card_number.length > 3) {
                return digit === '4' ? 'visa' : digit === '5' ? 'master' : digit === '6' ? 'maestro' : digit === '3' ? 'amex' : false
            }
            return false
        },
    },
    watch: {
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.form_submitted = false
                this.resetForm()
                this.modalActive()
            }
        },
    },
    methods: {
        async createSession() {
            // const response = await fetch('http://localhost:3005/create-session').then((res) => res.json())
            // const result = await stripe.initEmbeddedCheckout({
            //     clientSecret: response.clientSecret,
            // })
            // result.mount(this.$refs.checkoutForm)

            const options = {
                mode: 'setup',
                currency: 'usd',
                payment_method_types: ['card'],
                setupFutureUsage: 'off_session',
                paymentMethodCreation: 'manual',
            }

            // Set up Stripe.js and Elements to use in checkout form
            this.elements = stripe.elements(options)

            // Create and mount the Payment Element
            const paymentElement = this.elements.create('payment')
            paymentElement.mount(this.$refs.paymentElement)

            this.paymentElement = paymentElement
        },
        async handleSubmit() {
            this.form_submitted = true
            await this.elements.submit()
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                elements: this.elements,
            })
            console.log(paymentMethod)
            if (error) {
                this.$toast.error(error.message || 'Bir hata oluştu. Tekrar deneyin.')
                this.form_submitted = false

                return
            }
            try {
                const { data } = await WalletService.createIntent()

                if (data) {
                    const { error, setupIntent } = await stripe.confirmSetup({
                        elements: this.elements,
                        clientSecret: data.clientSecret,
                        confirmParams: {
                            return_url: window.location.origin + '/packages',
                        },
                        redirect: 'if_required',
                    })

                    if (error) {
                        this.$toast.error(error.message)
                        this.form_submitted = false
                        return
                    }
                    if (setupIntent.payment_method) {
                        await WalletService.saveCreditCard({
                            pmId: setupIntent.payment_method,
                        })
                        this.$toast.success('Kart başarıyla kaydedildi.')
                        this.form_submitted = false
                        this.$emit('handleSubmit')
                    }
                }
            } catch (e) {
                console.log(e)
                this.$toast.error('Hata oluştu. Tekrar deneyin.')
                this.form_submitted = false
            }
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('creditCardModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: true,
            })
            this.createSession()
            this.modalInstance.show()
        },
        hideModal: function () {
            this.v$.$reset()
            if (this.modalInstance) {
                this.modalInstance.hide()
                this.elements = null
                this.paymentElement = null
            }
            this.$emit('closeCreditModal')
        },
        resetForm: function () {
            this.card_number = ''
            this.card_name = ''
            this.card_expired = ''
            this.card_cvc = ''
            this.default_card = false
            this.save_card = true
        },
    },
}
</script>

<style scoped></style>
