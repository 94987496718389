<script>
export default {
    name: 'ExtensionKeepaError',
    props: {
        message: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            alwaysUseKeepa: false,
        }
    },
    methods: {
        handleHideModal() {
            const modal = document.querySelector('.keepa-error-modal')
            if (modal) {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }
        },
    },
}
</script>

<!-- eslint-disable max-len -->

<template>
    <div class="modal fade keepa-error-modal" tabindex="-1" role="dialog" aria-labelledby="keepa-error-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mx-auto" role="document" style="width: 330px">
            <div class="modal-content shadow-lg">
                <!-- Header -->
                <div class="modal-top-cover bg-dark text-center">
                    <figure class="position-absolute end-0 bottom-0 start-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                        </svg>
                    </figure>

                    <div class="modal-close">
                        <button type="button" @click="handleHideModal" class="btn-close btn-close-light" aria-label="Close"></button>
                    </div>
                </div>
                <!-- End Header -->
                <div class="modal-top-cover-icon">
                    <span class="icon icon-light icon-circle icon-centered shadow-sm">
                        <svg id="katman_2" data-name="katman 2" width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.55 128.53">
                            <g id="Layer_1" data-name="Layer 1">
                                <g>
                                    <polygon fill="#00203f" points="84.74 105.43 117.55 100.51 60.95 57.35 60.92 128.53 84.74 105.43" />
                                    <path
                                        class="cls-2"
                                        fill="#fd9403"
                                        d="m106.29,52.76c-2.84-21.15-19.48-37.95-40.56-41.03V0h-12.81v11.57C31.32,14.21,14.15,31.24,11.26,52.76H0v12.81h11.26c3,22.31,21.35,39.79,44.06,41.41v-11.21c-16.53-1.54-29.9-14.08-32.75-30.2h10.2v-12.81h-10.19c2.71-15.33,14.92-27.38,30.33-29.86v9.88h12.81v-9.71c14.89,2.86,26.59,14.73,29.23,29.69h-10.19v12.81h32.78v-12.81h-11.26Z"
                                    />
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>

                <div class="modal-body">
                    <p style="hyphens: auto" v-html="message === 'no_token' ? $t('views.extension.alertKeepaErrorNoToken') : $t('views.extension.alertKeepaError')"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-white p-1" @click="handleHideModal">{{ $t('actions.ok') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.keepa-error-modal {
    .modal-top-cover {
        min-height: 4rem;
    }

    .modal-body {
        padding: 0 1rem;
    }

    .modal-footer {
        padding: 0.5rem;

        button {
            font-size: 11px;
        }
    }
    .btn-close {
        width: 0.5rem;
        height: 0.5rem;
    }
}
</style>
