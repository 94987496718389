<template>
    <div
        :class="{
            card: true,
            'mb-3': true,
            'pb-3': true,
            'card--lg': size === 'lg',
            'card--md': size === 'md',
            'card--sm': size === 'sm',
            'card--xs': size === 'xs',
        }"
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'Card',
    components: {},
    props: {
        size: {
            type: String,
            default: 'lg',
        },
        sectioned: {
            type: Boolean,
            default: false,
        },
        noSpacing: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
    },
}
</script>
