<template>
    <div class="container-fluid py-3 py-sm-3 mb-2" style="max-width: 1600px">
        <div class="row justify-content-lg-center">
            <div class="col-lg-5 position-relative">
                <div class="position-sticky">
                    <Card>
                        <CardSection :title="id ? $t('cards.following_list.edit') : $t('cards.following_list.create_new')"></CardSection>
                        <div class="bottom-rounded">
                            <div class="pl-2 pr-2">
                                <div class="mb-3 mt-4">
                                    <label for="min_stock" class="align-self-center">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.following_list.labels.name') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <input v-model="name" class="form-control" :class="errors.name && 'is-invalid'" :placeholder="$t('inputs.following_list.labels.name')" />
                                    <span class="invalid-feedback" v-if="errors.url"
                                        ><small>{{ errors.name }}</small></span
                                    >
                                </div>
                                <div class="mb-3">
                                    <label for="min_stock" class="align-self-center">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.following_list.labels.type') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <select id="type" v-model="type" class="form-select">
                                        <option value="manual">{{ $t('common.manual') }}</option>
                                        <option value="daily">{{ $t('common.automatic') }}</option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="type === 'daily'">
                                    <label for="min_stock" class="align-self-center">
                                        <span class="flex-row">
                                            <div>{{ $t('inputs.following_list.labels.frequency') }}</div>
                                        </span>
                                        <small class="text-muted"></small>
                                    </label>
                                    <select v-model="selectedFrequency" class="form-select">
                                        <option v-for="list in frequencyList" :key="list.value" :value="list.value">{{ list.label }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="bordered-top mt-3">
                            <div class="d-flex justify-content-end mt-3 mr-2">
                                <button class="btn btn-primary" @click="handleFormSubmit">{{ id ? $t('actions.edit_following_list') : $t('actions.create_following_list') }}</button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Card from '@/components/Layouts/Card.vue'
import CardSection from '@/components/Layouts/CardSection.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'FollowingListCreate',
    data() {
        return {
            errors: {},
            name: '',
            type: 'manual',
            frequencyList: [],
            selectedFrequency: 6,
            notify_discount: false,
            notify_out_of_stock: false,
        }
    },
    methods: {
        ...mapActions('followingList', ['createFollowingList', 'getFollowingListDetail', 'editFollowingList']),
        handleFormSubmit: async function () {
            this.errors = {}
            if (this.name.length === 0) {
                this.errors.name = this.$t('inputs.following_list.errors.name_required')
            }
            if (Object.keys(this.errors).length > 0) {
                window.scrollTo(0, 70)
                return
            }
            this.$store.commit('set', { k: 'loading', v: { status: true, text: null } })
            try {
                if (this.id && this.id > 0) {
                    await this.editFollowingList({
                        id: this.id,
                        data: {
                            name: this.name,
                            type: this.type,
                            frequency: this.selectedFrequency,
                            notify_discount: this.notify_discount,
                            notify_out_of_stock: this.notify_out_of_stock,
                            notify_out_of_stock_count: this.notify_out_of_stock_count,
                        },
                    })

                    this.$toast.success(this.$t('inputs.following_list.success.update'))
                } else {
                    await this.createFollowingList({
                        name: this.name,
                        type: this.type,
                        frequency: this.selectedFrequency,
                        notify_discount: this.notify_discount,
                        notify_out_of_stock: this.notify_out_of_stock,
                        notify_out_of_stock_count: this.notify_out_of_stock_count,
                    })
                    this.$toast.success(this.$t('inputs.following_list.success.create'))
                }
                this.$router.push({ name: 'following-list.list' })
            } catch (e) {
                console.log(e)
            }

            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
    components: { Card, CardSection },
    mounted() {
        this.frequencyList = [
            {
                value: 6,
                label: this.$t('common.hourly', { count: 6 }),
            },
            {
                value: 12,
                label: this.$t('common.hourly', { count: 12 }),
            },
            {
                value: 24,
                label: this.$t('common.hourly', { count: 24 }),
            },
            {
                value: 36,
                label: this.$t('common.hourly', { count: 36 }),
            },
            {
                value: 48,
                label: this.$t('common.hourly', { count: 48 }),
            },
            {
                value: 72,
                label: this.$t('common.hourly', { count: 72 }),
            },
        ]

        if (this.id) {
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })
            ;(async () => {
                await this.getFollowingListDetail(this.id)
                this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
            })()
        }
    },
    computed: {
        ...mapGetters('followingList', ['followingListDetail']),
        id: {
            get() {
                return this.$route.params.id
            },
            set(v) {
                this.$router.push({ name: 'following-list.edit', params: { id: v } })
            },
        },
    },
    watch: {
        followingListDetail: {
            handler: function (val) {
                if (val) {
                    this.name = val.name
                    this.type = val.type
                    this.selectedFrequency = val.frequency
                    this.notify_discount = val.notify_discount
                    this.notify_out_of_stock = val.notify_out_of_stock
                    this.notify_out_of_stock_count = val.notify_out_of_stock_count
                } else {
                    this.name = ''
                    this.type = 'manual'
                    this.selectedFrequency = 6
                    this.notify_discount = false
                    this.notify_out_of_stock = false
                    this.notify_out_of_stock_count = 0
                }
            },
            deep: true,
        },
    },
}
</script>

<style>
.filter-item-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}
.asin-list {
    height: 100px !important;
    display: block;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    min-height: 250px !important;
    margin-bottom: 5px !important;
    max-height: 600px !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffe3be47), color-stop(50%, #fff));
    background-image: linear-gradient(#ffe3be47 50%, #fff 0);
    background-attachment: local;
    background-size: 100% 50px !important;
    line-height: 25px !important;
    font-size: 14px !important;
    padding: 0 8px !important;
}
.filter-item-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
    align-items: flex-start;
    line-height: normal;
}
.filter-item-row input[type='checkbox'] {
    margin-right: 8px;
}
input.check {
    display: none;
}
input.check[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    height: 20px;
    padding-left: 30px;
    padding-top: 3px;
}
input.check[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    -webkit-box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    box-shadow: inset 0 0 0 2px rgba(63, 87, 110, 0.2);
    border-radius: 4px;
}
input.check[type='checkbox']:checked + label:before {
    background-color: #fd9405;
    -webkit-box-shadow: inset 0 0 0 1px #fd9405;
    box-shadow: inset 0 0 0 1px #fd9405;
}
input.check[type='checkbox']:checked + label:after {
    content: '';
    width: 11px;
    height: 6px;
    display: block;
    position: absolute;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 5px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.star-select-box {
    position: relative;
    max-width: 190px;
    height: 45px;
    width: 100%;
}
.star-select-box .star-box {
    width: 100%;
    height: 45px;
    padding: 5px 8px;
    background-color: #fff;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.star-select-box ul {
    padding-left: 0;
}
.star-select-box ul.star-list {
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    border: 1px solid #dbe3ef;
    border-radius: 5px;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.star-select-box .active-star-list {
    opacity: 1 !important;
    pointer-events: auto !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.star-select-box ul.star-list li {
    padding: 0;
    margin: 0;
    list-style: none;
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #ecf0f7;
    font-size: 14px;
}
</style>
