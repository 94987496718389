import { ApiInstance } from './base'

const RestrictedListService = {
    findAll(query) {
        return ApiInstance.get('/following-list/?' + query)
    },
    getFollowingListDetail(id) {
        return ApiInstance.get('/following-list/' + id + '/detail')
    },
    create(payload) {
        return ApiInstance.post('/following-list', payload)
    },
    edit(id, payload) {
        return ApiInstance.put('/following-list/' + id + '/update', payload)
    },
    update(id, payload) {
        return ApiInstance.put('/following-list/' + id, payload)
    },
    delete(ids) {
        return ApiInstance.post('/following-list/delete', { ids })
    },
    findOne(id, payload) {
        return ApiInstance.get('/following-list/' + id + '?' + payload)
    },
    removeFromFollowingList(payload) {
        return ApiInstance.post('/following-list/remove-from/following-list', payload)
    },
    moveToAnotherList(payload) {
        return ApiInstance.post('/following-list/move-to', payload)
    },
    getFollowingProduct(id) {
        return ApiInstance.get('/following-list/product/' + id)
    },
    mergeList(payload) {
        return ApiInstance.post('/following-list/merge', payload)
    },
    updateFollowingListItem(payload) {
        return ApiInstance.post('/following-list/update-item', payload)
    },
    refreshFollowingList(id) {
        return ApiInstance.post('/following-list/refresh/' + id)
    },
}

export default RestrictedListService
