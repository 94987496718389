<template>
    <div class="dropdown">
        <button class="btn btn-ghost-secondary btn-icon rounded-circle" @click="setLocale(showLocale)">
            <span class="text-uppercase">{{ $i18n.locale }}</span>
        </button>
    </div>
</template>

<script>
export default {
    computed: {
        showLocale() {
            return this.$i18n.locale === 'tr' ? 'en' : 'tr'
        },
    },
    methods: {
        setLocale(lang) {
            localStorage.setItem('lang', lang)
            window.parent.postMessage({ lang })

            this.$i18n.locale = lang
        },
    },
}
</script>
