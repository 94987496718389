import SettingsService from '@/services/settings'

const state = {
    settings: null,
}

const getters = {
    settings: (state) => state.settings,
}

const mutations = {
    SET_SETTINGS(state, payload) {
        state.settings = payload
    },
}

const actions = {
    getSettings({ commit }) {
        return new Promise((resolve, reject) => {
            SettingsService.getSystemSettings()
                .then((response) => {
                    commit('SET_SETTINGS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
