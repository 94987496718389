<template>
    <Card>
        <CardSection :title="$t('cards.package.title')"></CardSection>
        <Spinner v-if="false" />
        <div class="overflow-hidden bottom-rounded" v-if="true">
            <div class="d-flex bordered-top" style="padding-top: 1rem">
                <div class="ml-1" :class="walletPackage && walletPackage.package && 'col-3'" v-if="walletPackage && walletPackage.package">
                    <PackageSummary :activePackage="walletPackage" />
                </div>
                <div class="col-9" :class="walletPackage && walletPackage.package ? 'col-3' : 'col-12'">
                    <Spinner v-if="!packageList || (packageList && packageList.length === 0)" />
                    <template v-else>
                        <h3 class="fw-bold ml-1 mr-1 mb-2" style="font-size: 16px; width: 93%" v-if="walletPackage && walletPackage.package">{{ $t('cards.package.select_plan') }}</h3>
                        <div class="fba-divider ml-1 mr-1" style="width: 93%" v-if="walletPackage && walletPackage.package"></div>
                        <ul class="list-group list-group-sm ml-1 mr-1 m-auto" style="padding: 0; width: 93%">
                            <template v-for="packageL in packageList" :key="packageL.walletPriceId">
                                <li v-if="+packageL.basePrice > 0" class="list-group-item list-group-item-light mb-2" style="padding: 0.5rem 0.25rem">
                                    <div class="row gx-1">
                                        <div class="col-sm-3 align-self-center text-center">
                                            <span class="text-dark" style=""
                                                ><strong>{{ $t(`components.subscription.overview.packages.${packageL.name}`) }}</strong></span
                                            >
                                        </div>
                                        <div class="col-sm-3 align-self-center d-flex justify-content-end flex-column text-right">
                                            <span class="text-dark" style="float: right" v-for="feature in packageL.features" :key="feature.id"
                                                ><strong>{{ feature.value === 0 ? $t('common.unlimited') : formatThousandsDecimal(feature.value) }}</strong> {{ getFeatureName(feature.productFeature.name) }}</span
                                            >
                                        </div>
                                        <div class="col-sm-3 align-self-center">
                                            <span class="text-dark" style="float: right; font-size: 15px; font-weight: 600; padding: 0 10px"
                                                >{{ getFormattedPrice(packageL.basePrice / 100) }}<small style="font-size: 13px; font-weight: 400"> / {{ $t('common.monthly') }}</small></span
                                            >
                                        </div>
                                        <div class="col-sm-3 align-self-center text-center">
                                            <button
                                                disabled
                                                class="btn btn-outline-success btn-sm"
                                                v-if="walletPackage && walletPackage.package && packageL.walletPriceId === walletPackage.package.walletPriceId && (walletPackage.status === 0 || walletPackage.status === 1)"
                                            >
                                                {{ $t('common.active') }}
                                            </button>
                                            <template v-else>
                                                <button disabled v-if="selectedPackage === packageL.walletPriceId" class="btn btn-outline-primary btn-sm" style="text-transform: capitalize">{{ $t('common.selected') }}</button>
                                                <button v-else-if="selectedPackage !== packageL.walletPriceId" @click="handlePackageSelect(packageL.walletPriceId)" class="btn btn-outline-primary btn-sm">{{ $t('common.select') }}</button>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>

                        <h3 class="fw-bold ml-1 mr-1 mb-2 mt-4" style="font-size: 16px; width: 93%" v-if="selectedPackage && coupons && coupons.length > 0">{{ $t('cards.package.select_discount') }}</h3>
                        <div class="fba-divider ml-1 mr-1" style="width: 93%" v-if="selectedPackage && coupons && coupons.length > 0"></div>
                        <ul v-if="selectedPackage" class="list-group list-group-sm ml-1 mr-1 m-auto" style="padding: 0; width: 93%">
                            <template v-for="coupon in coupons" :key="coupon.id">
                                <li v-if="!coupon.used" class="list-group-item list-group-item-light mb-2" style="padding: 0.5rem 0.25rem">
                                    <div class="row gx-1">
                                        <div class="col-sm-3 align-self-center text-center">
                                            <span class="text-dark" style=""
                                                ><strong>{{ coupon.coupon.name }}</strong></span
                                            >
                                        </div>
                                        <div class="col-sm-3 align-self-center d-flex justify-content-end flex-column text-right">
                                            <span class="text-dark" style="float: right">{{ coupon.coupon.description }}</span>
                                        </div>
                                        <div class="col-sm-3 align-self-center">
                                            <span class="text-dark fw-bold" style="float: right">{{ getCouponAmount(coupon.coupon) }}</span>
                                        </div>
                                        <div class="col-sm-3 align-self-center text-center">
                                            <template v-if="selectedCoupon === coupon.coupon.id">
                                                <button disabled class="btn btn-outline-primary btn-sm" style="text-transform: capitalize">{{ $t('common.selected') }}</button>
                                                <a href="#" class="text-danger" @click.prevent="couponChange(null)">
                                                    <i class="fa fa-times"></i>
                                                    {{ $t('actions.no') }}</a
                                                >
                                            </template>
                                            <template
                                                v-else-if="packageList && packageList.find((packages) => selectedPackage === packages.externalPriceId) && packageList.find((packages) => selectedPackage === packages.externalPriceId).basePrice < 4990"
                                            >
                                                <button disabled class="btn btn-outline-danger btn-sm" @click="couponChange(coupon.coupon.id)">{{ $t('common.unusable') }}</button>

                                                <Popper placement="top" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> {{ $t('cards.package.discount_warning') }}</template>
                                                    <i class="fa fa-info-circle text-danger"></i>
                                                </Popper>
                                            </template>

                                            <button v-else class="btn btn-outline-primary btn-sm" @click="couponChange(coupon.coupon.id)">{{ $t('common.select') }}</button>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </template>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Layouts/Card'
import CardSection from '@/components/Layouts/CardSection'
import Spinner from '@/components/Spinner'
import { number_format } from '@/utils/helper'
import { mapActions, mapGetters } from 'vuex'
import WalletService from '@/services/wallet'
import PackageSummary from './PackageSummary.vue'
export default {
    name: 'PackageList',
    components: {
        Card,
        CardSection,
        Spinner,
        PackageSummary,
    },
    data: () => ({
        selectedPackage: null,
        packageDetail: null,
        packageDetailAlert: false,
        selectedCoupon: null,
    }),
    mounted() {
        this.getAllPackages()
        this.getUserPackage()
        this.getCoupons()
    },
    computed: {
        ...mapGetters('auth', ['packageList']),
        ...mapGetters('wallet', ['walletPackage', 'coupons']),
    },
    methods: {
        ...mapActions('auth', ['getAllPackages']),
        ...mapActions('wallet', ['getUserPackage', 'getCoupons']),
        ...mapActions('payment', ['setSelectedPackage']),
        formatThousandsDecimal(value) {
            return number_format(value, 'money', {
                style: 'decimal',
                decimal: '',
                thousand: '.',
            })
        },
        getFormattedPrice(price) {
            return number_format(price, 'money', {
                style: 'currency',
                currency: 'USD',
            })
        },
        getFeatureName(key) {
            if (key === 'request_limit') {
                return 'Token'
            } else if (key === 'store_limit') {
                return this.$t('common.store')
            }
        },
        getCouponAmount(coupon) {
            if (coupon.type === 'fixed') {
                return `${this.getFormattedPrice(coupon.amount / 100)}`
            }
            return `${coupon.amount}%`
        },
        couponChange(coupon_id) {
            this.coupon_id = coupon_id
            if (this.selectedPackage === null) {
                return
            }
            this.handlePackageSelect(this.selectedPackage, coupon_id)
        },
        async handlePackageSelect(packageId, couponId = null) {
            this.selectedPackage = packageId
            this.selectedCoupon = couponId
            this.packageDetail = this.packageList.find((item) => item.walletPriceId === packageId)
            this.packageDetailAlert = false

            this.$store.commit('set', { k: 'loading', v: { status: true } })
            const params = {
                newExternalPriceId: packageId || this.package_detail.walletPriceId,
                couponId,
                productId: 9,
            }
            try {
                const response = await WalletService.getPreviewPackage(params)
                const preview = response.data
                const discounts = []
                Object.keys(preview.discounts).forEach((item) => {
                    if (preview.discounts[item] !== 0) {
                        discounts.push({
                            name: item,
                            amount: Math.abs(preview.discounts[item]),
                        })
                    }
                })
                this.setSelectedPackage({
                    summary: {
                        upgrade: preview.howManyDaysLeftForNewInvoice !== preview.newPackage.recurringPeriodCount,
                        period_invoice: preview.howManyDaysLeftForNewInvoice,
                        period: preview.newPackage.recurringPeriodCount,
                        price: preview.newPackage.basePrice / 100,
                        period_type: preview.newPackage.recurringPeriod,
                        package_price: preview.newPackage.basePrice,
                        total_price: preview.upcomingPrice,
                        discounts,
                    },
                    preview: {
                        ...preview,
                        newPackage: {
                            ...preview.newPackage,
                            features: this.packageList.find((item) => item.walletPriceId === packageId).features,
                            price: preview.newPackage.basePrice,
                        },
                    },
                    selectedCoupon: preview.coupon && preview.coupon.id ? preview.coupon.id : null,
                    externalPriceId: packageId,
                })
            } catch (e) {
                this.$toast.error(e.response.data.message)
            }
            this.$store.commit('set', { k: 'loading', v: { status: false } })
        },
    },
}
</script>
