<template>
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContainerNavDropdown" aria-controls="navbarContainerNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-default">
            <i class="bi-list"></i>
        </span>
        <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
        </span>
    </button>
    <!-- End Toggler -->
</template>
