<template>
    <a class="navbar-brand" href="/" aria-label="Front">
        <img class="navbar-brand-logo" src="@/assets/logo.svg" alt="Logo" />
    </a>
</template>

<script>
export default {
    name: 'Logo',
    data() {
        return {}
    },
    methods: {},
}
</script>
