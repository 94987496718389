import { AccountInstance } from './base'

const AuthService = {
    me() {
        return AccountInstance.post('/auth/verify')
    },
    loginByUserId(payload) {
        // change authorization header
        const adminToken = localStorage.getItem('adminToken')
        AccountInstance.defaults.headers.common['Authorization'] = 'Bearer ' + adminToken
        return AccountInstance.post('/auth/login-by-user-id', payload)
    },
    updateContract(payload) {
        return AccountInstance.post('/me/update-contract/' + payload.name, payload.data)
    },
    updateUser(payload) {
        return AccountInstance.put('/me', payload)
    },
    sendOtp(payload) {
        return AccountInstance.post('/auth/send-otp', payload)
    },
    sendEmail(payload) {
        return AccountInstance.post('/auth/send-email', payload)
    },
    verifyOtp(payload) {
        return AccountInstance.post('/auth/verify-otp', payload)
    },
    verifyOtpEmail(payload) {
        return AccountInstance.post('/auth/verify-otp-email', payload)
    },
    login(payload) {
        return AccountInstance.post('/auth/login', payload)
    },
    logout(payload) {
        return AccountInstance.post('/auth/logout', payload)
    },
    forgotPassword(payload) {
        return AccountInstance.post('/auth/forgot-password', payload)
    },
    resetPassword(params) {
        return AccountInstance.post('/auth/forgot-reminder', params)
    },
    sendRegisterOtp(payload) {
        return AccountInstance.post('/register-otp', payload)
    },
    verifyRegisterOtp(payload) {
        return AccountInstance.post('/register-verify-otp', payload)
    },
    register(payload) {
        return AccountInstance.post('/auth/register', payload)
    },
    getAllPackages() {
        return AccountInstance.get('/me/products/9/all-packages')
    },
    postSupportForm(payload) {
        AccountInstance.interceptors.request.use((config) => {
            config.headers['Content-Type'] = 'multipart/form-data'
            return config
        })
        return AccountInstance.post('/auth/support-form', payload)
    },
    postFeedbackForm(payload) {
        return AccountInstance.post('/auth/feedback-form', payload)
    },
}

export default AuthService
