<template>
    <div class="container d-flex justify-content-end">
        <LanguageSwitcher></LanguageSwitcher>
    </div>
    <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="https://fbahunter.com" tabindex="-1">
            <img class="zi-2" src="@/assets/logo.svg" alt="Image Description" style="width: 20rem" />
        </a>

        <div class="mx-auto" style="max-width: 30rem">
            <!-- Card -->
            <div class="card card-lg mb-5">
                <div v-if="!isWaitingAgainPasswordRequest" class="card-body">
                    <!-- Form -->
                    <div class="text-center">
                        <div class="mb-5">
                            <h1 class="display-5">{{ $t('views.auth.forgot_password.title') }}</h1>
                            <p>{{ $t('views.auth.forgot_password.description') }}</p>
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="mb-4">
                        <label class="form-label" for="signinSrEmail">{{ $t('views.auth.labels.email') }}</label>
                        <input v-model="email" tabindex="1" type="email" class="form-control form-control-lg" name="email" id="signinSrEmail" placeholder="email@address.com" aria-label="email@address.com" required="" />
                    </div>
                    <!-- End Form -->

                    <div class="d-grid">
                        <button :disabled="sending" type="button" class="btn btn-primary btn-lg" @click="handleSubmit">
                            {{ $t('actions.send') }}
                            <span v-if="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div v-else class="card-body">
                    <div class="text-center">
                        <p class="m-0">
                            Şifre yenileme bağlantısı <strong>{{ this.email }}</strong> adresine gönderilmiştir.
                        </p>
                        <p class="countdown-timer m-0">
                            {{ convertTime(countdown) }}
                        </p>
                    </div>

                    <div class="d-grid">
                        <button :disabled="countdown != 0" type="button" class="btn btn-primary btn-lg" @click="handleSubmit">Bağlantıyı Tekrar Gönder</button>
                    </div>
                </div>
            </div>
            <div class="position-relative text-center zi-1">
                <small class="text-cap text-body mb-4">© 2023 Fbahunter</small>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/auth'
import LanguageSwitcher from '@/components/Header/LanguageSwitcher.vue'

export default {
    name: 'ForgotPassword',
    components: {
        LanguageSwitcher,
    },
    data: () => ({
        email: null,
        sending: false,
        countdown: 180,
        isWaitingAgainPasswordRequest: false,
    }),
    methods: {
        handleSubmit() {
            if (!this.email) {
                this.$toast.error(this.$t('views.auth.forgot_password.email_required'))
                return
            }

            this.countdown = 180
            this.sending = true
            this.countTime()

            AuthService.forgotPassword({ email: this.email })
                .then(() => {
                    this.$router.push({ name: 'login' })
                    this.$toast.success(this.$t('views.auth.forgot_password.success'))
                })
                .catch((err) => {
                    this.$toast.error(err.response.data.message)
                })
                .finally(() => {
                    this.sending = false
                    this.isWaitingAgainPasswordRequest = true
                })
        },
        countTime() {
            const countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--
                } else {
                    clearInterval(countdownInterval)
                }
            }, 1000)
        },
        convertTime(seconds) {
            const format = (val) => `0${Math.floor(val)}`.slice(-2)
            return [(seconds % 3600) / 60, seconds % 60].map(format).join(':')
        },
    },
    mounted() {},
    computed: {},
}
</script>

<style scoped>
.countdown-timer {
    display: inline-block !important;
    padding: 1rem;
    font-weight: 900 !important;
    margin: 1rem 0 !important;
    background-color: var(--bs-gray-200);
}
</style>
