import { ApiInstance } from './base'

const ProductSearchService = {
    create(payload) {
        if (payload.type && payload.type === 'wholesale') {
            ApiInstance.interceptors.request.use((config) => {
                config.headers['Content-Type'] = 'multipart/form-data'
                return config
            })
        }
        return ApiInstance.post('/product-search', payload)
    },
    getRealtimeData(payload) {
        return ApiInstance.get('/fp', {
            params: payload.params,
        })
    },
    realTimeCalculator(payload) {
        return ApiInstance.post('/fp/calculator', payload)
    },
    findAll(payload) {
        return ApiInstance.get('/product-search?' + payload.params)
    },
    findOne(payload) {
        return ApiInstance.get('/product-search/' + payload)
    },
    delete(payload) {
        return ApiInstance.delete('/product-search/' + payload)
    },
    findAllProducts(id, params) {
        return ApiInstance.get('/product-search/' + id + '/products?' + params)
    },
    getProduct(id) {
        return ApiInstance.get('/product-search/product/' + id)
    },
    calculator(id, query, params) {
        return ApiInstance.post('/scanning/product/' + id + '/calculator?' + params, query)
    },
    applyFilter(id, params) {
        return ApiInstance.post('/product-search/' + id + '/apply-filter', { options: params })
    },
    getFilters(id) {
        return ApiInstance.get('/product-search/' + id + '/filters')
    },
    addToFollowingList(payload) {
        return ApiInstance.post('/product-search/add-to/following-list', payload)
    },
    deleteScanningReports(payload) {
        return ApiInstance.post('/product-search/delete', { ids: payload })
    },
    askToAssistant(id, payload) {
        return ApiInstance.post('/product-search/ask-to-assistant/' + id, payload)
    },
    getChatHistory(id) {
        return ApiInstance.get('/product-search/chat-history/' + id)
    },
    getCategories(payload) {
        return ApiInstance.post('/product-search/categories', payload)
    },
    exportExcel(id, payload) {
        return ApiInstance.post('/product-search/export-excel/' + id, payload)
    },
    getItemsKeepaData(payload) {
        return ApiInstance.post('/product-search/keepa-data', payload)
    },
}

export default ProductSearchService
