<!-- eslint-disable max-len -->
<template>
    <template v-if="isLoading">
        <div class="d-flex gap-2 justify-content-center align-items-center fs-6" style="height: 100dvh">
            <img src="@/assets/extension-loader.gif" width="32" height="32" /> <span>{{ $t('views.extension.loading') }}</span>
        </div></template
    >
    <template v-else>
        <CheckLogin v-if="!isAuth || !extension.availability?.auth" />
        <template v-else>
            <section v-show="!showOffer" class="fbahunter-wrapper">
                <div v-if="totalBalance" class="alert alert-primary mb-2" v-html="$t('views.extension.exhaustedTokenText', { subscriptionLink: '/settings/subscription' })"></div>

                <div class="d-flex align-items-baseline gap-2" :class="{ 'mb-2': !extension.availability?.auth }">
                    <img src="@/assets/logo.svg" @click="goTo" style="cursor: pointer" width="90" height="16" alt="Logo" />
                    <div v-show="extension.availability?.auth" class="line-gray" style="height: 13px"></div>
                    <ul class="nav nav-pills" :class="{ 'is-compare-tab': isFullScreen }" role="tablist">
                        <li v-if="false && extension.availability?.auth" class="nav-item">
                            <a class="nav-link pe-none" id="source-tab" href="#source" data-bs-toggle="pill" data-bs-target="#source" role="tab" aria-controls="source" aria-selected="true">
                                <div class="d-flex align-items-center gap-1">
                                    <img v-if="!isFullScreen" class="svg-flag" src="@/assets/svg-flag/US.svg" width="16" height="16" alt="" />
                                    {{ $t('views.extension.source') }}
                                </div>
                            </a>
                        </li>
                        <li v-if="!isFullScreen && extension.availability?.auth" class="nav-item">
                            <a class="nav-link" :class="{ active: !isFullScreen, 'pe-none': isFullScreen }" id="target-tab" href="#target" data-bs-toggle="pill" data-bs-target="#target" role="tab" aria-controls="target" aria-selected="false">
                                <div class="d-flex align-items-center gap-1">
                                    <img v-if="!isFullScreen && extension.product?.short" class="svg-flag" :src="extension.product?.short ? require('@/assets/svg-flag/' + extension.product?.short + '.svg') : ''" width="16" height="16" alt="" />
                                    {{ $t('views.extension.target') }}
                                </div>
                            </a>
                        </li>
                        <li v-if="extension.availability?.auth && extension.availability.targetDomain?.sourceDomains && showCompareTab" class="nav-item" @click="setFullScreen">
                            <a class="nav-link" href="#compare" data-bs-toggle="pill" :class="{ active: isFullScreen }" id="compare-tab" role="tab" aria-controls="compare" aria-selected="false">
                                <div class="d-flex align-items-start gap-1" style="margin-top: 1px">
                                    <i v-if="isFullScreen" class="bi bi-globe-americas"></i>
                                    <span>{{ $t('views.extension.compare.title') }} </span>
                                    <div v-if="isFullScreen" class="ms-2" style="margin-top: -3px">
                                        <multiselect
                                            :show-labels="false"
                                            v-model="selectedDomains"
                                            track-by="short"
                                            label="label"
                                            :searchable="true"
                                            :multiple="true"
                                            :placeholder="$t('inputs.general.labels.select_source')"
                                            :options="extension.availability.targetDomain?.sourceDomains"
                                            :close-on-select="false"
                                            :clear-on-select="false"
                                            @select="selectedDomain"
                                            @remove="removeDomain"
                                        >
                                            <template v-slot:selection="{ values, isOpen }"
                                                ><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} {{ $t('common.domain_selected') }}</span></template
                                            >
                                            <template v-slot:option="{ option }">
                                                <img class="option__image" :src="require(`@/assets/svg-flag/${option.short}.svg`)" />
                                                <div class="option__desc ml-1 d-flex justify-content-between" style="width: 100%">
                                                    <span class="option__title">{{ countries[option.short]?.domain }}</span>
                                                    <span class="text-subdued" v-if="selectedDomains.find((item) => item.short === option.short)"> {{ $t('common.selected') }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li v-if="extension.availability?.auth" class="nav-item">
                            <a
                                v-if="isFullScreen"
                                class="nav-link"
                                id="compare-tab"
                                href="#target"
                                data-bs-toggle="pill"
                                data-bs-target="#target"
                                role="tab"
                                aria-controls="target"
                                aria-selected="true"
                                @click="resetFullScreen"
                                style="font-size: 1.3125rem !important"
                            >
                                <i class="bi bi-x"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <hr />

                <template v-if="!isFullScreen">
                    <div class="tab-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <ExtensionSearch header :text="$route.query.title" />
                            <a v-if="extension.availability?.auth && extension.product?.offers" class="link-secondary" href="javascript:;" @click="showOfferModal">{{ $t('views.extension.offers') }}</a>
                            <!--                         <a class="link-secondary" :href="`https://www.${countries[extension.product?.short].domain}/dp/${productRefreshData.asin}#productDescription_feature_div`" target="_blank">{{ $t('views.extension.desc') }}</a>
 -->
                            <a v-if="extension.availability?.auth" class="link-secondary" :href="`https://file.trademarkia.com/search?query=${extension.product.detail?.brand}`" target="_blank">
                                <Popper class="light-popper" hover placement="bottom" arrow><template #content>Trade Mark</template>{{ $t('views.extension.tm') }} </Popper></a
                            >

                            <a class="link-secondary" :href="`https://sellercentral.${productRefreshData.domain}/gp/help/help.html?itemID=201003400`" target="_blank"
                                ><Popper class="light-popper" hover placement="bottom" arrow><template #content>Hazardous Materials</template>{{ $t('views.extension.hm') }}</Popper></a
                            >

                            <a
                                class="link-secondary"
                                :style="extension.availability?.settings?.always_use_keepa || extension.keepaData ? 'color: ' + keepaLogoColor + ' !important' : ''"
                                ref="keepaLogo"
                                href=""
                                @mouseover="handleMouseOver"
                                @mouseleave="handleMouseLeave"
                                data-bs-toggle="modal"
                                :data-bs-target="extension.availability?.settings?.always_use_keepa ? '.keepa-disable-modal' : '.keepa-data-modal'"
                                v-if="keepaAvailable"
                            >
                                <KeepaLogo
                                    :color="extension.availability?.settings?.always_use_keepa || extension.keepaData ? keepaLogoColor : '#CCC'"
                                    :style="{
                                        marginTop: '-2px',
                                    }"
                                    :width="15"
                                    :height="15"
                                />
                                Keepa</a
                            >
                        </div>
                        <div v-if="extension.availability?.auth" class="d-flex">
                            <Popper v-if="extension.product?.detail?.hazmat && extension.product?.detail?.hazmat?.length > 0" class="light-popper" hover placement="bottom" arrow>
                                <template #content>
                                    <div v-html="$t('views.extension.dangerousProduct.text')"></div>

                                    <!-- <div v-for="(item, index) in extension.product?.detail?.hazmat" :key="index" class="d-flex align-items-center gap-1 fw-semibold mb-2" style="color: #b12704">
                                        <span class="badge bg-danger" style="background-color: #b12704 !important">{{ item.label }}</span>
                                        <span>{{ item.value }}</span>
                                    </div> -->
                                </template>
                                <button type="button" class="btn btn-link">
                                    <i class="bi bi-exclamation-diamond-fill fs-5" style="color: #f5512d"></i></button
                            ></Popper>
                            <Popper v-if="showViolationData" class="light-popper" hover placement="bottom" arrow>
                                <template #content>
                                    <div v-html="$t('views.extension.violationData.text')"></div>

                                    <div v-for="(item, index) in extension.product?.violationData" :key="index" class="d-flex align-items-center gap-1 fw-semibold mb-2" style="color: #b12704">
                                        <span class="badge bg-danger" style="background-color: #b12704 !important">{{ item.dataType }}</span> <span>{{ moment(item.createdAt).format('YYYY-MM-DD') }}:</span>
                                        <span>{{ $t('views.extension.violationData.recieved') }}</span> <span>{{ item.violationTypeText }}</span> <span>{{ $t('views.extension.violationData.complaint') }}</span>
                                    </div>
                                </template>
                                <button type="button" class="btn btn-link">
                                    <i class="bi bi-exclamation-triangle-fill fs-5" style="color: #f5512d"></i></button
                            ></Popper>
                            <Popper v-if="extension.product?.detail?.isMeltable" class="light-popper" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.meltable')"></div></template>
                                <div style="margin-top: 7px; margin-right: 5px">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14.000000pt" height="14.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="rgb(237, 76, 120)" stroke="none">
                                            <path
                                                d="M0 4216 l0 -223 173 -6 c193 -7 246 -17 433 -81 165 -57 247 -70 394
-63 129 7 172 17 345 77 164 56 282 73 465 66 161 -6 246 -23 405 -81 125 -46
223 -64 345 -64 122 0 220 18 345 63 162 59 245 76 405 82 179 7 301 -9 450
-61 176 -62 239 -76 360 -82 147 -7 231 6 395 62 196 67 241 76 433 82 l172 6
0 223 0 224 -2560 0 -2560 0 0 -224z"
                                            />
                                            <path
                                                d="M0 3390 l0 -300 83 0 c180 0 291 -62 349 -195 10 -24 14 -208 18
-860 5 -825 5 -830 27 -877 84 -183 324 -235 471 -103 28 25 61 67 74 93 l23
47 5 830 c5 821 5 830 26 876 111 240 435 246 551 11 l28 -57 5 -380 c5 -370
6 -381 28 -422 58 -110 149 -167 266 -167 119 -1 216 59 274 169 l27 50 5 380
c4 332 7 385 22 418 31 69 76 115 145 149 54 27 77 32 133 32 120 0 228 -70
278 -181 16 -35 17 -116 22 -1018 l5 -980 28 -57 c66 -134 224 -200 365 -154
64 22 143 91 174 154 l23 47 5 980 c5 975 5 980 26 1026 89 191 323 244 477
109 51 -44 83 -103 96 -174 7 -38 11 -261 11 -611 0 -623 -2 -608 76 -694 60
-67 127 -95 224 -96 60 0 85 5 124 24 61 30 116 86 147 149 l24 47 5 605 c4
467 8 612 18 635 58 133 169 195 349 195 l83 0 0 300 0 300 -112 0 c-157 0
-230 -13 -397 -69 -209 -70 -240 -76 -446 -76 -205 0 -235 5 -442 75 -283 96
-448 95 -734 -4 -292 -102 -568 -103 -854 -1 -279 98 -472 100 -732 7 -186
-67 -244 -77 -448 -77 -205 0 -257 9 -458 80 -141 50 -232 65 -388 65 l-109 0
0 -300z"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </Popper>
                            <Popper v-if="extension.product?.eligibility?.isHazmat" class="light-popper" style="padding: 0.55rem 0.25rem 0.25rem 0px" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.hazmat')"></div></template>
                                <i class="bi bi-exclamation-diamond-fill text-danger" style="font-size: 14px"></i>
                            </Popper>
                            <Popper v-if="extension.product?.approvalRequired" class="light-popper" hover placement="bottom" arrow>
                                <template #content> <div v-html="$t('views.extension.approvalRequired')"></div></template>
                                <a :href="extension.product?.approvalRequired[0].links[0].resource" class="btn btn-link" target="_blank"> <i class="bi bi-lock-fill fs-5" style="color: #f5512d"></i></a
                            ></Popper>
                            <Popper class="light-popper" hover placement="bottom" arrow
                                ><template #content>Like</template>
                                <button type="button" class="btn btn-link" @click="setLike">
                                    <span v-if="loading.like" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <i v-else class="bi bi-bookmark-plus-fill fs-5" :class="{ 'text-primary': extension.product.userHistory?.isLiked }"></i>
                                </button>
                            </Popper>
                        </div>
                    </div>

                    <hr />
                </template>

                <div v-if="$route.query.redirect_asin && $route.query.asin !== $route.query.redirect_asin" class="alert alert-primary" role="alert">
                    <div class="d-flex align-items-center gap-2">
                        <div class="flex-shrink-0">
                            <i class="bi-exclamation-triangle-fill fa-2x"></i>
                        </div>
                        <div class="flex-grow-1 ms-2" v-html="$t('views.extension.redirectAsin', { asin: $route.query.asin, redirect_asin: $route.query.redirect_asin, domain: productRefreshData.domain })"></div>
                    </div>
                </div>

                <div class="tab-content mt-3">
                    <div class="tab-pane fade disabled" id="source" role="tabpanel" aria-labelledby="source-tab">{{ $t('views.extension.source') }}</div>

                    <div class="tab-pane fade" :class="{ 'show active': !isFullScreen }" id="target" role="tabpanel" aria-labelledby="target-tab">
                        <ExtensionTarget :keepaAvailable="keepaAvailable" :is-continue="isContinue" :product-refresh-data="productRefreshData" :cloned-calculate-data="clonedCalculateData" :keepa-payload="keepaPayload" />
                    </div>

                    <div class="tab-pane fade" :class="{ 'show active': isFullScreen }" id="compare" role="tabpanel" aria-labelledby="compare-tab">
                        <ExtensionCompare :selected-domains="selectedDomains" />
                    </div>
                </div>
            </section>

            <section v-show="showOffer">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <img src="@/assets/logo.svg" @click="goTo" style="cursor: pointer" width="90" height="16" alt="Logo" />
                    <button
                        type="button"
                        class="btn btn-link link-secondary fs-2"
                        aria-controls="target"
                        aria-selected="true"
                        @click="resetFullScreen"
                        style="background-color: var(--bs-gray-200); line-height: 100%; border-radius: 50%; padding: 0.3rem"
                    >
                        <i class="bi bi-x"></i>
                    </button>
                </div>
                <ExtensionOffers v-if="extension.availability?.auth" :offers="extension.product?.offers" :link="`https://www.${productRefreshData.domain}/dp/${productRefreshData.asin}`" />
            </section>
        </template>
    </template>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import ExtensionTarget from '@/components/Extension/Target.vue'
import ExtensionCompare from '@/components/Extension/Compare.vue'
import ExtensionSearch from '@/components/Extension/components/Search.vue'
import CheckLogin from '@/components/Extension/components/Login.vue'
import ExtensionOffers from '@/components/Extension/modals/Offers.vue'
import { countryInfo } from '@/utils/helper'
import KeepaLogo from '@/components/KeepaLogo.vue'

export default {
    name: 'Extension',
    components: { ExtensionTarget, KeepaLogo, ExtensionCompare, ExtensionSearch, ExtensionOffers, Multiselect, CheckLogin },
    data() {
        return {
            keepaLogoColor: '#256a93',
            showOffer: false,
            isFullScreen: false,
            productRefreshData: {
                asin: this.$route.query.asin,
                domain: this.$route.query.domain,
            },
            keepaPayload: {
                asin: this.$route.query.asin,
                domain: this.$route.query.domain,
            },
            isLoading: true,
            loading: {
                like: false,
            },
            moment,
            countries: countryInfo(),
            selectedDomains: [],
            showCompareTab: false,
            isContinue: false,
            clonedCalculateData: null,
            keepaActive: false,
        }
    },
    computed: {
        ...mapState(['extension']),
        ...mapGetters('wallet', ['summary']),
        ...mapGetters('extension', ['keepaData']),
        keepaAvailable() {
            const domain = this.$route.query?.domain
            const availableDomains = ['amazon.com', 'amazon.co.uk', 'amazon.de', 'amazon.fr', 'amazon.co.jp', 'amazon.ca', 'amazon.it', 'amazon.es', 'amazon.in', 'amazon.com.mx']
            return availableDomains.includes(domain)
        },
        showViolationData() {
            return this.extension.product.violationData && this.extension.product.violationData.length > 0
        },
        totalBalance() {
            return !this.summary.remainingToken
        },
        isAuth() {
            return this.$store.getters['auth/isAuthenticated']
        },
    },
    beforeMount() {
        document.querySelector('body').classList.add('is-extension')
    },
    mounted() {
        this.observeOffsetHeight()
        const keepaLogo = this.$refs.keepaLogo
        if (keepaLogo) {
            keepaLogo.$el.addEventListener('mouseenter', () => {
                this.keepaLogoColor = '#f5512d'
            })
        }
    },
    async created() {
        if (window.localStorage.getItem('token')) {
            this.availability(this.$route.query.domain)
                .then(() => {
                    this.getProduct({
                        asin: this.$route.query.asin,
                        domain: this.$route.query.domain,
                    }).then(() => {
                        this.clonedCalculateData = {
                            asin: this.$route.query.asin,
                            source: null,
                            target: this.extension.product?.short,
                            price: this.extension.product?.userHistory?.buyboxPrice,
                            buying_price: this.extension.product?.userHistory?.buyingPrice,
                            dimension: this.extension.product?.dimension,
                            fees: this.extension.product?.fees,
                            notes: this.extension.product?.userHistory?.notes,
                            parameters: {
                                ...(this.extension.product?.userHistory['COG']?.parameters || undefined),
                            },
                            costs: {
                                fba: {
                                    warehouseCost: this.extension.product?.userHistory?.costs?.fba?.warehouseCost,
                                    warehouseCostOnSource: null,
                                },
                                fbm: {
                                    warehouseCost: this.extension.product?.userHistory?.costs?.fbm?.warehouseCost,
                                    warehouseCostOnSource: null,
                                },
                            },
                        }
                        this.setCalculate(this.clonedCalculateData)
                        this.isContinue = true
                    })
                })
                .catch((e) => {
                    if (e.response.data.statusCode === 401) {
                        window.localStorage.clear()
                    }
                })
                .finally(() => {
                    this.isLoading = false
                    this.showCompareTab = true
                })
        } else {
            this.isLoading = false
        }
    },
    methods: {
        ...mapActions('extension', ['availability', 'getProduct', 'setCalculate', 'like', 'getCompareTabDetail']),
        ...mapMutations('extension', ['SET_MASS_COMPARE_DATA', 'SET_MASS_COMPARE_DATA']),
        handleMouseOver() {
            this.keepaLogoColor = '#fd9405'
        },
        handleMouseLeave() {
            this.keepaLogoColor = '#256a93'
        },
        goTo() {
            let url = 'https://fbahunter.com'

            const isAuth = this.extension.availability?.auth
            if (isAuth) {
                if (process.env.VUE_APP_ENV === 'development') {
                    url = 'https://dashboard-dev.fbahunter.com/'
                } else if (process.env.VUE_APP_ENV === 'localhost') {
                    url = 'http://localhost:8080'
                } else {
                    url = 'https://dashboard.fbahunter.com/'
                }
            }

            const targetId = this.extension.availability?.targetDomain?.id

            if (targetId) {
                url += `/?marketId=${targetId}`
            }
            window.open(url, '_blank')
        },
        observeOffsetHeight() {
            let previousOffsetHeight = document.body.offsetHeight
            const self = this
            const observer = new ResizeObserver(() => {
                const currentOffsetHeight = document.body.offsetHeight

                if (currentOffsetHeight !== previousOffsetHeight && !self.isLoading) {
                    previousOffsetHeight = currentOffsetHeight
                    window.parent.postMessage({ fbaScrollHeight: self.showOffer ? document.body.offsetHeight + 95 : document.body.offsetHeight }, '*')
                }
            })

            observer.observe(document.body)
        },
        setFullScreen() {
            this.isFullScreen = true
            window.parent.postMessage({ fbahunter_full_screen_active: true }, '*')
        },
        resetFullScreen() {
            this.isFullScreen = false
            this.showOffer = false
            window.parent.postMessage({ fbahunter_full_screen_inactive: true }, '*')
        },
        setLike() {
            this.loading.like = true
            this.like(this.productRefreshData).then(() => {
                this.getProduct(this.productRefreshData)
                    .then(() => {})
                    .finally(() => {
                        this.loading.like = false
                    })
            })
        },
        async setCompareTabDetail(sourceId) {
            await this.getCompareTabDetail({
                asin: this.productRefreshData.asin,
                sourceId,
                fees: this.extension.product?.userHistory?.fees,
                price: this.extension.product?.userHistory?.buyboxPrice,
                costs: {
                    fba: {
                        warehouseCost: this.extension.calculate.fba?.warehouseCost,
                    },
                    fbm: {
                        warehouseCost: this.extension.calculate.fbm?.warehouseCost,
                    },
                },
                target: this.extension.product?.short,
            })
        },
        selectedDomain({ id }) {
            this.setCompareTabDetail(id)
                .then(() => {})
                .catch(() => {})
        },
        removeDomain({ short }) {
            const list = this.extension.compare.list.filter((item) => item.short !== short)
            this.SET_MASS_COMPARE_DATA(list)
        },
        showOfferModal() {
            this.showOffer = true
            this.setFullScreen()
        },
    },
    watch: {
        isFullScreen: function (data) {
            if (!data) {
                this.selectedDomains = []
                this.SET_MASS_COMPARE_DATA([])
            }
        },
    },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&display=swap');

:root {
    --fba-extension-primary: #ef9938;
    --fba-extension-secondary: #677788;
}

.is-extension {
    background-color: #fff !important;
    font-family: 'Inter', sans-serif;
    font-size: 12px !important;
    line-height: 0.9rem !important;
    overflow: hidden !important;

    .bs-popover-auto,
    .bs-tooltip-auto,
    .dropdown-item,
    .input-group-text {
        font-size: 12px !important;
    }

    .popover-body {
        padding: 0.25rem 1rem !important;
    }

    .dropdown-item {
        padding: 0.25rem 0.5rem !important;

        &.with-border {
            border-bottom: 1px solid #e7eaf3;
        }

        &:hover {
            background-color: transparent !important;
        }
    }

    .nav-pills {
        .nav-item {
            margin: 0 0.25rem 0 0.25rem !important;
        }
        .nav-link {
            padding: 0 0.25rem !important;
            background-color: transparent !important;
            color: var(--fba-extension-secondary) !important;
            font-weight: bold !important;
            font-size: 13px !important;
            cursor: pointer !important;

            &.active {
                color: var(--fba-extension-primary) !important;
                border-radius: 0 !important;

                &:hover {
                    background-color: transparent !important;
                }

                > div {
                    border-bottom: 1px solid var(--fba-extension-primary);
                    padding-bottom: 0.75rem;
                }
            }
        }
    }

    .line-gray {
        width: 0.5px;
        background-color: #e7eaf3;
    }
    .tab-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button,
        a {
            padding: 0.25rem 0.25rem;
            font-weight: 400;
            color: var(--fba-extension-secondary);
        }
        button {
            &:first-child {
                padding-left: 0;
            }
        }
    }

    hr {
        margin: 0;
    }

    .input-group-text {
        background-color: #fff !important;
        border-radius: 3px;
        border: 1px solid var(--bs-gray-300);
        padding: 0.25rem 0.5rem;
        font-size: 11px;
    }

    .form-control {
        &-sm {
            padding: 0.25rem !important;
            &::placeholder {
                font-size: 12px;
            }
        }
    }

    .svg-flag {
        margin: 0 5px 0 0;
    }

    .btn-link {
        padding: 0.5rem;
    }

    .warehouse-modal {
        font-size: 10px;
    }
    input[type='text'],
    input[type='number'] {
        width: 35px !important;
        height: 22px !important;
        flex-shrink: 0 !important;
        border-radius: 3px !important;
        border: 1px solid #e7eaf3 !important;
        background: #fff !important;
        box-shadow: none !important;
    }
    .table-inline {
        .popper {
            display: inline-table;
        }
    }

    .text-down {
        color: #f5512d;
    }

    .table-offer {
        width: 100%;
        /*
        input {
            background: var(--fba-extension-primary) !important;
            width: 1rem !important;
            height: 1rem !important;
            border: none !important;
        } */

        table {
            width: 100%;

            th,
            td {
                font-size: 11px !important;
                border-bottom-width: 0.0625rem !important;
                vertical-align: middle;
            }
        }
    }
    .is-compare-tab {
        flex: 1;
        justify-content: flex-end;
    }
    .compare-tab {
        display: flex;
        flex-direction: column;
        font-size: 11px;

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 1rem;
            height: 105px;
            padding-top: 1rem;

            &:first-child {
                padding-top: 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--bs-gray-300);
                padding-bottom: 1rem;
            }

            &-title {
                color: var(--fba-extension-secondary);
                display: -webkit-box;
                -webkit-line-clamp: 5;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
            }

            span {
                color: var(--fba-extension-secondary);
            }

            &-specs {
                flex: 1;
                display: flex;
                justify-content: space-around;
                align-items: center;
                grid-gap: 1.5rem;
                padding: 0 0 0 1.5rem;
            }

            &-price {
                display: flex;
                flex-direction: column;

                span:first-child {
                    font-size: 12px;
                }

                span:nth-child(2) {
                    opacity: 0.7;
                    font-size: 11px;
                }

                &.lowest * {
                    color: #d80027;
                }

                &.highest * {
                    color: #00c9a7;
                }
            }

            &-actions {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .popper {
                    max-width: 100% !important;
                }
            }
        }
    }

    .vue-star-rating {
        svg {
            width: 14px;
            height: 14px;
        }
    }
    .modal-backdrop {
        opacity: 1 !important;
        background-color: transparent;
    }
}

#compare-tab {
    .multiselect {
        min-width: 220px !important;
        min-height: auto !important;
        height: 20px;
    }
    .option__image {
        width: 20px;
    }
    .multiselect__option {
        display: flex !important;
        align-items: center;
        font-size: 13px !important;
    }
    .multiselect__option--highlight {
        background-color: #fff !important;
        color: #000 !important;
    }
    .multiselect__option--selected {
        background-color: #f8fafd !important;
        color: #000 !important;
    }

    .multiselect__placeholder {
        font-size: 13px !important;
        margin-bottom: 4px !important;
        padding: 2px !important;
        font-weight: 500 !important;
    }

    .multiselect__select {
        height: auto !important;
        top: 13px !important;
    }

    .multiselect__single {
        font-size: 13px !important;
    }

    .multiselect__tags {
        min-height: auto !important;
        padding: 0 !important;
        input {
            width: 0px !important;
            position: absolute !important;
            padding: 0px !important;
            border: none !important;
            font-size: 13px !important;
        }
    }
    .multiselect__content-wrapper {
        min-height: 100px !important;
    }
}
</style>
