import FollowingListService from '@/services/following-list'
import { useToast } from 'vue-toast-notification'
import i18n from '../i18n'

const state = {
    followingList: null,
    selectedFollowingProduct: null,
    followingListDetail: null,
}

const $toast = useToast()

const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}
const getters = {
    followingList: (state) => state.followingList,
    followingListDetail: (state) => state.followingListDetail,
    selectedFollowingProduct: (state) => state.selectedFollowingProduct,
}

const mutations = {
    SET_FOLLOWING_LIST(state, payload) {
        state.followingList = payload
    },
    SET_FOLLOWING_LIST_DETAIL(state, payload) {
        state.followingListDetail = payload
    },
    SET_SELECTED_FOLLOWING_PRODUCT(state, payload) {
        state.selectedFollowingProduct = payload
    },
}

const actions = {
    getFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.findAll(payload)
                .then((response) => {
                    commit('SET_FOLLOWING_LIST', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getFollowingListDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.getFollowingListDetail(payload)
                .then((response) => {
                    commit('SET_FOLLOWING_LIST_DETAIL', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.create(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
    moveToAnotherList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.moveToAnotherList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    editFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.edit(payload.id, payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || 'Hata', toastOptions)
                    reject(error)
                })
        })
    },
    updateFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.update(payload.id, payload.data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
    deleteFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.delete(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
    findFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.findOne(payload.id, payload.params)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeFromFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.removeFromFollowingList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
    getFollowingProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.getFollowingProduct(payload.id)
                .then((response) => {
                    commit('SET_SELECTED_FOLLOWING_PRODUCT', response.data)

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateFollowingListItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.updateFollowingListItem(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    mergeList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.mergeList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
    refreshFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.refreshFollowingList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message || i18n.global.t('common.error'), toastOptions)
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
