<template>
    <div class="d-flex" style="justify-content: center">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ $t('common.loading') }}...</span>
        </div>
    </div>
</template>

<script>
/*
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
eklenecek
*/
export default {
    name: 'Spinner',
}
</script>
