import { ApiInstance } from './base'

const PrepRequestService = {
    create(type, payload) {
        return ApiInstance.post('/prep-request/' + type + '/new', payload)
    },
    findAll(payload) {
        return ApiInstance.get('/prep-request/' + payload + '/list')
    },
}

export default PrepRequestService
