<template>
    <div id="navbarSettingsCardWithNav" class="card card-navbar-nav nav nav-pills nav-vertical">
        <template v-if="walletPackage">
            <UserCard />
            <div class="d-flex align-items-center justify-content-between" style="gap: 10px; padding: 0.5rem 0.875rem">
                <div class="d-flex align-items-center" style="gap: 5px">
                    <h3 class="fw-bold" style="font-size: 12px !important">{{ $t(`components.subscription.overview.packages.${walletPackage.package.name}`) }}</h3>
                    <Badge v-if="walletPackage.status === 0 || walletPackage.status === 1" :style="'success'" :text="$t(`components.subscription.overview.status.${walletPackage.status}`)"></Badge>
                    <Badge v-if="walletPackage.status === 3" :style="'warning'" :text="$t(`components.subscription.overview.status.${walletPackage.status}`)"></Badge>
                    <Badge v-if="walletPackage.status === 5" :style="'danger'" :text="$t(`components.subscription.overview.status.${walletPackage.status}`)"></Badge>
                    <Badge v-if="walletPackage.status === 8" :style="'warning'" :text="$t(`components.subscription.overview.status.${walletPackage.status}`)"></Badge>
                </div>
            </div>
            <div style="padding: 0 0.875rem; border-bottom: 0.6px solid #e7eaf3; padding-bottom: 8px">
                <div class="progress rounded-pill mb-2">
                    <div class="progress-bar" role="progressbar" :aria-valuenow="progressBar" aria-valuemin="0" aria-valuemax="100" :style="`width: ${progressBar}%`"></div>
                </div>

                <div class="d-flex align-items-center" style="font-size: 11px">
                    <span class="fw-semibold">{{ formatThousandsDecimal(summary.usedToken) }}</span>
                    <span class="text-muted">/{{ formatThousandsDecimal(+summary.totalToken - +summary.expiredToken) }} token</span>
                </div>
            </div>
        </template>

        <span class="dropdown-header">{{ $t('menu.settings.system') }}</span>

        <router-link class="nav-link" :active-class="'active'" to="/settings/integration"><i class="bi-at nav-icon"></i> {{ $t('menu.settings.integration') }} </router-link>

        <span class="dropdown-header">{{ $t('menu.settings.account') }}</span>
        <router-link class="nav-link" :active-class="'active'" to="/settings/profile"> <i class="bi-person-circle nav-icon"></i> {{ $t('menu.settings.profile') }} </router-link>
        <router-link class="nav-link" :active-class="'active'" to="/settings/subscription"> <i class="bi-shield-lock nav-icon"></i> {{ $t('menu.settings.subscription') }} </router-link>
        <router-link class="nav-link" :active-class="'active'" to="/settings/subscription/history"> <i class="bi-list nav-icon"></i>{{ $t('menu.settings.subscriptionHistory') }}</router-link>
        <router-link class="nav-link" :active-class="'active'" to="/settings/affiliate"> <i class="bi-person nav-icon"></i> {{ $t('menu.settings.affiliate') }} </router-link>
        <router-link class="nav-link" :active-class="'active'" to="/settings/address"> <i class="bi-geo nav-icon"></i> {{ $t('menu.settings.address') }} </router-link>
        <router-link class="nav-link" :active-class="'active'" to="/settings/support"> <i class="bi-envelope nav-icon"></i> {{ $t('menu.settings.support') }} </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Badge from '@/components/Badge'
import UserCard from '@/components/Header/UserCard'
import { number_format } from '@/utils/helper'

export default {
    name: 'SettingsNav',
    components: {
        Badge,
        UserCard,
    },
    methods: {
        ...mapGetters('wallet', ['getWalletPackage']),
        formatThousandsDecimal(value) {
            return number_format(value, 'money', {
                style: 'decimal',
                decimal: '',
                thousand: '.',
            })
        },
    },
    computed: {
        ...mapGetters('wallet', ['walletPackage', 'summary']),
        ...mapGetters('auth', ['user']),
        progressBar() {
            return (this.summary.usedToken / (+this.summary.totalToken - +this.summary.expiredToken)) * 100
        },
    },
}
</script>

<style scoped>
.badge {
    font-size: 9px;
}
</style>
