<template>
    <div class="fw-bold" style="position: relative">
        <div
            class="price_to"
            :class="{
                'text-success': colored && toPrice > 0,
                'text-danger': colored && toPrice < 0,
            }"
        >
            <small style="font-weight: 500; margin-right: 0.5rem">{{ toCurrency }}</small>
            {{ toPrice.toFixed(2) }}
        </div>
        <i v-if="calculated" class="fa fa-solid fa-calculator" style="position: absolute; left: -10px; top: 40%"></i>
        <ComparePrices :singleRow="false" :value="toPrice" :oldValue="oldValue" />
        <div
            v-if="from"
            class="price_from"
            :class="{
                'text-success': colored && fromPrice > 0,
                'text-danger': colored && fromPrice < 0,
            }"
        >
            <small style="font-weight: 500; margin-right: 0.5rem">{{ fromCurrency }}</small>
            {{ fromPrice.toFixed(2) }}
        </div>
    </div>
</template>

<script>
import { countryInfo } from '@/utils/helper'
import { mapGetters } from 'vuex'
import ComparePrices from '@/components/Variations/ComparePrices.vue'
const countries = countryInfo()

export default {
    components: {
        ComparePrices,
    },
    props: {
        colored: {
            type: Boolean,
            default: false,
        },
        oldValue: {
            type: Number,
            default: 0,
        },
        calculated: {
            type: Boolean,
            default: false,
        },
        price: {
            type: Number,
            default: 0,
        },
        to: {
            type: String,
            default: null,
        },
        from: {
            type: String,
            default: null,
        },
        rate: {
            type: Number,
            default: null,
        },
    },
    data: function () {
        return {
            fromCurrency: null,
            toCurrency: null,
            fromPrice: null,
            toPrice: null,
        }
    },
    methods: {},
    mounted() {},
    watch: {
        from: {
            handler: function (val, oldVal) {
                if (!val) return
                const currency = countries[val].symbol

                this.fromCurrency = currency
            },
            deep: true,
            immediate: true,
        },
        to: {
            handler: function (val, oldVal) {
                if (!val) return
                const currency = countries[val].symbol

                this.toCurrency = currency
            },
            immediate: true,
        },
        price: {
            handler: function (val, oldVal) {
                if (!this.to) return
                this.toPrice = val
                if (this.rate) {
                    this.fromPrice = this.price / this.rate
                    return
                }
                const currency = countries[this.to].symbol
                const toRate = this.marketExchangeRates.find((rate) => (rate.currency = currency))
                const to = toRate ? toRate.value : 1
                this.fromPrice = this.price / to
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters('settings', ['marketExchangeRates']),
    },
}
</script>

<style>
.price_from {
    border-top: 1px solid rgba(255, 166, 1, 0.232);
    padding: 5px;
    white-space: nowrap;
}
.price_to {
    padding: 5px;
    white-space: nowrap;
}
</style>
