import IntegrationService from '@/services/integration'
import MarketService from '@/services/market'
import { useToast } from 'vue-toast-notification'
import i18n from '../i18n'

const state = {
    markets: [],
    currentMarket: null,
}

const getters = {
    markets: (state) => state.markets,
    currentMarket: (state) => state.currentMarket,
}

const mutations = {
    SET_MARKETS(state, payload) {
        state.markets = payload
    },
    SET_CURRENT_MARKET(state, payload) {
        state.currentMarket = payload
    },
}
const $toast = useToast()

const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}

const actions = {
    getCredentials({ commit }, payload) {
        return new Promise((resolve, reject) => {
            IntegrationService.spApiAuth(payload)
                .then((response) => {
                    $toast.success(i18n.global.t('store.storeConnected'), toastOptions)
                    if (response.data) {
                        commit('SET_MARKETS', response.data)
                        localStorage.setItem('markets', JSON.stringify(response.data))
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(i18n.global.t('store.storeNotConnected'), toastOptions)
                    reject(error)
                })
        })
    },
    setCurrentMarket({ commit }, payload) {
        commit('SET_CURRENT_MARKET', payload)
        localStorage.setItem('currentMarket', JSON.stringify(payload))
    },
    findOne({ commit }, payload) {
        return new Promise((resolve, reject) => {
            MarketService.findOne(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    update({ commit }, payload) {
        return new Promise((resolve, reject) => {
            MarketService.update(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    remove({ commit }, payload) {
        return new Promise((resolve, reject) => {
            MarketService.remove(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setSources({ commit }, payload) {
        return new Promise((resolve, reject) => {
            MarketService.setSources(payload)
                .then((response) => {
                    commit('SET_MARKETS', response.data)
                    localStorage.setItem('markets', JSON.stringify(response.data))
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findAll({ commit }) {
        return new Promise((resolve, reject) => {
            MarketService.findAll()
                .then((response) => {
                    commit('SET_MARKETS', response.data)
                    localStorage.setItem('markets', JSON.stringify(response.data))
                    const currentMarket = localStorage.getItem('currentMarket')
                    const customerLogin = localStorage.getItem('customerLogin')
                    console.log({ customerLogin })
                    if ((!currentMarket && response.data.length > 0) || (customerLogin && response.data.length > 0)) {
                        commit('SET_CURRENT_MARKET', response.data[0])
                        localStorage.setItem('currentMarket', JSON.stringify(response.data[0]))
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    autoRetrieve({ commit }) {
        const markets = localStorage.getItem('markets')
        const currentMarket = localStorage.getItem('currentMarket')
        if (markets) {
            const marketParsed = JSON.parse(markets)
            if (!currentMarket) {
                const market = JSON.parse(markets)[0]
                commit('SET_CURRENT_MARKET', market)
                localStorage.setItem('currentMarket', JSON.stringify(market))
            } else {
                commit('SET_CURRENT_MARKET', JSON.parse(currentMarket))
            }
            if (marketParsed.length === 0) {
                localStorage.removeItem('currentMarket')
                commit('SET_CURRENT_MARKET', null)
            }
            commit('SET_MARKETS', marketParsed)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
