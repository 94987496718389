<template>
    <div class="card-section bordered-bottom pl-2">
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center flex-1-1 justify-content-between">
                    <h2
                        class="d-flex card__title text__heading"
                        :class="{
                            ['text__heading--' + size]: !!size,
                        }"
                        :style="collapsed ? 'cursor:pointer; flex:1' : ''"
                        @click="toggleCollapse"
                    >
                        <slot name="header"> <img v-if="flag && currentMarket" width="30" style="margin-right: 1rem" :src="require('@/assets/svg-flag/' + currentMarket.short + '.svg')" />{{ title }} </slot>
                    </h2>
                    <slot name="header-right">
                        <span>{{ headerRight }}</span>
                    </slot>
                    <slot name="header-action" v-if="headerAction">
                        <span>
                            <button :class="headerAction.className ? headerAction.className : 'text-warning btn'" @click="headerAction.action"><i v-if="headerAction.icon" :class="headerAction.icon"></i> {{ headerAction.label }}</button>
                        </span>
                    </slot>
                    <span v-if="collapsed">
                        <button v-if="isCollapsed" :class="'text-warning btn'" @click.prevent="toggleCollapse">
                            <i :class="'fa fa-caret-down'"></i>
                        </button>
                        <button v-if="!isCollapsed" :class="'text-warning btn'" @click.prevent="toggleCollapse">
                            <i :class="'fa fa-caret-up'"></i>
                        </button>
                    </span>
                </div>
            </div>
            <p v-if="content">
                <slot name="content">
                    {{ content }}
                </slot>
            </p>
            <div class="d-flex align-items-center">
                <slot name="footer">
                    {{ footer }}
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'CardSection',
    props: {
        title: {
            type: String,
            default: '',
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: null,
        },
        footerAction: {
            type: Object,
            default: null,
        },
        footer: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: null,
        },
        flag: {
            type: Boolean,
            default: false,
        },
        headerRight: {
            type: String,
            default: '',
        },
        headerAction: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isCollapsed: false,
        }
    },
    computed: {
        ...mapGetters('market', ['currentMarket']),
    },
    methods: {
        toggleCollapse() {
            if (!this.collapsed) return
            this.isCollapsed = !this.isCollapsed
            this.emitter.emit('toggleCollapse', this.isCollapsed)
        },
    },
}
</script>
<style></style>
