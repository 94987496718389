import ProductSearchService from '@/services/product-search'
import UserFilterService from '@/services/user-filter'
import { useToast } from 'vue-toast-notification'
import FollowingListService from '@/services/following-list'
import i18n from '../i18n'

const state = {
    filters: [],
    defaultFilter: null,
    scanningReports: [],
    scanningReportsProducts: [],
    selectedProduct: [],
    productChatHistory: [],
    calculatedPrices: null,
    categories: null,
    fpData: null,
    calculated: null,
}

const $toast = useToast()

const toastOptions = {
    position: 'top-right',
    duration: 5000,
    pauseOnHover: true,
    dismissible: true,
    closeButton: 'button',
    icon: true,
    iconPack: 'fontawesome',
}

const getters = {
    scanningReports: (state) => state.scanningReports,
    scanningReportsProducts: (state) => state.scanningReportsProducts,
    selectedProduct: (state) => state.selectedProduct,
    filters: (state) => state.filters,
    defaultFilter: (state) => state.defaultFilter,
    calculatedPrices: (state) => state.calculatedPrices,
    productChatHistory: (state) => state.productChatHistory,
    fpData: (state) => state.fpData,
    calculated: (state) => state.calculated,
    categories: (state) => state.categories,
}

const mutations = {
    SET_SCANNING_REPORTS(state, payload) {
        state.scanningReports = payload
    },
    SET_SCANNING_REPORTS_PRODUCTS(state, payload) {
        state.scanningReportsProducts = payload
    },
    SET_SELECTED_PRODUCT(state, payload) {
        state.selectedProduct = payload
    },
    SET_FILTERS(state, payload) {
        state.filters = payload
    },
    SET_DEFAULT_FILTER(state, payload) {
        state.defaultFilter = payload
    },
    SET_CALCULATED_PRICES(state, payload) {
        state.calculatedPrices = payload
    },
    SET_PRODUCT_CHAT_HISTORY(state, payload) {
        state.productChatHistory = payload
    },
    SET_FP_DATA(state, payload) {
        state.fpData = payload
    },
    SET_CALCULATED(state, payload) {
        state.calculated = payload
    },
    SET_CATEGORIES(state, payload) {
        state.categories = payload
    },
}

const actions = {
    getCategories({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.getCategories(payload)
                .then((response) => {
                    commit('SET_CATEGORIES', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setCategories({ commit }, payload) {
        commit('SET_CATEGORIES', payload)
    },
    getRealtimeData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.getRealtimeData(payload)
                .then((response) => {
                    commit('SET_FP_DATA', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getItemsKeepaData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.getItemsKeepaData(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    realTimeCalculator({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.realTimeCalculator(payload)
                .then((response) => {
                    commit('SET_CALCULATED', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createProductSearch({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.create(payload)
                .then((response) => {
                    resolve(response.data)
                    $toast.success(i18n.global.t('store.productScanningLaunched'), toastOptions)
                })
                .catch((error) => {
                    reject(error)
                    $toast.error(error.response.data.message, toastOptions)
                })
        })
    },
    getFilters({ commit }, payload) {
        return new Promise((resolve, reject) => {
            UserFilterService.findAll('inventory')
                .then((response) => {
                    commit('SET_FILTERS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    applyFilter({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.applyFilter(payload.id, payload.options)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message, toastOptions)
                    reject(error)
                })
        })
    },
    getScanningReports({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.findAll(payload)
                .then((response) => {
                    commit('SET_SCANNING_REPORTS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getScanningReportsProducts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.findAllProducts(payload.id, payload.params)
                .then((response) => {
                    commit('SET_SCANNING_REPORTS_PRODUCTS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.getProduct(payload)
                .then((response) => {
                    commit('SET_SELECTED_PRODUCT', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getFollowingProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            FollowingListService.getFollowingProduct(payload)
                .then((response) => {
                    commit('SET_SELECTED_PRODUCT', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setDefaultFilter({ commit }, payload) {
        commit('SET_DEFAULT_FILTER', payload)
    },
    calculatePrices({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.calculator(payload.id, payload.query, payload.params)
                .then((response) => {
                    commit('SET_CALCULATED_PRICES', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addToFollowingList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.addToFollowingList(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message, toastOptions)
                    reject(error)
                })
        })
    },
    deleteScanningReports({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.deleteScanningReports(payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    $toast.error(error.response.data.message, toastOptions)
                    reject(error)
                })
        })
    },
    askToAssistant({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.askToAssistant(payload.id, payload.data)
                .then((response) => {
                    commit('SET_PRODUCT_CHAT_HISTORY', response.data)

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getChatHistory({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ProductSearchService.getChatHistory(payload)
                .then((response) => {
                    commit('SET_PRODUCT_CHAT_HISTORY', response.data)

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
