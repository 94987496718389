<template>
    <div>
        <div id="supportFormModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title" id="accountAddCardModalLabel">{{ $t('menu.settings.support') }}</h2>
                        <button type="button" class="btn-close" @click="hideModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="alert alert-soft-warning text-primary" role="alert">Destek talebinizi iletirken mesajınız açıklayıcı, anlaşılır ve imla kurallarına uygun olmalıdır.</div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-4">
                                    <label for="cardNameLabel" class="form-label">İlgili Departman</label>
                                    <select v-model="department" class="form-select" :class="v$.department.$error && 'is-invalid'">
                                        <option value="">Seçim Yapın</option>
                                        <option value="Üyelik, Paket veya Token">Üyelik, Paket veya Token Hakkında</option>
                                        <option value="Ürün Arama ve Filtreler">Ürün Arama ve Filtreler Hakkında</option>
                                        <option value="FBA Koli Oluşturma">FBA Koli Oluşturma Hakkında</option>
                                        <option value="Talep ve Öneri">Talep ve Öneri Hakkında</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-4">
                                    <label for="cardNameLabel" class="form-label">Önem Durumu</label>
                                    <select v-model="priority" class="form-select" :class="v$.priority.$error && 'is-invalid'">
                                        <option value="">Seçim Yapın</option>
                                        <option value="Düşük">Düşük</option>
                                        <option value="Orta">Orta</option>
                                        <option value="Yüksek">Yüksek</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="mb-4">
                            <label for="cardNameLabel" class="form-label">Konu</label>
                            <input v-model="subject" type="text" class="form-control" :class="v$.subject.$error && 'is-invalid'" id="cardNameLabel" placeholder="Konu" />
                        </div>
                        <div class="mb-4">
                            <label for="cardNameLabel" class="form-label">Mesaj</label>
                            <textarea rows="5" v-model="message" type="text" class="form-control" :class="v$.message.$error && 'is-invalid'" id="cardNameLabel" placeholder="Mesajınız"> </textarea>
                        </div>
                        <div class="mb-4">
                            <label for="cardNameLabel" class="form-label">Dosya</label>
                            <input ref="fileInput" type="file" class="form-control" id="cardNameLabel" multiple placeholder="Mesajınız" />
                            <small>Birden fazla dosya ekleyebilirsiniz. Her bir dosya boyutu 5MB'dan küçük olmalıdır.</small>
                        </div>
                        <div class="d-flex justify-content-end gap-3">
                            <button type="button" class="btn btn-white" @click="hideModal">Vazgeç</button>
                            <button type="button" class="btn btn-primary" @click="handleSubmit">
                                <Spinner v-if="form_submitted" />
                                <span v-if="!form_submitted">Kaydet</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap'
import { useVuelidate } from '@vuelidate/core'

import { minLength, required } from 'vuelidate/lib/validators'
import AuthService from '@/services/auth'
import Spinner from '../Spinner.vue'
export default {
    name: 'SupportFormModal',
    setup() {
        return { v$: useVuelidate() }
    },
    components: { Spinner },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        form_submitted: false,
        modalInstance: null,
        form_id: Math.round(Math.random() * 1000),
        department: '',
        priority: '',
        subject: '',
        message: '',
    }),
    validations() {
        return {
            department: {
                required,
            },
            priority: {
                required,
            },
            subject: {
                required,
                minLength: minLength(2),
            },
            message: {
                required,
                minLength: minLength(3),
            },
        }
    },
    mounted() {
        this.emitter.on('modal:submitted', (response) => {
            if (response) {
                this.hideModal()
            }
            this.form_submitted = false
        })
    },
    computed: {},
    watch: {
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.form_submitted = false
                this.modalActive()
            }
        },
    },
    methods: {
        async handleSubmit() {
            const result = await this.v$.$validate()
            if (!result) return
            this.form_submitted = true

            const formData = new FormData()
            const fileInput = this.$refs.fileInput

            formData.append('department', this.department)
            formData.append('priority', this.priority)
            formData.append('subject', this.subject)
            formData.append('message', this.message)

            if (fileInput.files.length > 0) {
                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('files[]', fileInput.files[i])
                }
            }

            AuthService.postSupportForm(formData)
                .then((response) => {
                    this.$emit('modal:submitted', response)
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message)
                })
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('supportFormModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
        },
    },
}
</script>

<style scoped></style>
