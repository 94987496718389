<template>
    <div class="collapse navbar-collapse align-items-center" id="navbarContainerNavDropdown">
        <ul class="navbar-nav custom-navbar-links" style="padding-left: 2rem">
            <li class="nav-item dropdown">
                <button
                    type="button"
                    :class="{
                        active: $route.path.indexOf('product-search') > -1,
                    }"
                    class="btn nav-link dropdown-toggle"
                    href="#"
                    id="navbarLeftAlignedDropdownSubMenu"
                    role="button"
                    aria-expanded="false"
                >
                    <i class="bi-search dropdown-item-icon"></i>{{ $t('navbar.product_search.title') }}
                </button>
                <div class="dropdown-menu" aria-labelledby="navbarLeftAlignedDropdownSubMenu" style="min-width: 230px">
                    <router-link class="dropdown-item" to="/product-search">{{ $t('navbar.product_search.search') }}</router-link>
                    <router-link class="dropdown-item" to="/product-search/history">{{ $t('navbar.product_search.results') }}</router-link>
                    <router-link class="dropdown-item" to="/following-list">{{ $t('navbar.following_list.watchlist') }}</router-link>
                </div>
            </li>
            <li class="nav-item dropdown">
                <button
                    type="button"
                    :class="{
                        active: $route.path.indexOf('wholesale-search') > -1,
                    }"
                    class="btn nav-link dropdown-toggle"
                    href="#"
                    id="navbarLeftAlignedDropdownSubMenu"
                    role="button"
                    aria-expanded="false"
                >
                    <i class="bi-search dropdown-item-icon"></i>{{ $t('navbar.wholesale_search.title') }}
                </button>
                <div class="dropdown-menu" aria-labelledby="navbarLeftAlignedDropdownSubMenu" style="min-width: 230px">
                    <router-link class="dropdown-item" to="/wholesale-search">{{ $t('navbar.wholesale_search.search') }}</router-link>
                    <router-link class="dropdown-item" to="/wholesale-search/history">{{ $t('navbar.wholesale_search.results') }}</router-link>
                </div>
            </li>

            <li class="nav-item dropdown">
                <router-link class="nav-link" href="#" id="navbarLeftAlignedDropdownSubMenu" role="button" to="/extension-watchlist" aria-expanded="false"
                    ><i class="bi bi-list-check dropdown-item-icon"></i>{{ $t('navbar.extension_watchlist') }}</router-link
                >
            </li>
            <li class="nav-item dropdown">
                <router-link class="nav-link" href="#" id="navbarLeftAlignedDropdownSubMenu" role="button" to="/prep-request/new" aria-expanded="false"><i class="bi bi-box dropdown-item-icon"></i>{{ $t('navbar.prep_request') }}</router-link>
            </li>
            <li class="nav-item dropdown">
                <button
                    type="button"
                    :class="{
                        active: $route.path.indexOf('restricted-list') > -1,
                    }"
                    class="btn nav-link dropdown-toggle"
                    href="#"
                    id="navbarLeftAlignedDropdownSubMenu"
                    role="button"
                    aria-expanded="false"
                >
                    <i class="bi bi-shield-check dropdown-item-icon"></i>{{ $t('navbar.restricted_list.title') }}
                </button>
                <div class="dropdown-menu" aria-labelledby="navbarLeftAlignedDropdownSubMenu" style="min-width: 230px">
                    <router-link class="dropdown-item" to="/restricted-list/products">{{ $t('navbar.restricted_list.products') }}</router-link>
                    <router-link class="dropdown-item" to="/restricted-list/brands">{{ $t('navbar.restricted_list.brands') }}</router-link>
                    <router-link class="dropdown-item" to="/restricted-list/sellers">{{ $t('navbar.restricted_list.sellers') }}</router-link>
                    <div class="dropdown-divider"></div>
                    <router-link class="dropdown-item" to="/restricted-list/violation-pool/brands">{{ $t('navbar.restricted_list.violation_pool') }}</router-link>
                </div>
            </li>
        </ul>
        <div v-if="this.currentMarket" class="responsive-store">
            <MarketSelect :isCurrentMarket="true" @onSelect="handleSelect" :multiple="false" session></MarketSelect>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MarketSelect from '../Inputs/MarketSelect.vue'

export default {
    name: 'Navbar',
    components: {
        MarketSelect,
    },
    data() {
        return {
            isNavbarCollapsed: true,
        }
    },
    computed: {
        ...mapGetters('market', ['currentMarket']),
    },
    methods: {
        ...mapActions('market', ['setCurrentMarket', 'findOne']),
        async handleSelect(value) {
            if (value && value.id) {
                await this.$store.commit('set', { k: 'loading', v: { status: true } })
                const market = await this.findOne(value.id)
                await this.setCurrentMarket(market.data)
                await this.$store.commit('set', { k: 'loading', v: { status: false } })
                const params = this.$route.params

                let route = this.$route.fullPath
                if (Object.keys(params).length > 0) {
                    const param = '/' + Object.values(params)
                    route = route.replace(param, '')
                }
                window.location.href = route
            }
        },
        toggleNavbar() {
            this.isNavbarCollapsed = !this.isNavbarCollapsed
        },
    },
    watch: {
        '$route.query.marketId': {
            handler: function (value) {
                if (value) {
                    this.$router.push({ query: { ...this.$route.query, marketId: undefined } })
                    this.handleSelect({ id: value })
                }
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss">
.dropdown:hover > .dropdown-menu {
    display: block;
}
@media (width <= 768px) {
    .responsive-store {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.custom-navbar-links {
    button,
    .nav-link {
        color: var(--bs-nav-link-color) !important;
        border: none !important;
        font-size: 0.79rem !important;

        &.active {
            color: var(--bs-primary) !important;
        }
    }
    .router-link-active {
        color: var(--bs-primary) !important;
    }
}
</style>
