<script>
export default {
    name: 'ExtensionOffers',
    props: {
        offers: {
            type: Array,
            default: () => [],
        },
        link: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isFbaFbm: [],
        }
    },
    computed: {
        filteredOffers() {
            if (this.isFbaFbm.length > 0 && this.isFbaFbm.length < 2) {
                return this.offers.filter((item) => (this.isFbaFbm.includes('FBA') ? item.fullfilledByAmazon : !item.fullfilledByAmazon))
            }
            return this.offers
        },
    },
}
</script>

<template>
    <div v-if="offers && offers.length >= 20" class="alert alert-primary" role="alert" v-html="$t('views.extension.offerTwentyLimit', { link })"></div>
    <div class="table-offer">
        <table class="table">
            <thead class="thead-light">
                <tr>
                    <th>{{ $t('views.extension.store') }}</th>
                    <th>{{ $t('views.extension.price') }}</th>
                    <th></th>
                    <th>
                        FBA/FBM
                        <Popper class="light-popper" click placement="bottom" arrow style="cursor: pointer">
                            <template #content
                                ><div v-for="n in ['FBA', 'FBM']" :key="n" class="d-flex align-items-center gap-2 my-3">
                                    <label class="form-check-label" :for="`fbafbm_${n}`">{{ n }}</label>
                                    <input v-model="isFbaFbm" type="checkbox" :value="n" :id="`fbafbm_${n}`" class="form-check-input" /></div></template
                            ><i class="bi bi-caret-down-fill"></i
                        ></Popper>
                    </th>
                    <th>{{ $t('views.extension.new') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="offer in filteredOffers" :key="offer.id">
                    <td>
                        <a :href="`https://www.${$route.query.domain}/s?me=${offer.sellerId}`" target="_blank">{{ offer.sellerId }}</a> <br />
                        <star-rating :round-start-rating="false" :star-size="20" :padding="3" pointer="none" :rating="offer.sellerRating" :showRating="false" :totalRating="5"></star-rating>
                    </td>
                    <td>${{ offer.price }}</td>
                    <td><span v-if="offer.buyboxWinner" class="badge text-bg-warning">Buybox</span></td>
                    <td>
                        <template v-if="offer.fullfilledByAmazon">FBA</template>
                        <template v-if="!offer.fullfilledByAmazon">FBM</template>
                    </td>
                    <td><i v-if="offer.isNew" class="bi bi-check-lg text-success fs-4"></i> <i v-else class="bi bi-x-lg text-down"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
