<template>
    <transition name="fade">
        <div class="loading-screen" v-if="isLoading">
            <div class="content">
                <div class="loading-icon"></div>
                <div class="loading-text" v-if="loadingText">{{ loadingText }}</div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Loading',
    computed: {
        isLoading() {
            return this.$store.state.loading.status
        },
        loadingText() {
            return this.$store.state.loading.text
        },
    },
}
</script>

<style scoped lang="scss">
@keyframes spin {
    from {
        transform: rotate(0deg);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 2px #336af736;
    }
    to {
        transform: rotate(360deg);
        box-shadow: 0 0 0 35px rgba(255, 255, 255, 0), 0 0 0 35px rgba(255, 255, 255, 0);
    }
}

.loading-screen {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255);

    .content {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 45%;
        margin: auto;
        width: 35%;
        text-align: center;

        .loading-icon {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: solid 5px #eee;
            border-right-color: #fd9405;
            animation: spin 700ms infinite linear;
        }

        .loading-text {
            position: relative;
            z-index: 10;
            padding: 30px 15px;
        }
    }
}
</style>
