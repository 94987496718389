import { createI18n } from 'vue-i18n'
import tr from './locales/tr'
import en from './locales/en'

const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'tr', // set default locale
    // messages: loadLocaleMessages(),
    messages: {
        tr,
        en,
    },
    fallbackLocale: 'tr',
    globalInjection: true,
})

export default i18n
