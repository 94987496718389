<template>
    <div class="container-fluid pt-3">
        <div class="row justify-content-lg-center">
            <div class="col-lg-12" style="font-size: 0.8rem">
                <PageHeader :title="$t('cards.product_search.detail')" />
                <Card>
                    <div class="option-bar d-flex flex-column flex-sm-row justify-content-between w-100 mb-3" v-if="data">
                        <div class="option-wrapper">
                            <div class="row">
                                <div class="col">
                                    <div class="result-count">
                                        <span class="count">{{ data.total }}</span>
                                        <small>{{ $t('list.result') }}</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="search-form">
                                        <input v-model="searchText" class="form-control input-sm" type="text" @input="searchInput" v-debounce:300ms="handleSearchInput" :placeholder="$t('list.placeholder')" />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div class="col">
                                    <select v-model="requests.perPage" @change="changePerPage" class="form-select">
                                        <option value="10">10 {{ $t('list.showing') }}</option>
                                        <option value="25">25 {{ $t('list.showing') }}</option>
                                        <option value="50" selected>50 {{ $t('list.showing') }}</option>
                                        <option value="100">100 {{ $t('list.showing') }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="requests.page" @change="changeCurrentPage" class="form-select" v-if="data.last_page">
                                        <option v-if="[...Array(data.last_page).keys()].length === 0" value="">{{ $t('list.no_result') }}</option>
                                        <option :key="page" v-for="page of [...Array(data.last_page).keys()]" :value="page + 1">{{ page + 1 }}. {{ $t('list.page') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="action-wrapper">
                            <div class="d-flex flex-column flex-sm-row" style="justify-content: flex-end">
                                <a href="#" @click.prevent="showExcelExportModal = true" data-tooltip="Excel'e Aktar" class="btn mr-1 btn-sm btn-white"><i class="fa-solid fa-file-excel"></i></a>

                                <a href="#" @click.prevent="show_filters = !show_filters" data-tooltip="Özel Filtre" class="btn mr-1 btn-sm btn-white"><i class="fa-solid fa-filter"></i></a>

                                <button id="dropdownMenuClickableInside" class="dropdown btn btn-white btn-sm d-flex align-items-center mr-1" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style="min-height: 37.5px">
                                    <i class="fa-solid fa-columns"></i>
                                </button>
                                <div
                                    class="dropdown-menu dropdown-menu-end dropdown-menu-bottom navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                                    aria-labelledby="dropdownMenuClickableInside"
                                    data-popper-placement="bottom-end"
                                    style="overflow: auto; height: 300px"
                                >
                                    <template :key="column" v-for="column in Object.keys(columns)">
                                        <li style="padding-bottom: 10px">
                                            <input class="input form-check-input" style="height: 15px !important; width: 15px !important; vertical-align: middle" type="checkbox" :id="'li_' + column" v-model="columns[column]" /><label
                                                :for="'li_' + column"
                                                style="margin-left: 5px; cursor: pointer; vertical-align: middle"
                                                >{{ $t('list.product_search.columns.' + column) }}</label
                                            >
                                        </li>
                                    </template>
                                </div>
                                <button :disabled="selectedList.length === 0" style="min-height: 37.5px" class="dropdown btn btn-secondary btn-sm d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="true">
                                    <span>{{ $t('common.actions') }}</span>
                                    <span style="margin-left: 2px; font-weight: 600" v-if="selectedList.length > 0">({{ selectedList.length }})</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" data-popper-placement="top-end">
                                    <a class="dropdown-item" href="#" @click.prevent="handleToggleAcions">{{ $t('actions.add_to_following_list') }}</a>
                                    <a class="dropdown-item" href="#" @click.prevent="handleCreatePrepRequest">{{ $t('actions.create_package') }}</a>
                                    <a v-if="keepaAvailable" class="dropdown-item" href="#" @click.prevent="showDialog = true">{{ $t('actions.get_keepa_data') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Filters v-if="show_filters" :keepaDomainAvailable="keepaAvailable" detailed @handleFilterFormChange="handleFilterFormChange" />
                    <div class="selected-filters d-flex w-100 mb-3" style="padding-left: 2rem" v-if="this.requests && this.requests.filtration && this.requests.filtration.length > 0">
                        <span>{{ $t('common.filtered') }}</span>
                        <div style="font-size: 16px; padding-left: 1rem">
                            <span class="badge bg-soft-primary text-primary mr-05" v-for="filtration in this.requests.filtration" :key="filtration.key">
                                <a href="#" @click.prevent="handleRemoveFilteration(filtration.key)">
                                    <i class="fa-solid fa-times"></i>
                                </a>
                                {{ getFilterName(filtration.key) }}: {{ getFilterValue(filtration.value, filtration.key === 'srp.status') }}</span
                            >
                        </div>
                    </div>
                    <div class="col-12 relative" v-if="data">
                        <div class="table-container">
                            <table class="table table-sticky table-fixed">
                                <thead class="thead-light">
                                    <tr ref="tableHeads">
                                        <th colspan="3" class="sticky-left"></th>
                                        <th v-if="getColumnsCount(['title', 'price']) > 0" :colspan="getColumnsCount(['title', 'price'])"></th>
                                        <th
                                            v-if="getColumnsCount(['product_cost', 'warehouse_cost', 'total_cost']) > 0"
                                            :colspan="getColumnsCount(['product_cost', 'warehouse_cost', 'total_cost'])"
                                            class="align-middle text-center column-border"
                                            style=""
                                        >
                                            {{ $t('list.product_search.groups.costs') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fba__profit', 'fba__profit_rate', 'fba__profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fba__profit', 'fba__profit_rate', 'fba__profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fba_gross_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fba__net_profit', 'fba__roi', 'fba__net_profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fba__net_profit', 'fba__roi', 'fba__net_profit_margin'])"
                                            class="align-middle text-center column-border"
                                        >
                                            {{ $t('list.product_search.groups.fba_net_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fbm__profit', 'fbm__profit_rate', 'fbm__profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fbm__profit', 'fbm__profit_rate', 'fbm__profit_margin'])"
                                            class="align-middle text-center column-border-left"
                                        >
                                            {{ $t('list.product_search.groups.fbm_gross_profit') }}
                                        </th>
                                        <th
                                            v-if="getColumnsCount(['fbm__net_profit', 'fbm__roi', 'fbm__net_profit_margin']) > 0"
                                            :colspan="getColumnsCount(['fbm__net_profit', 'fbm__roi', 'fbm__net_profit_margin'])"
                                            class="align-middle text-center column-border-left"
                                        >
                                            {{ $t('list.product_search.groups.fbm_net_profit') }}
                                        </th>
                                        <th :colspan="getColumnsCount(['bestsellers_rank', 'category', 'main_category', 'brand', 'is_amazon_seller', 'variations', 'price_diff_rate'])"></th>
                                        <th :colspan="getColumnsCount(['fba_sellers_count', 'fbm_sellers_count'])" class="align-middle text-center column-border-left">
                                            {{ $t('list.product_search.groups.seller_count') }}
                                        </th>
                                        <th :colspan="getColumnsCount(['buybox_status', 'buybox_seller'])" class="align-middle text-center column-border-left">
                                            {{ $t('list.product_search.groups.buybox') }}
                                        </th>
                                        <th :colspan="getColumnsCount(['width', 'length', 'height', 'volume', 'max_item_on_package_weight'])" class="align-middle text-center column-border-left">
                                            {{ $t('list.product_search.groups.dimensions') }}
                                        </th>
                                        <th colspan="2" class="align-middle text-center column-border-left">
                                            {{ $t('list.product_search.groups.estimated_amount') }}
                                        </th>
                                        <th colspan="2"></th>
                                        <th colspan="1" class="sticky-right"></th>
                                    </tr>
                                    <tr>
                                        <th class="align-middle sticky-left">
                                            <input class="form-check-input" @change="handleCheckboxChange" type="checkbox" />
                                        </th>
                                        <th class="sticky-left" style="left: 47.5px !important" scope="col">
                                            {{ $t('list.product_search.columns.image') }}
                                        </th>
                                        <th class="sticky-left" style="left: 118px !important" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" type="text" data-column="srp.asin" id="col_asin" autofocus :placeholder="$t('list.product_search.columns.asin')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('asin')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.asin') }}
                                        </th>
                                        <th v-if="columns.title" class="position-relative fixed-width-column" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" type="text" data-column="details.title" id="col_title" autofocus :placeholder="$t('list.product_search.columns.title')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('title')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.title') }}
                                        </th>
                                        <th v-if="columns.price" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_price_min" placeholder="Min" />
                                                        <input class="form-control" id="col_price_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('price', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'price',
                                                        event: $event,
                                                        sort: {
                                                            column: 'srp.price',
                                                            dir: columnSorting.price ? columnSorting.price.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.price') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.price && columnSorting.price.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.product_cost" class="position-relative text-center" style="border-left: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_product_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_product_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('product_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'product_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'product_cost',
                                                            dir: columnSorting.product_cost ? columnSorting.product_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.product_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.product_cost && columnSorting.product_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.warehouse_cost" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_warehouse_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_warehouse_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('warehouse_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'warehouse_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'warehouse_cost',
                                                            dir: columnSorting.warehouse_cost ? columnSorting.warehouse_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.warehouse_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.warehouse_cost && columnSorting.warehouse_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.total_cost" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_total_cost_min" placeholder="Min" />
                                                        <input class="form-control" id="col_total_cost_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('total_cost', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'total_cost',
                                                        event: $event,
                                                        sort: {
                                                            column: 'total_cost',
                                                            dir: columnSorting.total_cost ? columnSorting.total_cost.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.total_cost') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.total_cost && columnSorting.total_cost.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit',
                                                            dir: columnSorting.profit ? columnSorting.profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit && columnSorting.profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit_rate',
                                                            dir: columnSorting.profit_rate ? columnSorting.profit_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit_rate && columnSorting.profit_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'profit_margin',
                                                            dir: columnSorting.profit_margin ? columnSorting.profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.profit_margin && columnSorting.profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__net_profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_net_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_net_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('net_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'net_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'net_profit',
                                                            dir: columnSorting.net_profit ? columnSorting.net_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.net_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.net_profit && columnSorting.net_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__roi" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_roi_min" placeholder="Min" />
                                                        <input class="form-control" id="col_roi_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('roi', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'roi',
                                                        event: $event,
                                                        sort: {
                                                            column: 'roi',
                                                            dir: columnSorting.roi ? columnSorting.roi.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.roi') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.roi && columnSorting.roi.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fba__net_profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_net_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_net_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('net_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'net_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'net_profit_margin',
                                                            dir: columnSorting.net_profit_margin ? columnSorting.net_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.net_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.net_profit_margin && columnSorting.net_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <!-- FBM Profits-->
                                        <th v-if="columns.fbm__profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit',
                                                            dir: columnSorting.fbm_profit ? columnSorting.fbm_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit && columnSorting.fbm_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__profit_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit_rate',
                                                            dir: columnSorting.fbm_profit_rate ? columnSorting.fbm_profit_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit_rate && columnSorting.fbm_profit_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__profit_margin" class="position-relative text-center" style="border-right: 1px solid rgba(231, 234, 243, 0.7)" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_profit_margin',
                                                            dir: columnSorting.fbm_profit_margin ? columnSorting.fbm_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_profit_margin && columnSorting.fbm_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__net_profit" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_net_profit_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_net_profit_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_net_profit', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_net_profit',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_net_profit',
                                                            dir: columnSorting.fbm_net_profit ? columnSorting.fbm_net_profit.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_net_profit') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_net_profit && columnSorting.fbm_net_profit.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__roi" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_roi_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_roi_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_roi', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_roi',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_roi',
                                                            dir: columnSorting.fbm_roi ? columnSorting.fbm_roi.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_roi') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_roi && columnSorting.fbm_roi.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm__net_profit_margin" class="position-relative text-center column-border-right" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_net_profit_margin_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_net_profit_margin_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_net_profit_margin', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_net_profit_margin',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_net_profit_margin',
                                                            dir: columnSorting.fbm_roi ? columnSorting.fbm_net_profit_margin.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_net_profit_margin') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_net_profit_margin && columnSorting.fbm_net_profit_margin.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.price_diff_rate" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_price_diff_rate_min" placeholder="Min" />
                                                        <input class="form-control" id="col_price_diff_rate_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('price_diff_rate', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'price_diff_rate',
                                                        event: $event,
                                                        sort: {
                                                            column: 'price_diff_rate',
                                                            dir: columnSorting.price_diff_rate ? columnSorting.price_diff_rate.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.price_diff_rate') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.price_diff_rate && columnSorting.price_diff_rate.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.variations" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_variations_min" placeholder="Min" />
                                                        <input class="form-control" id="col_variations_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('variations', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'variations',
                                                        event: $event,
                                                        sort: {
                                                            column: 'variations',
                                                            dir: columnSorting.variations ? columnSorting.variations.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.variations') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.variations && columnSorting.variations.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.bestsellers_rank" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_bestsellers_rank_min" placeholder="Min" />
                                                        <input class="form-control" id="col_bestsellers_rank_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('bestsellers_rank', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'bestsellers_rank',
                                                        event: $event,
                                                        sort: {
                                                            column: 'bestsellers_rank',
                                                            dir: columnSorting.bestsellers_rank ? columnSorting.bestsellers_rank.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.bestsellers_rank') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.bestsellers_rank && columnSorting.bestsellers_rank.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.category" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_category" type="text" data-column="details.category" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('category')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.category') }}
                                        </th>
                                        <th v-if="columns.main_category" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_main_category" data-column="details.main_category" type="text" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('main_category')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.main_category') }}
                                        </th>
                                        <th v-if="columns.brand" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <input class="form-control filtering-input" id="col_brand" type="text" data-column="details.brand" :placeholder="$t('list.product_search.columns.category')" />
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('brand')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            {{ $t('list.product_search.columns.brand') }}
                                        </th>
                                        <th v-if="columns.is_amazon_seller" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.is_amazon_seller') }}
                                        </th>
                                        <th v-if="columns.fba_sellers_count" class="position-relative text-center column-border-left" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fba_sellers_count_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fba_sellers_count_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fba_sellers_count', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fba_sellers_count',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fba_sellers_count',
                                                            dir: columnSorting.fba_sellers_count ? columnSorting.fba_sellers_count.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fba_sellers_count') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fba_sellers_count && columnSorting.fba_sellers_count.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.fbm_sellers_count" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_fbm_sellers_count_min" placeholder="Min" />
                                                        <input class="form-control" id="col_fbm_sellers_count_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('fbm_sellers_count', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'fbm_sellers_count',
                                                        event: $event,
                                                        sort: {
                                                            column: 'fbm_sellers_count',
                                                            dir: columnSorting.fbm_sellers_count ? columnSorting.fbm_sellers_count.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.fbm_sellers_count') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.fbm_sellers_count && columnSorting.fbm_sellers_count.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.buybox_status" class="position-relative text-center column-border-left" scope="col">
                                            {{ $t('list.product_search.columns.buybox_status') }}
                                        </th>
                                        <th v-if="columns.buybox_seller" class="position-relative text-center column-border-left" scope="col">
                                            {{ $t('list.product_search.columns.buybox_seller') }}
                                        </th>
                                        <th v-if="columns.width" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.width') }}
                                        </th>
                                        <th v-if="columns.height" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.height') }}
                                        </th>
                                        <th v-if="columns.length" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.length') }}
                                        </th>
                                        <th v-if="columns.weight" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.weight') }}
                                        </th>
                                        <th v-if="columns.volume" class="position-relative text-center column-border-right" scope="col">
                                            {{ $t('list.product_search.columns.volume') }}
                                        </th>
                                        <th v-if="columns.max_item_on_package_weight" class="position-relative text-center" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_max_item_on_package_weight_min" placeholder="Min" />
                                                        <input class="form-control" id="col_max_item_on_package_weight_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('max_item_on_package_weight', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'max_item_on_package_weight',
                                                        event: $event,
                                                        sort: {
                                                            column: 'max_item_on_package_weight',
                                                            dir: columnSorting.max_item_on_package_weight ? columnSorting.max_item_on_package_weight.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.max_item_on_package_weight') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.max_item_on_package_weight && columnSorting.max_item_on_package_weight.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.max_item_on_package_dim" class="position-relative text-center column-border-right" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <div class="input-group">
                                                        <input class="form-control" type="text" id="col_max_item_on_package_dim_min" placeholder="Min" />
                                                        <input class="form-control" id="col_max_item_on_package_dim_max" type="text" placeholder="Max" />
                                                    </div>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('max_item_on_package_dim', 'between')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'max_item_on_package_dim',
                                                        event: $event,
                                                        sort: {
                                                            column: 'max_item_on_package_dim',
                                                            dir: columnSorting.max_item_on_package_dim ? columnSorting.max_item_on_package_dim.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.max_item_on_package_dim') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.max_item_on_package_dim && columnSorting.max_item_on_package_dim.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.first_available" class="position-relative text-center" scope="col">
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'first_available',
                                                        event: $event,
                                                        sort: {
                                                            column: 'first_available',
                                                            dir: columnSorting.first_available ? columnSorting.first_available.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.first_available') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.first_available && columnSorting.first_available.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                        <th v-if="columns.search_other_stores" class="position-relative text-center" scope="col">
                                            {{ $t('list.product_search.columns.search_other_stores') }}
                                        </th>
                                        <th v-if="columns.status" class="text-center sticky-right" scope="col">
                                            <font-awesome-icon class="dropdown" data-bs-toggle="dropdown" aria-expanded="true" :icon="['fas', 'filter']" style="margin-right: 5px; cursor: pointer; color: #fd9405" size="sm" />
                                            <div class="filtering-input-wrapper2 dropdown-menu dropdown-menu-start dropdown-menu-bottom navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account">
                                                <div class="d-flex">
                                                    <select id="col_status" class="form-select">
                                                        <option value="available">{{ $t('common.available') }}</option>
                                                        <option value="not_available">{{ $t('common.not_available') }}</option>
                                                    </select>
                                                    <button class="btn btn-primary btn-sm" @click="handleFilterColumn('status')">
                                                        <font-awesome-icon :icon="['fas', 'filter']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span
                                                @click="
                                                    sortAble().changeSortDir({
                                                        index: 'status',
                                                        event: $event,
                                                        sort: {
                                                            column: 'IF(srp.failed_filters=\'[]\',1,-1)',
                                                            dir: columnSorting.status ? columnSorting.status.dir : 'asc',
                                                        },
                                                    })
                                                "
                                                style="margin-right: 3px; cursor: pointer"
                                                >{{ $t('list.product_search.columns.status') }}

                                                <font-awesome-icon :icon="['fas', columnSorting.price && columnSorting.price.dir === 'asc' ? 'angle-down' : 'angle-up']" size="sm" />
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr
                                        :class="{
                                            bgdefault: true,
                                            disabled: !item.id || !item.is_available,
                                            bgdanger: item.failed_filters && item.failed_filters.length > 0,
                                            bgsuccess: item.failed_filters && item.failed_filters.length === 0,
                                            bgdisabled: !item.id || !item.is_available,
                                        }"
                                        v-for="item in data.data"
                                        :key="item.id"
                                    >
                                        <template v-if="false">
                                            <td class="align-middle sticky-left" colspan="100">
                                                <span class="span-skeleton"> </span>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td class="align-middle sticky-left">
                                                <input ref="checkbox" :checked="selectedList.indexOf(item.id) > -1" @change="handleCheckboxSingleChange" :value="item.id" class="form-check-input" type="checkbox" />
                                            </td>
                                            <td class="align-middle sticky-left" style="left: 47.5px !important" scope="row">
                                                <span v-if="item.image" class="avatar avatar-sm">
                                                    <a href="#" @click.prevent="openModal(item)"> <img :src="item.image" class="avatar-img" /></a>
                                                </span>
                                                <span v-else>-</span>
                                            </td>
                                            <td class="align-middle sticky-left fw-bold" style="left: 118px !important" scope="row">
                                                <Popper v-if="item.is_restricted_asin" placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> <span v-html="$t('list.product_search.warnings.is_restricted_asin')"></span></template>
                                                    <a :href="item.sourceLink" target="_blank"
                                                        ><span class="text-danger" style="cursor: pointer; font-weight: 600">{{ item.requested_asin }} <i class="fas fa-exclamation-circle"></i></span
                                                    ></a>
                                                </Popper>
                                                <Popper v-else-if="item.requested_asin !== item.asin" placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> <span v-html="$t('list.product_search.warnings.asins_not_equal', { requested_asin: item.requested_asin, asin: item.asin })"></span></template>
                                                    <a :href="item.sourceLink" target="_blank"
                                                        ><span class="text-danger" style="cursor: pointer; font-weight: 600">{{ item.requested_asin }} <i class="fas fa-exclamation-circle"></i></span
                                                    ></a>
                                                </Popper>
                                                <a v-else-if="!item.is_restricted_asin && item.requested_asin" :href="item.sourceLink" target="_blank">{{ item.requested_asin }}</a>
                                                <span v-else>-</span>
                                                <KeepaLogo
                                                    v-if="item.target_keepa_data"
                                                    :color="'#256a93'"
                                                    :style="{
                                                        marginTop: '-2px',
                                                        marginLeft: '7px',
                                                    }"
                                                    :width="15"
                                                    :height="15"
                                                />
                                            </td>
                                            <td v-if="columns.title" class="fw-bold align-middle fixed-width-column two-line-text" scope="row">
                                                <a v-if="item.title" target="_blank" :href="item.targetLink">
                                                    {{ strLimit(item.title, 35) }}
                                                </a>
                                                <span v-else>-</span>
                                            </td>
                                            <td v-if="columns.price" class="align-middle text-center" scope="row">
                                                <span v-if="!item.price">-</span>
                                                <ExchangablePrice v-else :rate="this.filterParameters ? this.filterParameters.exchange_rate : null" :price="item.price" :to="item.target" :from="item.source" />
                                            </td>
                                            <td v-if="columns.product_cost" class="align-middle text-center" scope="row">
                                                <span v-if="!item.product_cost">-</span>
                                                <CalculatedPrices
                                                    v-else-if="item.product_cost_detail"
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(item.product_cost_detail).map((pcd) => ({ key: pcd, value: item.product_cost_detail[pcd] }))"
                                                    :price="item.product_cost"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.warehouse_cost" class="align-middle text-center" scope="row">
                                                <span v-if="!item.warehouse_cost">-</span>
                                                <CalculatedPrices
                                                    v-else-if="item.warehouse_cost_detail"
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(item.warehouse_cost_detail).map((pcd) => ({ key: pcd, value: item.warehouse_cost_detail[pcd] }))"
                                                    :price="item.warehouse_cost"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.total_cost" class="align-middle text-center" scope="row">
                                                <span v-if="!item.total_cost">-</span>
                                                <ExchangablePrice v-else :rate="this.filterParameters ? this.filterParameters.exchange_rate : null" :price="item.total_cost" :to="item.target" :from="item.source" />
                                            </td>
                                            <td v-if="columns.fba__profit" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.profit">-</span>
                                                <CalculatedPrices
                                                    v-else-if="item.profit_detail"
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(item.profit_detail).map((pcd) => ({ key: pcd, value: item.profit_detail[pcd] }))"
                                                    :price="item.profit"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.fba__profit_rate" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.profit_rate">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.profit_rate < 0,
                                                        'text-success': item.profit_rate > 0,
                                                    }"
                                                    >{{ item.profit_rate.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fba__profit_margin" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.profit_margin">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.profit_margin < 0,
                                                        'text-success': item.profit_margin > 0,
                                                    }"
                                                    >{{ item.profit_margin.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fba__net_profit" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.net_profit">-</span>
                                                <CalculatedPrices
                                                    v-else
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(getNetProfit(item)).map((pcd) => ({ key: pcd, value: getNetProfit(item)[pcd] }))"
                                                    :price="item.net_profit"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.fba__roi" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.roi">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.roi < 0,
                                                        'text-success': item.roi > 0,
                                                    }"
                                                    >{{ item.roi.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fba__net_profit_margin" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.net_profit_margin">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.net_profit_margin < 0,
                                                        'text-success': item.net_profit_margin > 0,
                                                    }"
                                                    >{{ item.net_profit_margin.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <!-- fbm-->
                                            <td v-if="columns.fbm__profit" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_profit">-</span>
                                                <CalculatedPrices
                                                    v-else-if="item.fbm_profit_detail || item.fbm_profit"
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(item.fbm_profit_detail).map((pcd) => ({ key: pcd, value: item.fbm_profit_detail[pcd] }))"
                                                    :price="item.fbm_profit"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.fbm__profit_rate" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_profit_rate">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.fbm_profit_rate < 0,
                                                        'text-success': item.fbm_profit_rate > 0,
                                                    }"
                                                    >{{ (+item.fbm_profit_rate).toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fbm__profit_margin" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_profit_margin">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.fbm_profit_margin < 0,
                                                        'text-success': item.fbm_profit_margin > 0,
                                                    }"
                                                    >{{ (+item.fbm_profit_margin).toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fbm__net_profit" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_net_profit">-</span>
                                                <CalculatedPrices
                                                    v-else-if="item.fbm_net_profit"
                                                    :rate="this.filterParameters ? this.filterParameters.exchange_rate : null"
                                                    :items="Object.keys(getFbmNetProfit(item)).map((pcd) => ({ key: pcd, value: getFbmNetProfit(item)[pcd] }))"
                                                    :price="item.fbm_net_profit"
                                                    :to="item.target"
                                                    :from="item.source"
                                                />
                                            </td>
                                            <td v-if="columns.fbm__roi" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_roi">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.fbm_roi < 0,
                                                        'text-success': item.fbm_roi > 0,
                                                    }"
                                                    >{{ item.fbm_roi.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.fbm__net_profit_margin" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.fbm_net_profit_margin">-</span>
                                                <strong
                                                    v-else
                                                    :class="{
                                                        'text-danger': item.fbm_net_profit_margin < 0,
                                                        'text-success': item.fbm_net_profit_margin > 0,
                                                    }"
                                                    >{{ item.fbm_net_profit_margin.toFixed(2) }}%</strong
                                                >
                                            </td>
                                            <td v-if="columns.price_diff_rate" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.price_diff_rate">-</span>
                                                <strong v-else>{{ item.price_diff_rate.toFixed(2) }}</strong>
                                            </td>
                                            <td v-if="columns.variations" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.variations">-</span>
                                                <span v-else>{{ item.variations }}</span>
                                            </td>
                                            <td v-if="columns.bestsellers_rank" class="align-middle text-center fw-bold" scope="row">
                                                <span v-if="!item.is_available || !item.bestsellers_rank || item.bestsellers_rank === '-'">-</span>
                                                <Popper v-else placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> {{ item.bsr_category }} </template>
                                                    <span class="text-warning" style="cursor: pointer; font-weight: 600">{{ item.bestsellers_rank }}</span>
                                                </Popper>
                                            </td>
                                            <td v-if="columns.category" class="align-middle text-center fw-bold" scope="row">
                                                <span v-if="!item.is_available || !item.category">-</span>
                                                <Popper v-else placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> {{ item.categories_flat }} </template>
                                                    <span class="text-warning" style="cursor: pointer; font-weight: 600">{{ item.category }}</span>
                                                </Popper>
                                            </td>
                                            <td v-if="columns.main_category" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.main_category">-</span>
                                                <span v-else>{{ item.main_category }}</span>
                                            </td>
                                            <td v-if="columns.brand" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.brand">-</span>
                                                <Popper v-else-if="item.is_restricted_brand" placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                    <template #content> {{ $t('list.product_search.warnings.is_restricted_brand') }} </template>
                                                    <span class="text-danger" style="cursor: pointer; font-weight: 600">{{ item.brand }} <i class="fas fa-exclamation-circle"></i></span>
                                                </Popper>
                                                <span v-else>{{ item.brand }}</span>
                                            </td>
                                            <td v-if="columns.is_amazon_seller" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available">-</span>
                                                <strong v-else>{{ item.is_amazon_seller ? $t('actions.yes') : $t('actions.no') }}</strong>
                                            </td>
                                            <td v-if="columns.fba_sellers_count" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available">-</span>
                                                <span v-else>{{ item.fba_sellers_count }}</span>
                                            </td>
                                            <td v-if="columns.fbm_sellers_count" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available">-</span>
                                                <span v-else>{{ item.fbm_sellers_count }}</span>
                                            </td>
                                            <td v-if="columns.buybox_status" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available">-</span>
                                                <span v-else v-html="getBuyboxStatus(item)" />
                                            </td>
                                            <td v-if="columns.buybox_seller" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.buybox_seller">-</span>
                                                <a v-else :href="item.buybox_seller_link" target="_blank"
                                                    ><span>{{ item.buybox_seller }}</span></a
                                                >
                                            </td>
                                            <td v-if="columns.width" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.width">-</span>
                                                <span v-else>{{ item.width.toFixed(2) }} in</span>
                                            </td>
                                            <td v-if="columns.height" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.height">-</span>
                                                <span v-else>{{ item.height.toFixed(2) }} in</span>
                                            </td>
                                            <td v-if="columns.length" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.length">-</span>
                                                <span v-else>{{ item.length.toFixed(2) }} in</span>
                                            </td>
                                            <td v-if="columns.weight" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.weight">-</span>
                                                <span v-else>{{ item.weight.toFixed(2) }} {{ $t('common.metricUnit') }}</span>
                                            </td>
                                            <td v-if="columns.volume" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.volume">-</span>
                                                <span v-else>{{ item.volume.toFixed(2) }} {{ $t('common.metricUnit') }}</span>
                                            </td>
                                            <td v-if="columns.max_item_on_package_weight" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.max_item_on_package_weight">-</span>
                                                <span v-else>{{ item.max_item_on_package_weight }} {{ $t('common.quantity') }}</span>
                                            </td>
                                            <td v-if="columns.max_item_on_package_dim" class="align-middle text-center" scope="row">
                                                <span v-if="!item.is_available || !item.max_item_on_package_dim">-</span>
                                                <span v-else>{{ item.max_item_on_package_dim }} {{ $t('common.quantity') }}</span>
                                            </td>
                                            <td v-if="columns.first_available" class="align-middle" scope="row">
                                                <span v-if="!item.is_available || !item.first_available">-</span>
                                                <span v-else>{{ formatDate(item.first_available) }}</span>
                                            </td>
                                            <td v-if="columns.search_other_stores" class="align-middle" scope="row">
                                                <span v-if="!item.title">-</span>
                                                <template v-else>
                                                    <a :href="'https://www.alibaba.com/trade/search?tab=all&searchText=' + item.title" target="_blank" style="margin-right: 3px"
                                                        ><img :src="require('@/assets/svg/stores/alibaba.svg')" alt="" width="25" height="20"
                                                    /></a>
                                                    <a :href="'https://www.walmart.com/search?q=' + item.title" target="_blank" style="margin-right: 3px"><img :src="require('@/assets/svg/stores/walmart.svg')" alt="" width="25" height="20" /></a>
                                                </template>
                                            </td>
                                            <td v-if="columns.status" class="align-middle text-center sticky-right" scope="row">
                                                <span
                                                    class="badge bg-info"
                                                    v-if="
                                                        item.filtering_step &&
                                                        (!item.target_detail_step || !item.target_offers_step || !item.target_dimension_step || !item.source_detail_step || !item.source_offers_step || !item.source_dimension_step)
                                                    "
                                                    >Taranıyor</span
                                                >
                                                <span class="badge bg-info" v-else-if="!item.filtering_step">Filtreleniyor</span>
                                                <span v-else-if="item.status === 'unavailable' || !item.is_available" class="badge bg-danger">{{ $t('list.product_search.warnings.not_found') }}</span>
                                                <span v-else-if="getStatuses(item).length > 0">
                                                    <Popper placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                        <template #content>
                                                            <ul style="padding: 0.5rem">
                                                                <li v-for="statuses in getStatuses(item)" :key="statuses">{{ statuses }}</li>
                                                            </ul>
                                                        </template>
                                                        <span class="badge bg-warning" style="font-weight: 600; cursor: pointer">{{ $t('list.product_search.warnings.not_found') }} <i class="fas fa-exclamation-circle"></i></span> </Popper
                                                    ><br />
                                                </span>
                                                <span class="badge bg-danger" v-else-if="!item.status">{{ $t('list.product_search.warnings.not_found') }}</span>
                                                <span class="badge bg-danger" v-else-if="item.status === 'failed_on_dimension'">{{ $t('list.product_search.warnings.not_found_dimension') }}</span>
                                                <span v-else-if="getFailedFilters(item).length > 0">
                                                    <Popper placement="left" v-bind="$attrs" hover arrow :class="'popp'">
                                                        <template #content>
                                                            <ul style="padding: 0.5rem">
                                                                <li v-for="failedFilter in getFailedFilters(item)" :key="failedFilter">{{ $t('list.product_search.statuses.' + failedFilter) }}</li>
                                                            </ul>
                                                        </template>
                                                        <span class="badge bg-danger" style="font-weight: 600; cursor: pointer">{{ $t('list.product_search.warnings.failed_filters') }} <i class="fas fa-exclamation-circle"></i></span> </Popper
                                                    ><br />
                                                </span>
                                                <span class="badge bg-success" v-else>{{ $t('list.product_search.warnings.no_errors') }}</span>
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    </div>
    <AddtoFollowingListModal :showModal="isShowModal" :selectedItems="selectedList" :scanningType="scanningType" @closeModal="isShowModal = false" />
    <ProductDetailModal :showModal="showModal" @closeModal="showModal = false" />
    <ExcelExportModal
        :id="$route.params.id"
        :entityType="'scanning_report'"
        :query="querystring"
        :columns="
            Object.keys(columns)
                .filter((column) => column !== 'search_other_stores')
                .map((column) => {
                    return {
                        key: column,
                        label: $t('list.product_search.columns.' + column),
                        isDefault: defaultColumns.indexOf(column) > -1,
                    }
                })
        "
        :selectedItems="selectedList"
        :showModal="showExcelExportModal"
        @closeModal="showExcelExportModal = false"
    />
    <Dialog
        :options="{ title: $t('dialogs.get_keepa_data.title'), description: $t('dialogs.get_keepa_data.description', { token: selectedList && selectedList.length > 0 ? selectedList.length * 2 : 0 }) }"
        :showModal="showDialog"
        @closeModal="showDialog = false"
        @handleAccept="handleGetKeepaData"
    />
    <Dialog
        :name="'errorDialog'"
        :actionYesTitle="$t('actions.ok')"
        :hideCancel="true"
        :options="{ title: $t('dialogs.error_keepa_data.title'), description: $t('dialogs.error_keepa_data.description') }"
        :showModal="showErrorDialog"
        @closeModal="showErrorDialog = false"
    />
</template>

<script>
import Card from '@/components/Layouts/Card.vue'
import { convertObjectToQueryString, countryInfo } from '@/utils/helper'
import { mapActions } from 'vuex'
import ProductDetailModal from '@/components/ProductSearch/ProductDetailModal.vue'
import AddtoFollowingListModal from '@/components/ProductSearch/AddtoFollowingListModal.vue'
import ExcelExportModal from '@/components/ProductSearch/ExcelExportModal.vue'
import moment from 'moment'
import Filters from '@/components/Filters'
import PrepRequestService from '@/services/prep_requests'
import PageHeader from '@/components/Layouts/PageHeader.vue'
import KeepaLogo from '@/components/KeepaLogo.vue'
import Dialog from '@/components/Variations/Dialog.vue'

const countries = countryInfo()

export default {
    name: 'ProductSearchHistory',
    components: {
        Card,
        ProductDetailModal,
        AddtoFollowingListModal,
        Filters,
        PageHeader,
        ExcelExportModal,
        KeepaLogo,
        Dialog,
    },
    data: function () {
        return {
            completed: false,
            show_filters: false,
            toggleActions: false,
            toggleColumns: false,
            showDialog: false,
            showErrorDialog: false,
            defaultColumns: [
                'asin',
                'title',
                'price',
                'product_cost',
                'warehouse_cost',
                'total_cost',
                'fba__profit',
                'fba__profit_rate',
                'fba__profit_margin',
                'fba__net_profit',
                'fba__roi',
                'fba__net_profit_margin',
                'price_diff_rate',
                'bestsellers_rank',
                'is_amazon_seller',
                'fba_sellers_count',
                'fbm_sellers_count',
                'buybox_status',
                'buybox_seller',
                'width',
                'height',
                'length',
                'weight',
                'volume',
                'max_item_on_package_weight',
                'max_item_on_package_dim',
                'status',
            ],
            showExcelExportModal: false,
            selectedList: [],
            columnFilters: {},
            columnSorting: {},
            data: null,
            reportParameters: null,
            filterOptions: null,
            stats: null,
            querystring: '',
            loading: false,
            showModal: false,
            selectedFilters: null,
            isShowModal: false,
            scanningType: null,
            keepaAvailable: false,
            searchText: '',
            requests: {
                page: 1,
                perPage: 50,
                sort: [],
                filtration: [],
            },
            columns: {
                asin: true,
                title: true,
                price: true,
                product_cost: true,
                warehouse_cost: true,
                total_cost: true,
                fba__profit: true,
                fba__profit_rate: true,
                fba__profit_margin: true,
                fba__net_profit: true,
                fba__roi: true,
                fba__net_profit_margin: true,
                fbm__profit: true,
                fbm__profit_rate: true,
                fbm__profit_margin: true,
                fbm__net_profit: true,
                fbm__roi: true,
                fbm__net_profit_margin: true,
                price_diff_rate: true,
                variations: true,
                bestsellers_rank: true,
                category: true,
                main_category: true,
                brand: true,
                is_amazon_seller: true,
                fba_sellers_count: true,
                fbm_sellers_count: true,
                buybox_status: true,
                buybox_seller: true,
                width: true,
                height: true,
                length: true,
                weight: true,
                volume: true,
                max_item_on_package_weight: true,
                max_item_on_package_dim: true,
                first_available: true,
                search_other_stores: true,
                status: true,
            },
        }
    },
    mounted() {
        this.prepareData()
    },
    beforeUnmount() {
        this.setDefaultFilter(null)
    },
    methods: {
        ...mapActions('productSearch', ['getScanningReportsProducts', 'getItemsKeepaData', 'getProduct', 'setDefaultFilter']),
        handleToggleAcions() {
            this.toggleActions = !this.toggleActions
            this.isShowModal = true
        },
        async handleCreatePrepRequest() {
            const response = await PrepRequestService.create('product-search', {
                product_ids: this.selectedList,
            })
            if (response.data) {
                this.$router.push({
                    name: 'create-prep-request',
                })
            }
        },
        getImage(src) {
            return require('@/assets/svg-flag/' + src)
        },
        search: function () {
            this.page = 1
            this.requests.page = 1
            this.querystringChanged(this.requests)
        },
        handleGetKeepaData: async function (asin) {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                await this.getItemsKeepaData({
                    items: this.selectedList,
                })
                await this.prepareData()
            } catch (e) {
                this.showErrorDialog = true
            } finally {
                this.showDialog = false
                this.selectedList = []
                // uncheck all checkboxes
                const allInputs = this.$refs.checkbox
                allInputs.forEach((input) => {
                    input.checked = false
                })
                this.$store.commit('set', { k: 'loading', v: { status: false } })
            }
        },
        handleFilterColumn(id, condition = '=') {
            let value = ''
            let column = ''

            this.columnFilters[id] = false
            if (condition === 'between') {
                const value_1 = document.getElementById(`col_${id}_min`).value
                const value_2 = document.getElementById(`col_${id}_max`).value

                column = document.getElementById(`col_${id}_min`).getAttribute('data-column') || 'srp.' + id

                value = [value_1, value_2]
            } else {
                value = document.getElementById(`col_${id}`).value
                column = document.getElementById(`col_${id}`).getAttribute('data-column') || 'srp.' + id
            }
            const findSelectedFilterIndex = this.requests.filtration.findIndex((item) => {
                return item.key.toString() === column
            })

            if (value.length > 0) {
                if (findSelectedFilterIndex === -1) {
                    this.requests.filtration.push({
                        key: column,
                        value,
                        condition,
                    })
                } else {
                    this.requests.filtration[findSelectedFilterIndex].value = value
                }
            } else {
                this.requests.filtration = this.requests.filtration.filter((item) => item.key !== column)
            }
            this.search()
        },
        sortAble: function () {
            return {
                changeSortDir: (item) => {
                    const sort = [
                        {
                            dir: this.sortAble().dirToggle(item.index, item.sort.dir),
                            column: item.sort.column,
                        },
                    ]
                    this.requests.sort = sort
                    this.page = 1
                    this.requests.page = 1
                    this.querystringChanged(this.requests)
                },
                dirExists: (dir) => {
                    if (typeof dir == 'undefined') dir = 'asc'
                    return dir
                },
                dirToggle: (index, dir) => {
                    let newDir = this.sortAble().dirExists(dir)
                    newDir = newDir === 'asc' ? 'desc' : 'asc'
                    if (typeof this.columnSorting[index] === 'undefined') this.columnSorting[index] = {}

                    this.columnSorting[index].dir = newDir

                    console.log(this.columnSorting)
                    return newDir
                },
            }
        },
        handleCheckboxChange: function (e) {
            const checked = e.target.checked
            const allInputs = this.$refs.checkbox

            if (checked) {
                allInputs.forEach((input) => {
                    if (input.value && input.value !== 'on') {
                        console.log(input.value)
                        if (!this.selectedList.includes(input.value)) {
                            this.selectedList.push(input.value)
                            input.checked = true
                        }
                    }
                })
            } else {
                this.selectedList = []
                allInputs.forEach((input) => {
                    input.checked = false
                })
            }
        },
        handleCheckboxSingleChange: function (e) {
            const value = e.target.value
            const checked = e.target.checked

            if (checked) {
                this.selectedList.push(value)
                // eslint-disable-next-line vue/no-mutating-props
            } else {
                this.selectedList = this.selectedList.filter((item) => item !== value)
                // eslint-disable-next-line vue/no-mutating-props
            }
        },
        searchInput: function (e) {
            this.requests.search = e.target.value
        },
        handleSearchInput: function () {
            this.page = 1
            this.requests.page = 1
            this.querystringChanged(this.requests)
        },
        async openModal(item) {
            if (!item.id) return
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            await this.getProduct(item.id)
            this.$store.commit('set', { k: 'loading', v: { status: false } })
            this.showModal = true
        },
        strLimit(str, limit = 50) {
            return str.length > limit ? str.substring(0, limit) + '...' : str
        },
        async prepareData() {
            this.loading = true
            const id = this.$route.params.id
            this.$store.commit('set', { k: 'loading', v: { status: true, text: '' } })

            const response = await this.getScanningReportsProducts({
                id,
                params: this.querystring,
            })
            this.data = response.data

            if (this.data.data && this.data.data.length > 0) {
                this.filterParameters = response.scanningReport.parameters
                const short = this.data.data[0].target
                if (short) {
                    const domain = countryInfo()[short].domain
                    const availableDomains = ['amazon.com', 'amazon.co.uk', 'amazon.de', 'amazon.fr', 'amazon.co.jp', 'amazon.ca', 'amazon.it', 'amazon.es', 'amazon.in', 'amazon.com.mx']
                    this.keepaAvailable = availableDomains.includes(domain)
                }
                this.scanningType = response.scanningReport.search_type
                this.filterOptions = {
                    filter_id: response.scanningReport.filter_id,
                    id: response.scanningReport.id,
                    filter: response.scanningReport.options,
                }
            }
            if (response.stats) {
                this.stats = response.stats
            }
            this.completed = response.completed
            this.loading = false
            this.$store.commit('set', { k: 'loading', v: { status: false, text: '' } })
        },
        changePerPage: function (e) {
            this.page = 1
            this.requests.page = 1
            this.requests.perPage = e.target.value
            this.querystringChanged(this.requests)
        },
        changeCurrentPage: function (e) {
            this.page = e.target.value
            this.requests.page = e.target.value
            this.querystringChanged(this.requests)
        },
        querystringChanged(data) {
            this.querystring = convertObjectToQueryString(data)
            this.prepareData()
        },
        getCurrency(short) {
            return countries[short].currency
        },
        formatDate(date, format = 'DD.MM.YYYY') {
            if (!date) return '-'
            return moment(date).format(format)
        },
        getNetProfit(item) {
            return {
                profit: item.profit,
                bank_transfer_fee: item.bank_transfer_fee,
                estimated_refund_amount: item.estimated_refund_amount,
            }
        },
        getFbmNetProfit(item) {
            return {
                profit: item.fbm_profit,
                bank_transfer_fee: item.bank_transfer_fee,
                estimated_refund_amount: item.estimated_refund_amount,
            }
        },
        getBuyboxStatus(item) {
            const parse = item.buybox_status

            let buybox = this.$t('common.not_exists')
            if (parse.amazon) buybox = 'Amazon'
            else if (parse.fba) buybox = 'FBA'
            else if (parse.fbm) buybox = 'FBM'

            return '<strong>' + buybox + '</strong>'
        },
        getStatuses(item) {
            const statuses = item.statuses
            const uniqueStatuses = [...new Set(statuses)]

            return uniqueStatuses
        },
        getFailedFilters(item) {
            const failedFilters = item.failed_filters || []
            return failedFilters
        },
        getColumnsCount(arr) {
            let count = 0
            arr.forEach((column) => {
                if (this.columns[column]) count++
            })
            return count
        },
        async handleRemoveFilteration(key) {
            // remove filtration
            this.requests.filtration = this.requests.filtration.filter((item) => item.key !== key)

            await this.search()
        },
        getFilterName(key) {
            const filterName = key.replace('srp.', '').replace('sp.', '').replace('details.', '')

            return this.$t('list.product_search.columns.' + filterName)
        },
        getFilterValue(value, translated = false) {
            if (typeof value === 'string') {
                return translated ? this.$t('common.' + value) : value
            }

            if (value && value.filter((v) => !!v).length === 2) {
                return value.join(' - ')
            }

            if (value && value.length > 0) {
                const min = value[0]
                const max = value[1]

                if (min && !max) {
                    return `>= ${min}`
                }

                if (!min && max) {
                    return `<= ${max}`
                }
            }
        },
    },
    created() {
        const columns = localStorage.getItem('product_search_columns') || null
        if (columns) {
            this.columns = JSON.parse(columns)
        }
    },
    watch: {
        page() {
            this.prepareData()
        },
        filterOptions: {
            handler(val) {
                if (val) {
                    this.setDefaultFilter(val)
                }
            },
            deep: true,
            immediate: true,
        },
        columns: {
            handler: function (to, from) {
                localStorage.setItem('product_search_columns', JSON.stringify(to))
            },
            deep: true,
        },
    },
}
</script>

<style>
.span-skeleton {
    width: 100%;
    height: 13px;
    display: block;
    animation: pulse-bg 1s infinite;
    border-radius: 2px;
}
@keyframes pulse-bg {
    0% {
        background-color: #fafafb;
    }
    50% {
        background-color: #eaeaea;
    }
    100% {
        background-color: #fafafb;
    }
}
.fixed-width-column {
    max-width: 170px;
    min-width: 170px;
}
.two-line-text {
    display: table-cell; /* İçeriği iki satıra bölmek için */
    word-break: break-all;
    white-space: normal;
    -webkit-line-clamp: 2; /* İki satıra bölme */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.costs_column:first-child {
    border-left: 1px solid rgba(231, 234, 243, 0.7) !important;
}
.costs_column:last-child {
    border-left: 1px solid rgba(231, 234, 243, 0.7) !important;
}
.table tr:first-child > * {
    border-bottom-width: 0 !important;
}
</style>
