<template>
    <div class="navbar-nav-wrap-secondary-content">
        <ul class="navbar-nav">
            <!-- <li class="nav-item d-none d-md-inline-block">
                <Notification></Notification>
            </li> -->
            <li class="nav-item">
                <LanguageSwitcher></LanguageSwitcher>
            </li>
            <li class="nav-item">
                <ProfileNav></ProfileNav>
            </li>
        </ul>
    </div>
</template>

<script>
// import Notification from '@/components/Header/Notification.vue'
import ProfileNav from '@/components/Header/ProfileNav.vue'
import LanguageSwitcher from '@/components/Header/LanguageSwitcher.vue'

export default {
    name: 'SecondaryContent',
    components: {
        // Notification,
        ProfileNav,
        LanguageSwitcher,
    },
}
</script>
