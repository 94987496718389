<template>
    <div id="AddNoteModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" style="margin-right: 5px" class="btn-close" @click="hideModal"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="filter-item-col">
                            <textarea v-model="note" class="form-control" placeholder="Not" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary btn-sm" @click="handleAddNote">{{ $t('actions.save') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'AddtoFollowingListModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        noteSelectedItem: {
            default: null,
        },
        defaultNote: {
            default: null,
        },
    },
    components: {},
    data: () => ({
        form_submitted: false,
        modalInstance: null,
        note: null,
    }),
    mounted() {
        this.refresh()
    },
    computed: {
        ...mapGetters('followingList', ['followingList']),
    },
    watch: {
        showModal(newValue, oldValue) {
            if (newValue === true) {
                this.modalActive()
            }
        },
        defaultNote(newValue, oldValue) {
            this.note = newValue
        },
    },
    methods: {
        ...mapActions('followingList', ['updateFollowingListItem']),
        refresh() {
            this.note = this.defaultNote || ''
        },
        async handleAddNote() {
            this.$store.commit('set', { k: 'loading', v: { status: true } })
            try {
                await this.updateFollowingListItem({
                    id: this.noteSelectedItem,
                    data: {
                        note: this.note,
                    },
                })
                this.$store.commit('set', { k: 'loading', v: { status: false } })
                this.$toast.success('Güncellendi')
                this.refresh()
                this.hideModal()
            } catch (e) {
                this.$toast.success(this.$t('toast.error_accured'))
            }
        },
        modalActive: function () {
            this.modalInstance = new Modal(document.getElementById('AddNoteModal'), {
                target: '#my-modal',
                backdrop: 'static',
                keyboard: false,
            })
            this.modalInstance.show()
        },
        hideModal: function () {
            this.modalInstance.hide()
            this.$emit('closeModal')
            this.refresh()
        },
    },
}
</script>
