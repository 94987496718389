import { ApiInstance } from './base'

const SettingsService = {
    getSystemSettings() {
        return ApiInstance.get('/system-settings')
    },
    updateScanningOptions(payload) {
        return ApiInstance.post('/scanning-options', payload)
    },
    getScanningOptions(payload) {
        return ApiInstance.get('/scanning-options/' + payload.type + '/' + payload.marketSourceId)
    },
    getMarketExchangeRates() {
        return ApiInstance.get('/scanning-options/exchange-rates')
    },
    getExchangeRates() {
        return ApiInstance.get('/scanning-options/default-exchange-rates')
    },
    setMarketExchangeRates(payload) {
        return ApiInstance.post('/scanning-options/exchange-rates', payload)
    },
    setDefaultParameters(payload) {
        return ApiInstance.post('/scanning-options/default-parameters', payload)
    },
}

export default SettingsService
