import { createStore } from 'vuex'
import auth from './auth'
import market from './market'
import extension from './extension'
import settings from './settings'
import wallet from './wallet'
import payment from './payment'
import restrictedList from './restricted-list'
import followingList from './following-list'
import system from './system'
import productSearch from './product-search'

const store = createStore({
    state: {
        loading: {},
    },
    modules: {
        auth,
        market,
        extension,
        settings,
        wallet,
        productSearch,
        payment,
        system,
        restrictedList,
        followingList,
    },
    getters: {
        get(state) {
            return state
        },
    },
    mutations: {
        set(state, value) {
            state[value.k] = value.v
            if (value.storage) localStorage.setItem(value.k, value.v)
        },
        clear(state, value) {
            state[value.k] = null
            if (value.storage) localStorage.removeItem(value.k)
        },
    },
})

export default store
